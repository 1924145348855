import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {Claim} from "../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import {EmployeeCompensation} from "../../../../model/domestic-helper/employee-compensation.model";

@Component({
  selector: 'app-employee-compensation-detail',
  templateUrl: './employee-compensation-detail.component.html',
  styleUrls: ['./employee-compensation-detail.component.css']
})

export class EmployeeCompensationDetailComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    employeeCompensationForm: FormGroup;
    claim: Claim;
    employeeCompensation: EmployeeCompensation;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.employeeCompensation = this.claim.getClaimDomesticHelper().getEmployeeCompensation();
    }

    ngOnInit() {

        this.pushGAViewEmployeeCompensationDetail();

        // set default values - country and currency
        if(this.employeeCompensation.getCountryOccurredDesc() == null || this.employeeCompensation.getCountryOccurredDesc() == ""){
            this.employeeCompensation.setCountryOccurredDesc("HONG KONG");
            this.employeeCompensation.setCountryOccurred("HKG");
        }
        if(this.employeeCompensation.getTotalAmountCurrency() == null || this.employeeCompensation.getTotalAmountCurrency() == ""){
            this.employeeCompensation.setTotalAmountCurrency("HKD")
        }

        if (!this.employeeCompensation.getDateOfIncident()) {
            this.employeeCompensation.setDateOfIncident(this.claim.getDateOfLoss());
        }

        this.employeeCompensationForm = this.fb.group({
            countryOccured: [this.employeeCompensation.getCountryOccurred()],
            dateOfIncident: [this.employeeCompensation.getDateOfIncident()],
            descriptionOfIncident: [this.employeeCompensation.getDescriptionOfIncident()],
            totalAmountCurrency: [this.employeeCompensation.getTotalAmountCurrency()],
            totalAmountValue: [this.employeeCompensation.getTotalAmountValue()]
        });
    }

    ngAfterViewInit() {
        this.employeeCompensationForm.valueChanges.subscribe(data => {
            this.employeeCompensation.setCountryOccurred(super.getValueInComponent('countryOccured'));
            this.employeeCompensation.setCountryOccurredDesc(this.getTextInComponent('countryOccured'));
            this.employeeCompensation.setDateOfIncident(super.getValueInComponent('dateOfIncident'));
            this.employeeCompensation.setDescriptionOfIncident(super.getValueInComponent('descriptionOfIncident'));
            this.employeeCompensation.setTotalAmountCurrency(this.employeeCompensationForm.get('totalAmountCurrency').value);
            this.employeeCompensation.setTotalAmountValue(super.getValueInComponent('totalAmountValue'));
        });

    }



    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 6, subMenuIndex: 0});
            this.router.navigate(["/claimform/employeeCompensation/supportDoc"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.employeeCompensationForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }


    //Google Analytics
    pushGAViewEmployeeCompensationDetail() {
        (<any>window).dataLayer.push({
            'pageStep': 'Employee Compensation – Incident Details',
            'vPath': '/claim/employee-compensation/incident-details',
            'event': 'vpageview',
            'ecommerce': {
              'checkout': {
                  'actionField': {'step': 5},	//Step5-ClaimDetailsStart
              }
            }                                                                                                                  
        });
    }

}
