import {Component, Injector, OnInit} from "@angular/core";
import {SideMenuService} from "../../../../services/side-menu.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {DeathAndSeriousBodyInjuryLoss} from "../../../../model/travel/death-and-serious-body-injury-loss";
import {UtilitiesService} from "../../../../utilities/utilities.service";
@Component({
    selector: 'app-accident-medical-bills',
    templateUrl: './accident-medical-bills.component.html',
    styleUrls: ['./accident-medical-bills.component.css']
})
export class AccidentMedicalBillsComponent extends ClaimsBaseComponent implements OnInit {
    medicalBillForm: FormGroup;
    wasVisitedpractitioner: boolean;
    showTotalBox = false;
    deathAndSeriousBodyInjuryLoss: DeathAndSeriousBodyInjuryLoss;

    constructor(private router: Router, private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.deathAndSeriousBodyInjuryLoss = this.claim.getClaimTravel().getDeathAndSeriousBodyInjuryLoss();
    }

    ngOnInit() {

        this.pushGAViewAccidentMedBills();
        if(this.deathAndSeriousBodyInjuryLoss.getMedicalExpenseType() == null){
            this.deathAndSeriousBodyInjuryLoss.setMedicalExpenseType(UtilitiesService.getCountryCurrency(this.claim.getCountry()));
        }

        this.medicalBillForm = this.fb.group(
            {
                numberOfTreatmentBill: [this.deathAndSeriousBodyInjuryLoss.getNumberOfTreatmentBill()],
                otherNumOfTreatmentBill: [this.deathAndSeriousBodyInjuryLoss.getOtherNumOfTreatmentBill()],
                medicalExpenseType: [this.deathAndSeriousBodyInjuryLoss.getMedicalExpenseType()],
                medicalExpenseTypeAmount: [this.deathAndSeriousBodyInjuryLoss.getMedicalExpenseTypeAmount()]
            }
        );
    }

    ngAfterViewInit() {
        this.medicalBillForm.valueChanges.subscribe(data => {
            this.deathAndSeriousBodyInjuryLoss.setNumberOfTreatmentBill(super.getTextInComponent('numberOfTreatmentBill'));
            this.deathAndSeriousBodyInjuryLoss.setOtherNumOfTreatmentBill(super.getTextInComponent('otherNumOfTreatmentBill'));
            this.deathAndSeriousBodyInjuryLoss.setMedicalExpenseType(this.medicalBillForm.get('medicalExpenseType').value);
            this.deathAndSeriousBodyInjuryLoss.setMedicalExpenseTypeAmount(super.getTextInComponent('medicalExpenseTypeAmount'));

            this.validateClaimDetailsComplete();
        })
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }


    back() {
        if (this.deathAndSeriousBodyInjuryLoss.getNatureOfIllness().toLowerCase() == 'death by Accident'.toLowerCase() ||
            this.deathAndSeriousBodyInjuryLoss.getNatureOfIllness().toLowerCase() == 'death due to sickness'.toLowerCase()) {
            this.router.navigate(['/claimform/personalAccident/detailOfOversea'], {
                relativeTo: this.activatedRoute
            });

        } else {
            this.router.navigate(['/claimform/personalAccident/followUpTreatment'], {
                relativeTo: this.activatedRoute
            });
        }
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 13, subMenuIndex: 3});
            this.router.navigate(["/claimform/personalAccident/accidentSupportDoc/uploadDocument"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.medicalBillForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.medicalBillForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitClaimComplete({claimTypeId: 13, subMenuIndex: 3});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 13, subMenuIndex: 3});
        }
    }

    //Google Analytics
    pushGAViewAccidentMedBills() {
        (<any>window).dataLayer.push({
            'pageStep': 'Serious body injury & Death – Medical Bills',
            'vPath': '/claim/serious-injury-death/medical-bills',
            'event': 'vpageview',
        });
    }

}
