import {Component, ElementRef, Injector, OnInit, ViewChild} from "@angular/core";
import {SideMenuService} from "../../../../services/side-menu.service";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {TripCancellation} from "../../../../model/travel/trip-cancellation.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import { UtilitiesService } from "src/app/utilities/utilities.service";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UICountryService } from "src/app/ui/ui-country.service";

@Component({
  selector: 'app-detail-of-cancel-trip',
  templateUrl: './detail-of-cancel-trip.component.html',
  styleUrls: ['./detail-of-cancel-trip.component.css']
})
export class DetailOfCancelTripComponent extends ClaimsBaseComponent implements OnInit {

    @ViewChild('lossType', {static: true}) el: ElementRef;
    detailOfCancelForm: FormGroup;
    multiSelectForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    tripCancellation: TripCancellation;
    dropdownList = [];
    selectedItems = [];
    dropdownSettings:IDropdownSettings;
    pleaseSelect = '';
    requiredMultiSelectField: boolean = false;

    constructor(private fb: FormBuilder, 
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private uiCountryService: UICountryService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.tripCancellation = this.claim.getClaimTravel().getTripCancellation();
        this.pleaseSelect = this.translate.instant('claimSection.generalLabels.placeHolders.pleaseSelect');

        if (this.tripCancellation.getCountryOccurred() === null) {
            this.tripCancellation.setCountryOccurred([]);
        }

        if (this.tripCancellation.getCountryOccurredDesc() === null) {
            this.tripCancellation.setCountryOccurredDesc([]);
        }
    }

    ngOnInit() {

        this.pushGAViewTripCancelDetails();

        // default date of cancel trip
        if(UtilitiesService.isNullOrUndefined(this.tripCancellation.getCancelDate()) &&
            !UtilitiesService.isNullOrUndefined(this.claim.getDateOfLoss())){
            this.tripCancellation.setCancelDate(this.claim.getDateOfLoss());
        }

        this.tripCancellation.setCountryOfLoss(this.claim.getCountry());
        this.tripCancellation.setCountryOfLossDesc(UtilitiesService.getCountryDesc(this.claim.getCountry()));

        this.detailOfCancelForm = this.fb.group({
            dateOriginBook: [this.tripCancellation.getOriginalBookDate()],
            dateCancelTrip: [this.tripCancellation.getCancelDate()],
            reasonForCancel: [this.tripCancellation.getCancelTripReason()],
            otherReason: [this.tripCancellation.getOtherCancelTripReason()],
            countryOfLoss: [this.tripCancellation.getCountryOfLoss()],
            countryOccurred: [this.tripCancellation.getCountryOccurred()]
        });

        this.multiSelectForm = this.fb.group({
            countries: [this.selectedItems, Validators.required],
        });

        this.populateCountryMultiSelectDropdown();
    }

    ngAfterViewInit() {

        this.detailOfCancelForm.valueChanges.subscribe(data => {

            this.tripCancellation.setOriginalBookDate(super.getValueInComponent('dateOriginBook'));
            this.tripCancellation.setCancelDate(super.getValueInComponent('dateCancelTrip'));
            this.tripCancellation.setCancelTripReason(super.getValueInComponent('reasonForCancel'));
            this.tripCancellation.setCancelTripReasonDesc(super.getTextInComponent('reasonForCancel'));
            this.tripCancellation.setOtherCancelTripReason(super.getValueInComponent('otherReason'));
            this.validateClaimDetailsComplete();
        });

        // Stop validation of fields when not required.
        let self = this;

        setTimeout(function () {
            self.hideValidationReasonForCancel(self.tripCancellation.getCancelTripReason()), 10
        });
        this.detailOfCancelForm.get('reasonForCancel').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideValidationReasonForCancel(data);
        });
    }

    populateCountryMultiSelectDropdown() {
        let countryService: UICountryService = new UICountryService();
        let list = [];

        countryService.getCountryOptions().forEach(country => {
            list.push({value: this.uiCountryService.getCountryDescription(country.value),
                label: this.translate.instant(country.label) });
        });

        this.dropdownList = list;

        this.dropdownSettings = {
            singleSelection: false,
            idField: 'value',
            textField: 'label',
            allowSearchFilter: false,
            enableCheckAll: false,
            limitSelection: 3,
        };

        let countrySelectedCount = this.tripCancellation.getCountryOccurred().length;
        if (countrySelectedCount > 0) {
            for (let i = 0; i < countrySelectedCount; i++) {
                this.selectedItems.push({value: this.tripCancellation.getCountryOccurred()[i],
                    label: this.tripCancellation.getCountryOccurredDesc()[i]});
            }
        }
    }

    onItemSelect(item: any) {
        this.setClass();
        this.tripCancellation.getCountryOccurred().push(item.value);
        this.tripCancellation.getCountryOccurredDesc().push(item.label);
    }

    onDeSelect(item: any) {
        this.setClass();
        this.tripCancellation.setCountryOccurred(this.tripCancellation.getCountryOccurred().filter(countries => countries !== item.value));
        this.tripCancellation.setCountryOccurredDesc(this.tripCancellation.getCountryOccurredDesc().filter(countries => countries !== item.label));
    }

    setClass() : string {
        this.requiredMultiSelectField = this.tripCancellation.getCountryOccurred().length > 0;
        if (this.requiredMultiSelectField) {
            return 'validField';
        } else { 
            return 'invalidField';
        }
    }

    hideValidationReasonForCancel(data) {
        if (data == 'Others') {
            this.detailOfCancelForm.get('otherReason').enable({onlySelf: false, emitEvent: false});
        } else {
            this.detailOfCancelForm.get('otherReason').reset();
            this.detailOfCancelForm.get('otherReason').disable({onlySelf: false, emitEvent: false});
        }
    }

    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/claimDetail"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        let isMultiSelectValid = this.validateMultiSelectForm();
        if (this.validateForm() && isMultiSelectValid) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 6, subMenuIndex: 0});
            this.router.navigate(["/claimform/tripCancellation/lossCauseByCancellation"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.detailOfCancelForm);
    }

    validateMultiSelectForm(): boolean {
        if (!this.multiSelectForm.valid) {
            this.multiSelectForm.markAllAsTouched();
            this.multiSelectForm.updateValueAndValidity({emitEvent: false});
        }
        return!(this.validateMultiSelect());
    }

    validateMultiSelect(): boolean {
        return this.multiSelectForm.touched && (!this.multiSelectForm.value.countries || this.multiSelectForm.value.countries.length == 0);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.detailOfCancelForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitClaimComplete({claimTypeId: 6, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 6, subMenuIndex: 0});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewTripCancelDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Trip Cancellation – Trip Details',
            'vPath': '/claim/trip-cancellation/trip-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }

}
