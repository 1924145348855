import {Injectable} from "@angular/core";
import {ClaimTypeItem} from "../model/claim-type-item.model";
import {ClaimTypes} from "../model/claim-type.model";
import {ClaimService} from "./claim.service";
import {Claim} from "../model/claim.model";
import {TransactionInfo} from "../model/transaction-info.model";
import {PlanHelperService} from "./plan-helper.service";
import { UtilitiesService } from "../utilities/utilities.service";
import {PLAN_TYPES} from '../model/travel/plan-types.constants';

@Injectable()
export class ClaimTypeItemService {

    travelClaimItemList: ClaimTypeItem[] = [
        {
            "claimTypeId": 0,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL],
            "claimTypeIdName": "claimDetails.claimTypeName.travelDelayed",
            "description": "",
            "icon": "assets/images/travel-delay.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 1,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST],
            "claimTypeIdName": "claimDetails.claimTypeName.lostPersonalItem",
            "description": "",
            "icon": "assets/images/lost-personal-item.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 2,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG],
            "claimTypeIdName": "claimDetails.claimTypeName.dmgPersonalItem",
            "description": "",
            "icon": "assets/images/damanged-personal-item.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 3,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_BDEL],
            "claimTypeIdName": "claimDetails.claimTypeName.baggageDelay",
            "description": "",
            "icon": "assets/images/baggage-delay.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 4,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_VISIT],
            "claimTypeIdName": "claimDetails.claimTypeName.visitedDoctor",
            "description": "",
            "icon": "assets/images/visited-doctor.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 5,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_STY],
            "claimTypeIdName": "claimDetails.claimTypeName.stayedInHosp",
            "description": "",
            "icon": "assets/images/stayed-at-hospital.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 6,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN],
            "claimTypeIdName": "claimDetails.claimTypeName.tripCancellation",
            "description": "",
            "icon": "assets/images/trip-cancellation.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 7,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_UNKNOWN],
            "claimTypeIdName": "claimDetails.claimTypeName.otherClaim",
            "description": "",
            "icon": "assets/images/if_other_2126131.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 8,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_CRTL],
            "claimTypeIdName": "claimDetails.claimTypeName.tripCurtailment",
            "description": "",
            "icon": "assets/images/trip-curtailment.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 9,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_RVE],
            "claimTypeIdName": "claimDetails.claimTypeName.rentalVehicle",
            "description": "",
            "icon": "assets/images/rental-vehicle.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 10,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_RROUT],
            "claimTypeIdName": "claimDetails.claimTypeName.travelReRoute",
            "description": "",
            "icon": "assets/images/travel-reroute.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 11,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_MSEVNT],
            "claimTypeIdName": "claimDetails.claimTypeName.missedEvent",
            "description": "",
            "icon": "assets/images/missed-event.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 12,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL_MC],
            "claimTypeIdName": "claimDetails.claimTypeName.missedConnection",
            "description": "",
            "icon": "assets/images/missed-connection.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 13,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_DTH],
            "claimTypeIdName": "claimDetails.claimTypeName.deathSeriousBodyInjury",
            "description": "",
            "icon": "assets/images/personal-accident.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 14,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_LIAB],
            "claimTypeIdName": "claimDetails.claimTypeName.persLiab",
            "description": "",
            "icon": "assets/images/private-liability.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 15,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_OTH],
            "claimTypeIdName": "claimDetails.claimTypeName.notSure",
            "description": "",
            "icon": "assets/images/not-sure.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        }
    ];

    domesticHelperClaimItemList: ClaimTypeItem[] = [
        {
            "claimTypeId": 0,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_CLINICAL],
            "claimTypeIdName": "domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.title",
            "description": "",
            "icon": "assets/images/visited-doctor.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 1,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DENTAL],
            "claimTypeIdName": "domesticHelperClaim.dmhClaimTypeDetails.dentalExpenses.title",
            "description": "",
            "icon": "assets/images/domestic-helper-icon/dental.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 2,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_UNKNOWN],
            "claimTypeIdName": "pageSummaryClaim.claimItem.sectionOther.title",
            "description": "",
            "icon": "assets/images/domestic-helper-icon/others.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 3,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HOSPITAL],
            "claimTypeIdName": "domesticHelperClaim.dmhClaimTypeDetails.hospitalization.title",
            "description": "",
            "icon": "assets/images/stayed-at-hospital.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 4,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DEATH],
            "claimTypeIdName": "domesticHelperClaim.dmhClaimTypeDetails.deathOrDisability.title",
            "description": "",
            "icon": "assets/images/domestic-helper-icon/permanent-disability-or-death.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 5,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HELP_LIABILITY],
            "claimTypeIdName": "domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.title",
            "description": "",
            "icon": "assets/images/domestic-helper-icon/helper-liability.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 6,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_EMP_LIABILITY],
            "claimTypeIdName": "domesticHelperClaim.dmhClaimTypeDetails.employeeCompensation.title",
            "description": "",
            "icon": "assets/images/domestic-helper-icon/employee-compensation.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 7,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DISHONESTY],
            "claimTypeIdName": "domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.title",
            "description": "",
            "icon": "assets/images/domestic-helper-icon/dishonesty.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 8,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_REPATRIATION],
            "claimTypeIdName": "domesticHelperClaim.dmhClaimTypeDetails.repatriation.title",
            "description": "",
            "icon": "assets/images/domestic-helper-icon/repatriation.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 9,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_OTHERS],
            "description": "",
            "claimTypeIdName": "claimDetails.claimTypeName.notSure",
            "icon": "assets/images/not-sure.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        }

    ];

    motorClaimItemList: ClaimTypeItem[] = [
        {
            "claimTypeId": 0,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_WINDSCREEN],
            "claimTypeIdName": "motorClaim.selectClaim.windscreen",
            "description": "",
            "icon": "assets/images/motor-icon/windscreen.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 1,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION],
            "claimTypeIdName": "motorClaim.selectClaim.damage",
            "description": "",
            "icon": "assets/images/motor-icon/collision.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 2,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT],
            "claimTypeIdName": "motorClaim.selectClaim.theft",
            "description": "",
            "icon": "assets/images/motor-icon/theft.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        }
    ];

    homeClaimItemList: ClaimTypeItem[] = [
        {
            "claimTypeId": 0,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_WATER],
            "claimTypeIdName": "homeProduct.selectClaim.water",
            "description": "",
            "icon": "assets/images/home-icon/waterdamageblack.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "Water Damage",
            "gaVPath": "water-damage"
        },
        {
            "claimTypeId": 1,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_WEATHER],
            "claimTypeIdName": "homeProduct.selectClaim.weather",
            "description": "",
            "icon": "assets/images/home-icon/badweatherblack.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "Bad Weather",
            "gaVPath": "bad-weather"
        },
        {
            "claimTypeId": 2,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_ACCIDENT],
            "claimTypeIdName": "homeProduct.selectClaim.accident",
            "description": "",
            "icon": "assets/images/home-icon/accidentblack.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "Accidental Damage",
            "gaVPath": "accidental-damage"
        },
        {
            "claimTypeId": 3,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_FIRE],
            "claimTypeIdName": "homeProduct.selectClaim.fire",
            "description": "",
            "icon": "assets/images/home-icon/fireblack.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "Fire",
            "gaVPath": "fire"
        },
        {
            "claimTypeId": 4,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_BURGLARY],
            "claimTypeIdName": "homeProduct.selectClaim.burglary",
            "description": "",
            "icon": "assets/images/home-icon/theftblack.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "Burglary",
            "gaVPath": "burglary"
        },
        {
            "claimTypeId": 5,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_VANDALISM],
            "claimTypeIdName": "homeProduct.selectClaim.vandalism",
            "description": "",
            "icon": "assets/images/home-icon/vandalismblack.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "Vandalism",
            "gaVPath": "vandalism"
        },
        {
            "claimTypeId": 6,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST],
            "claimTypeIdName": "claimDetails.claimTypeName.lostPersonalItem",
            "description": "",
            "icon": "assets/images/lost-personal-item.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "Lost Personal Item",
            "gaVPath": "lost-personal-item"
        },
        {
            "claimTypeId": 7,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG],
            "claimTypeIdName": "claimDetails.claimTypeName.dmgPersonalItem",
            "description": "",
            "icon": "assets/images/damanged-personal-item.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "Damaged Personal Item",
            "gaVPath": "damaged-personal-item"
        },
        {
            "claimTypeId": 8,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_DMH],
            "claimTypeIdName": "homeProduct.domesticEmployer.title",
            "description": "",
            "icon": "assets/images/domestic-helper-icon/employee-compensation.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "Domestic Employer's Liability",
            "gaVPath": "domestic-employer-liability"
        },
        {
            "claimTypeId": 9,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_PA],
            "claimTypeIdName": "homeProduct.personalAccident.title",
            "description": "",
            "icon": "assets/images/domestic-helper-icon/permanent-disability-or-death.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "Personal Accident",
            "gaVPath": "personal-accident"
        },
        {
            "claimTypeId": 10,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_TP_LIABILITY],
            "claimTypeIdName": "homeProduct.liability.title",
            "icon": "assets/images/home-icon/liabilityblack.svg",
            "description": "",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "Third Party Liability",
            "gaVPath": "third-party-liability"
        },
        {
            "claimTypeId": 11,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_OTHERS],
            "claimTypeIdName": "homeProduct.selectClaim.others",
            "description": "",
            "icon": "assets/images/if_other_2126131.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "Others claim type",
            "gaVPath": "others-claim-type"
        }
    ];

    paClaimItemList: ClaimTypeItem[] = [
        {
            "claimTypeId": 0,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES],
            "claimTypeIdName": "paClaim.accidentalMedicalExpenses.title",
            "description": "",
            "icon": "assets/images/visited-doctor.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "Accidental Medical Expenses",
            "gaVPath": "/claim/accidental-medical-expenses"
        },
        {
            "claimTypeId": 1,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH],
            "claimTypeIdName": "paClaim.hospitalCash.title",
            "description": "",
            "icon": "assets/images/stayed-at-hospital.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "Hospital Cash",
            "gaVPath": "/claim/hospital-cash"
        },
        {
            "claimTypeId": 2,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS],
            "claimTypeIdName": "paClaim.others.title",
            "description": "",
            "icon": "assets/images/not-sure.svg",
            "show": false,
            "selected": false,
            "completed": false,
            "gaPageStep": "Others",
            "gaVPath": "/claim/others"
        }
    ];

    ecClaimItemList: ClaimTypeItem[] = [
        {
            "claimTypeId": 0,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2B],
            "claimTypeIdName": "ecClaim.selectClaim.form2b",
            "description": "ecClaim.selectClaim.form2bDesc",
            "icon": "assets/images/ec-icon/form2b.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 1,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2],
            "claimTypeIdName": "ecClaim.selectClaim.form2",
            "description": "ecClaim.selectClaim.form2Desc",
            "icon": "assets/images/ec-icon/form2.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 2,
            "claimTypeCode":  ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2A],
            "claimTypeIdName": "ecClaim.selectClaim.form2a",
            "description": "ecClaim.selectClaim.form2aDesc",
            "icon": "assets/images/ec-icon/form2a.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        }
    ];

    gbaTravelClaimItemList: ClaimTypeItem[] = [
        {
            "claimTypeId": 1,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST],
            "claimTypeIdName": "claimDetails.claimTypeName.lostPersonalItem",
            "description": "",
            "icon": "assets/images/lost-personal-item.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 2,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG],
            "claimTypeIdName": "claimDetails.claimTypeName.dmgPersonalItem",
            "description": "",
            "icon": "assets/images/damanged-personal-item.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },

        {
            "claimTypeId": 4,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_VISIT],
            "claimTypeIdName": "claimDetails.claimTypeName.visitedDoctor",
            "description": "",
            "icon": "assets/images/visited-doctor.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 5,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_STY],
            "claimTypeIdName": "claimDetails.claimTypeName.stayedInHosp",
            "description": "",
            "icon": "assets/images/stayed-at-hospital.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 13,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_DTH],
            "claimTypeIdName": "claimDetails.claimTypeName.deathSeriousBodyInjury",
            "description": "",
            "icon": "assets/images/personal-accident.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 14,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_LIAB],
            "claimTypeIdName": "claimDetails.claimTypeName.persLiab",
            "description": "",
            "icon": "assets/images/private-liability.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        },
        {
            "claimTypeId": 15,
            "claimTypeCode": ClaimTypes[ClaimTypes.CLAIM_TYPE_OTH],
            "claimTypeIdName": "claimDetails.claimTypeName.notSure",
            "description": "",
            "icon": "assets/images/not-sure.svg",
            "show": true,
            "selected": false,
            "completed": false,
            "gaPageStep": "",
            "gaVPath": ""
        }
    ];

    claim: Claim;

    constructor(private planHelperService: PlanHelperService,
                private claimService: ClaimService) {

        this.claimService.getClaimSubject().subscribe(claim => {
            this.claim = claim;
        });    
    }

    public getClaimTypeList(country: string, transactionInfo: TransactionInfo, planType: string): ClaimTypeItem[] {

        if (transactionInfo.isTravelProduct()) {
            if (country?.toLowerCase() == "hgi") {
                return this.travelClaimItemList.filter(claimType => claimType.claimTypeCode !== ClaimTypes[ClaimTypes.CLAIM_TYPE_MSEVNT] &&
                    claimType.claimTypeCode !== ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL_MC]);
            } else if (country?.toLowerCase() == "hkg") {
                if (planType) {
                    if (planType == PLAN_TYPES.BASIC) {
                        return this.gbaTravelClaimItemList.filter(claimType => [ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST],
                            ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG],
                            ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_VISIT],
                            ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_STY]].indexOf(claimType.claimTypeCode) === -1);
                    } else if (planType == PLAN_TYPES.DELUXE) {
                        return this.gbaTravelClaimItemList;
                    }
                }
                return this.travelClaimItemList;
            } else {
                // sgp and mys
                return this.travelClaimItemList.filter(claimType => claimType.claimTypeCode !== ClaimTypes[ClaimTypes.CLAIM_TYPE_MSEVNT]);
            }
        } else if (transactionInfo.isDomesticHelperProduct()) {
            // get available domestic claim types
            let availableClaimType = this.planHelperService.getAvailableDomesticClaimType(this.claim.getPlanCode(), this.claim.getCatalogueCode(), country);
            return this.buildDomesticClaimTypeList(availableClaimType, this.domesticHelperClaimItemList);
        } else if (transactionInfo.isMotorProduct()) {
            let motorClaimTypeList: ClaimTypeItem[] = [];
            let isCoverNotePreNumbered = this.claim.getIsCoverNotePreNumbered();
            if(!UtilitiesService.isNullOrUndefined(isCoverNotePreNumbered) && isCoverNotePreNumbered != "") {
                motorClaimTypeList = this.buildCoverNoteClaimTypeList(this.motorClaimItemList);
            } else {
                let contractType = this.claim.getProduct();
                let riskType = this.claim.getRiskType();
                let isPolicyHolder = this.claim.getClaimMotor().getIsLodgeByPolicyHolder();
                let coverType = this.claim.getClaimMotor().getCoverType();
               let eligibleClaimTypes = this.planHelperService.getEligibleMotorClaimTypes(isPolicyHolder, contractType, riskType, coverType);
               motorClaimTypeList = this.buildMotorClaimTypeList(eligibleClaimTypes, this.motorClaimItemList);
            }
            return motorClaimTypeList;
        } else if (transactionInfo.isHomeProduct()) {
            let itemList: ClaimTypeItem[] = this.homeClaimItemList;
            if (!this.claim.getClaimHome().getIsContentPremClassExist()) {
                itemList.splice(2, 1);
            }
            return itemList;
        } else if (transactionInfo.isAccidentProduct()) {
            return this.paClaimItemList;
        } else if (transactionInfo.isEmployeeCompensationProduct()) {
            return this.ecClaimItemList;
        }
    }

    buildDomesticClaimTypeList(availableClaimTypes: string[], domesticDefaultClaimTypeList: ClaimTypeItem[]) {
        let domesticClaimTypeList: ClaimTypeItem[] = [];
        let count: number = 0;
        let showDescription = !this.claimService.getClaim()?.getClaimDomesticHelper().getIsNewArrangement() &&
            this.claimService.getClaim().isMILDMHProductPlan();

        if (availableClaimTypes.length > 0) {
            availableClaimTypes.forEach(function (availableClaimTypeItem) {
                domesticDefaultClaimTypeList.forEach(function (claimTypeItem) {
                   if(claimTypeItem.claimTypeCode == availableClaimTypeItem) {
                       count++;
                       //always show two first available claim type
                       if (count < 3) {
                           claimTypeItem.show = true;
                       }
                       // if more than 3 available claim types - add in the otherclaims - selector
                       if (count == 3){
                           if(availableClaimTypes.length > 3) {
                               domesticClaimTypeList.push(domesticDefaultClaimTypeList[2]);
                           } else if (availableClaimTypes.length == 3) {
                               claimTypeItem.show = true;
                           }
                       }
                       if (claimTypeItem.claimTypeCode === ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_CLINICAL] && showDescription) {
                           claimTypeItem.description = "domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.description";
                       } else {
                           claimTypeItem.description = "";
                       }
                       domesticClaimTypeList.push(claimTypeItem);
                   }
                });
            });
            return domesticClaimTypeList;
        } else {
            return domesticDefaultClaimTypeList;
        }
    }

    buildMotorClaimTypeList(availableClaimTypes: string[], motorClaimTypeList: ClaimTypeItem[]) {
        if (availableClaimTypes.length > 0) {
            availableClaimTypes.forEach(function (availableClaimTypeItem) {
                motorClaimTypeList.forEach(function (claimTypeItem) {
                    if(claimTypeItem.claimTypeCode == availableClaimTypeItem) {
                        claimTypeItem.show = true;
                    }
                });
            });

        }
        return motorClaimTypeList;
    }

    buildCoverNoteClaimTypeList(motorClaimTypeList: ClaimTypeItem[]) {
        motorClaimTypeList.forEach(function (claimTypeItem) {
            claimTypeItem.show = true;
        });
        return motorClaimTypeList;
    }
}
