import {Component, Injector, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { ClaimsBaseComponent } from '../../../claim-base.component';
import { ClaimPersonalAccident } from 'src/app/model/claim-personal-accident.model';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { takeUntil } from 'rxjs/operators';
import { UtilitiesService } from 'src/app/utilities/utilities.service';
import { UiPopoverModel } from 'src/app/ui/ui.model';
import { PersonalAccidentHelperService } from 'src/app/services/personal-accident-helper.service';
import { ClaimTypeItem } from 'src/app/model/claim-type-item.model';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaBodyPartsComponent } from '../../body-parts/pa-body-parts.component';

declare const $: any;

@Component({
    selector: 'app-pa-details-of-accident',
    templateUrl: './details-of-accident.component.html',
    styleUrls: ['./details-of-accident.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PaDetailsOfAccidentComponent extends ClaimsBaseComponent  implements OnInit {

    showTotalBox = false;
    accidentDetailsForm: FormGroup;
    injuryDetailsForm: FormGroup;
    transactionInfo: TransactionInfo;
    claimPA: ClaimPersonalAccident;
    paHelperService: PersonalAccidentHelperService;
    uiPopover: UiPopoverModel = new UiPopoverModel();
    claimTypeData: ClaimTypeItem[] = [];
    selectedClaimTypeId: number;
    modalService: NgbModal;

    selectedBodyParts: string[] = [];
    selectedBodyPartsDesc: string[] = [];
    bodyPartsList = [];
    selectBodyPartStatus: string = 'init';
    right: string = 'Right';
    left: string = 'Left';
    backBodyPartsList: string[] = ['Right Ankle', 'Left Ankle', 'Back', 'Right Elbow', 'Left Elbow'];

    @ViewChild(PaBodyPartsComponent) paBodyParts;
    
    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private sideMenuService: SideMenuService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimPA = this.claim.getClaimPersonalAccident();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
        this.paHelperService = this.injector.get(PersonalAccidentHelperService);
        this.claimTypeData = this.transactionInfo.getClaimTypeItemList();
        this.modalService = this.injector.get(NgbModal);
        this.right = this.translate.instant('paClaim.injuryDetails.right');
        this.left = this.translate.instant('paClaim.injuryDetails.left');
    }

    ngOnInit() {
        this.pushGAView();

        let dateOfLoss = this.claimPA.getAccidentDetails().getAccidentDate() != null ? this.claimPA.getAccidentDetails().getAccidentDate() : this.claim.getDateOfLoss();
        this.claimPA.getAccidentDetails().setCountryIncidentOccur("HKG");
        this.claimPA.getAccidentDetails().setCountryIncidentOccurDesc("HONG KONG");
        this.claimPA.getInjuryDetails().setHasBeenInjured(true); //default to true for PA HKG

        this.accidentDetailsForm = this.fb.group({
            accidentDate: [dateOfLoss],
            accidentDescription: [this.claimPA.getAccidentDetails().getAccidentDescription()],
            occupation: [this.claimPA.getAccidentDetails().getOccupation()],
            occupationDesc: [this.claimPA.getAccidentDetails().getOccupationDesc()],
            occupationOthers: [this.claimPA.getAccidentDetails().getOccupationOthers()]
        });

        this.injuryDetailsForm = this.fb.group({
            hasEncounteredSimilarIncident: [super.getBooleanString(this.claimPA.getInjuryDetails().getHasEncounteredSimilarIncident())],
            placeOfIncident: [this.claimPA.getInjuryDetails().getPlaceOfIncident()]
        });
    }

    ngAfterViewInit() {
        this.accidentDetailsForm.valueChanges.subscribe(data => {
            this.claimPA.getAccidentDetails().setAccidentDate(super.getValueInComponent('accidentDate'));
            this.claimPA.getAccidentDetails().setAccidentDescription(super.getValueInComponent('accidentDescription'));
            this.claimPA.getAccidentDetails().setOccupation(super.getValueInComponent('occupation'));
            this.claimPA.getAccidentDetails().setOccupationDesc(super.getTextInComponent('occupation'));
            this.claimPA.getAccidentDetails().setOccupationOthers(super.getValueInComponent('occupationOthers'));
        });

        // Stop validation of the other field when reason isn't other
        let self = this;
        
        setTimeout(function () {self.hideShowOthersField(self.claimPA.getAccidentDetails().getOccupation())},10);

        this.accidentDetailsForm.get('occupation').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowOthersField(data);
        });

        this.injuryDetailsForm.valueChanges.subscribe(data => {
            if (!UtilitiesService.isNullOrUndefined(this.selectedBodyParts) && this.selectedBodyParts.length > 0) {
                this.selectedBodyParts.sort((a, b) => a.localeCompare(b));
                this.claimPA.getInjuryDetails().setInjuredPartsList(this.selectedBodyParts);
            }
            if (!UtilitiesService.isNullOrUndefined(this.selectedBodyPartsDesc) && this.selectedBodyPartsDesc.length > 0) {
                this.selectedBodyPartsDesc.sort((a, b) => a.localeCompare(b));
                this.claimPA.getInjuryDetails().setInjuredPartsDescList(this.selectedBodyPartsDesc);
            }
            this.claimPA.getInjuryDetails().setHasEncounteredSimilarIncident(this.injuryDetailsForm.get('hasEncounteredSimilarIncident')?.value === 'true');
            this.claimPA.getInjuryDetails().setPlaceOfIncident(super.getValueInComponent('placeOfIncident'));
        });

        setTimeout(function () {
            self.hideDetailsOfIncidentField(self.getBooleanString(self.claimPA.getInjuryDetails().getHasEncounteredSimilarIncident()))},10);
            this.injuryDetailsForm.get('hasEncounteredSimilarIncident').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.hideDetailsOfIncidentField(data);
       });
    }

    hideShowOthersField(occupation) {

        if (occupation && occupation.toLowerCase() == 'others') {
            this.accidentDetailsForm.get('occupationOthers').enable({onlySelf: false, emitEvent: false});
        } else {
            this.accidentDetailsForm.get('occupationOthers').reset();
            this.accidentDetailsForm.get('occupationOthers').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideDetailsOfIncidentField(hasExperienced) {
        if (hasExperienced === "true") {
            this.injuryDetailsForm.get('placeOfIncident').enable({onlySelf: false, emitEvent: false});
        } else {
            this.injuryDetailsForm.get('placeOfIncident').reset();
            this.injuryDetailsForm.get('placeOfIncident').disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        this.setEligibleClaimTypes();
        this.paBodyParts.checkSelectBodyPartStatus();
        if (this.validateForm()) {
            this.sideMenuService.emitComplete('finishAccidentDetails');
            this.sideMenuService.emitProgress(1);
            this.transactionInfo.setIsClaimDetailsLabel(!this.claimPA.getHasEligibleClaimTypes());
            this.goToNextPage();
        }
    }

    goToNextPage() {
        if (this.paBodyParts.selectBodyPartStatus === 'selected') {
            if (!this.claimPA.getHasEligibleClaimTypes()) {
                this.goToOthersClaimDetails();
            } else {
                this.transactionInfo.setIsClaimDetailsLabel(false);
                this.sideMenuService.emitSelectClaimType(true);
                this.router.navigate(['/claimform/claimDetail'], {
                    relativeTo: this.activatedRoute
                });
            }
        }
    }

    goToOthersClaimDetails() {
        this.sideMenuService.emitSelectClaimType(false);
        let chooseIndex:number = 2;
        let selectedClaimCode:string = ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS];
        let claimTypeDetailMenu = this.transactionInfo.getNavigationMenu().getClaimDetailMenu();
        const selectedClaimItemList: string[] = this.claim.getSelectedClaimTypesList();

        let claimTypeSelected =  this.claimTypeData.find(claimTypeItem =>
            claimTypeItem.claimTypeCode === selectedClaimCode);
        if(!UtilitiesService.isNullOrUndefined(claimTypeSelected)){
            claimTypeSelected.selected = true;
            if(selectedClaimItemList.indexOf(claimTypeSelected.claimTypeCode) < 0){
                selectedClaimItemList.push(claimTypeSelected.claimTypeCode);
            }
            this.transactionInfo.setGaPageStep(claimTypeSelected.gaPageStep);
            this.transactionInfo.setGaVPath(claimTypeSelected.gaVPath);
        }

        this.claim.setSelectedClaimTypesList(selectedClaimItemList);
        this.transactionInfoService.getTransactionInfo().setClaimTypeItemList(this.claimTypeData);
        this.sideMenuService.emitCliamType(chooseIndex);
        this.sideMenuService.emitComplete('finishDetail');
        if (!this.claim.isMox()) this.sideMenuService.emitProgress(1);

        let nextLinkUrl: string = claimTypeDetailMenu[chooseIndex]['url'];
        this.router.navigate([nextLinkUrl]);

    }

    setEligibleClaimTypes() {
        // set flag to determine if user has eligible claim type aside from others
        let hasPAClaimTypes = this.claimTypeData.filter(y => y.show === true &&
            y.claimTypeCode.indexOf("OTHERS") === -1).length > 0;

        this.claimPA.setHasEligibleClaimTypes(hasPAClaimTypes);
    }

    validateForm(): boolean {
        let isAccidentFormValid = super.validateForm(this.accidentDetailsForm);
        let isInjuryFormValid = super.validateForm(this.injuryDetailsForm);
        return isAccidentFormValid && isInjuryFormValid;
    }

    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/selectInsured"], {
            relativeTo: this.activatedRoute
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAView() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Confirm Accident Details',
            'vPath': '/confirm-accident-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 4}
                }
            }
        });
    }
}
