import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {Claim} from "../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import {HospitalizationHelper} from "../../../../model/domestic-helper/hospitalization-helper.model";
import { PlanHelperService } from 'src/app/services/plan-helper.service';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { DomesticInfoPlan } from 'src/app/model/domestic-helper/domestic-info-plan.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import {Notifications} from "../../../../utilities/components/notification-messages/notifications.model";
import {ClaimTypeItemHelperService} from "../../../../services/claim-type-item-helper.service";

@Component({
  selector: 'app-hospitalization-helper-detail',
  templateUrl: './hospitalization-helper-detail.component.html',
  styleUrls: ['./hospitalization-helper-detail.component.css']
})

export class HospitalizationHelperDetailComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    hospitalizationForm: FormGroup;
    claim: Claim;
    hospitalizationHelper : HospitalizationHelper;
    transactionInfo: TransactionInfo;
    domesticInfoPlan : DomesticInfoPlan;
    notifications: Notifications

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private planHelperService : PlanHelperService,
                private transactionInfoService : TransactionInfoService,
                private injector : Injector,
                private claimTypeItemHelperService: ClaimTypeItemHelperService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.hospitalizationHelper = this.claim.getClaimDomesticHelper().getHospitalizationHelper();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {

        this.pushGAViewHospitalizationHelperDetail();

        let planCode = this.claim.getPlanCode();
        let catalogCode = this.claim.getCatalogueCode();
        let country = this.transactionInfo.getCountry();
    
        this.domesticInfoPlan = this.planHelperService.getDomesticPlanDetails(planCode, catalogCode, country);

        if(this.hospitalizationHelper.getCountryIncidentOccurDesc() == null || this.hospitalizationHelper.getCountryIncidentOccurDesc() == ""){
            this.hospitalizationHelper.setCountryIncidentOccurDesc('HONG KONG');
            this.hospitalizationHelper.setCountryIncidentOccur('HKG');
        }
        if(this.hospitalizationHelper.getAmountClaimCurrency() == null){
            this.hospitalizationHelper.setAmountClaimCurrency('HKD');
        }
            this.hospitalizationHelper.setLossOfCashServiceAllowance(this.domesticInfoPlan.lossOfCashServiceAllowance);
        this.hospitalizationForm = this.fb.group({            
            dateOfAdmission: [this.hospitalizationHelper.getDateOfAdmission()],
            dateOfDischarge: [this.hospitalizationHelper.getDateOfDischarge()],
            countryIncidentOccur: [this.hospitalizationHelper.getCountryIncidentOccur()],
            countryIncidentOccurDesc: [this.hospitalizationHelper.getCountryIncidentOccurDesc()],
            descOfIncident: [this.hospitalizationHelper.getDescOfIncident()],
            amountClaimCurrency: [this.hospitalizationHelper.getAmountClaimCurrency()],
            amountClaim: [this.hospitalizationHelper.getAmountClaim()]
        });

        this.notifications = new Notifications();
    }

    ngAfterViewInit() {

        this.hospitalizationForm.valueChanges.subscribe(data => {
            this.hospitalizationHelper.setCountryIncidentOccur(this.hospitalizationForm.get('countryIncidentOccur').value);
            this.hospitalizationHelper.setCountryIncidentOccurDesc(this.getTextInComponent('countryIncidentOccur'));
            this.hospitalizationHelper.setDateOfAdmission(super.getValueInComponent('dateOfAdmission'));
            this.hospitalizationHelper.setDateOfDischarge(super.getValueInComponent('dateOfDischarge'));
            this.hospitalizationHelper.setDescOfIncident(super.getValueInComponent('descOfIncident'));
            this.hospitalizationHelper.setAmountClaimCurrency(this.hospitalizationForm.get('amountClaimCurrency').value);
            this.hospitalizationHelper.setAmountClaim(super.getValueInComponent('amountClaim'));

        });

    }


    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        this.notifications.clearMessages();

        if (this.validateForm()) {
            let isDateOfVisitAllowed = this.claimTypeItemHelperService.isAllowedDomesticClaimDetailsGroup(2, this.claim);
            if (isDateOfVisitAllowed) {
                this.sideMenuService.emitClaimComplete({claimTypeId: 3, subMenuIndex: 0});
                this.router.navigate(["/claimform/hospitalization/supportDoc"], {
                    relativeTo: this.activatedRoute
                });
            } else {
                this.notifications = new Notifications();
                this.notifications.showPreamble = false;
                let errorMessage = "error_messages.domesticClaimGroup.notAllowedDateOfVisit";
                this.notifications.addErrorMessage(errorMessage);
            }
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.hospitalizationForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }


    //Google Analytics
    pushGAViewHospitalizationHelperDetail() {
        (<any>window).dataLayer.push({
            'pageStep': 'Hospitalization of Helper – Hospital Stay Details',
            'vPath': '/claim/hospitalization-of-helper/hospital-stay-details',
            'event': 'vpageview',
            'ecommerce': {
              'checkout': {
                  'actionField': {'step': 5},	//Step5-ClaimDetailsStart
              }
            }
                                                      
        });
    }

}
