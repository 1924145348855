import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {SideMenuService} from "../../../../../services/side-menu.service";
import { ClaimDocumentsComponent } from '../../../../claim-documents/claim-documents.component';
import { ClaimService } from '../../../../../services/claim.service';
import { DocumentHelperService } from '../../../../../services/document-helper.service';
import { TransactionInfoService } from '../../../../../services/transaction-info.service';
import { HttpClient } from '@angular/common/http';
import { DocumentFormFactoryService } from '../../../../../services/document-form-factory.service';
import { ClaimTypes } from '../../../../../model/claim-type.model';
import { SpinnerService } from '../../../../../core/spinner/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-baggage-delay-upload-document',
  templateUrl: './baggage-delay-upload-document.component.html',
  styleUrls: ['./baggage-delay-upload-document.component.css']
})
export class BaggageDelayUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {

    public upLoadFileModel: FormGroup;
    showTotalBox = false;
    
    constructor(private fb: FormBuilder,
        public sideMenuService: SideMenuService, 
        claimService: ClaimService,
        documentHelperService: DocumentHelperService,
        transactionInfoService: TransactionInfoService,
        http: HttpClient,
        documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
                super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
                this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_BDEL]);
    }

    ngOnInit() {
        this.pushGAViewBaggageDocs();
        this.upLoadFileModel = this.fb.group(
            {
                airTicket: [],
                carrierCertificate: [],
                receiptEmergencyPurchase: [],
                otherItems: this.fb.array([this.createItem()])

            });
            super.ngOnInit();
    }

    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: []
        });
    }
    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }
    goToNext() {
        this.sideMenuService.emitClaimComplete({claimTypeId: 3, subMenuIndex: 2});
        this.sideMenuService.emitProgress(1);
    }

    //Google Analytics
    pushGAViewBaggageDocs() {
        (<any>window).dataLayer.push({
            'pageStep': 'Baggage Delay – Document Upload',
            'vPath': '/claim/baggage-delay/documents',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 6},	//Step6-DocumentUpload
                }
            }

        });
    }
}
