import {Injectable, Injector} from "@angular/core";
import {Logger} from "../utilities/service/logger/logger";
import {Claim} from "../model/claim.model";
import {BehaviorSubject, Observable, throwError, catchError, finalize, map} from "rxjs";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {SpinnerService} from "../core/spinner/spinner.service";
import {ClaimTypes} from "../model/claim-type.model";
import {TravelDelay} from "../model/travel/travel-delay.model";
import { Version } from "../../version";
import {TransactionInfo} from "../model/transaction-info.model";
import { UtilitiesService } from "../utilities/utilities.service";

@Injectable()
export class ClaimService {

    claim: Claim;
    claimSubject: BehaviorSubject<Claim>;

    constructor(private http: HttpClient,
                private _logger: Logger,
                private spinnerService : SpinnerService,
                private injector: Injector) {
        this.claimSubject  = new BehaviorSubject(this.claim);
    }

    getClaim(): Claim {
        if (this.claim == null) {
            this.claim = new Claim();
            this.claimSubject.next(this.claim);
            //console.log("Get claim null");
        }
        return this.claim;
    }

    getClaimSubject(): Observable<Claim> {
        return this.claimSubject;
    }

    setClaim(claim: Claim) {
        this.claim = claim;
        //console.log("Set claim",this.claim);
        this.claimSubject.next(this.claim);
    }

    saveClaim(claim: Claim, integrationToken: string): Observable<Claim> {

        this._logger.log("saveClaim");
        this._logger.log(JSON.stringify(claim));
        this.spinnerService.displayLoader(true);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + integrationToken
            })
        };

        return this.http.post<Claim>("/ms/claims-service/rest/claims/post", JSON.stringify(claim), httpOptions)
            .pipe(map((res: Claim) => {
                this._logger.log("http return result");
                this._logger.log(res);

                let claimReturned: Claim = Object.assign(new Claim(), res);
                claimReturned  = Claim.jsonConvertClaim(claimReturned);

                this.claim.setClaimNumberList(claimReturned.getClaimNumberList());
                this.claim.setNotificationNumber(claimReturned.getNotificationNumber());
                this.claim.setNotificationNumberList(claimReturned.getNotificationNumberList());
                this.claim.setErrorMessage(claimReturned.getErrorMessage());
                this.claim.setClaimResponseList(claimReturned.getClaimResponseList());
                this.claim.setDocToken(claimReturned.getDocToken());

                //map estimated amount for each claim types selected - google analytics
                let product = this.claim.getProduct();
                if(!UtilitiesService.isNullOrUndefined(product)) {
                    if(['ptr', 'pit', 'ppt', 'psa'].indexOf(product.toLowerCase()) != -1){
                        this.setEstimatedAmountsForClaimTypeTravel(claimReturned);
                    } else if (product.toLowerCase() === "dmh")  {
                        this.setEstimatedAmountsForClaimTypeDomesticHelper(claimReturned);
                    } else if (['hpk', 'hhh'].indexOf(product.toLowerCase()) != -1) {
                        this.setClaimTypeAmount(claimReturned);
                    } else if (['pan', 'pad', 'pam'].indexOf(product.toLowerCase()) != -1) {
                        this.setTotalsForPersonalAccident(claimReturned);
                        this.setClaimTypeAmount(claimReturned);
                    }
                }

                this._logger.log("returning claim okay");
                return this.claim;
            }))
            //...errors if any
            .pipe(catchError<any,Observable<Claim>>(this.handleError))
            .pipe(finalize( () => {this.spinnerService.displayLoader(false) }));
    }

    downloadPdfDocument(integrationToken: string, claim: Claim, docType: string) {

        this.spinnerService.displayLoader(true);
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + integrationToken
            }),
            'responseType': 'blob' as 'blob'
        };

        const formData = new FormData();
        formData.append('claim', JSON.stringify(claim));
        formData.append('docType', docType);

        return this.http.post('/ms/claims-service/getPdfDocument',
            formData, httpOptions)
            .pipe(finalize( () => {this.spinnerService.displayLoader(false)}));
            // .pipe(map(res => {
            //     console.log("downloadClaimsDetailsPDF returned");
            //     return new Blob([res], {type: 'application/pdf'})
            // }))
    }

    private handleError(error: HttpResponse<any> | any) {
        // In a real world app, you might use a remote logging infrastructure
        
        let errMsg: string;
        if (error instanceof HttpResponse) {
            errMsg = `${error.status} - ${error.statusText || ''}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        errMsg = 'claimService handleError' + errMsg;

        return throwError(() => new Error(errMsg));
    }

    getClaimTypeSubMenu(transactionInfo: TransactionInfo): Observable<any> {
        if (transactionInfo.isDomesticHelperProduct()) {
            return this.http.get('assets/product-claim-types-menu/DomesticClaimTypeMenu.json' + Version.getUrlVersion());
        } else if (transactionInfo.isMotorProduct()) {
            return this.http.get('assets/product-claim-types-menu/MotorClaimTypeMenu.json' + Version.getUrlVersion());
        } else if (transactionInfo.isHomeProduct()) {
            return this.http.get('assets/product-claim-types-menu/HomeClaimTypeMenu.json' + Version.getUrlVersion());
        } else if (transactionInfo.isAccidentProduct()) {
            if (this.claim.getCountry().toLowerCase() === 'sgp') {
                return this.http.get('assets/product-claim-types-menu/PAClaimTypeMenuSGP.json' + Version.getUrlVersion());
            } else {
                return this.http.get('assets/product-claim-types-menu/PAClaimTypeMenu.json' + Version.getUrlVersion());
            }
        } else if (transactionInfo.isEmployeeCompensationProduct()) {
            return this.http.get('assets/product-claim-types-menu/ECClaimTypeMenu.json' + Version.getUrlVersion());
        } else {
            return this.http.get('assets/product-claim-types-menu/ClaimTypeMenu.json' + Version.getUrlVersion());
        }

    }

    setTotalsForPersonalAccident(claimReturned: Claim) {
        if(this.claim.getClaimPersonalAccident() != null) {
            if(this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses() != null){
                this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().setTotalClinicalExpenses(claimReturned.getClaimPersonalAccident().getAccidentalMedicalExpenses().getTotalClinicalExpenses());
                this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().setTotalHospitalExpenses(claimReturned.getClaimPersonalAccident().getAccidentalMedicalExpenses().getTotalHospitalExpenses());
            }
            if(this.claim.getClaimPersonalAccident().getHospitalCash() != null){
                this.claim.getClaimPersonalAccident().getHospitalCash().setTotalHospitalExpenses(claimReturned.getClaimPersonalAccident().getHospitalCash().getTotalHospitalExpenses());
            }
        }
    }

    setClaimTypeAmount(claimReturned: Claim) {
        if (this.claim.getServiceUnit() !== 'GP' && !UtilitiesService.isNullOrUndefined(claimReturned.getEstimatedAmountList()) &&
            claimReturned.getEstimatedAmountList().length > 0) {
           this.claim.setEstimatedAmountList(claimReturned.getEstimatedAmountList());
        }
    }

    setEstimatedAmountsForClaimTypeTravel(claimReturned: Claim) {
        let claimTravel =  this.claim.getClaimTravel();
        let claimTypeSelected = this.claim.getSelectedClaimTypesList();
        let claimTravelReturned = claimReturned.getClaimTravel();

        claimTypeSelected.forEach(claimTypeCode => {
            switch(claimTypeCode) {
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL]:
                    let travelDelayReturned: TravelDelay = claimTravelReturned.getTravelDelay();
                    let estiamtedAmount = travelDelayReturned.getEstimatedAmount();
                    let travelDelay = claimTravel.getTravelDelay();
                    travelDelay.setEstimatedAmount(estiamtedAmount);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]:
                    let lostItemReturned = claimTravelReturned.getLostPersonalItem();
                    let lostItem = claimTravel.getLostPersonalItem();
                    lostItem.setEstimatedAmount(lostItemReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]:
                    let dmgItemReturned = claimTravelReturned.getDamagePersonalItem();
                    let damageItem = claimTravel.getDamagePersonalItem();
                    damageItem.setEstimatedAmount(dmgItemReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_BDEL]:
                    let bgDelayReturned = claimTravelReturned.getBaggageDelay();
                    let bgDelay = claimTravel.getBaggageDelay();
                    bgDelay.setEstimatedAmount(bgDelayReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_VISIT]:
                    let vDoctorReturned = claimTravelReturned.getVisitedDoctor();
                    let vsitDoctor = claimTravel.getVisitedDoctor();
                    vsitDoctor.setEstimatedAmount(vDoctorReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_STY]:
                    let styHospitalReturned = claimTravelReturned.getStayInHospital();
                    let styHospital = claimTravel.getStayInHospital();
                    styHospital.setEstimatedAmount(styHospitalReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN]:
                    let trpCancelReturned = claimTravelReturned.getTripCancellation();
                    let trpCancel = claimTravel.getTripCancellation();
                    trpCancel.setEstimatedAmount(trpCancelReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_CRTL]:
                    let trpCurtailReturned = claimTravelReturned.getTripCurtailment();
                    let trpCurtail = claimTravel.getTripCurtailment();
                    trpCurtail.setEstimatedAmount(trpCurtailReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_RVE]:
                    let rveReturned = claimTravelReturned.getRentalVehicle();
                    let rve = claimTravel.getRentalVehicle();
                    rve.setEstimatedAmount(rveReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_RROUT]:
                    let rerouteReturned = claimTravelReturned.getTravelReRoute();
                    let reroute = claimTravel.getTravelReRoute();
                    reroute.setEstimatedAmount(rerouteReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_MSEVNT]:
                    let msEventReturned = claimTravelReturned.getMissedEvent();
                    let msEvent = claimTravel.getMissedEvent();
                    msEvent.setEstimatedAmount(msEventReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL_MC]:
                    let msConnectReturned = claimTravelReturned.getMissConnection();
                    let msConnect = claimTravel.getMissConnection();
                    msConnect.setEstimatedAmount(msConnectReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_DTH]:
                    let accidentReturned = claimTravelReturned.getDeathAndSeriousBodyInjuryLoss();
                    let accident = claimTravel.getDeathAndSeriousBodyInjuryLoss();
                    accident.setEstimatedAmount(accidentReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_LIAB]:
                    let liabiliyReturned = claimTravelReturned.getPersonalLiability();
                    let liability = claimTravel.getPersonalLiability();
                    liability.setEstimatedAmount(liabiliyReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_OTH]:
                    let otherReturned = claimTravelReturned.getOtherClaim();
                    let otherss = claimTravel.getOtherClaim();
                    otherss.setEstimatedAmount(otherReturned.getEstimatedAmount());
                    break;
                default:
            }

        });

    }

    setEstimatedAmountsForClaimTypeDomesticHelper(claimReturned: Claim) {
        let claimDomesticHelper = this.claim.getClaimDomesticHelper();
        let claimTypeSelected = this.claim.getSelectedClaimTypesList();
        let claimDomesticHelperReturned = claimReturned.getClaimDomesticHelper();
        claimTypeSelected.forEach(claimTypeCode => {
            switch(claimTypeCode) {
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_CLINICAL]:
                    let clinicalExpensesReturned = claimDomesticHelperReturned.getClinicalExpenses();
                    let clinicalExpenses = claimDomesticHelper.getClinicalExpenses();
                    clinicalExpenses.setEstimatedAmount(clinicalExpensesReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DENTAL]:
                    let dentalExpensesReturned = claimDomesticHelperReturned.getDentalExpenses();
                    let dentalExpenses = claimDomesticHelper.getDentalExpenses();
                    dentalExpenses.setEstimatedAmount(dentalExpensesReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HOSPITAL]:
                    let hospitalHelperReturned = claimDomesticHelperReturned.getHospitalizationHelper();
                    let hospitalHelper = claimDomesticHelper.getHospitalizationHelper();
                    hospitalHelper.setEstimatedAmount(hospitalHelperReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DEATH]:
                    let deathDisablityReturned = claimDomesticHelperReturned.getDeathAndPermanentDisability();
                    let deathDisability = claimDomesticHelper.getDeathAndPermanentDisability();
                    deathDisability.setEstimatedAmount(deathDisablityReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HELP_LIABILITY]:
                    let helperLiabilityReturned = claimDomesticHelperReturned.getHelpersLiability();
                    let helpersLiability = claimDomesticHelper.getHelpersLiability();
                    helpersLiability.setEstimatedAmount(helperLiabilityReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_EMP_LIABILITY]:
                    let empCompensationReturned = claimDomesticHelperReturned.getEmployeeCompensation();
                    let empCompensation = claimDomesticHelper.getEmployeeCompensation();
                    empCompensation.setEstimatedAmount(empCompensationReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DISHONESTY]:
                    let dishonestyHelperReturned = claimDomesticHelperReturned.getDishonestyHelper();
                    let dishonestyHelper = claimDomesticHelper.getDishonestyHelper();
                    dishonestyHelper.setEstimatedAmount(dishonestyHelperReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_REPATRIATION]:
                    let repatrationReturned = claimDomesticHelperReturned.getRepatriation();
                    let repatriation = claimDomesticHelper.getRepatriation();
                    repatriation.setEstimatedAmount(repatrationReturned.getEstimatedAmount());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_OTHERS]:
                    let otherClaimDomesticReturned = claimDomesticHelperReturned.getOtherClaimDMH();
                    let otherClaimDomestic = claimDomesticHelper.getOtherClaimDMH();
                    otherClaimDomestic.setEstimatedAmount(otherClaimDomesticReturned.getEstimatedAmount());
                    break;
                default:
            }

        });

    }

    getPanelRepairers(): Observable<any> {
       return this.http.get('assets/repairers/PanelRepairers.json' + Version.getUrlVersion());
    }

    isMILDMHProductPlan(claim: Claim) : boolean {
        return (claim.getCountry()?.toLowerCase() === 'hkg' && claim.getIsManulife() && 
            ['N6', 'N7', 'N8', 'N9'].indexOf(claim.getPlanCode()) > -1);
    }
}


