import { Component, OnInit, Injector } from '@angular/core';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Claim } from 'src/app/model/claim.model';
import { Repatriation } from 'src/app/model/domestic-helper/repatriation.model';
import { Router, ActivatedRoute } from '@angular/router';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { ClaimService } from 'src/app/services/claim.service';
import { takeUntil } from 'rxjs';
import { PlanHelperService } from 'src/app/services/plan-helper.service';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { DomesticInfoPlan } from 'src/app/model/domestic-helper/domestic-info-plan.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';


@Component({
  selector: 'app-repatriation-extra-costs',
  templateUrl: './repatriation-extra-costs.component.html',
  styleUrls: ['./repatriation-extra-costs.component.css']
})
export class RepatriationExtraCostsComponent extends ClaimsBaseComponent implements OnInit {

  showTotalBox = false;
  repatriationForm: FormGroup;
  claim: Claim;
  repatriation: Repatriation;
  transactionInfo: TransactionInfo;
  domesticInfoPlan : DomesticInfoPlan;
  

  constructor(private fb: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private sideMenuService: SideMenuService,
              private claimService: ClaimService,
              private planHelperService : PlanHelperService,
              private transactionInfoService : TransactionInfoService,
              private injector : Injector) {

      super(injector);
      this.claim = this.claimService.getClaim();
      this.transactionInfo = this.transactionInfoService.getTransactionInfo();
      this.repatriation = this.claim.getClaimDomesticHelper().getRepatriation();
  }

  ngOnInit() {

    this.pushGAViewRepatriationExtraCost();

    let planCode = this.claim.getPlanCode();
    let catalogCode = this.claim.getCatalogueCode();
    let country = this.transactionInfo.getCountry();

    this.domesticInfoPlan = this.planHelperService.getDomesticPlanDetails(planCode, catalogCode, country);


      if(this.repatriation.getTempHelperAmount() == null){
          this.repatriation.setTempHelperAmountCurrency('HKD')
      }

      if(this.repatriation.getAgentFeePaidAmount() == null){
        this.repatriation.setAgentFeePaidCurrency('HKD')
      }

      this.repatriationForm = this.fb.group({
        tempHelperAmount: [this.repatriation.getTempHelperAmount()],
        tempHelperAmountCurrency: [this.repatriation.getTempHelperAmountCurrency()],
        agentFeePaidAmount: [this.repatriation.getAgentFeePaidAmount()],
        agentFeePaidCurrency: [this.repatriation.getAgentFeePaidCurrency()],
        hasTempHelper: [super.getBooleanString(this.repatriation.getHasTempHelper())],
        hasAgentFeePaid: [super.getBooleanString(this.repatriation.getHasAgentFeePaid())]
      });
  }

  ngAfterViewInit() {

      this.repatriationForm.valueChanges.subscribe(data => {
          let repatriation: Repatriation = this.claim.getClaimDomesticHelper().getRepatriation();
          repatriation.setAgentFeePaidAmount(super.getValueInComponent('agentFeePaidAmount'));
          repatriation.setAgentFeePaidCurrency(this.repatriationForm.get('agentFeePaidCurrency').value);
          repatriation.setTempHelperAmount(super.getValueInComponent('tempHelperAmount'));
          repatriation.setTempHelperAmountCurrency(this.repatriationForm.get('tempHelperAmountCurrency').value);

          if (this.repatriationForm.get('hasAgentFeePaid').value !== null) {
              repatriation.setHasAgentFeePaid(this.repatriationForm.get('hasAgentFeePaid').value == 'true' ? true: false);
          } else {
            repatriation.setHasAgentFeePaid(this.repatriationForm.get('hasAgentFeePaid').value);
          }

          if (this.repatriationForm.get('hasTempHelper').value !== null) {
              repatriation.setHasTempHelper(this.repatriationForm.get('hasTempHelper').value == 'true' ? true: false);
          } else {
              repatriation.setHasTempHelper(this.repatriationForm.get('hasTempHelper').value);
          }

      });

   // Stop validation of fields when not required.
   let self = this;
   setTimeout(function () {self.hideAgentFeePaid(self.getBooleanString(self.repatriation.getHasAgentFeePaid())),10});
   this.repatriationForm.get('hasAgentFeePaid').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
       this.hideAgentFeePaid(data);
   });

   // Stop validation of fields when not required.
   setTimeout(function () {self.hideTempHelper(self.getBooleanString(self.repatriation.getHasTempHelper())),10});
   this.repatriationForm.get('hasTempHelper').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
       this.hideTempHelper(data);
   });

   
}

 hideAgentFeePaid(hasAgentFeePaid) {
   if (hasAgentFeePaid == 'true') {
       this.repatriationForm.get('agentFeePaidAmount').enable({onlySelf: false, emitEvent: false});
       this.repatriationForm.get('agentFeePaidCurrency').enable({onlySelf: false, emitEvent: false});
   } else {
       this.repatriationForm.get('agentFeePaidAmount').reset();
       this.repatriationForm.get('agentFeePaidCurrency').setValue('HKD');
       this.repatriationForm.get('agentFeePaidAmount').disable({onlySelf: false, emitEvent: false});
       this.repatriationForm.get('agentFeePaidCurrency').disable({onlySelf: false, emitEvent: false});
   }
}

hideTempHelper(hasTempHelper) {
   if (hasTempHelper == 'true') {
       this.repatriationForm.get('tempHelperAmount').enable({onlySelf: false, emitEvent: false});
       this.repatriationForm.get('tempHelperAmountCurrency').enable({onlySelf: false, emitEvent: false});
   } else {
       this.repatriationForm.get('tempHelperAmount').reset();
       this.repatriationForm.get('tempHelperAmountCurrency').setValue('HKD');
       this.repatriationForm.get('tempHelperAmount').disable({onlySelf: false, emitEvent: false});
       this.repatriationForm.get('tempHelperAmountCurrency').disable({onlySelf: false, emitEvent: false});
   }
}

  back() {
      this.router.navigate(["/claimform/repatriation/detailsOfIncident"], {
          relativeTo: this.activatedRoute
      });
  }

  goToNext() {
      if (this.validateForm()) {
          this.sideMenuService.emitClaimComplete({claimTypeId: 8, subMenuIndex: 1});
          this.router.navigate(["/claimform/repatriation/supportDoc"], {
              relativeTo: this.activatedRoute
          });
      }
  }

  validateForm(): boolean {
      return super.validateForm(this.repatriationForm);
  }

  showModal() {
      this.showTotalBox = true;
  }

  getData(msg) {
      this.showTotalBox = msg;
  }

    //Google Analytics
pushGAViewRepatriationExtraCost() {
    (<any>window).dataLayer.push({
        'pageStep': 'Repatriation – Replacement or Temporary Helper',
        'vPath': '/claim/repatriation/replacement-temp-helper',
        'event': 'vpageview',                                                                                                                                                         
    });
}

}

