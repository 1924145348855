import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { ClaimsBaseComponent } from "src/app/claim-pages/claim-base.component";
import { Claim } from "src/app/model/claim.model";
import { SideMenuService } from "src/app/services/side-menu.service";
import { ClaimService } from "src/app/services/claim.service";
import { PoliceReport } from "src/app/model/motor/police-report.model";

@Component({
    selector: 'app-lost-report',
    templateUrl: './lost-report.component.html',
    styleUrls: ['./lost-report.component.css']
})
export class LostReportComponent extends ClaimsBaseComponent implements OnInit {

    reportTheLossForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    policeReport: PoliceReport;
    currency: string;
    isHomeProduct: boolean = false;

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private transactionInfoService: TransactionInfoService,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.policeReport = this.claim.getClaimHome().getHomeDetails().getPoliceReport();
    }

    ngOnInit() {
        this.pushGALostReportView();
        
        this.currency = this.claim.getCountry().toLowerCase() === 'sgp' ? 'SGD' : 'HKD';

        this.reportTheLossForm = this.fb.group({
            isIncidentReport: [super.getBooleanString(this.policeReport.getHasReportedIncidentToPolice())],
            policeStationNameAndAddress: [this.policeReport.getPoliceStation()],
            referenceNumber: [this.policeReport.getPoliceReportNumber()],
            dateOfReport: [this.policeReport.getDateOfReport()],
        });
    }

    ngAfterViewInit() {

        this.reportTheLossForm.valueChanges.subscribe(data => {
            if (this.reportTheLossForm.get('isIncidentReport').value != null) {
                this.policeReport.setHasReportedIncidentToPolice(this.reportTheLossForm.get('isIncidentReport').value == 'true');
            }
            this.policeReport.setPoliceStation(super.getValueInComponent('policeStationNameAndAddress'))
            this.policeReport.setPoliceReportNumber(super.getValueInComponent('referenceNumber'))
            this.policeReport.setDateOfReport(super.getValueInComponent('dateOfReport'));
        });

        // Stop validation of fields when not required.
        let self = this;

        setTimeout(function () {self.hidePoliceReport(self.getBooleanString(self.policeReport.getHasReportedIncidentToPolice())),10});
        this.reportTheLossForm.get('isIncidentReport').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hidePoliceReport(data);
        });

    }

    hidePoliceReport(isIncidentReport) {
        if (isIncidentReport == 'true') {
            this.reportTheLossForm.get('policeStationNameAndAddress').enable({onlySelf: false, emitEvent: false});
            this.reportTheLossForm.get('referenceNumber').enable({onlySelf: false, emitEvent: false});
            this.reportTheLossForm.get('dateOfReport').enable({onlySelf: false, emitEvent: false});
        } else {
            this.reportTheLossForm.get('policeStationNameAndAddress').reset();
            this.reportTheLossForm.get('referenceNumber').reset();
            this.reportTheLossForm.get('dateOfReport').reset();
            this.reportTheLossForm.get('policeStationNameAndAddress').disable({onlySelf: false, emitEvent: false});
            this.reportTheLossForm.get('referenceNumber').disable({onlySelf: false, emitEvent: false});
            this.reportTheLossForm.get('dateOfReport').disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        if (this.validateForm()) {
            this.transactionInfoService.getDynamicRoutingService().goToNextRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
        }
    }

    back() {
        this.transactionInfoService.getDynamicRoutingService().goToPreviousRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
    }

    validateForm(): boolean {
        return super.validateForm(this.reportTheLossForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.reportTheLossForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 2});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 1, subMenuIndex: 2});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGALostReportView() {
        let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep() + ' - Report the loss';
        let vPath = '/claim/' + this.transactionInfoService.getTransactionInfo().getGaVPath() + '/report-the-loss';
    
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
          'pageStep': pageStep,
          'vPath': vPath,
          'event': 'vpageview',
        });
    }
}
