import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Claim} from "../../../../model/claim.model";
import {TravelReRoute} from "../../../../model/travel/travel-reroute.model";

@Component({
  selector: 'app-means-of-transportation',
  templateUrl: './means-of-transportation.component.html',
  styleUrls: ['./means-of-transportation.component.css']
})

export class MeansOfTransportationComponent extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    reroutingForm: FormGroup;
    claim: Claim;
    travelReRoute: TravelReRoute;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.travelReRoute = this.claim.getClaimTravel().getTravelReRoute();
    }

    ngOnInit() {

        this.pushGAViewRerouteMeans();

        this.reroutingForm = this.fb.group({
                reroutingTakeDiffRoute: [this.travelReRoute.getReroutingTakeDiffRoute()],
                reroutingTranspoChange: [this.travelReRoute.getReroutingTranspoChange()],
            }
        );
    }

    ngAfterViewInit() {
        this.reroutingForm.valueChanges.subscribe(data => {
            if (this.reroutingForm.get('reroutingTakeDiffRoute').value != null) {
                this.travelReRoute.setReroutingTakeDiffRoute(super.getValueInComponent('reroutingTakeDiffRoute'));
            }
            if (this.reroutingForm.get('reroutingTranspoChange').value != null) {
                this.travelReRoute.setReroutingTranspoChange(super.getValueInComponent('reroutingTranspoChange'));
            }
            //var result = this.getDateDiff(this.reroutingForm.get('reroutingOriginalSchedule').value, this.reroutingForm.get('reroutingActualDelaySchedule').value);
            //this.travelReRoute.setReroutingTotalDelay(result);
            this.validateClaimDetailsComplete();
        });
    }

    back() {
        this.router.navigate(["/claimform/reRouting/detailsOfRerouting"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 10, subMenuIndex: 1});
            this.router.navigate(["/claimform/reRouting/reroutingSupportDoc"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.reroutingForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.reroutingForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitClaimComplete({claimTypeId: 10, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 10, subMenuIndex: 1});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }


    //Google Analytics
    pushGAViewRerouteMeans() {
        (<any>window).dataLayer.push({
            'pageStep': 'Travel Re-routing – Means of Transportation',
            'vPath': '/claim/travel-re-routing/meansOfTransportation',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }

}