import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {Claim} from "../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import {PersonalLiability} from "../../../../model/travel/personal-liability.model";
import {UtilitiesService} from "../../../../utilities/utilities.service";


@Component({
  selector: 'app-liability-desc-of-incident',
  templateUrl: './liability-desc-of-incident.component.html',
  styleUrls: ['./liability-desc-of-incident.component.css']
})


export class LiabilityDescOfIncidentComponent extends ClaimsBaseComponent implements OnInit {

    liabilityForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    personalLiability: PersonalLiability;


    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private fb: FormBuilder,
                private claimService: ClaimService,
                private sideMenuService: SideMenuService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.personalLiability = this.claim.getClaimTravel().getPersonalLiability();

    }

    ngOnInit() {

        this.pushGAViewLiablityDescription();
        if(!this.isNotNullOrUndefinedStr(this.personalLiability.getLiabilityCurrency())){
            this.personalLiability.setLiabilityCurrency(UtilitiesService.getCountryCurrency(this.claim.getCountry()));
        }

        this.liabilityForm = this.fb.group({
                liabilityIncidentDescription: [this.personalLiability.getLiabilityIncidentDescription()],
                liabilityAmount: [this.personalLiability.getLiabilityAmount()],
                liabilityCurrency: [this.personalLiability.getLiabilityCurrency()]
            }
        );
    }

    ngAfterViewInit() {
        this.liabilityForm.valueChanges.subscribe(data => {
            this.personalLiability.setLiabilityIncidentDescription(super.getValueInComponent('liabilityIncidentDescription'));
            this.personalLiability.setLiabilityAmount(super.getValueInComponent('liabilityAmount'));
            this.personalLiability.setLiabilityCurrency(this.liabilityForm.get('liabilityCurrency').value);

            this.validateClaimDetailsComplete();
        });
    }

    back() {
        this.router.navigate(["/claimform/personalLiability/detailOfIncident"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 14, subMenuIndex: 1});

            this.router.navigate(["/claimform/personalLiability/liabilitySupportDoc"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.liabilityForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.liabilityForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitClaimComplete({claimTypeId: 14, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 14, subMenuIndex: 1});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewLiablityDescription() {
        (<any>window).dataLayer.push({
            'pageStep': 'Personal Liability – Incident Description',
            'vPath': '/claim/personal-liability/incident-description',
            'event': 'vpageview',
        });
    }
}

