import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ClaimDocumentsComponent } from 'src/app/claim-pages/claim-documents/claim-documents.component';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { ClaimService } from 'src/app/services/claim.service';
import { DocumentHelperService } from 'src/app/services/document-helper.service';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { HttpClient } from '@angular/common/http';
import { DocumentFormFactoryService } from 'src/app/services/document-form-factory.service';
import { SpinnerService } from 'src/app/core/spinner/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
  selector: 'app-windscreen-upload-document',
  templateUrl: './windscreen-upload-document.component.html',
  styleUrls: ['./windscreen-upload-document.component.css']
})
export class WindscreenUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {

  public upLoadFileModel: FormGroup;
  showTotalBox = false;
  
  constructor(private fb: FormBuilder,
      public sideMenuService: SideMenuService, 
      claimService: ClaimService,
      private activatedRoute: ActivatedRoute,
      private router: Router, 
      documentHelperService: DocumentHelperService,
      transactionInfoService: TransactionInfoService,
      http: HttpClient,
      documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal,  private translate: TranslateService) {
              super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
              this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_WINDSCREEN]);
  }

  ngOnInit() {

      this.pushGAWsDocsView();

      this.upLoadFileModel = this.fb.group(
          {
              airTicket: [],
              carrierCertificate: [],
              otherItems: this.fb.array([this.createItem()])

          });
          super.ngOnInit();
  }

  addFile() {
      let items = this.upLoadFileModel.get('otherItems') as FormArray;
      items.push(this.createItem());
  }

  createItem(): FormGroup {
      return this.fb.group({
          otherFile: [],
      });
  }

  showModal() {
      this.showTotalBox = true;
  }

  getData(msg) {
      this.showTotalBox = msg;
  }

  goToNext() {
    let stpMessage = this.buildSTPAlertMessage(this.claimService.getClaim(), this.translate, ['PHOTOOFREPAIR', 'PHOTOOFDAMAGE', 'BILL', 'RECEIPT']);
    if(!UtilitiesService.isEmpty(stpMessage)){
        this.showDocAlertModal();
    } else {
        this.redirectToNext();
    }
  }

  continueToNext(){
    this.redirectToNext();
    this.docAlertModalRef.close();
 }

 redirectToNext() {
    this.sideMenuService.emitProgress(1);
    this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 2});
    this.sideMenuService.emitComplete('finishChooseClaim');
    this.router.navigate(["/claimform/anotherInsurance"], {
      relativeTo: this.activatedRoute
  }); 
 }

 showDocAlertModal() {
     this.docAlertModalRef = this.modalService.open(this.docAlertModal, {size: 'lg',
         backdrop: 'static',
         keyboard: false
     });

 }

    pushGAWsDocsView() {
        (<any>window).dataLayer.push({
            'pageStep': 'Damage of Windscreen – Document Upload',
            'vPath': '/claim/damage-of-windscreen/documents',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 6},	//Step6-DocumentUpload
                }
            }
        });
    }

}
