import {Component, Injector, OnInit, ElementRef, ViewChild, NgZone} from "@angular/core";
import {FormBuilder, FormGroup, FormControl} from "@angular/forms";
import {SideMenuService} from "../../../../../services/side-menu.service";
import {ClaimService} from "../../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../../claim-base.component";
import {Claim} from "../../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import {Windscreen} from '../../../../../model/motor/windscreen.model';
import { MapsAPILoader } from "@agm/core";
import { UtilitiesService } from "src/app/utilities/utilities.service";

declare let google: any;
@Component({
  selector: 'app-clinical-expenses-detail',
  templateUrl: './windscreen-location.component.html',
  styleUrls: ['./windscreen-location.component.css']
})

export class WindscreenLocationComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    detailForm: FormGroup;
    claim: Claim;
    windscreen: Windscreen;
    lat:any;
    lng:any;
    address:any;
    searchControl: FormControl;
    zoom: any;

    @ViewChild("search", {static: true})
    public searchElementRef: ElementRef;
    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector,
                private mapsAPILoader: MapsAPILoader,
                private ngZone: NgZone) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.windscreen = this.claim.getClaimMotor().getWindscreen();
    }
  
    ngOnInit() {

        this.pushGAWsLocation();

        // set default values
        if (UtilitiesService.isNullOrUndefined(this.windscreen.getDateOfAccident()) && !UtilitiesService.isNullOrUndefined(this.claim.getDateOfLoss())) {
            this.windscreen.setDateOfAccident(this.claim.getDateOfLoss());
        }

        if (UtilitiesService.isNullOrUndefined(this.windscreen.getCountryOccuredDesc()) || this.windscreen.getCountryOccuredDesc() == "") {
            this.windscreen.setCountryOccuredDesc("HONG KONG");
            this.windscreen.setCountryOccured("HKG");
        }

        this.detailForm = this.fb.group({
            dateOfAccident: [this.windscreen.getDateOfAccident()],
            causeOfDamage: [this.windscreen.getCauseOfDamage()],
            otherCauseOfDamage: [this.windscreen.getOtherCauseOfDamage()],
            countryOccured: [this.windscreen.getCountryOccured()],
            placeOfIncident: [this.windscreen.getPlaceOccured()]
        });

    }

    ngAfterViewInit() {

        this.detailForm.valueChanges.subscribe(data => {
            this.windscreen.setDateOfAccident(super.getValueInComponent('dateOfAccident'));
            this.windscreen.setCountryOccured(this.detailForm.get('countryOccured').value);
            this.windscreen.setCountryOccuredDesc(super.getTextInComponent('countryOccured'));
            this.windscreen.setCauseOfDamage(this.detailForm.get('causeOfDamage').value);
            this.windscreen.setCauseOfDamageDesc(super.getTextInComponent('causeOfDamage'));
            this.windscreen.setOtherCauseOfDamage(this.detailForm.get('otherCauseOfDamage').value);
            this.windscreen.setPlaceOccured(this.detailForm.get('placeOfIncident').value);

        });

        // Stop validation of the other field when reason isn't other
        let self = this;
        setTimeout(function () {self.hideShowOthersField(self.windscreen.getCauseOfDamage()),10});
        this.detailForm.get('causeOfDamage').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowOthersField(data);
        });
    }

    hideShowOthersField(damagedCause) {

        if (damagedCause && damagedCause.toLowerCase() == 'others') {
            this.detailForm.get('otherCauseOfDamage').enable({onlySelf: false, emitEvent: false});
        } else {
            this.detailForm.get('otherCauseOfDamage').reset();
            this.detailForm.get('otherCauseOfDamage').disable({onlySelf: false, emitEvent: false});
        }
    }


    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 0});
            this.router.navigate(["/claimform/windscreen/detailOfDamage"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.detailForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAWsLocation() {
        (<any>window).dataLayer.push({
            'pageStep': 'Damage of Windscreen – Damage-Accident Details',
            'vPath': '/claim/damage-of-windscreen/damage-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	 //Step5-ClaimDetailsStart
                }
            }
        });
    }

}
