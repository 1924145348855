import {Component, OnInit, HostListener, ViewEncapsulation, Injector} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {SideMenuService} from '../services/side-menu.service';
import { PageService } from '../services/page.service';
import {ClaimsBaseComponent} from './claim-base.component';

@Component({
    selector: 'app-claim-form',
    templateUrl: './claim-form.component.html',
    styleUrls: ['./claim-form.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ClaimFormComponent extends ClaimsBaseComponent  implements OnInit {

    customClass: string;
    _route: string;
    public pctOfProcess: any;
    mouseX = 0;
    mouseY = 0;
    tooltipText = '';

    constructor(private router: Router,
                public sideMenuService: SideMenuService,
                private pageService : PageService,
                private injector : Injector) {

        super(injector);

        router.events.subscribe((value) => {
            if (value instanceof NavigationEnd) {
                if (value.url === '/claimform/complete') {
                    this.customClass = 'customClass';
                } else {
                    this.customClass = '';
                }
            }
        });

        this.pageService.tooltipText$.subscribe(text => {
            this.tooltipText = text;
        })
    }

    ngOnInit() {
        this.sideMenuService.changePctOfProcessComplete$.subscribe(
            pctOfProcess => {
                this.pctOfProcess = pctOfProcess;
            }
        );
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(e: MouseEvent) {
        this.mouseX = e.clientX;
        this.mouseY = e.clientY + window.scrollY - 22;
    }
}
