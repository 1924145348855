import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {TripCurtailment} from "../../../../model/travel/trip-curtailment.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import {UtilitiesService} from "../../../../utilities/utilities.service";

@Component({
  selector: 'app-extra-cost',
  templateUrl: './extra-cost.component.html',
  styleUrls: ['./extra-cost.component.css']
})
export class ExtraCostComponent extends ClaimsBaseComponent implements OnInit {
    travelExtralCostForm: FormGroup;
    showTotalBox = false;
    tripCurtailment: TripCurtailment;
    currency: string;

    constructor(private fb: FormBuilder, 
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.tripCurtailment = this.claim.getClaimTravel().getTripCurtailment();
        this.currency = UtilitiesService.getCountryCurrency(this.claim.getCountry());
    }


    ngOnInit() {

        this.pushGAViewCurtailmentCost();

        this.travelExtralCostForm = this.fb.group({
            hasTranExtraCost: [super.getBooleanString(this.tripCurtailment.getHasTransExtraCost())],
            extraTransCostVal: [this.tripCurtailment.getExtraTransCostVal()],
            extraTransCostCurrency: [this.tripCurtailment.getExtraTransCostCurrency()],
            hasAccomExtraCost: [super.getBooleanString(this.tripCurtailment.getHasAccomExtraCost())],
            extraAccomCostVal: [this.tripCurtailment.getExtraAccomCostVal()],
            extraAccomCostCurrency: [this.tripCurtailment.getExtraAccomCostCurrency()]
        }); 
    }

    ngAfterViewInit() {

        this.travelExtralCostForm.valueChanges.subscribe(data => {
            if (this.travelExtralCostForm.get('hasTranExtraCost').value != null) {
                this.tripCurtailment.setHasTransExtraCost(this.travelExtralCostForm.get('hasTranExtraCost').value == 'true');
            }
            this.tripCurtailment.setExtraTransCostVal(super.getValueInComponent('extraTransCostVal'));
            this.tripCurtailment.setExtraTransCostCurrency(this.travelExtralCostForm.get('extraTransCostCurrency').value);
            if (this.travelExtralCostForm.get('hasAccomExtraCost').value != null) {
                this.tripCurtailment.setHasAccomExtraCost(this.travelExtralCostForm.get('hasAccomExtraCost').value == 'true');
            }
            this.tripCurtailment.setExtraAccomCostVal(super.getValueInComponent('extraAccomCostVal'));
            this.tripCurtailment.setExtraAccomCostCurrency(this.travelExtralCostForm.get('extraAccomCostCurrency').value);

            this.validateClaimDetailsComplete();
        });

        // Stop validation of fields when not required.
        let self = this;
        setTimeout(function () {self.hideExtraTransCost(self.getBooleanString(self.tripCurtailment.getHasTransExtraCost())),10});
        this.travelExtralCostForm.get('hasTranExtraCost').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideExtraTransCost(data);
        });

        // Stop validation of fields when not required.
        setTimeout(function () {self.hideAccomExtraCost(self.getBooleanString(self.tripCurtailment.getHasAccomExtraCost())),10});
        this.travelExtralCostForm.get('hasAccomExtraCost').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideAccomExtraCost(data);
        });
    }

    hideExtraTransCost(hasTranExtraCost) {
        if (hasTranExtraCost == 'true') {
            this.travelExtralCostForm.get('extraTransCostVal').enable({onlySelf: false, emitEvent: false});
            this.travelExtralCostForm.get('extraTransCostCurrency').enable({onlySelf: false, emitEvent: false});
        } else {
            this.travelExtralCostForm.get('extraTransCostVal').reset();
            this.travelExtralCostForm.get('extraTransCostCurrency').setValue(this.currency);
            this.travelExtralCostForm.get('extraTransCostVal').disable({onlySelf: false, emitEvent: false});
            this.travelExtralCostForm.get('extraTransCostCurrency').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideAccomExtraCost(hasAccomExtraCost) {
        if (hasAccomExtraCost == 'true') {
            this.travelExtralCostForm.get('extraAccomCostVal').enable({onlySelf: false, emitEvent: false});
            this.travelExtralCostForm.get('extraAccomCostCurrency').enable({onlySelf: false, emitEvent: false});
        } else {
            this.travelExtralCostForm.get('extraAccomCostVal').reset();
            this.travelExtralCostForm.get('extraAccomCostCurrency').setValue(this.currency);
            this.travelExtralCostForm.get('extraAccomCostVal').disable({onlySelf: false, emitEvent: false});
            this.travelExtralCostForm.get('extraAccomCostCurrency').disable({onlySelf: false, emitEvent: false});
        }
    }


    back() {
        this.router.navigate(["/claimform/tripCurtailment/lossCauseByCurtailment"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 8, subMenuIndex: 2});
            this.router.navigate(["/claimform/tripCurtailment/curtailmentSupportDoc/uploadDocument"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.travelExtralCostForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.travelExtralCostForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitClaimComplete({claimTypeId: 8, subMenuIndex: 2});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 8, subMenuIndex: 2});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewCurtailmentCost() {
        (<any>window).dataLayer.push({
            'pageStep': 'Trip Curtailment – Extra Cost',
            'vPath': '/claim/trip-curtailment/extra-cost',
            'event': 'vpageview',
        });
    }
}
