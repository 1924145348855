<div class="select-claim-container" [ngClass]="{'mox-style': claim.isMox()}">
  <div class="prev-bar">
    <a (click)="back()"  style="color: #0064c1" class="btn btn-secondary new-btn"><i class="fas fa-arrow-left"></i>&nbsp;{{'claimDetails.body.back' | translate}}&nbsp;</a>
    <a (click)="back()" class="back-chevron"></a>
  </div>
  <div class="col-12 bobby">
    <h5> {{'claimDetails.body.title' | translate}}</h5>
    <p *ngIf="!this.transactionInfo.isHomeProduct() && !this.transactionInfo.isEmployeeCompensationProduct()">{{'claimDetails.body.selectType' | translate}}</p>
    <p *ngIf="this.transactionInfo.isHomeProduct()">{{'claimDetails.body.selectCauseType' | translate}}</p>
    <ng-container *ngIf="this.transactionInfo.isEmployeeCompensationProduct()">
      <p>{{'ecClaim.selectClaim.subHeading1' | translate}}</p>
      <p>{{'ecClaim.selectClaim.subHeading2' | translate}}</p>
    </ng-container>
    <qnect-notification-messages [notifications]="notifications"></qnect-notification-messages>
  </div>
  <div class="row claimTypeBox col-12">
    <div [ngClass]="{'col-lg-3 col-md-6 col-sm-6': this.transactionInfo.isTravelProduct() || this.transactionInfo.isHomeProduct() || this.transactionInfo.isEmployeeCompensationProduct(),
      'col-lg-4 col-md-6 col-sm-6': this.transactionInfo.isDomesticHelperProduct() || this.transactionInfo.isMotorProduct() || this.transactionInfo.isAccidentProduct()}"
         *ngFor="let claimType of claimTypeData; let i = index"
         (click)="toggleActive(claimType.claimTypeId, claimType)" [hidden]="!claimType.show">
      <div class="cperson-wrapper text-center " style="height: 200px;display: flex;align-items: center;justify-content: center;" [class.active]="chooseIndex == claimType.claimTypeId || claimType.selected">
        <div [ngClass]="!this.transactionInfo.isMotorProduct() ? 'cpreson-outter' : 'cpreson-outter-motor-icons'" style="align-items:center; display: flex; justify-content: center">
          <div>
            <img class="icon" [src]="claimType['icon']" alt="Claim Type Icon">
            <h5 style="margin-top: 10px;">{{claimType.claimTypeIdName | translate}}</h5>
            <h6 *ngIf="claimType.description != ''"> {{claimType.description | translate}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row col-12" *ngIf="this.transactionInfo.isEmployeeCompensationProduct()">
    <div class="col-md-12 col-sm-12">
      <p>
        <a href="javascript:;" (click)="formSelectionModal.open();" class="click-more">{{ 'ecClaim.selectClaim.clickHere' | translate}}</a>
        {{ 'ecClaim.selectClaim.unsureForm' | translate}}
      </p>
    </div>
  </div>
  <div class="submit-bar">
    <a href="javascript:;" (click)="showModal()" class="Tertiary" [hidden]="claim.isMox()"> {{'claimDetails.body.save' | translate}}</a>
    &nbsp;
    <abandon-button></abandon-button>
    <button (click)="goToNext()"  class="new-btn btn btn-primary float-right">&nbsp;{{'claimDetails.body.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

<ng-template #alert_claim_type let-c="close" let-d="dismiss">
  <div class="modal-header">
      <h4 class="modal-title cursor-pointer">{{'error_messages.notificationsErrMsg.sameClaimTypeMsg1'  | translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="continue()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <p>{{'error_messages.notificationsErrMsg.sameClaimTypeMsg2' | translate}}</p>
  </div>
  <div class="modal-footer">
      <div class="col-md-12">
          <button type="button" class="btn btn-default btn-sm" (click)="continue()">{{'claimSection.generalLabels.generalButtonLabels.close'  | translate}}</button>
      </div>
  </div>
</ng-template>

<ng-template #confirm_delete_claim_type let-c="close" let-d="dismiss">
  <div [ngClass]="{'mox-style': claim.isMox()}">
      <div class="modal-header">
          <h5 class="modal-title">{{'sideMenu.deleteClaim.header' | translate }}</h5>
          <button type="button" class="close" aria-label="Close" (click)="cancelDeleteClaimType()">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
        <p *ngIf="!claim.isMox()">{{'sideMenu.deleteClaim.question1' | translate }}</p>
        <p *ngIf="claim.isMox()">{{'sideMenu.deleteClaim.question-mox' | translate }}</p>
      </div>
      <div class="modal-footer">
          <div class="col-md-6 text-right">
              <button type="button" class="btn btn-primary btn-sm" (click)="deleteClaimType()">
                  <span *ngIf="!claim.isMox()">{{'sideMenu.deleteClaim.yes' | translate }}</span>
                  <span *ngIf="claim.isMox()">{{'sideMenu.deleteClaim.yes-mox' | translate }}</span>
              </button>
          </div>
          <div class="col-md-6 text-left">
              <button type="button" class="btn btn-default btn-sm modal-btn-secondary" (click)="cancelDeleteClaimType()">
                  <span *ngIf="!claim.isMox()">{{'sideMenu.deleteClaim.no' | translate }}</span>
                  <span *ngIf="claim.isMox()">{{'sideMenu.deleteClaim.no-mox' | translate }}</span>
              </button>
          </div>
      </div>
  </div>
</ng-template>

<ec-form-selection-modal #ec_form_selection_modal [claimTypeData]="claimTypeData" (onConfirmEmit)="onConfirmECFormSelection($event)"></ec-form-selection-modal>
