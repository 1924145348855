import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {VisitedDoctor} from "../../../../model/travel/visted-a-doctor.model";
import {takeUntil} from "rxjs";

@Component({
    selector: 'app-follow-up-treatment',
    templateUrl: './follow-up-treatment.component.html',
    styleUrls: ['./follow-up-treatment.component.css']
})
export class FollowUpTreatmentComponent extends ClaimsBaseComponent implements OnInit {

    detailOfFollowUpTreamentForm: FormGroup;
    showTotalBox = false;
    visitedDoctor: VisitedDoctor;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.visitedDoctor = this.claim.getClaimTravel().getVisitedDoctor();
    }

    ngOnInit() {

        this.pushGAViewDoctorFollowUp();

        this.detailOfFollowUpTreamentForm = this.fb.group(
            {
                hasReceiveFollowUpTreament: [super.getBooleanString(this.visitedDoctor.getHasReceiveFollowUpTreatment())],
                isTreatmentCompleted: [super.getBooleanString(this.visitedDoctor.getIsTreatmentCompleted())],
                hasSufferedIllness: [super.getBooleanString(this.visitedDoctor.getHasSufferedIllness())],
                returnHkDate: [this.visitedDoctor.getReturnHkDate()],
                lastTreamentDate: [this.visitedDoctor.getLastTreatmentDate()],
                dateOfFirstVisit: [this.visitedDoctor.getDateOfFirstVisit()]
            }
        );
    }

    ngAfterViewInit() {
        this.detailOfFollowUpTreamentForm.valueChanges.subscribe(data => {
            this.visitedDoctor.setHasReceiveFollowUpTreatment(this.detailOfFollowUpTreamentForm.get('hasReceiveFollowUpTreament').value == 'true');
            if (this.detailOfFollowUpTreamentForm.get('isTreatmentCompleted').value != null) {
                this.visitedDoctor.setIsTreatmentCompleted(this.detailOfFollowUpTreamentForm.get('isTreatmentCompleted').value == 'true');
            }
            if (this.detailOfFollowUpTreamentForm.get('hasSufferedIllness').value != null) {
                this.visitedDoctor.setHasSufferedIllness(this.detailOfFollowUpTreamentForm.get('hasSufferedIllness').value == 'true');
            }
            this.visitedDoctor.setReturnHkDate(super.getValueInComponent('returnHkDate'));
            this.visitedDoctor.setLastTreatmentDate(super.getValueInComponent('lastTreamentDate'));
            this.visitedDoctor.setDateOfFirstVisit(super.getValueInComponent('dateOfFirstVisit'));
            this.validateClaimDetailsComplete();
        });

        // Stop validation of fields when not required.
        let self = this;
        setTimeout(function () {self.hideFollowUpTreatment(self.getBooleanString(self.visitedDoctor.getHasReceiveFollowUpTreatment())),10});
        this.detailOfFollowUpTreamentForm.get('hasReceiveFollowUpTreament').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideFollowUpTreatment(data);
        });
    }

    hideFollowUpTreatment(hasReceiveFollowUpTreament) {

        if (hasReceiveFollowUpTreament == 'true') {
            this.detailOfFollowUpTreamentForm.get('lastTreamentDate').enable({onlySelf: false, emitEvent: false});
            this.detailOfFollowUpTreamentForm.get('isTreatmentCompleted').enable({onlySelf: false, emitEvent: false});
        } else {
            this.detailOfFollowUpTreamentForm.get('lastTreamentDate').reset();
            this.detailOfFollowUpTreamentForm.get('isTreatmentCompleted').reset();
            this.detailOfFollowUpTreamentForm.get('lastTreamentDate').disable({onlySelf: false, emitEvent: false});
            this.detailOfFollowUpTreamentForm.get('isTreatmentCompleted').disable({onlySelf: false, emitEvent: false});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 4, subMenuIndex: 2});
            this.router.navigate(["/claimform/visitedADoctor/medicalBills"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.detailOfFollowUpTreamentForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.detailOfFollowUpTreamentForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitClaimComplete({claimTypeId: 4, subMenuIndex: 2});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 4, subMenuIndex: 2});
        }
    }

    back() {
        this.router.navigate(["/claimform/visitedADoctor/detailsOfOverseaDoctorVisit"], {relativeTo: this.activatedRoute});
    }

    //Google Analytics
    pushGAViewDoctorFollowUp() {
        (<any>window).dataLayer.push({
            'pageStep': 'Visit Doctor – Follow-up Treatment',
            'vPath': '/claim/visit-doctor/followup',
            'event': 'vpageview',
        });
    }
}
