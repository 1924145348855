import {ClaimTravel} from "./claim-travel.model";
import {ClaimDomesticHelper} from "./claim-domestic-model";
import {ClaimMotor} from "./claim-motor.model";
import {ClaimHome} from "./claim-home.model";
import {JsonProperty} from "../utilities/utils/json-property.decorator";
import {DocumentDetails} from "./document-details.model";
import {DocumentForm} from "./document-form.model";
import {PolicyRisks} from "./policy-risks.model";
import {ClaimResponse} from "./claim-response-model";
import {Claimant} from './claimant.model';
import { OverseasBankPayment } from "./overseas-bank-payment.model";
import { ClaimPersonalAccident } from "./claim-personal-accident.model";
import {ClaimEmployeeCompensation} from './claim-employee-compensation.model';
export class Claim {

  private channel: string;
  private notificationNumber: string;
  private forNotificationReason: string;
  private notificationNumberList: string[] = [];
  private claimNumberList: string[] = [];
  private claimResponseList: ClaimResponse[] = [];
  private currentClaimIndex = 0;
  private policyNumber: string = "";
  private product: string = "";
  private productDescription: string = "";
  private agentName: string = "";
  private agentEmail: string = "";
  private policyHolderName: string = "";
  private policyHolderType: string = "";
  private riskNumber: number = 1;
  private riskType: string;
  private contactEmail: string;
  private contactNumber: string;
  private contactNumberCountryCode: string;
  private contactName: string = "";
  private contactGivenName: string = "";
  private contactFamilyName: string = "";
  private otherPolicyQuestion: string = "";
  private otherPolicyName: string = "";
  private otherPolicyNumber: string = "";
  private otherPolicyGoingToSubmit: boolean;
  private declaration: string = "";
  private notifyAgent: boolean;
  private directMail: string = "";
  private premiumClass: string = "";
  private premiumClassList: string[] = [];
  private country: string;
  private language: string = 'en';
  private planCode: string;
  private insuredPersonNumber: string;
  private isManulife: boolean = false;
  private catalogueCode: string = "";
  private termsAndConditionsCheckbox : boolean;
  private hkfiTermsAndConditions: boolean;
  private staffCode: string;
  private agentCode: string;
  private isCoverNotePreNumbered: string = "";
  private coverNoteNumber: string = "";
  private serviceUnit: string = "";
  private isGeneric: boolean = false;
  private estimatedAmountList: string[] = [];
  private policyHolderId: string = "";
  private isPolicyholder: boolean;
  private policyholderEmail: string;
  private documentShareToAgent: boolean;
  private agentBranch: string;

  //GCTPASIA-3753
  private accountNameDefault        : string;

  private claimantClientKey         : string;
  private claimantClientType        : string;
  private claimantName              : string;
  private claimantCompanyName       : string;
  private claimantIdNumber          : string;
  private claimantDateOfBirth       : Date;
  private claimantAddress1          : string;
  private claimantAddress2          : string;
  private claimantAddress3          : string;
  private claimantPostcode          : string;
  private claimantCountry           : string;
  private claimantCountryText       : string;
  private claimantContactNumber     : string = '';
  private claimantEmail             : string;
  private claimantRegistration      : string;
  private claimantGivenName         : string = '';
  private claimantFamilyName        : string = '';
  private claimantTitle             : string;
  private modeOfPayment             : string = "";
  private paymentRefundToBank       : string = "Y"; // default to direct bank
  private claimantIdentifier        : string = '';


  // payment details: Direct
  private accountNameDirect: string;
  private accountGivenNameDirect: string;
  private accountFamilyNameDirect: string;
  private branchCode: string;
  private bankDirect: string;
  private bankDirectDesc: string;
  private accountNumberDirect: string;

  // payment details: Cheque
  private paymentClaimantName: string;
  private paymentClaimantGivenName: string;
  private paymentClaimantFamilyName: string;
  private addressLine1: string;
  private addressLine2: string;
  private addressLine3: string;
  private addressLine4: string;
  private addressPostcode: string;
  private addressCountry: string;
  private addressCountryText: string;
  // claim details: Generic
  private dateOfLoss: Date;
  private claimType: string;
  private claimEventOccurred: string;
  private claimEventOccurredDesc: string;
  private claimLocation: string;
  private amountClaimed: string;
  private incidentDescription: string;
  private currencyOfAmount: string;
  private errorMessage: string;
  private authenticateDOB: Date;
  private transactionOrigin: string;
  private docToken: string;
  private vendor: string;
  private callbackUrl: string;

  // List of selected claim types
  private selectedClaimTypesList: string[] = [];

  // Head office classes
  private headOfficeClassesList: string[] = [];

  //policy risks info
  private policyRisks: PolicyRisks[] = [];

  // claimant list
  private claimantList: Claimant[] = [];

  // Travel Details
  @JsonProperty({name: 'claimTravel', clazz: ClaimTravel})
  private claimTravel: ClaimTravel;

  // Domestic Helper Details
  @JsonProperty({name: 'claimDomesticHelper', clazz: ClaimDomesticHelper})
  private claimDomesticHelper: ClaimDomesticHelper;

  // Motor Details
  @JsonProperty({name: 'claimMotor', clazz: ClaimMotor})
  private claimMotor: ClaimMotor;

  // Home Details
  @JsonProperty({name: 'ClaimHome', clazz: ClaimHome})
  private claimHome: ClaimHome;

  // Employee Compensation Details
  @JsonProperty({name: 'ClaimPersonalAccident', clazz: ClaimPersonalAccident})
  private claimPersonalAccident: ClaimPersonalAccident;

  @JsonProperty({name: 'documentForm', clazz: DocumentDetails})
  documentForm: DocumentForm = new DocumentForm;

  @JsonProperty({name: 'overseasBankPayment', clazz: OverseasBankPayment})
  private overseasBankPayment: OverseasBankPayment;

  @JsonProperty({name: 'claimEmployeeCompensation', clazz: ClaimEmployeeCompensation})
  private claimEmployeeCompensation: ClaimEmployeeCompensation;

  getAuthenticateDOB(): Date {
    return this.authenticateDOB;
  }

  setAuthenticateDOB(value: Date) {
    this.authenticateDOB = value;
  }

  getChannel(): string {
      return this.channel;
  }

  setChannel(value: string) {
      this.channel = value;
  }

  getInsuredPersonNumber(): string {
    return this.insuredPersonNumber;
  }

  setInsuredPersonNumber(value: string) {
    this.insuredPersonNumber = value;
  }

  getPlanCode(): string {
    return this.planCode;
  }

  setPlanCode(value: string) {
    this.planCode = value;
  }


    getIsManulife(): boolean {
        return this.isManulife;
    }

    setIsManulife(value: boolean) {
        this.isManulife = value;
    }

    getCatalogueCode(): string {
        return this.catalogueCode;
    }

    setCatalogueCode(value: string) {
        this.catalogueCode = value;
    }

    getAddressCountryText(): string {
    return this.addressCountryText;
  }

  setAddressCountryText(value: string) {
    this.addressCountryText = value;
  }

  getClaimantRegistration(): string {
    return this.claimantRegistration;
  }

  setClaimantRegistration(value: string) {
    this.claimantRegistration = value;
  }

  getClaimantGivenName(): string {
    return this.claimantGivenName;
  }

  setClaimantGivenName(value: string) {
    this.claimantGivenName = value;
  }

  getClaimantFamilyName(): string {
    return this.claimantFamilyName;
  }

  setClaimantFamilyName(value: string) {
    this.claimantFamilyName = value;
  }

  getClaimantTitle(): string {
    return this.claimantTitle;
  }

  setClaimantTitle(value: string) {
    this.claimantTitle = value;
  }

  getNotificationNumber(): string {
    return this.notificationNumber;
  }

  setNotificationNumber(value: string) {
    this.notificationNumber = value;
  }

  getForNotificationReason(): string {
    return this.forNotificationReason;
  }

  setForNotificationReason(value: string) {
    this.forNotificationReason = value;
  }

   getNotificationNumberList(): string[] {
        return this.notificationNumberList;
    }

    setNotificationNumberList(value: string[]) {
        this.notificationNumberList = value;
    }

  public getClaimNumberList(): string[] {
    return this.claimNumberList;
  }

  public setClaimNumberList(value: string[]) {
    this.claimNumberList = value;
  }

  getPolicyNumber(): string {
    return this.policyNumber;
  }

  setPolicyNumber(value: string) {
    this.policyNumber = value;
  }

  getProduct(): string {
    return this.product;
  }

  setProduct(value: string) {
    this.product = value;
  }

  getAgentName(): string {
    return this.agentName;
  }

  setAgentName(value: string) {
    this.agentName = value;
  }

  getAgentEmail(): string {
    return this.agentEmail;
  }

  setAgentEmail(value: string) {
    this.agentEmail = value;
  }

  getPolicyHolderName(): string {
    return this.policyHolderName;
  }

  setPolicyHolderName(value: string) {
    this.policyHolderName = value;
  }

  getPolicyHolderType(): string {
    return this.policyHolderType;
  }

  setPolicyHolderType(value: string) {
    this.policyHolderType = value;
  }

  getRiskNumber(): number {
    return this.riskNumber;
  }

  setRiskNumber(value: number) {
    this.riskNumber = value;
  }

  getRiskType(): string {
    return this.riskType;
  }

  setRiskType(value: string) {
    this.riskType = value;
  }

  getContactName(): string {
    return this.contactName;
  }

  setContactName(value: string) {
    this.contactName = value;
  }

  getContactGivenName(): string {
    return this.contactGivenName;
  }

  setContactGivenName(value: string) {
    this.contactGivenName = value;
  }

  getContactFamilyName(): string {
    return this.contactFamilyName;
  }

  setContactFamilyName(value: string) {
    this.contactFamilyName = value;
  }

  getDateOfLoss(): Date {
    return this.dateOfLoss;
  }

  setDateOfLoss(value: Date) {
    this.dateOfLoss = value;
  }

  getContactEmail(): string {
    return this.contactEmail;
  }

  setContactEmail(value: string) {
    this.contactEmail = value;
  }

  getContactNumber(): string {
    return this.contactNumber;
  }

  setContactNumber(value: string) {
    this.contactNumber = value;
  }

  getContactNumberCountryCode(): string {
    return this.contactNumberCountryCode;
  }

  setContactNumberCountryCode(value: string) {
    this.contactNumberCountryCode = value;
  }


  getOtherPolicyQuestion(): string {
    return this.otherPolicyQuestion;
  }

  setOtherPolicyQuestion(value: string) {
    this.otherPolicyQuestion = value;
  }

  getOtherPolicyName(): string {
    return this.otherPolicyName;
  }

  setOtherPolicyName(value: string) {
    this.otherPolicyName = value;
  }

  getOtherPolicyNumber(): string {
    return this.otherPolicyNumber;
  }

  setOtherPolicyNumber(value: string) {
    this.otherPolicyNumber = value;
  }


    getOtherPolicyGoingToSubmit(): boolean {
        return this.otherPolicyGoingToSubmit;
    }

    setOtherPolicyGoingToSubmit(value: boolean) {
        this.otherPolicyGoingToSubmit = value;
    }

    getDeclaration(): string {
    return this.declaration;
  }

  setDeclaration(value: string) {
    this.declaration = value;
  }

  getTermsAndConditionsCheckbox(): boolean {
     return this.termsAndConditionsCheckbox;
  }

  setTermsAndConditionsCheckbox(value: boolean) {
    this.termsAndConditionsCheckbox = value;
  }

  getHkfiTermsAndConditions(): boolean {
     return this.hkfiTermsAndConditions;
  }

  setHkfiTermsAndConditions(value: boolean) {
    this.hkfiTermsAndConditions = value;
  }

  getModeOfPayment(): string {
    return this.modeOfPayment;
  }

  setModeOfPayment(value: string) {
    this.modeOfPayment = value;
  }

  getAccountNameDirect(): string {
    return this.accountNameDirect;
  }

  setAccountNameDirect(value: string) {
    this.accountNameDirect = value;
  }

  getAccountGivenNameDirect(): string {
    return this.accountGivenNameDirect;
  }

  setAccountGivenNameDirect(value: string) {
    this.accountGivenNameDirect = value;
  }

  getAccountFamilyNameDirect(): string {
    return this.accountFamilyNameDirect;
  }

  setAccountFamilyNameDirect(value: string) {
    this.accountFamilyNameDirect = value;
  }

    getBranchCode(): string {
        return this.branchCode;
    }

    setBranchCode(value: string) {
        this.branchCode = value;
    }

  getBankDirect(): string {
    return this.bankDirect;
  }

  setBankDirect(value: string) {
    this.bankDirect = value;
  }

  getBankDirectDesc(): string {
    return this.bankDirectDesc;
  }

  setBankDirectDesc(value: string) {
    this.bankDirectDesc = value;
  }

  getAccountNumberDirect(): string {
    return this.accountNumberDirect;
  }

  setAccountNumberDirect(value: string) {
    this.accountNumberDirect = value;
  }

  getAccountNameDefault(): string {
    return this.accountNameDefault;
  }

  setAccountNameDefault(value: string) {
    this.accountNameDefault = value;
  }

  getPaymentClaimantName(): string {
    return this.paymentClaimantName;
  }

  setPaymentClaimantName(value: string) {
    this.paymentClaimantName = value;
  }

  getPaymentClaimantGivenName(): string {
    return this.paymentClaimantGivenName;
  }

  setPaymentClaimantGivenName(value: string) {
    this.paymentClaimantGivenName = value;
  }

  getPaymentClaimantFamilyName(): string {
    return this.paymentClaimantFamilyName;
  }

  setPaymentClaimantFamilyName(value: string) {
    this.paymentClaimantFamilyName = value;
  }

  getAddressLine1(): string {
    return this.addressLine1;
  }

  setAddressLine1(value: string) {
    this.addressLine1 = value;
  }

  getAddressLine2(): string {
    return this.addressLine2;
  }

  setAddressLine2(value: string) {
    this.addressLine2 = value;
  }

  getAddressLine3(): string {
    return this.addressLine3;
  }

  setAddressLine3(value: string) {
    this.addressLine3 = value;
  }

  getAddressLine4(): string {
    return this.addressLine4;
  }

  setAddressLine4(value: string) {
    this.addressLine4 = value;
  }

  getAddressPostcode(): string {
    return this.addressPostcode;
  }

  setAddressPostcode(value: string) {
    this.addressPostcode = value;
  }

  getAddressCountry(): string {
    return this.addressCountry;
  }

  setAddressCountry(value: string) {
    this.addressCountry = value;
  }

  getClaimType(): string {
    return this.claimType;
  }

  setClaimType(value: string) {
    this.claimType = value;
  }

  getClaimEventOccurred(): string {
    return this.claimEventOccurred;
  }

  setClaimEventOccurred(value: string) {
    this.claimEventOccurred = value;
  }

  getClaimEventOccurredDesc(): string {
    return this.claimEventOccurredDesc;
  }

  setClaimEventOccurredDesc(value: string) {
    this.claimEventOccurredDesc = value;
  }

  getClaimLocation(): string {
    return this.claimLocation;
  }

  setClaimLocation(value: string) {
    this.claimLocation = value;
  }

  getAmountClaimed(): string {
    return this.amountClaimed;
  }

  setAmountClaimed(value: string) {
    this.amountClaimed = value;
  }

  getIncidentDescription(): string {
    return this.incidentDescription;
  }

  setIncidentDescription(value: string) {
    this.incidentDescription = value;
  }

  getCurrencyOfAmount(): string {
    return this.currencyOfAmount;
  }

  setCurrencyOfAmount(value: string) {
    this.currencyOfAmount = value;
  }

  getErrorMessage(): string {
    return this.errorMessage;
  }

  setErrorMessage(value: string) {
    this.errorMessage = value;
  }

  getPaymentRefundToBank(): string {
    return this.paymentRefundToBank;
  }

  setPaymentRefundToBank(value: string) {
    this.paymentRefundToBank = value;
  }


  getClaimTravel(): ClaimTravel {
    return this.claimTravel;
  }

  setClaimTravel(value: ClaimTravel) {
    this.claimTravel = value;
  }

  getDocumentForm(): DocumentForm {
    return this.documentForm;
  }

  setDocumentForm(value: DocumentForm) {
    this.documentForm = value;
  }

  getDirectMail(): string {
    return this.directMail;
  }

  setDirectMail(value: string) {
    this.directMail = value;
  }

  getPremiumClass(): string {
    return this.premiumClass;
  }

  setPremiumClass(value: string) {
    this.premiumClass = value;
  }

  getCountry(): string {
    return this.country;
  }

  setCountry(value: string) {
    this.country = value;
  }

  getLanguage(): string {
    return this.language;
  }

  setLanguage(value: string) {
    this.language = value;
  }

  getClaimantName(): string {
    return this.claimantName;
  }

  setClaimantName(value: string) {
    this.claimantName = value;
  }

  getClaimantCompanyName(): string {
    return this.claimantCompanyName;
  }

  setClaimantCompanyName(value: string) {
    this.claimantCompanyName = value;
  }

  getClaimantClientKey(): string {
    return this.claimantClientKey;
  }

  setClaimantClientKey(value: string) {
    this.claimantClientKey = value;
  }

  getClaimantClientType(): string {
    return this.claimantClientType;
  }

  setClaimantClientType(value: string) {
    this.claimantClientType = value;
  }

  getClaimantIdNumber(): string {
    return this.claimantIdNumber;
  }

  setClaimantIdNumber(value: string) {
    this.claimantIdNumber = value;
  }

  getClaimantDateOfBirth(): Date {
    return this.claimantDateOfBirth;
  }

  setClaimantDateOfBirth(value: Date) {
    this.claimantDateOfBirth = value;
  }

  getClaimantAddress1(): string {
    return this.claimantAddress1;
  }

  setClaimantAddress1(value: string) {
    this.claimantAddress1 = value;
  }

  getClaimantAddress2(): string {
    return this.claimantAddress2;
  }

  setClaimantAddress2(value: string) {
    this.claimantAddress2 = value;
  }

  getClaimantAddress3(): string {
    return this.claimantAddress3;
  }

  setClaimantAddress3(value: string) {
    this.claimantAddress3 = value;
  }

  getClaimantPostcode(): string {
    return this.claimantPostcode;
  }

  setClaimantPostcode(value: string) {
    this.claimantPostcode = value;
  }

  getClaimantCountry(): string {
    return this.claimantCountry;
  }

  setClaimantCountry(value: string) {
    this.claimantCountry = value;
  }

  getClaimantCountryText(): string {
    return this.claimantCountryText;
  }

  setClaimantCountryText(value: string) {
    this.claimantCountryText = value;
  }

  getClaimantContactNumber(): string {
    return this.claimantContactNumber;
  }

  setClaimantContactNumber(value: string) {
    this.claimantContactNumber = value;
  }

  getClaimantEmail(): string {
    return this.claimantEmail;
  }

  setClaimantEmail(value: string) {
    this.claimantEmail = value;
  }

  getSelectedClaimTypesList(): string[] {
    return this.selectedClaimTypesList;
  }

  setSelectedClaimTypesList(value: string[]) {
    this.selectedClaimTypesList = value;
  }

  getClaimDomesticHelper(): ClaimDomesticHelper {
    return this.claimDomesticHelper;
  }

  setClaimDomesticHelper(value: ClaimDomesticHelper) {
    this.claimDomesticHelper = value;
  }


  getClaimMotor(): ClaimMotor {
    return this.claimMotor;
  }

  setClaimMotor(value: ClaimMotor) {
    this.claimMotor = value;
  }

  getClaimHome(): ClaimHome {
    return this.claimHome;
  }

  setClaimHome(value: ClaimHome) {
    this.claimHome = value;
  }

  getClaimPersonalAccident(): ClaimPersonalAccident {
    return this.claimPersonalAccident;
  }

  setClaimPersonalAccident(value: ClaimPersonalAccident) {
    this.claimPersonalAccident = value;
  }

  getClaimEmployeeCompensation(): ClaimEmployeeCompensation {
    return this.claimEmployeeCompensation;
  }

  setClaimEmployeeCompensation(value: ClaimEmployeeCompensation) {
   this.claimEmployeeCompensation = value;
  }

  getHeadOfficeClassesList(): string[] {
    return this.headOfficeClassesList;
  }

  setHeadOfficeClassesList(value: string[]) {
    this.headOfficeClassesList = value;
  }

  getPremiumClassList(): string[] {
    return this.premiumClassList;
  }

  setPremiumClassList(value: string[]) {
    this.premiumClassList = value;
  }

  getPolicyRisks(): PolicyRisks[] {
    return this.policyRisks;
  }

  setPolicyRisks(value: PolicyRisks[]) {
    this.policyRisks = value;
  }

  getCurrentClaimIndex(): number {
    return this.currentClaimIndex;
  }

  setCurrentClaimIndex(value: number){
      this.currentClaimIndex = value;
  }

  getAgentCode(): string {
    return this.agentCode;
  }

  setAgentCode(value: string) {
    this.agentCode = value;
  }

  getStaffCode(): string {
    return this.staffCode;
  }

  setStaffCode(value: string) {
    this.staffCode = value;
  }

  getClaimResponseList(): ClaimResponse[] {
    return this.claimResponseList;
  }

  setClaimResponseList(value: ClaimResponse[]) {
    this.claimResponseList = value;
  }

  getClaimantList(): Claimant[] {
    return this.claimantList;
  }

  setClaimantList(value: Claimant[]) {
    this.claimantList = value;
  }

  getClaimantIdentifier(): string {
    return this.claimantIdentifier;
  }

  setClaimantIdentifier(value: string) {
    this.claimantIdentifier = value;
  }

  getIsCoverNotePreNumbered(): string {
    return this.isCoverNotePreNumbered;
  }

  setIsCoverNotePreNumbered(value: string) {
    this.isCoverNotePreNumbered = value;
  }

  getCoverNoteNumber(): string {
    return this.coverNoteNumber;
  }

  setCoverNoteNumber(value: string) {
    this.coverNoteNumber = value;
  }

  getServiceUnit(): string {
    return this.serviceUnit;
  }

  setServiceUnit(value: string) {
    this.serviceUnit = value;
  }

  getProductDescription(): string {
    return this.productDescription;
  }

  setProductDescription(value: string) {
    this.productDescription = value;
  }

  getIsGeneric(): boolean {
    return this.isGeneric;
  }

  setIsGeneric(value: boolean) {
    this.isGeneric = value;
  }

  getEstimatedAmountList(): string[] {
    return this.estimatedAmountList;
  }

  setEstimatedAmountList(value: string[]) {
    this.estimatedAmountList = value;
  }

  getPolicyHolderId(): string {
    return this.policyHolderId;
  }

  setPolicyHolderId(value: string) {
    this.policyHolderId = value;
  }

  getIsPolicyholder(): boolean {
    return this.isPolicyholder;
  }

  setIsPolicyholder(isPolicyholder: boolean): void {
    this.isPolicyholder = isPolicyholder;
  }

  getPolicyholderEmail(): string {
    return this.policyholderEmail;
  }

  setPolicyholderEmail(value: string) {
    this.policyholderEmail = value;
  }

  getNotifyAgent(): boolean {
    return this.notifyAgent;
  }

  setNotifyAgent(value: boolean) {
    this.notifyAgent = value;
  }

  getDocumentShareToAgent(): boolean {
    return this.documentShareToAgent;
  }

  setDocumentShareToAgent(value: boolean) {
   this.documentShareToAgent = value;
  }

  getAgentBranch(): string {
    return this.agentBranch;
  }

  setAgentBranch(value: string) {
    this.agentBranch = value;
  }

  getOverseasBankPayment(): OverseasBankPayment {
    return this.overseasBankPayment;
  }

  setOverseasBankPayment(obj: OverseasBankPayment) {
    this.overseasBankPayment = obj;
  }

  getTransactionOrigin(): string {
    return this.transactionOrigin;
  }

  setTransactionOrigin(value: string) {
   this.transactionOrigin = value;
  }

  getDocToken(): string {
    return this.docToken;
  }

  setDocToken(value: string) {
     this.docToken = value;
  }

  isHKGorHGI(): boolean {
    return this.country == 'HKG' || this.country == 'HGI';
  }

  getVendor(): string {
    return this.vendor;
  }
  setVendor(value: string) {
    this.vendor = value;
  }
  getCallbackUrl(): string {
    return this.callbackUrl;
  }

  setCallbackUrl(value: string) {
    this.callbackUrl = value;
  }

  isMox(): boolean {
    return this.vendor == 'MOX' || this.vendor == 'mox';
  }

  isMILDMHProductPlan() : boolean {
    return (this.country?.toLowerCase() === 'hkg' && this.isManulife &&
        ['N6', 'N7', 'N8', 'N9'].indexOf(this.planCode) > -1);
  }

  static jsonConvertClaim(claim : Claim): Claim {

    claim = Object.assign(new Claim(), claim);

    if (claim.getClaimantDateOfBirth() != null) {
      claim.setClaimantDateOfBirth(new Date(claim.getClaimantDateOfBirth()));
    }

    if (claim.getDateOfLoss() != null) {
      claim.setDateOfLoss(new Date(claim.getDateOfLoss()));
    }

    if(claim.getPolicyRisks() != null) {
      claim.setPolicyRisks(PolicyRisks.jsonConvert(claim.getPolicyRisks()));
    }

    // Convert the domestic helper
    if (claim.getClaimDomesticHelper()) {
      claim.setClaimDomesticHelper(ClaimDomesticHelper.jsonConvertClaimDomestic(claim.getClaimDomesticHelper()));
    }
    else {
      claim.setClaimDomesticHelper(null);
    }

    // Convert the claim travel object
    if (claim.getClaimTravel()) {
      claim.setClaimTravel(ClaimTravel.jsonConvertClaimTravel(claim.getClaimTravel()));
    }
    else {
      claim.setClaimTravel(null);
    }

    // Convert the claim motor object
    if (claim.getClaimMotor()) {
      claim.setClaimMotor(ClaimMotor.jsonConvertClaimMotor(claim.getClaimMotor()));
    }
    else {
      claim.setClaimMotor(null);
    }

     // Convert the claim home object
     if (claim.getClaimHome()) {
      claim.setClaimHome(ClaimHome.jsonConvertClaimHome(claim.getClaimHome()));
    }
    else {
      claim.setClaimHome(null);
    }

     // Convert the claim Personal Accident object
     if (claim.getClaimPersonalAccident()) {
      claim.setClaimPersonalAccident(ClaimPersonalAccident.jsonConvertClaimPersonalAccident(claim.getClaimPersonalAccident()));
    }
    else {
      claim.setClaimPersonalAccident(null);
    }

    // Convert the claim Employee Compensation object
    if (claim.getClaimEmployeeCompensation) {
      claim.setClaimEmployeeCompensation(ClaimEmployeeCompensation.jsonConvertClaimEmployeeCompensation(claim.getClaimEmployeeCompensation()));
    } else {
      claim.setClaimEmployeeCompensation(null);
    }

    // Convert Overseas Bank Payment
    if (claim.getOverseasBankPayment()) {
      claim.setOverseasBankPayment(OverseasBankPayment.jsonConvert(claim.getOverseasBankPayment()));
    } else {
      claim.setOverseasBankPayment(null);
    }

    let documentForm: DocumentForm = Object.assign(new DocumentForm(), claim.getDocumentForm());
    let documentDetails: DocumentDetails[] = [];

    for (const documentDetail of documentForm.documents) {
        let documentDetailItem = DocumentDetails.jsonConvert(documentDetail);
        documentDetails.push(documentDetailItem);
    }

    documentForm.documents = documentDetails;
    claim.setDocumentForm(documentForm);

      let responseList: ClaimResponse[] = [];
      for (const claimResp of claim.getClaimResponseList()) {
          let claimResponseItem = ClaimResponse.jsonConvert(claimResp);
          responseList.push(claimResponseItem);
      }
      claim.setClaimResponseList(responseList);

      if (claim.getClaimantList() != null) {
          let claimantDetailList: Claimant[] = [];

          for (const claimantItems of claim.getClaimantList()) {
              let claimant = Object.assign(new Claimant(), claimantItems);
              claimant = Claimant.jsonConvert(claimant);
              claimantDetailList.push(claimant);
          }
          claim.setClaimantList(claimantDetailList);

      }

    return claim;
  }
}
