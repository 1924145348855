import {Component, OnInit} from "@angular/core";
import { FormBuilder, FormGroup} from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SideMenuService } from "src/app/services/side-menu.service";
import { ClaimService } from "src/app/services/claim.service";
import { DocumentHelperService } from "src/app/services/document-helper.service";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { DocumentFormFactoryService } from "src/app/services/document-form-factory.service";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { ClaimTypes } from "src/app/model/claim-type.model";
import { ClaimDocumentsComponent } from "src/app/claim-pages/claim-documents/claim-documents.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'app-pa-others-upload-document',
    templateUrl: './pa-others-upload-document.component.html'
})
export class PaOthersUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {

    public hasRefunded: boolean = false;
    upLoadFileModel: FormGroup;
    showTotalBox = false;
    country: string;

    constructor(private fb: FormBuilder,
                private sideMenuService: SideMenuService,
                claimService: ClaimService,
                documentHelperService: DocumentHelperService,
                transactionInfoService: TransactionInfoService,
                http: HttpClient,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
                super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
                this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS]);
                this.country = claimService.getClaim().getCountry();
    }

    ngOnInit() {

        this.pushGAView();
        super.ngOnInit();
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goBack() {
        this.router.navigate(["/claimform/anotherInsurance" ], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {        
        this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 3});
        this.sideMenuService.emitProgress(1);
        this.sideMenuService.emitComplete('finishChooseClaim');
        this.router.navigate(["/claimform/paymentAndContact"], {
            relativeTo: this.activatedRoute
        });
    }

    pushGAView() {
        let stepNum = !this.claimService.getClaim().getClaimPersonalAccident().getHasEligibleClaimTypes() ?  6 : 7;
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Document Upload',
            'vPath': '/documents',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': stepNum},	//Step6-DocumentUpload
                }
            }
        });
    }
}
