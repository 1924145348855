import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {SideMenuService} from "../../../../../services/side-menu.service";
import { ClaimDocumentsComponent } from '../../../../claim-documents/claim-documents.component';
import { ClaimService } from '../../../../../services/claim.service';
import { DocumentHelperService } from '../../../../../services/document-helper.service';
import { TransactionInfoService } from '../../../../../services/transaction-info.service';
import { HttpClient } from '@angular/common/http';
import { DocumentFormFactoryService } from '../../../../../services/document-form-factory.service';
import { ClaimTypes } from '../../../../../model/claim-type.model';
import { SpinnerService } from '../../../../../core/spinner/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-hospital-upload-document',
  templateUrl: './hospital-upload-document.component.html',
  styleUrls: ['./hospital-upload-document.component.css']
})
export class HospitalUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {
    public lostPersondata: any;
    public hasCompensationReceived:boolean = false;
    public hasChoosePassword: boolean =false;
    public hasPassportLossTransCost: boolean = false;
    public hasPassportLossAcommCost = false;
    public hasFollowUpTreatment: boolean;
    upLoadFileModel: FormGroup;
    showTotalBox = false;
    billNum: number;
    showAddDoc = false;
    
    constructor(public sideMenuService: SideMenuService,
        private fb: FormBuilder,
        claimService: ClaimService,
        documentHelperService: DocumentHelperService,
        transactionInfoService: TransactionInfoService,
        http: HttpClient,
        documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
                super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
                this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_STY]);
    }

    ngOnInit() {
        this.pushGAViewHospitalDocs();
        super.ngOnInit();
        this.upLoadFileModel = this.fb.group(
            {
                airTicket: [],
                carrierCertificate: [],
                medicalReport: [],
                medicalBills: this.fb.array([]),
                receiptOfFollowUp: [],
                receiptOfOtherExpense: [],
                otherItems: this.fb.array([this.createItem()])

            });
    }

    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

   

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: [],
        });
    }
    showModal() {
        this.showTotalBox = true;
    }
    goToNext() {
        this.sideMenuService.emitProgress(1);
        this.sideMenuService.emitClaimComplete({claimTypeId: 5, subMenuIndex: 4});
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewHospitalDocs() {
        (<any>window).dataLayer.push({
            'pageStep': 'Stay Hospital – Document Upload',
            'vPath': '/claim/stay-hospital/documents',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 6},	//Step6-DocumentUpload
                }
            }
        });
    }

}
