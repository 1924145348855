import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  ChangeDetectorRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {UiConfigService} from './ui-config.service';
import {UiTextfieldComponent} from './ui-textfield/ui-textfield.component';
import {UiTextfieldLabelComponent} from './ui-textfield-label/ui-textfield-label.component';
import {UiMoneyfieldComponent} from './ui-moneyfield/ui-moneyfield.component';
import {UiUnknownTypeComponent} from './ui-unknown-type/ui-unknown-type.component';
import {UiButtonGrpSingleSelectComponent} from './ui-button-grp-single-select/ui-button-grp-single-select.component';
import {UiMultipleSelectComponent} from "./ui-multiple-select/ui-multiple-select.component";
import {UiDropdownSelectComponent} from "./ui-dropdown-select/ui-dropdown-select.component";
import {UiRadioComponent} from "./ui-radio/ui-radio.component";
import {UiCaptionComponent} from './ui-caption/ui-caption.component';
import {UiOptionsModel} from './ui-options.model';
import {UiDatepickerComponent} from './ui-datepicker/ui-datepicker.component';
import {UiTextareaComponent} from './ui-textarea/ui-textarea.component';
import {UiTimepickerComponent} from './ui-timepicker/ui-timepicker.component';
import { Observable } from 'rxjs';
import {UiDateTimePickerComponent} from "./ui-datetimepicker/ui-datetimepicker.component";
import {UiCostfieldComponent} from './ui-costfield/ui-costfield.component';
import { UiMapComponent } from './ui-map/ui-map.component';
import { UiCostfieldCurrencyComponent } from './ui-costfield-currency/ui-costfield-currency.component';
import {UiPhoneNumberComponent} from './ui-phonenumber/ui-phonenumber.component';
import {UiPopoverModel} from './ui.model';
import {UiRadioButtonComponent} from "./ui-radio-button/ui-radio-button.component";
import {UiDropdownTextfieldComponent} from './ui-dropdown-textfield/ui-dropdown-textfield.component';
import {UiMultiSelectDropdownComponent} from './ui-multiselect-dropdown/ui-multiselect-dropdown.component';
import {UiPasswordfieldComponent} from './ui-passwordfield/ui-passwordfield.component';

@Component({
  selector: 'qnect-ui',
  template: '<span #dynamicUiComponent></span>',
  styles: [],
  entryComponents: [
    UiTextfieldComponent,
    UiTextfieldLabelComponent,
    UiTextareaComponent,
    UiMoneyfieldComponent,
    UiButtonGrpSingleSelectComponent,
    UiMultipleSelectComponent,
    UiDropdownSelectComponent,
    UiRadioComponent,
    UiRadioButtonComponent,
    UiCaptionComponent,
    UiDatepickerComponent,
    UiTimepickerComponent,
    UiDateTimePickerComponent,
    UiMapComponent,
    UiUnknownTypeComponent,
    UiCostfieldComponent,
    UiCostfieldCurrencyComponent,
    UiPhoneNumberComponent,
    UiMultiSelectDropdownComponent,
    UiPasswordfieldComponent
  ]
})
export class UiComponent implements OnInit {

  @Input() fieldId: string;
  @Input() value: string;
  @Input() formGroup: FormGroup;
  @Input() option: string;
  @Input() dynamicOptions: UiOptionsModel[];
  @Input() disabled: string;
  fieldConfig: any = {};
  @Input() dynamicLabel: string;
  @Input() frmArrayGroup: FormGroup;
  @Input() frmArrayName: string;
  @Input() frmArrayIndex: string;
  @Input() uiPopover: UiPopoverModel;
  @Input() amountFieldRequired: boolean;
  @Input() prefix: string;
  @Input() type: string;
  @Input() isRequired: boolean;
  @Input() maxLength: string;
  @Input() dynamicPlaceholder: string;
  @Input() regexPattern: string;

  @Output() uiComponentCreated = new EventEmitter<string>();

  constructor(private uiConfigService: UiConfigService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private cdr: ChangeDetectorRef) {
  }

  // Get tag child component will be placed
  @ViewChild('dynamicUiComponent', {static: true, read: ViewContainerRef}) target: ViewContainerRef;
  private componentRef: ComponentRef<any>;

  // Child components
  private children = {
    "textfield": UiTextfieldComponent,
    "passwordfield": UiPasswordfieldComponent,
    "textfieldLabel": UiTextfieldLabelComponent,
    "textarea": UiTextareaComponent,
    "moneyfield": UiMoneyfieldComponent,
    "btnGrpSingleSelect": UiButtonGrpSingleSelectComponent,
    "multiSelect": UiMultipleSelectComponent,
    "dropdownSelect": UiDropdownSelectComponent,
    "radio": UiRadioComponent,
    "radiobutton": UiRadioButtonComponent,
    "caption": UiCaptionComponent,
    "datepicker": UiDatepickerComponent,
    "timepicker": UiTimepickerComponent,
    "datetimepicker": UiDateTimePickerComponent,
    "costfield": UiCostfieldComponent,
    "costfieldCurrency": UiCostfieldCurrencyComponent,
    "map": UiMapComponent,
    "unknown": UiUnknownTypeComponent,
    "phoneNumber": UiPhoneNumberComponent,
    "dropdowntextfield": UiDropdownTextfieldComponent,
    "dropdownMultiSelect": UiMultiSelectDropdownComponent,
    undefined: UiUnknownTypeComponent
  };

  // Pass through value to child component
  renderComponent() {
      if (this.componentRef) {
      this.componentRef.instance.type = this.type;
      this.componentRef.instance.fieldId = this.fieldId;
      this.componentRef.instance.fieldConfig = this.fieldConfig;
      this.componentRef.instance.formGroup = this.formGroup;
      this.componentRef.instance.frmArrayName = this.frmArrayName;
      this.componentRef.instance.frmArrayIndex = this.frmArrayIndex;
      this.componentRef.instance.value = this.value;
      this.componentRef.instance.option = this.option;
      this.componentRef.instance.dynamicOptions = this.dynamicOptions;
      this.componentRef.instance.disabled = this.disabled;
      this.componentRef.instance.dynamicLabel = this.dynamicLabel;
      this.componentRef.instance.uiPopover = this.uiPopover;
      this.componentRef.instance.amountFieldRequired = this.amountFieldRequired;
      this.componentRef.instance.prefix = this.prefix;
      this.componentRef.instance.isRequired = this.isRequired;
      this.componentRef.instance.maxLength = this.maxLength;
      this.componentRef.instance.dynamicPlaceholder = this.dynamicPlaceholder;

      if (this.regexPattern) {
        this.componentRef.instance.regexPattern = this.regexPattern;
      }
    }
  }

  // Compile child component
  compileChild() {
    let type: string = "unknown";
    if (this.type) {
      type = this.type;
    } else {
        if (this.fieldConfig && this.fieldConfig["type"]) {
            type = this.fieldConfig["type"];
        }
    }
    let childComponent = this.children[type];
    // Resolve child component
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(childComponent);
    this.componentRef = this.target.createComponent(componentFactory);
    this.renderComponent();
  }

  // Pass through value to child component when value changes
  ngOnChanges(changes: Object) {
    this.renderComponent();
  }

  ngOnInit() {

    if (this.frmArrayGroup && this.frmArrayGroup != null) {
       this.formGroup = <FormGroup> this.frmArrayGroup.get(this.frmArrayName).get(this.frmArrayIndex);
     }

    this.uiConfigService.loadUiConfig().subscribe(
      data => {
        this.fieldConfig = data[this.fieldId];
        this.compileChild();
        try {
          // if ui component is already destroyed before this code block is finished
          this.cdr.detectChanges();
          this.uiComponentCreated.emit(this.fieldId);
        } catch (ex) {

        }
      }
    );
  }

  setupCustomValidatorList(customValidators) {
    this.componentRef.instance.setupCustomValidatorList(customValidators);
  }

  loadDropDownOptions(options: UiOptionsModel[]) {
    if (this.componentRef && typeof this.componentRef.instance.loadDropDownOptions === 'function') {
      this.componentRef.instance.loadDropDownOptions(options);
    }
  }

  hide(): UiComponent {
    if (this.componentRef && typeof this.componentRef.instance.hide === 'function') {
      this.componentRef.instance.hide();
    }
    return this;
  }
  show(): UiComponent {
    if (this.componentRef && typeof this.componentRef.instance.show === 'function') {
      this.componentRef.instance.show();
    }
    return this;
  }
  isHidden(): boolean {
    if (this.componentRef && typeof this.componentRef.instance.isHidden === 'function') {
      return this.componentRef.instance.isHidden();
    }
  }
  enable(): UiComponent {
    if (this.componentRef && typeof this.componentRef.instance.enable === 'function') {
      this.componentRef.instance.enable();
    }
    return this;
  }
  disable(opts?: any): UiComponent {
    if (this.componentRef && typeof this.componentRef.instance.disable === 'function') {
      this.componentRef.instance.disable(opts);
    }
    return this;
  }
  isDisabled(): boolean {
    if (this.componentRef && typeof this.componentRef.instance.isDisabled === 'function') {
      return this.componentRef.instance.isDisabled();
    }
  }
  setDefaultValue(defaultValue: string): UiComponent {
    if (this.componentRef && typeof this.componentRef.instance.setDefaultValue === 'function') {
      this.componentRef.instance.setDefaultValue(defaultValue);
    }
    return this;
  }
  getDefaultValue(): string {
    if (this.componentRef && typeof this.componentRef.instance.getDefaultValue === 'function') {
      return this.componentRef.instance.getDefaultValue();
    }
    return null;
  }
  setValue(value: string, options?: any): UiComponent {
    if (this.componentRef && typeof this.componentRef.instance.setValue === 'function') {
      this.componentRef.instance.setValue(value, options);
    }
    return this;
  }
  getValue(): string {
    if (this.componentRef && typeof this.componentRef.instance.getValue === 'function') {
      return this.componentRef.instance.getValue();
    }
  }
  valueChanges(): Observable<any> {
    if (this.componentRef && typeof this.componentRef.instance.valueChanges === 'function') {
      return this.componentRef.instance.valueChanges();
    }
  }
  error(errorCode: string): UiComponent {
    if (this.componentRef && typeof this.componentRef.instance.error === 'function') {
      this.componentRef.instance.error(errorCode);
    }
    return this;
  }
  getOptionLabel(value: string): string {
    if (this.componentRef && typeof this.componentRef.instance) {
      return this.componentRef.instance.getOptionLabel(value);
    }
  }
  getDateValue() {
    if (this.componentRef && typeof this.componentRef.instance.getDateValue === 'function') {
      return this.componentRef.instance.getDateValue();
    }
  }
  setDateValue(date : Date) {
    if (this.componentRef && typeof this.componentRef.instance.setDateValue === 'function') {
      return this.componentRef.instance.setDateValue(date);
    }
  }

  getTimeValue() {
    if (this.componentRef && typeof this.componentRef.instance.getTimeValue === 'function') {
      return this.componentRef.instance.getTimeValue();
    }
  }

  setTimeValue(date: Date) {
    if (this.componentRef && typeof this.componentRef.instance.setTimeValue === 'function') {
      return this.componentRef.instance.setTimeValue(date);
    }
  }

  getSelectedTextDropDownSelect() {
    if (this.componentRef && typeof this.componentRef.instance.getSelectedTextDropDownSelect === 'function') {
      return this.componentRef.instance.getSelectedTextDropDownSelect();
    }
  }

  getMultiSelectDropdownValue() {
    if (this.componentRef && typeof this.componentRef.instance.getMultiSelectDropdownValue === 'function') {
      return this.componentRef.instance.getMultiSelectDropdownValue();
    }
  }

  getSelectedRadioText() {
    if (this.componentRef && typeof this.componentRef.instance.getSelectedRadioText === 'function') {
      return this.componentRef.instance.getSelectedRadioText();
    }
  }

  getSelectedRadioButtonText() {
    if (this.componentRef && typeof this.componentRef.instance.getSelectedRadioButtonText === 'function') {
      return this.componentRef.instance.getSelectedRadioButtonText();
    }
  }

  checkTimePickerError() {
    if (this.componentRef && typeof this.componentRef.instance.checkTimePickerError === 'function') {
      return this.componentRef.instance.checkTimePickerError();
    }
  }

  update(): UiComponent {
    if (this.componentRef && typeof this.componentRef.instance) {
      this.componentRef.instance.update();
    }
    return this;
  }

  set validationRegex(regex: string){
    if (this.componentRef && typeof this.componentRef.instance) {
      this.componentRef.instance.validationRegex = regex;
    }
  }

  set validationRegexList(regex: Object){
    if (this.componentRef && typeof this.componentRef.instance) {
      this.componentRef.instance.validationRegexList = regex;
    }
  }

  set required(required: boolean) {
    if (this.componentRef && typeof this.componentRef.instance) {
      this.componentRef.instance.required = required;
    }
  }

  isValid() {
    if (this.componentRef && typeof this.componentRef.instance) {
      return this.componentRef.instance.isValid();
    }
  }

  getUiModel() {
    if (this.componentRef && typeof this.componentRef.instance) {
      return this.componentRef.instance.uiModel;
    }
  }
}
