import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ClaimsBaseComponent} from "../claim-base.component";
import {SideMenuService} from "../../services/side-menu.service";
import {TransactionInfoService} from '../../services/transaction-info.service';
import {TransactionInfo} from "../../model/transaction-info.model";
import {ClaimTypes} from "../../model/claim-type.model";
import {UiPopoverModel} from '../../ui/ui.model';
import { UtilitiesService } from "src/app/utilities/utilities.service";

@Component({
    selector: 'app-provide-contact-info',
    templateUrl: './provide-contact-info.component.html',
    styleUrls: ['./provide-contact-info.component.css']
})
export class ProvideContactInfoComponent extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    contactInfoForm: FormGroup;
    transactionInfo: TransactionInfo;
    countryCode: string;
    uiPopover: UiPopoverModel = new UiPopoverModel();
    uiPopoverAgentEmail: UiPopoverModel = new UiPopoverModel();

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private transactionInfoService: TransactionInfoService,
                private router: Router,
                private injector : Injector) {
        super(injector);
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {
        this.pushGAContactInfoView();
        this.showPopover();

        if (!this.isNotNullOrUndefinedStr(this.claim.getContactNumberCountryCode())){
            this.claim.setContactNumberCountryCode(UtilitiesService.getCountryCode(this.claim.getCountry()));
        }

        // default to Yes if customer wants to send a copy of all email notifications to agent
        if (!this.transactionInfo.isGroupProduct() && !this.isNotNullOrUndefined(this.claim.getNotifyAgent())) {
            this.claim.setNotifyAgent(true);
        }

        // default to Yes for ebusiness - documents to be shared with broker / agent
        if (this.transactionInfo.isGroupProduct() && !this.isNotNullOrUndefined(this.claim.getNotifyAgent()) &&
            this.transactionInfo.getTransactionApplicationOrigin() === TransactionInfo.EBUSINESS) {
            this.claim.setDocumentShareToAgent(true);
        }

        this.contactInfoForm = this.fb.group(
            {
                contactName: [this.claim.getContactName()],
                contactNumber: [this.claim.getContactNumber()],
                contactEmail: [this.claim.getContactEmail()],
                confirmEmail: [],
                isAgentEmailNotification:[super.getBooleanString(this.claim.getNotifyAgent())],
                contactCountryCode: [this.claim.getContactNumberCountryCode()],
                agentEmail: [this.claim.getAgentEmail()],
                documentShareToAgent:[super.getBooleanString(this.claim.getDocumentShareToAgent())],
            }
        );
    }

    ngAfterViewInit() {
        this.contactInfoForm.valueChanges.subscribe(data => {

            this.claim.setContactName(super.getValueInComponent('contactName'));
            this.claim.setContactNumber(super.getValueInComponent('contactNumber'));
            this.claim.setContactNumberCountryCode(this.contactInfoForm.get('contactCountryCode').value);
            this.claim.setContactEmail(super.getValueInComponent('contactEmail'));
            this.claim.setAgentEmail(super.getValueInComponent('agentEmail'));
            if (this.contactInfoForm.get('isAgentEmailNotification').value !== null) {
                this.claim.setNotifyAgent(this.contactInfoForm.get('isAgentEmailNotification').value == 'true');
            }
            if (this.contactInfoForm.get('documentShareToAgent').value !== null) {
                this.claim.setDocumentShareToAgent(this.contactInfoForm.get('documentShareToAgent').value == 'true');
            }
        })
    }

    disabledPasteEvent(event: ClipboardEvent) {
        event.preventDefault();
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    validateForm(): boolean {
        return super.validateForm(this.contactInfoForm);
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProgress(1);
            this.sideMenuService.emitComplete('finishPayment');

            this.router.navigate(["/claimform/summary"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        let paymentType : string = this.claim.getPaymentRefundToBank();

        if (!UtilitiesService.isNullOrUndefined(paymentType) && paymentType != "") {
            if (paymentType === "Y"){
                this.router.navigate(["/claimform/paymentAndContact/bankAccount"], {
                    relativeTo: this.activatedRoute
                });
            } else if (paymentType === "O") {
                this.router.navigate(["/claimform/paymentAndContact/payOverseasBank"], {
                    relativeTo: this.activatedRoute
                });
            } else {
                this.router.navigate(["/claimform/paymentAndContact/sendCheque"], {
                    relativeTo: this.activatedRoute
                });
            }
        } else {
            if (this.transactionInfo.getCurrentClaimType() == ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT] ||
                this.transactionInfo.getCurrentClaimType() == ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION]) {
                this.router.navigate(["/claimform/anotherInsurance"], {
                    relativeTo: this.activatedRoute
                });
            }
        }
    }

    applyP400Format(event: any, fieldName: string) {
        let inputValue = event.target.value;
        if(!UtilitiesService.isNullOrUndefined(inputValue) && inputValue.length > 1) {
            inputValue = inputValue.trim();
            inputValue = inputValue.replace(/\s*-\s*/g, "-").replace(/\s*,\s*/g, ",").replace(/\s*[']\s*/g, "'");
            this.contactInfoForm.get(fieldName).setValue(inputValue);
        }
    }

    showPopover() {
        if (this.transactionInfo.isGroupProduct() && this.transactionInfo.getTransactionApplicationOrigin() === TransactionInfo.EBUSINESS) {
            this.uiPopover.icon = "hits";
            this.uiPopover.content =  "pageGetStarted.body.groupMedToolTipSgp";
       } else if (this.claim.getProduct() === 'GMB') {
              this.uiPopover.icon = "hits";
              this.uiPopover.content =  "pageGetStarted.body.groupMedToolTip";
       }
       if (this.transactionInfo.isGroupProduct() && this.transactionInfo.getTransactionApplicationOrigin() !== TransactionInfo.EBUSINESS) {
           this.uiPopoverAgentEmail.icon = "hits";
           this.uiPopoverAgentEmail.content =  "If you are unsure about your agent or broker’s email address, please check with your HR. " +
               "Meanwhile, you could click “Save for later” at the bottom of the page to save the progress if necessary.";
       }
    }


    //START - Google Analytics
    pushGAContactInfoView() {
       if (this.transactionInfo.isMotorProduct() && this.claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_MVA_WINDSCREEN') === -1) {
          this.pushGAContactInfoOnly();
       } else {
           this.pushGAPaymentContact();
       }
    }

    pushGAPaymentContact() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Contact Information',
            'vPath': '/contact-information',
            'event': 'vpageview'
        });
    }

    pushGAContactInfoOnly() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Contact Information',
            'vPath': '/contact-information',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 7}   //Step7-Contact
                }
            }
        });
    }
    //END - Google Analytics


}
