import {Injectable} from '@angular/core';
import {UUID} from 'angular2-uuid';
import {DatePipe} from '@angular/common';
import {InsuredPerson} from '../model/insured-person.model';

@Injectable()
export class UtilitiesService {

  public static trim(value: string): string {
    if (value) {
      return value.replace(/^\s+|\s+$/g, '');
    }
    return '';
  }

  public static trimObjectValues(obj: any) {
    if (obj) {
      for (const key of Object.keys(obj)) {
        if (obj[key]) {
          const objValue = obj[key];
          if (objValue && typeof objValue === 'object') {
            this.trimObjectValues(objValue);
          } else if (isNaN(obj[key])) {
            obj[key] = this.trim(obj[key]);
          }
        }
      }
    }
  }

    public static combineString(str1 : string, str2 :string, seperator : string) : string {

        let combinedString : string = str2;

        if (str1 && str1 != null &&  str1 != '') {
            combinedString = str1 + seperator + str2;
        }

        return combinedString;
    }

    public static splitString(str1 : string, seperator : string, index : number) : string {

        let stringPart = str1;

        let indexOfSep = str1.indexOf(seperator);

        if (indexOfSep > 0) {

            if (index == 0) {
                stringPart = str1.substring(0,indexOfSep);
            }
            else {
                stringPart = str1.substring(indexOfSep + seperator.length,str1.length);
            }
        }

        return stringPart;
    }


  public static isEmpty(value: string): boolean {
    return !value || UtilitiesService.trim(value) === '';
  }

  public static toNumber(value: string): number {
    if (UtilitiesService.isEmpty(value)) {
      return 0;
    }
    return +UtilitiesService.trim(value);
  }

  public static concatUnique(arr1: any[], arr2: any[]) {
    arr2.forEach(str => {
      if (!arr1.some(value => value === str)) {
        arr1.push(str);
      }
    });
    return arr1;
  }

  public static createUniqueIdentifier(): string {
    return 'RAND-' + UUID.UUID().substring(0, 18);
  }


  /**
   * detect IE
   * returns version of IE or false, if browser is not Internet Explorer
   */
  public static detectIE() {
    const ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }

  public static calculateAge(dateOfBirth: Date): number{
    var ageDifMs = Date.now() - dateOfBirth.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  
  public static isMinor(dateOfBirth: Date): boolean{
      //console.log('Date of Birth: ' + dateOfBirth);
      //console.log('Age: ' + this.calculateAge(dateOfBirth));
      let dob = dateOfBirth;

      if (dob == null) {
          return false;
      }
      else {
          dob = new Date(dob);
          return (this.calculateAge(dob) < 18);
      }
  }

  
  public static checkForMinors(claim): boolean {
    if(UtilitiesService.isMinor(claim.getClaimantDateOfBirth())){
      return true;
    }
    let isMinor = false;
    if(claim.getClaimantList() != undefined 
    && claim.getClaimantList().length > 0){
        claim.getClaimantList().forEach(function (claimant) {
           if(UtilitiesService.isMinor(claimant.getClaimantDateOfBirth())){
             isMinor = true;
             return;
           }
        });
    }return isMinor;
  }

  public static getDiffDays(date1: Date, date2: Date): number {
      let diffDays = Math.floor((<any>date2-<any>date1)/1000/60/60/24)+1;
      return diffDays;
  }
  public static createClaimantIdentifier(insuredPerson: InsuredPerson): string {
      let claimantIdentifier: string =  "";

      if (!this.isNullOrUndefined(insuredPerson.getInsuredPersonNationalIdNumber())) {
          claimantIdentifier = claimantIdentifier + insuredPerson.getInsuredPersonNationalIdNumber();
      }

      if (!this.isNullOrUndefined(insuredPerson.getInsuredDateOfBirth())) {
          let dob = new DatePipe('en-US').transform(insuredPerson.getInsuredDateOfBirth(), "yyyyMMdd");
          claimantIdentifier = claimantIdentifier + dob
      }

      if (!this.isNullOrUndefined(insuredPerson.getInsuredName())) {
          claimantIdentifier = claimantIdentifier + insuredPerson.getInsuredName().replace(/\s/g,'');
      }

      if (claimantIdentifier.length > 30) {
          claimantIdentifier = claimantIdentifier.substring(0,30);
      }

      return claimantIdentifier;

  }

  public static getDateDiffInHours(originalDate: Date, delayedDate: Date): number {
      let diffInMs: number = (delayedDate.getTime() - originalDate.getTime());
      let diffInHours: number = Math.floor(diffInMs / 1000 / 60 / 60);
      return diffInHours;
  }


  public static p400Format(str: string): string {
    if(!this.isNullOrUndefined(str) && str.length > 1) {
      str = str.trim();
      while (str.charAt(0)=="\\" || str.charAt(0)=="/") {
        if(str)
        str = str.substr(1, str.length);
      }
      str = str.replace("- ", "-").replace(" -", "-");
      var newStr = '';
      for(var x = 0; x < str.length; x++){
        if(x < str.length){
          if((str.charAt(x) === "," || str.charAt(x) === ".") && !this.isNullOrUndefined(str.charAt(x + 1)) && str.charAt(x + 1) != " "){
            newStr = newStr + str.charAt(x)+ " ";
          } else {
            newStr = newStr + str.charAt(x);
          }
        }
      }
      str = newStr;
    }
      return str;
  }

  public static truncateValue(input: string, maxlength: number): string {
      if (!this.isNullOrUndefined(input) && input.length > maxlength) {
          return input.substring(0, maxlength);
      }
      return input;
  }

  public static isNullOrUndefined(value: any) {
    return value === null || value === undefined;
  }

  public static getCountryCurrency(country: string) {
      switch (country?.toUpperCase()) {
          case 'HKG':
              return 'HKD';
          case 'HGI':
              return 'HKD';
          case 'SGP':
              return 'SGD';
          case 'MYS':
              return 'MYR';
          default:
              return '';
      }
  }

   public static getCountryDesc(country: string) {
       switch (country?.toUpperCase()) {
           case 'HKG':
               return 'HONG KONG';
           case 'HGI':
               return 'HONG KONG';
           case 'SGP':
              return 'SINGAPORE';
           case 'MYS':
               return 'MALAYSIA';
           default:
               return '';
       }
  }

  public static getCountryCode(country: string) {
        switch (country?.toUpperCase()) {
            case 'HKG':
            case 'HGI':
                return '+852';
            case 'SGP':
                return '+65';
            case 'MYS':
                return '+60';
            default:
                return '';
        }
  }

  public static getDefaultProductCode(type: string) {
      let productCode = "";
      switch (type?.toLowerCase()) {
          case "travel":
              productCode = "PTR";
              break;
          case "helper":
          case "domestichelper" :
              productCode = "DMH";
              break;
          case "motor":
              productCode = "MVA";
              break;
          case "home":
              productCode = "HPK";
              break;
          case "accident":
              productCode = "PAN";
              break;
          case "ec":
              productCode = "WCA";
              break;
          default:
              productCode = "";
      }
      return productCode;
  }

  public static getCountryUrl(country: string) {
    switch (country?.toUpperCase()) {
        case 'HKG':
        case 'HGI':
            return 'hk';
        case 'SGP':
            return 'sg';
        case 'MYS':
            return 'my';
        default:
            return '';
    }
  }

  public static customReplaceAll = (regex, stringToBeChanged, changeToThisCharacter) => {
    let matchedString = stringToBeChanged.match(regex);
    if (matchedString[0]) {
        let changedString = matchedString[0].replace(/./g, changeToThisCharacter);
        return stringToBeChanged.replace(regex, changedString);
    }
  }

  public static maskEmail(emailAddress: string) {
    let val = emailAddress.split('@')[0];
    let regexToUse = val.length > 4 ? /^.*(?=....@)/g : /^.*(?=@)/g;
    let emailMasked = UtilitiesService.customReplaceAll(regexToUse, emailAddress, '•');
    return emailMasked;
  }

  public static termsOfUseLink(country: string) {
    return "https://qbe.com/" + this.getCountryUrl(country) + "/portal-terms-of-use";
  }

  public static privacyPolicyLink(country: string) {
    return "https://qbe.com/" + this.getCountryUrl(country) + "/privacy-policy";
  }

  public static isDateAfterOrEqualTo(inputDate: Date, staticDate: Date): boolean {
      inputDate.setHours(0, 0, 0, 0);
      staticDate.setHours(0, 0, 0, 0);
      return inputDate.getTime() >= staticDate.getTime();  // compare dates (time is ignored)
  }
}
