import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ClaimsBaseComponent } from '../claim-base.component';
import { TransactionInfoService } from '../../services/transaction-info.service';
import { ClaimService } from '../../services/claim.service';
import { SideMenuService } from '../../services/side-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

declare var $: any;

@Component({
    selector: 'app-confirm-travel-period',
    templateUrl: './confirm-travel-period.component.html',
    styleUrls: ['./confirm-travel-period.component.css']
})
export class ConfirmTravelPeriodComponent extends ClaimsBaseComponent  implements OnInit {
    confirmTravelModel: FormGroup;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private claimService: ClaimService,
                private sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        //console.log('confirming travel period');
        //console.log(this.claim);
    }

    ngOnInit() {

        this.pushGAViewConfirmTravel();

        this.confirmTravelModel = this.fb.group({
            originalDepartureDate: [this.claim.getClaimTravel().getOriginalDepartureDate()],
            originalReturnDate: [this.claim.getClaimTravel().getOriginalReturnDate()]
        });
    }

    ngAfterViewInit() {
         this.confirmTravelModel.valueChanges.subscribe(data => {
            this.claim.getClaimTravel().setOriginalDepartureDate(super.getValueInComponent('originalDepartureDate'));
            this.claim.getClaimTravel().setOriginalReturnDate(super.getValueInComponent('originalReturnDate'));
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitConfirmTravelPeriod('');
            this.sideMenuService.emitComplete('finishConfirmTravelPeriod');
            this.sideMenuService.emitProgress(1);
            this.router.navigate(["/claimform/selectInsured"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.confirmTravelModel);
    }

    //Google Analytics
    pushGAViewConfirmTravel() {
        let claimInfo = this.claim;
        let channel: string = "";
        if (!UtilitiesService.isNullOrUndefined(claimInfo) && !UtilitiesService.isNullOrUndefined(claimInfo.getCountry())) {
            if (claimInfo.getCountry() == "HGI") {
                 channel = "QGI";
            }  else {
                if (claimInfo.getIsManulife()) {
                    channel = "Manulife";
                } else {
                    channel = "HKSI";
                }
            }
        }

        (<any>window).dataLayer.push({
            'pageStep': 'Confirm Travel Period',
            'Channel': channel,     // QGI, HKSI or Manulife
            'vPath': '/confirm-travel-period',
            'event': 'vpageview',
        });
    }
}
