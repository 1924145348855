import {Component, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../../services/side-menu.service";
import {ClaimDocumentsComponent} from "../../../../claim-documents/claim-documents.component";
import {DocumentHelperService} from "../../../../../services/document-helper.service";
import {TransactionInfoService} from "../../../../../services/transaction-info.service";
import {ClaimService} from "../../../../../services/claim.service";
import { HttpClient } from '@angular/common/http';
import { DocumentFormFactoryService } from "../../../../../services/document-form-factory.service";
import { ClaimTypes } from "../../../../../model/claim-type.model";
import { SpinnerService } from '../../../../../core/spinner/spinner.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-other-claim-upload-document',
    templateUrl: './other-claim-upload-document.component.html',
    styleUrls: ['./other-claim-upload-document.component.css']
})
export class OtherClaimUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {

    public hasRefunded: boolean = false;
    upLoadFileModel: FormGroup;
    showTotalBox = false;

    constructor(private fb: FormBuilder,
                private sideMenuService: SideMenuService,
                claimService: ClaimService,
                documentHelperService: DocumentHelperService,
                transactionInfoService: TransactionInfoService,
                http: HttpClient,
                documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
                super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
                this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_OTH]);
    }

    ngOnInit() {

        this.pushGAViewOtherDocs();
        super.ngOnInit();
        this.upLoadFileModel = this.fb.group(
            {
                airTicket: [],
                otherDoc: [],
                otherItems: this.fb.array([this.createItem()])

            });
    }

    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: [],
            // detail: this.fb.group(that.createDetail('mobile'))
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        console.log("Other documents goToNext");
        this.sideMenuService.emitClaimComplete({claimTypeId: 15, subMenuIndex: 2});
        this.sideMenuService.emitProgress(1);
    }

    //Google Analytics
    pushGAViewOtherDocs() {
        (<any>window).dataLayer.push({
            'pageStep': 'Other Claims – Document Upload',
            'vPath': '/claim/other-claims/documents',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 6},	//Step6-DocumentUpload
                }
            }
        });
    }
}
