import {Component, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../../services/side-menu.service";
import {MultiClaimDocumentsComponent} from "../../../../multi-claim-documents/multi-claim-documents.component";
import {ClaimService} from "../../../../../services/claim.service";
import {DocumentHelperService} from "../../../../../services/document-helper.service";
import {TransactionInfoService} from "../../../../../services/transaction-info.service";
import {HttpClient} from "@angular/common/http";
import {DocumentFormFactoryService} from "../../../../../services/document-form-factory.service";
import {ClaimTypes} from "../../../../../model/claim-type.model";
import { SpinnerService } from '../../../../../core/spinner/spinner.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

declare var $: any;

@Component({
  selector: 'app-miss-connect-uploa-document',
  templateUrl: './miss-connect-uploa-document.component.html',
  styleUrls: ['./miss-connect-uploa-document.component.css']
})
export class MissConnectUploaDocumentComponent extends MultiClaimDocumentsComponent implements OnInit {

    public upLoadFileModel: FormGroup;
    showTotalBox = false;
    
    constructor(private fb: FormBuilder,
        public sideMenuService: SideMenuService, 
        claimService: ClaimService,
        documentHelperService: DocumentHelperService,
        transactionInfoService: TransactionInfoService,
        http: HttpClient,
        documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
                super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
                this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL_MC]);
                this.setupMultiClaimant();
    }
    
    ngOnInit() {

        this.pushGAViewConnectDocs();
        this.upLoadFileModel = this.fb.group(
            {
                airTicket: [],
                relevantDocForMiss: [],
                prepaidCostDoc: [],
                extraTransDoc: [],
                extraAccomDoc: [],
                otherItems: this.fb.array([this.createItem()])

            });
            super.ngOnInit();
    }

    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

    createItem(): FormGroup {
        // setTimeout(() => {
        //   console.log('lossType1111', this.el.nativeElement.value);
        //   this.selectType = this.el.nativeElement.value;
        // });
        return this.fb.group({
            otherFile: [],


            // detail: this.fb.group(that.createDetail('mobile'))
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        this.sideMenuService.emitClaimComplete({claimTypeId: 12, subMenuIndex: 2});
        this.sideMenuService.emitProgress(1);
    }

    //Google Analytics
    pushGAViewConnectDocs() {
        (<any>window).dataLayer.push({
            'pageStep': 'Missed Connection – Document Upload',
            'vPath': '/claim/missed-connection/documents',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 6},	//Step6-DocumentUpload
                }
            }
        });
    }

}
