import {Component, Injector, OnInit, ViewChild, TemplateRef, ViewEncapsulation} from "@angular/core";
import {ActivatedRoute, Router} from '@angular/router';
import {SideMenuService} from "../../../services/side-menu.service";
import {ClaimTypeItem} from "../../../model/claim-type-item.model";
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ClaimService} from "../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-pages/claim-base.component";
import {Notifications} from "../../../utilities/components/notification-messages/notifications.model";
import {ClaimTypes} from "../../../model/claim-type.model";
import {DocumentHelperService} from "../../../services/document-helper.service";
import {TransactionInfo} from "../../../model/transaction-info.model";
import { DynamicRoutingService } from "src/app/services/dynamic-routing.service";
import { Claim } from "src/app/model/claim.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UtilitiesService } from "src/app/utilities/utilities.service";
import { EcFormSelectionModalComponent } from "src/app/utilities/components/ec-form-selection-modal/ec-form-selection-modal";
import { ClaimTypeItemHelperService } from "src/app/services/claim-type-item-helper.service";

declare var $: any;

@Component({
    selector: 'app-select-claim',
    templateUrl: './select-claim.component.html',
    styleUrls: ['./select-claim.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SelectClaimComponent extends ClaimsBaseComponent implements OnInit {
    public claimTypeData: ClaimTypeItem[];
    public chooseIndex: number;
    public selectedClaimCode: string = '';
    public menu: any;
    public limit = {ClaimTypeId: null};
    public nextLinkUrl: string = '';
    showTotalBox = false;
    notifications: Notifications = new Notifications();
    hasActiveClaimType: boolean = false;
    transactionInfo: TransactionInfo;
    claim: Claim;
    closeAlert: boolean = false;

    alertClaimTypeModalRef: any;
    @ViewChild('alert_claim_type', {static: true}) alertClaimTypeModal: TemplateRef<any>;

    @ViewChild('ec_form_selection_modal', {static: true}) 
    formSelectionModal: EcFormSelectionModalComponent;

    @ViewChild('confirm_delete_claim_type', {static: true}) deleteClaimTypeModal: TemplateRef<any>;
    deleteClaimTypeModalRef: any;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private transactionInfoService: TransactionInfoService,
                private claimService: ClaimService,
                private documentHelperService : DocumentHelperService,
                private dynamicRoutingService: DynamicRoutingService,
                private modalService: NgbModal,
                private claimTypeItemHelperService: ClaimTypeItemHelperService,
                private injector : Injector) {

        super(injector);
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
        this.claim = this.claimService.getClaim();
    }

    ngOnInit() {
        this.pushGASelectClaimView();
        this.claimTypeData = this.transactionInfo.getClaimTypeItemList();

        setTimeout(() => {
            this.showSvgPath();
        }, 10);
    }

    toggleActive(claimTypeId, claimType: ClaimTypeItem) {
        this.chooseIndex = claimTypeId;
        this.selectedClaimCode = claimType.claimTypeCode;
        this.hasActiveClaimType = true;
    
        if (this.transactionInfo.isTravelProduct() && claimTypeId === 7) {
            this.handleTravelProduct();
        } else if (this.transactionInfo.isDomesticHelperProduct() && claimType.claimTypeCode == ClaimTypes[ClaimTypes.CLAIM_TYPE_UNKNOWN]) {
            this.handleDomesticHelperProduct();
        } else if (this.transactionInfo.isMotorProduct()) {
            this.handleMotorProduct();
        } else if (this.transactionInfo.isHomeProduct() || this.transactionInfo.isDomesticHelperProduct() || this.transactionInfo.isEmployeeCompensationProduct()) {
            this.handleHomeDomesticEmployeeProduct(claimType);
        } else if (this.transactionInfo.isAccidentProduct()) {
            this.handleAccidentProduct(claimType);
        }
    }

    private handleTravelProduct() {
        this.pushGASelectClaimOthers();
        this.claimTypeData[7]['show'] = false;
        for (let i = 8; i < this.claimTypeData.length; i++) {
            this.claimTypeData[i]['show'] = true;
        }
        this.selectedClaimCode = '';
        this.hasActiveClaimType = false;
    }
    
    private handleDomesticHelperProduct() {
        this.claimTypeData[2]['show'] = false;
        for (let i = 3; i < this.claimTypeData.length; i++) {
            this.claimTypeData[i]['show'] = true;
        }
        this.selectedClaimCode = '';
        this.hasActiveClaimType = false;
    }
    
    private handleMotorProduct() {
        if (this.claim.getSelectedClaimTypesList().length > 0) {
            this.chooseIndex = undefined;
            this.selectedClaimCode = '';
            this.hasActiveClaimType = true;
        }
    }
    
    private handleHomeDomesticEmployeeProduct(claimType: ClaimTypeItem) {
        if (this.claim.getSelectedClaimTypesList().length > 0) {
            this.chooseIndex = undefined;
            this.selectedClaimCode = '';
            this.hasActiveClaimType = true;
        }
        this.setGAClaimType(claimType);
    }
    
    private handleAccidentProduct(claimType: ClaimTypeItem) {
        if (this.claim.getSelectedClaimTypesList().length > 0) {
            if (this.claim.isHKGorHGI() && claimType.claimTypeCode !== this.claim.getSelectedClaimTypesList()[0]) {
                this.openDeleteClaimTypePopup();
            } else {
                this.chooseIndex = undefined;
                this.selectedClaimCode = '';
                this.hasActiveClaimType = true;
            }
        }
        this.setGAClaimType(claimType);
    }

    goToNext() {

        this.notifications.clearMessages();
        const selectedClaimItemList: string[] = this.claim.getSelectedClaimTypesList();
        let oneSelected: boolean = false;

        //check if combination of claim types is allowed
        let isSelectedNotAllowedMsg: string = this.checkCombinationOfClaimTypes(selectedClaimItemList, this.hasActiveClaimType,
            this.selectedClaimCode);

        if (!UtilitiesService.isNullOrUndefined(isSelectedNotAllowedMsg)) {
            this.hasActiveClaimType = false;
            this.chooseIndex = undefined;
            this.selectedClaimCode = '';
            this.displayNotificationMsg(isSelectedNotAllowedMsg);
        } else {
            //check if no active selected claim type, set the current to last entry in select claim type list
            if(this.claim.getSelectedClaimTypesList().length > 0 && this.chooseIndex == undefined){
                if (this.transactionInfo.isDomesticHelperProduct() && !this.closeAlert) {
                    this.openAlertModal();
                    return false;
                } else {
                    oneSelected = this.getCurrentClaimType();
                }
            } else {
                if (this.hasActiveClaimType) {
                    let claimTypeSelected =  this.claimTypeData.find(claimTypeItem =>
                        claimTypeItem.claimTypeCode === this.selectedClaimCode);
                    if(!UtilitiesService.isNullOrUndefined(claimTypeSelected)){
                        claimTypeSelected.selected = true;
                        if(selectedClaimItemList.indexOf(claimTypeSelected.claimTypeCode) < 0){
                            selectedClaimItemList.push(claimTypeSelected.claimTypeCode);
                        } else {
                            if (this.transactionInfo.isDomesticHelperProduct() && !this.closeAlert) {
                                this.openAlertModal();
                                return false;
                            }                            
                        }
                    }
                    oneSelected = true;
                }
            }

            if (oneSelected) {
                //get claim type menu from transaction info
                let claimTypeDetailMenu = this.transactionInfo.getNavigationMenu().getClaimDetailMenu();
                if (Object.keys(claimTypeDetailMenu[this.chooseIndex]['subMenus']).length !== 0) {
                    this.claim.setSelectedClaimTypesList(selectedClaimItemList);
                    this.transactionInfoService.getTransactionInfo().setClaimTypeItemList(this.claimTypeData);
                    this.sideMenuService.emitCliamType(this.chooseIndex);
                    this.sideMenuService.emitComplete('finishDetail');
                    this.sideMenuService.emitProgress(1);

                    this.limit = { ClaimTypeId: this.chooseIndex };

                    if (this.transactionInfo.isAccidentProduct() && this.claim.isHKGorHGI()) {
                        this.nextLinkUrl = claimTypeDetailMenu[this.chooseIndex]['url'];
                    } else {
                        this.nextLinkUrl = claimTypeDetailMenu[this.chooseIndex]['subMenus'][0]['url'];
                    }
                    this.router.navigate([this.nextLinkUrl]);

                    //set document form builder of selected claim type
                    if(this.selectedClaimCode !== '' && this.hasActiveClaimType) {
                        if (this.transactionInfo.isHomeProduct()) {
                            this.transactionInfo.setDynamicRoutes(this.dynamicRoutingService.getDynamicHomeRoutes(this.selectedClaimCode));
                        } else {
                        this.documentHelperService.addDocumentFormBuilder(this.selectedClaimCode);
                        }
                    }
                }
            } else {
                this.displayNotificationMsg("error_messages.notificationsErrMsg.claimType");
            }
        }
    }

    checkCombinationOfClaimTypes(selectedClaimTypeList: string[], hasActiveClaimType: boolean,
                                 selectedClaimCode: string): string {
        if (this.transactionInfo.isTravelProduct() && selectedClaimTypeList.length > 0 && hasActiveClaimType) {
            if (selectedClaimTypeList.find(claimType => claimType === ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN]) &&
                selectedClaimCode === ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_CRTL]) {
                return "error_messages.travelClaimGroup.tripCancellationCurtailment";
            } else if (selectedClaimTypeList.find(claimType => claimType === ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_CRTL]) &&
                selectedClaimCode === ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN]) {
                return "error_messages.travelClaimGroup.tripCurtailmentCancellation";
            }
        }
        return null;
    }

    displayNotificationMsg(errorMsg: string) {
        this.notifications = new Notifications();
        this.notifications.showPreamble = false;
        this.notifications.addErrorMessage(errorMsg);
        this.scrollToTop();
    }

    getCurrentClaimType() : boolean {
       let currentClaimType = this.transactionInfo.getCurrentClaimType();
         if (this.isNotNullOrUndefinedStr(currentClaimType) && (this.chooseIndex == undefined || this.hasActiveClaimType)) {
             let currentClaimTypeSelected =  this.claimTypeData.find(claimTypeItem =>
                 claimTypeItem.claimTypeCode === currentClaimType);
             if(!UtilitiesService.isNullOrUndefined(currentClaimTypeSelected)) {
                 this.chooseIndex = currentClaimTypeSelected.claimTypeId;
                 this.selectedClaimCode = currentClaimTypeSelected.claimTypeCode;
                 return true;
             }
         }
       return false;
    }

    showModal() {
        console.log('showModel');
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    back() {
        this.sideMenuService.emitProgress(-1);
        if (this.transactionInfo.isMotorProduct()) {
            this.router.navigate(["/claimform/confirmVehicleDetails"], {
                relativeTo: this.activatedRoute
            });
        } else if (this.transactionInfo.isHomeProduct()) {
            this.router.navigate(["/claimform/confirmIncidentDetails"], {
                relativeTo: this.activatedRoute
            });
        } else if (this.transactionInfo.isAccidentProduct()) {
            let url = '/claimform/paInjuryDetails';
            if (this.claim.isHKGorHGI()) {
                url = '/claimform/paDetailsOfAccident';
            }
            this.router.navigate([url], {
                relativeTo: this.activatedRoute
            });
        } else if (this.transactionInfo.isEmployeeCompensationProduct()) {
            this.router.navigate(["/claimform/employerDetails"], {
                relativeTo: this.activatedRoute
            });
        } else {
            this.router.navigate(["/claimform/selectInsured"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    scrollToTop() {
        (function smoothScroll() {
            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 2) {
                window.requestAnimationFrame(smoothScroll);
                window.scrollTo(0, currentScroll - (currentScroll / 5));
            }
        })();
    }

    showSvgPath() {
        $('img.svg,.icon').each(function () {
            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            $.get(imgURL, function (data) {
                // Get the SVG tag, ignore the rest
                var $svg = $(data).find('svg');

                // Add replaced image's ID to the new SVG
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Check if the viewport is set, else we gonna set it if we can.
                if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                }

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');
        });
    }

    openAlertModal() {
        this.alertClaimTypeModalRef = this.modalService.open(this.alertClaimTypeModal, {size: 'sm',
            backdrop: 'static',
            keyboard: false
        });
    }

    continue() {
        this.closeAlert = true;
        this.alertClaimTypeModalRef.close();
    }

    openDeleteClaimTypePopup() {
        this.deleteClaimTypeModalRef = this.modalService.open(this.deleteClaimTypeModal, {size: 'md',
            backdrop: 'static',
            keyboard: false
        });
    }

    deleteClaimType() {
        const selectedClaimType = this.claimTypeData.find(claimType => claimType.selected);
        let claimTypeId = selectedClaimType ? selectedClaimType.claimTypeId : -1;
        this.claimTypeItemHelperService.deleteClaimType(claimTypeId);
        this.deleteClaimTypeModalRef.close();
    }

    cancelDeleteClaimType() {
        this.chooseIndex = undefined;
        this.selectedClaimCode = '';
        this.hasActiveClaimType = true;
        this.deleteClaimTypeModalRef.close();
    }

    //START - Google Analytics
    pushGASelectClaimView() {
        let ecommerce: any;
        if (!this.transactionInfo.isHomeProduct()) {
            let step = this.transactionInfo.isAccidentProduct() ? 5 : 4;
            ecommerce = {'checkout': {'actionField': {'step': step}}};
        }

        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Select Claim Type',
            'vPath': '/select-claim-type',
            'event': 'vpageview',
            'ecommerce': ecommerce

        });
    }

    pushGASelectClaimOthers() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({'event': 'otherclaimtypes'});
    }

    //END - Google Analytics

    setGAClaimType(claimType: ClaimTypeItem) {
        this.transactionInfo.setGaPageStep(claimType.gaPageStep);
        this.transactionInfo.setGaVPath(claimType.gaVPath);
    }

    onConfirmECFormSelection(claimTypeItem) {
        this.toggleActive(claimTypeItem.claimTypeId, claimTypeItem);
        this.goToNext();
    }
}
