import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { SideMenuService } from 'src/app/services/side-menu.service';
import {TransactionInfoService} from '../../../services/transaction-info.service';
import {InsuranceDetails} from '../../../model/employee-compensation/insurance-details.model';
import {TransactionInfo} from '../../../model/transaction-info.model';

@Component({
    selector: 'app-ec-insurance-details',
    templateUrl: './ec-insurance-details.component.html'
})
export class ECInsuranceDetailsComponent extends ClaimsBaseComponent implements OnInit {

    insuranceForm: FormGroup;
    showTotalBox = false;
    insuranceDetails: InsuranceDetails;
    transactionInfo: TransactionInfo;

    constructor(private fb: FormBuilder,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private sideMenuService: SideMenuService) {
        super(injector);
        this.insuranceDetails = this.claim.getClaimEmployeeCompensation().getInsuranceDetails();
        this.transactionInfo = this.injector.get(TransactionInfoService).getTransactionInfo();
    }

    ngOnInit() {
        this.insuranceDetails.setCompanyName(
            this.setDefaultIfNullOrUndefined(this.insuranceDetails.getCompanyName(), 'QBE Hongkong & Shanghai Insurance Limited')
        );

        this.insuranceDetails.setCompanyAddress(
            this.setDefaultIfNullOrUndefined(this.insuranceDetails.getCompanyAddress(), '33/F, Oxford House, Taikoo Place, 979 King\'s Road, Quarry Bay, Hong Kong')
        );

        this.insuranceDetails.setPolicyNumber(
            this.setDefaultIfNullOrUndefined(this.insuranceDetails.getPolicyNumber(), this.claim.getPolicyNumber())
        );

        this.insuranceForm = this.fb.group({
            name: [this.insuranceDetails.getCompanyName()],
            address: [this.insuranceDetails.getCompanyAddress()],
            policyNumber: [this.insuranceDetails.getPolicyNumber()]
        });
    }

    ngAfterViewInit() {
        this.insuranceForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data) => {
                this.insuranceDetails.setCompanyName(this.insuranceForm.get('name').value);
                this.insuranceDetails.setCompanyAddress(this.insuranceForm.get('address').value);
                this.insuranceDetails.setPolicyNumber(this.insuranceForm.get('policyNumber').value);
            });
    }

    goToNext() {
        if (this.validateForm()) {
            let route = this.transactionInfo.getRouteByCurrentUrl('/insuranceDetails', true);
            if (route) {
                this.sideMenuService.emitClaimComplete({claimTypeId: route.claimIndex, subMenuIndex: route.routeIndex});
                this.router.navigate([route.routeUrl], {
                    relativeTo: this.activatedRoute
                })
            }
        }
    }

    back() {
        let route = this.transactionInfo.getRouteByCurrentUrl('/insuranceDetails', false);
        this.router.navigate([route.routeUrl], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.insuranceForm);
    }

    showModal() {
        this.showTotalBox = true;
    }
  
    getData(msg) {
        this.showTotalBox = msg;
    }
}
