import {Component, Injector, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {BaggageDelayItemDetail} from "../../../../model/travel/baggage-delay-item.model";
import {takeUntil} from "rxjs";
import {UtilitiesService} from "../../../../utilities/utilities.service";
import { DateService } from "src/app/ui/ui-datepicker/date.service";

@Component({
    selector: 'app-emergency-purchased-item',
    templateUrl: './emergency-purchased-item.component.html',
    styleUrls: ['./emergency-purchased-item.component.css']
})
export class EmergencyPurchasedItemComponent extends ClaimsBaseComponent implements OnInit {

    emergencyPurchaseForm: FormGroup;
    showTotalBox = false;
    emergencyPurchasedItemsArray: BaggageDelayItemDetail[] = [];
    defaultCurrency: string;

    constructor(private fb: FormBuilder, 
                public sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private dateService: DateService) {
        super(injector);
        this.defaultCurrency = UtilitiesService.getCountryCurrency(this.claim.getCountry());
    }

    ngOnInit() {
        this.pushGAViewPurchaseItem();
        this.emergencyPurchasedItemsArray = this.claim.getClaimTravel().getBaggageDelay().getEmergencyPurchasedItemsArray();
        this.emergencyPurchaseForm = this.fb.group({
            items: this.fb.array(this.buildStoredItems(this.emergencyPurchasedItemsArray))
        });

        this.initializeArrays();
    }

    ngAfterViewInit() {
        this.emergencyPurchaseForm.valueChanges.subscribe(data => {
            this.emergencyPurchasedItemsArray = [];
            data.items.forEach((details, index) => {

                let itemDetail : BaggageDelayItemDetail = new BaggageDelayItemDetail();

                itemDetail.setEmergencyPurchaseType(details.emergencyPurchaseType);
                itemDetail.setEmergencyPurchaseTypeDesc(super.getIndexedTextInComponent("emergencyPurchaseType", index));
                itemDetail.setEmergencyPurchaseTypeDetail(details.emergencyPurchaseTypeDetail);
                itemDetail.setPurchaseDate(this.dateService.stringToDate(details.purchaseDate, 'dd/MM/yyyy'));
                itemDetail.setPurchasePriceType(details.purchasePriceType);
                itemDetail.setPurchasePrice(details.purchasePrice);
                this.emergencyPurchasedItemsArray.push(itemDetail);
            });

            this.claim.getClaimTravel().getBaggageDelay().setEmergencyPurchasedItemsArray(this.emergencyPurchasedItemsArray);

            setTimeout(() => {
                this.validateClaimDetailsComplete();
            }, 200);

        });

        // Stop validation of fields when not required.
        let formArray : FormArray =  <FormArray> this.emergencyPurchaseForm.controls["items"];
        for (let i = 0; i < formArray.controls.length; i++) {
             formArray.controls[i].get('emergencyPurchaseType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.emergencyPurchaseTypeChanged(data, formArray.controls[i]);
             });
        }

    }

    initializeArrays() {
        if (UtilitiesService.isNullOrUndefined(this.emergencyPurchasedItemsArray)) {
            this.emergencyPurchasedItemsArray = [];
            this.claim.getClaimTravel().getBaggageDelay().setEmergencyPurchasedItemsArray(this.emergencyPurchasedItemsArray);
        }

        if (this.emergencyPurchasedItemsArray.length === 0) {
            this.emergencyPurchasedItemsArray.push(new BaggageDelayItemDetail());
        }
    }

    emergencyPurchaseTypeChanged(lossType, formGroup) {

        formGroup.get('purchaseDate').disable({onlySelf: false, emitEvent: false});
        formGroup.get('purchasePriceType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('purchasePrice').disable({onlySelf: false, emitEvent: false});
        formGroup.get('emergencyPurchaseTypeDetail').disable({onlySelf: false, emitEvent: false});
        
        if (lossType === 'Others') {
            formGroup.get('emergencyPurchaseTypeDetail').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchaseDate').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePriceType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePrice').enable({onlySelf: false, emitEvent: false});
        }
        else {
            formGroup.get('purchaseDate').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePriceType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePrice').enable({onlySelf: false, emitEvent: false});
        }
    }

    buildStoredItems(emergencyPurchasedItemsArray: BaggageDelayItemDetail[]): FormGroup[] {

        let formGroupArray : FormGroup[] = [];

        if(emergencyPurchasedItemsArray != null && emergencyPurchasedItemsArray.length > 0 ) {

            for (let emergencyPurchasedItem of emergencyPurchasedItemsArray) {

                let rowFormGroup = this.fb.group({
                    emergencyPurchaseType: [emergencyPurchasedItem.getEmergencyPurchaseType()],
                    emergencyPurchaseTypeDesc: [emergencyPurchasedItem.getEmergencyPurchaseTypeDesc()],
                    emergencyPurchaseTypeDetail: [emergencyPurchasedItem.getEmergencyPurchaseTypeDetail()],
                    purchaseDate: [emergencyPurchasedItem.getPurchaseDate()],
                    purchasePriceType: [emergencyPurchasedItem.getPurchasePriceType()],
                    purchasePrice: [emergencyPurchasedItem.getPurchasePrice()],
                });

                formGroupArray.push(rowFormGroup);
            }
        }
        else {
            formGroupArray.push(this.createDefaultItems());
        }

        return formGroupArray;
    }


    createDefaultItems(): FormGroup {
        return this.fb.group({
            emergencyPurchaseType: null,
            emergencyPurchaseTypeDesc: '',
            emergencyPurchaseTypeDetail: '',
            purchaseDate: null,
            purchasePriceType: this.defaultCurrency,
            purchasePrice: ''
        });

    }

    addItem(): void {
        let self = this;
        setTimeout(function () {
            let items = self.emergencyPurchaseForm.get('items') as FormArray;
            let newItem : FormGroup = self.createDefaultItems();
            items.push(newItem);

            newItem.get('emergencyPurchaseType').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.emergencyPurchaseTypeChanged(data, newItem);
            });
        }, 10);
    }

    removeItem(i: number) {
        let items = this.emergencyPurchaseForm.get('items') as FormArray;
        items.removeAt(i);

        items.value.forEach(formItem =>{
            this.emergencyPurchasedItemsArray.forEach(item =>{
                if (item.getEmergencyPurchaseType() === formItem.emergencyPurchaseType) {
                    item.setEmergencyPurchaseTypeDesc(formItem.emergencyPurchaseTypeDesc);
                }
            });
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 3, subMenuIndex: 1});

            this.router.navigate(["/claimform/baggageDelay/baggageDelaySupportDoc/uploadDocument"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.router.navigate(["/claimform/baggageDelay/detailsOfBaggageDelay"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.emergencyPurchaseForm);
    }

    validateMoneyFields(): boolean {

        let data = this.emergencyPurchasedItemsArray;
        for (let i = 0; i < data.length; i++) {
                let formArray : FormArray =  <FormArray> this.emergencyPurchaseForm.controls["items"];
                for (let i = 0; i < formArray.controls.length; i++) {                    

                    if (formArray.controls[i].get('purchasePrice').value == '' 
                    || formArray.controls[i].get('purchasePrice').value == null) { 
                        return true;
 
                    }
                }

        }
        return false;
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.emergencyPurchaseForm);
        let isMoneyFieldsPopulated : boolean = this.validateMoneyFields();
        if(isFormValidOnChange && !isMoneyFieldsPopulated){
            this.sideMenuService.emitClaimComplete({claimTypeId: 3, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 3, subMenuIndex: 1});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewPurchaseItem() {
        (<any>window).dataLayer.push({
            'pageStep': 'Baggage Delay – Emergency Item',
            'vPath': '/claim/baggage-delay/emergency-items',
            'event': 'vpageview',
        });
    }

}
