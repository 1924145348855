import {Component, Injector, OnInit, ElementRef, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {Claim} from "../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import {ClinicalExpenses} from "../../../../model/domestic-helper/clinical-expenses.model";

@Component({
  selector: 'app-clinical-expenses-detail',
  templateUrl: './clinical-expenses-detail.component.html',
  styleUrls: ['./clinical-expenses-detail.component.css']
})

export class ClinicalExpensesDetailComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    clinicalForm: FormGroup;
    claim: Claim;
    clinicalExpenses: ClinicalExpenses;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.clinicalExpenses = this.claim.getClaimDomesticHelper().getClinicalExpenses();
        
        if(this.clinicalExpenses.getTotalAmountOfExpensesCurrency() == null){
            this.clinicalExpenses.setTotalAmountOfExpensesCurrency('HKD')
        }

        if(this.clinicalExpenses.getCountryOccurred() == null){
            this.clinicalExpenses.setCountryOccurred('HKG');
        }
    }

    ngOnInit() {

        this.pushGAViewClinicalExpensesDetails();

        this.clinicalForm = this.fb.group({
            countryOccured: [this.clinicalExpenses.getCountryOccurred()],
            natureOfIllness: [this.clinicalExpenses.getNatureOfIllness()],
            didHelperSustainInjury: [super.getBooleanString(this.clinicalExpenses.getDidHelperSustainInjury())]
        });
    }

    ngAfterViewInit() {
        this.clinicalForm.valueChanges.subscribe(data => {
            this.clinicalExpenses.setCountryOccurred(super.getValueInComponent('countryOccured'));
            this.clinicalExpenses.setCountryOccurredDesc(this.getTextInComponent('countryOccured'));
            this.clinicalExpenses.setNatureOfIllness(super.getValueInComponent('natureOfIllness'));
            this.clinicalExpenses.setNatureOfIllnessDesc(this.getTextInComponent('natureOfIllness'));
            if (this.clinicalForm.get('didHelperSustainInjury').value != null) {
                this.clinicalExpenses.setDidHelperSustainInjury(this.clinicalForm.get('didHelperSustainInjury').value == 'true');
            }
        });

        // Stop validation of the other field when reason isn't other
        let self = this;
        self.clinicalForm.get('natureOfIllness').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowIlnessOrInjuryDetail(data);
        });

    }

    hideShowIlnessOrInjuryDetail(value){
        if (value && value.toLowerCase() === 'injury') {
            this.clinicalForm.get('didHelperSustainInjury').reset();
            this.clinicalForm.get('didHelperSustainInjury').enable({onlySelf: false, emitEvent: false});
            this.clinicalExpenses.setHasRelatedConditionsForSickness(null);
            this.clinicalExpenses.setTypeOfIllnessOrInjury(null);
            this.clinicalExpenses.setTypeOfIllnessOrInjuryDesc(null);
            this.clinicalExpenses.setOtherTypeOfIllnessOrInjury(null);
        } else  {
            this.clinicalForm.get('didHelperSustainInjury').reset();
            this.clinicalForm.get('didHelperSustainInjury').disable({onlySelf: false, emitEvent: false});
            this.clinicalExpenses.setHasRelatedConditionsForInjury(null);
            this.clinicalExpenses.setDidHelperSustainInjury(null);
            this.clinicalExpenses.setTypeOfIllnessOrInjury(null);
            this.clinicalExpenses.setTypeOfIllnessOrInjuryDesc(null);
        }
    }

    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 0});
            this.router.navigate(["/claimform/clinical/relatedConditions"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.clinicalForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewClinicalExpensesDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Clinical Expenses – Illness Details',
            'vPath': '/claim/clinical-expenses/illness-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }
}
