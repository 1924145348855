<!-- Another Insurance -->
<div class="form-group section">
    <div class="section-header sub-section-header anotherInsuranceHeader">
        <label class="custom-summary-title section-title">{{ 'pageSummaryClaim.anotherInsurance.title' | translate }}</label>
    </div>
    <div class="section-content">
        <div class="sub-section">
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'anotherInsuranceSection.hasAnotherInsurance' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content b1">
                    {{returnYesOrNoString(claim.getOtherPolicyQuestion())}}
                </div>
            </div>
            <ng-container *ngIf="claim.getOtherPolicyQuestion() == 'Y'">
                <div class="row adjusted-row-alignment" *ngIf="claim.getOtherPolicyName()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'pageSummaryClaim.anotherInsurance.companyName' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content forced-left">
                        {{claim.getOtherPolicyName()}}
                    </div>
                </div>
                <div class="row" *ngIf="claim.getOtherPolicyNumber()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'pageSummaryClaim.anotherInsurance.policyNumber' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content b3">
                        {{claim.getOtherPolicyNumber()}}
                    </div>
                </div>
                <div class="row" *ngIf="isNotNullOrUndefined(claim.getOtherPolicyGoingToSubmit())">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'pageSummaryClaim.anotherInsurance.submitClaim' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content b4">
                        {{returnYesOrNo(claim.getOtherPolicyGoingToSubmit())}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
