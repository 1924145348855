import {Component, Injector, OnInit} from '@angular/core';
import {InsuredPerson} from '../../model/insured-person.model';
import {TransactionInfo} from '../../model/transaction-info.model';
import {TransactionInfoService} from '../../services/transaction-info.service';
import {Claimant} from '../../model/claimant.model';
import {ClaimsBaseComponent} from '../claim-base.component';
import {ClaimService} from '../../services/claim.service';
import {Claim} from '../../model/claim.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UtilitiesService} from '../../utilities/utilities.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'additional-claimant',
    templateUrl: './additional-claimant.component.html',
    styleUrls: ['./additional-claimant.component.css']
})

export class AdditionalClaimantComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    showAdditionalClaimant : boolean = null;
    showMoreContent = false;
    insuredPersonList: InsuredPerson[] = [];     //transaction info - insured person list without primary insured
    transactionInfo: TransactionInfo;
    currentClaimType: string = '';               //current claim type holder
    otherInsuredPersonForm: FormGroup;
    moreInsuredForm: FormGroup;
    matchedInsuredPerson: InsuredPerson;
    insuredPersList: InsuredPerson[] = [];
    isAddMoreInsured: boolean = false;
    otherIdType: string = "hkid";

    constructor(private fb: FormBuilder,
                private claimService: ClaimService,
                private transactionInfoService: TransactionInfoService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
        this.insuredPersList = this.transactionInfo.getInsuredPersonsList();
        this.matchedInsuredPerson = this.transactionInfo.getMatchedInsuredPerson();
    }
    
    ngOnInit() {

        this.currentClaimType = this.transactionInfo.getCurrentClaimType();
        this.populateInsuredPersonList();

        //form group for more insured question 
        this.moreInsuredForm = this.fb.group(
            {
                moreInsured: [super.getBooleanString(this.showAdditionalClaimant)]
            }
        );

        //form group for other insured person
        this.otherInsuredPersonForm = this.fb.group(
            {
                otherPersonDOB: [],
                otherPersonName: [],
                otherPersonHKID: [],
                otherPersonIdType: [this.otherIdType]

            }
        );

        if (this.transactionInfo.isTravelCorporatePolicyHolder()) {
            if (this.insuredPersList.length > 1) {
                this.isAddMoreInsured = true;
            } else {
                this.showMoreContent = true;
            }
        }
    }

    ngAfterViewInit(){
        this.moreInsuredForm.valueChanges.subscribe(data => {
            this.showAdditionalClaimant = this.moreInsuredForm.get('moreInsured').value  == 'true';
        });
        // Stop validation of fields when not required.
        let self = this;
        setTimeout(function () {self.showClaimants(self.getBooleanString(self.showAdditionalClaimant)),10});
            this.moreInsuredForm.get('moreInsured').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.showClaimants(data);
        });

        setTimeout(function () {self.setIdTypeSelected(self.otherInsuredPersonForm.get('otherPersonIdType').value),10});
        this.otherInsuredPersonForm.get('otherPersonIdType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.setIdTypeSelected(data);
        });
    }

    showClaimants(show: any) {
        if(String(show) == 'true'){
            this.showAdditionalClaimant = true;
        } else {
            this.showAdditionalClaimant = false;
            this.clearClaimantsList();
        }
        
    }

    showClaimantList() {
        this.showAdditionalClaimant = !this.showAdditionalClaimant;
    }

    populateInsuredPersonList() {

        this.showAdditionalClaimant = null;
        let transInfo = Object.assign(new TransactionInfo(), this.transactionInfo);
        let transInfoInsuredPersonList =  transInfo.getInsuredPersonsList();

        this.getOtherInsuredPersonList(transInfoInsuredPersonList);

        // check if claimant list has entry with current claim type (set showMoreClaimant to true)
        let claimantList = this.claim.getClaimantList();
        let self = this;
        claimantList.forEach(function (claimantDetail) {
             let claimantClaimTypes = claimantDetail.getSelectedClaimTypesList();
                if (claimantClaimTypes.length > 0) {
                    claimantClaimTypes.forEach(function (claimTypeItem) {
                        if (claimTypeItem == self.currentClaimType) {
                            self.showAdditionalClaimant = true;
                        }
                    });
                }
        });

    }

    chooseClaimant(insuredPerson: InsuredPerson) {
        let insuredPersonIdentifier = UtilitiesService.createClaimantIdentifier(insuredPerson);
        this.updateClaimantList(insuredPerson, insuredPersonIdentifier);
    }

    updateClaimantList(selectedInsured: InsuredPerson, selectedInsuredIdentifier: string) {

        if (!UtilitiesService.isNullOrUndefined(selectedInsured)) {
            let claimantList = this.claim.getClaimantList();

            //loop through additional claimant list
            let self = this;
            let isRemoveClaimType = false;
            let isRemoveFromClaimantList = false;
            let ignoreClaimantList = false;
            if (claimantList.length > 0) {
               claimantList.forEach(function (claimantDetail, claimantIndex) {
                   let claimantName = claimantDetail.getClaimantName();
                   let claimantIdentifier = claimantDetail.getClaimantIdentifier();
                   if (claimantName == selectedInsured.getInsuredName() && claimantIdentifier == selectedInsuredIdentifier) {
                       //console.log("CLAIMANT AND INSURED NAME ARE THE SAME");
                        let claimantClaimTypes = claimantDetail.getSelectedClaimTypesList();

                        if (claimantClaimTypes.length > 0) {
                            claimantClaimTypes.forEach(function (claimTypeItem, claimTypeIndex) {
                                if (claimTypeItem == self.currentClaimType && claimantClaimTypes.length > 1) {
                                    isRemoveClaimType = true;
                                    ignoreClaimantList = true;
                                    claimantClaimTypes.splice(claimTypeIndex, 1);
                                    //console.log("updateClaimantList: selected insured claim type remove to claimant list");
                                }
                            });
                        }

                       if (!isRemoveClaimType) {
                           if (claimantClaimTypes.length == 1 && claimantClaimTypes[0] == self.currentClaimType) {
                               isRemoveFromClaimantList = true;
                               isRemoveClaimType = true;
                               claimantList.splice(claimantIndex, 1);
                               //console.log("updateClaimantList: selected insured remove to claimant list");
                           } else {
                               ignoreClaimantList = true;
                               claimantClaimTypes.push(self.currentClaimType);
                               //console.log("updateClaimantList: claim type add to claimant list");
                           }
                       }
                   }
               });
           }

           if (!isRemoveFromClaimantList && !ignoreClaimantList) {
               let newClaimant: Claimant = new Claimant();
               newClaimant.setClaimantName(selectedInsured.getInsuredName());
               newClaimant.setClaimantIdNumber(selectedInsured.getInsuredPersonNationalIdNumber());
               newClaimant.setClaimantDateOfBirth(selectedInsured.getInsuredDateOfBirth());

               let claimTypeList: string[] = [];
               claimTypeList.push(self.currentClaimType);
               newClaimant.setSelectedClaimTypesList(claimTypeList);
               newClaimant.setClaimantIdentifier(selectedInsuredIdentifier);
               claimantList.push(newClaimant);
               //console.log("updateClaimantList: new claimant list added");
           }

            this.claim.setClaimantList(claimantList);
        }

    }

    checkInsuredPersonSelected(insured: InsuredPerson): boolean {
        let claimantList = this.claim.getClaimantList();
        let claimantIdentifier = UtilitiesService.createClaimantIdentifier(insured);

        let claimantItem = claimantList.find(x => x.getClaimantName() == insured.getInsuredName() && x.getClaimantIdentifier() == claimantIdentifier);

        if (!UtilitiesService.isNullOrUndefined(claimantItem)) {
           let selectedClaimTypeList = claimantItem.getSelectedClaimTypesList();
           let claimType = selectedClaimTypeList.find(claimType => claimType == this.currentClaimType);
           if (!UtilitiesService.isNullOrUndefined(claimType)) {
               return true;
           } else {
               return false;
           }
       } else {
           return false;
       }

    }

    clearClaimantsList() {
        let claimantList = this.claim.getClaimantList();
        let newClaimantList = claimantList.filter(item => item.getSelectedClaimTypesList().find(claimType => claimType != this.currentClaimType));
        this.claim.setClaimantList(newClaimantList);
        this.populateInsuredPersonList();

    }

    showOtherContent() {
        this.showMoreContent = !this.showMoreContent;
    }

    addInsuredPerson() {

        if (this.validateForm()) {

            let otherInsuredPerson: InsuredPerson = new InsuredPerson();
            otherInsuredPerson.setInsuredPersonNationalIdNumber(this.otherInsuredPersonForm.get('otherPersonHKID').value);
            otherInsuredPerson.setInsuredName(this.otherInsuredPersonForm.get('otherPersonName').value);
            otherInsuredPerson.setInsuredDateOfBirth(super.getValueInComponent('otherPersonDOB'));
            this.insuredPersList.push(otherInsuredPerson);

            let otherInsuredPersonIdentifier = UtilitiesService.createClaimantIdentifier(otherInsuredPerson);

            this.transactionInfo.setInsuredPersonsList(this.insuredPersList);
            this.showMoreContent = false;
            this.otherInsuredPersonForm.reset();

            // get other insured persons list
            this.getOtherInsuredPersonList(this.insuredPersList);

            //pre select new insured person
            this.updateClaimantList(otherInsuredPerson, otherInsuredPersonIdentifier);
            this.isAddMoreInsured = true;
        }

    }

    getOtherInsuredPersonList(transInfoInsuredPersonList: InsuredPerson[]) {

        if(transInfoInsuredPersonList.length > 1) {
           let claimantName = this.claim.getClaimantName();
           let claimantIdentifier = this.claim.getClaimantIdentifier();
            let otherInsuredPersonList: InsuredPerson[] = [];
            transInfoInsuredPersonList.forEach(function (insuredPersonDetail) {
                let insuredPersonIdentifier = UtilitiesService.createClaimantIdentifier(insuredPersonDetail);
                if (claimantName != insuredPersonDetail.getInsuredName()  && claimantIdentifier != insuredPersonIdentifier) {
                    otherInsuredPersonList.push(insuredPersonDetail);
                }
            });

            this.insuredPersonList = otherInsuredPersonList;
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.otherInsuredPersonForm);
    }

    setIdTypeSelected(value) {
        if (!UtilitiesService.isNullOrUndefined(value) && value) {
            this.otherIdType = value;
        } else {
            this.otherIdType = "hkid";
        }
    }

}
