import {Component, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../../services/side-menu.service";
import {ClaimService} from "../../../../../services/claim.service";
import {DocumentHelperService} from "../../../../../services/document-helper.service";
import {TransactionInfoService} from "../../../../../services/transaction-info.service";
import {HttpClient} from "@angular/common/http";
import {ClaimDocumentsComponent} from "../../../../claim-documents/claim-documents.component";
import {DocumentFormFactoryService} from "../../../../../services/document-form-factory.service";
import {ClaimTypes} from "../../../../../model/claim-type.model";
import { SpinnerService } from '../../../../../core/spinner/spinner.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

declare var $: any;

@Component({
    selector: 'app-accident-upload-document',
    templateUrl: './accident-upload-document.component.html',
    styleUrls: ['./accident-upload-document.component.css']
})
export class AccidentUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {

    upLoadFileModel: FormGroup;
    showTotalBox = false;

    constructor(public sideMenuService: SideMenuService,
        private fb: FormBuilder,
        claimService: ClaimService,
        documentHelperService: DocumentHelperService,
        transactionInfoService: TransactionInfoService,
        http: HttpClient,
        documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
                super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
                this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_DTH]);
    }

    ngOnInit() {

        this.pushGAViewAccidentDocs();
        super.ngOnInit();
        this.upLoadFileModel = this.fb.group(
            {
                airTicket: [],
                deathCertificate: [],
                medicalReportOfDiagnosis: [],
                relevantIncidentReport: [],
                medicalBills: this.fb.array([]),
                receiptOfFollowUp: [],
                receiptOfOtherExpense: [],
                otherItems: this.fb.array([this.createItem()])

            });
    }

    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: []
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    goToNext() {
        this.sideMenuService.emitClaimComplete({claimTypeId: 13, subMenuIndex: 4});
        this.sideMenuService.emitProgress(1);
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewAccidentDocs() {
        (<any>window).dataLayer.push({
            'pageStep': 'Serious body injury & Death – Document Upload',
            'vPath': '/claim/serious-injury-death/documents',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 6},	//Step6-DocumentUpload
                }
            }
        });
    }

}
