import {Component, ElementRef, Injector, OnInit, ViewChild} from "@angular/core";
import {SideMenuService} from "../../../../services/side-menu.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ClaimService} from "../../../../services/claim.service";
import {TripCurtailment} from "../../../../model/travel/trip-curtailment.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import {AutoSaveService} from '../../../../services/autosave.service';
import {TransactionInfoService} from '../../../../services/transaction-info.service';

declare var $: any;

@Component({
    selector: 'app-detail-of-trip-curtailment',
    templateUrl: './detail-of-trip-curtailment.component.html',
    styleUrls: ['./detail-of-trip-curtailment.component.css']
})
export class DetailOfTripCurtailmentComponent extends ClaimsBaseComponent implements OnInit {

    @ViewChild('lossType', {static: true}) el: ElementRef;
    deTailOfCancelForm: FormGroup;
    showTotalBox = false;
    tripCurtailment: TripCurtailment;
    isHgi = false;


    constructor(private fb: FormBuilder, 
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private transactionInfoService: TransactionInfoService,
                private autoSaveService: AutoSaveService,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.tripCurtailment = this.claim.getClaimTravel().getTripCurtailment();
        if(this.tripCurtailment.getDateActualDeparture() == null){
            this.tripCurtailment.setDateActualDeparture(this.claim.getDateOfLoss());
        }
        if(this.claim.getCountry().toLowerCase() != 'hgi'){
            this.isHgi = true;
        }
    }

    ngOnInit() {

        this.pushGAViewCurtailmentDetails();
        this.deTailOfCancelForm = this.fb.group(
            {
                dateOriginBook: [this.tripCurtailment.getDateOriginBook()],
                countryCurtailmentOccurred: [this.tripCurtailment.getCountryCurtailmentOccurred()],
                dateActualDeparture: [this.tripCurtailment.getDateActualDeparture()],
                reasonForCancel: [this.tripCurtailment.getReasonForCancel()],
                otherReason: [this.tripCurtailment.getOtherReason()],
                wasLossDueToRobbery: [super.getBooleanString(this.tripCurtailment.getWasLossDueToRobbery())],
                didYouReport: [super.getBooleanString(this.tripCurtailment.getDidYouReport())],
                authorityType: [this.tripCurtailment.getAuthorityType()],
                otherAuthorityType: [this.tripCurtailment.getOtherAuthorityType()],
                reportDate: [this.tripCurtailment.getReportDate()]

            }
        );
    }

    ngAfterViewInit() {

        this.deTailOfCancelForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.tripCurtailment.setDateOriginBook(super.getValueInComponent('dateOriginBook'));
            this.tripCurtailment.setCountryCurtailmentOccurred(super.getValueInComponent('countryCurtailmentOccurred'));
            this.tripCurtailment.setCountryCurtailmentOccurredDesc(this.getTextInComponent('countryCurtailmentOccurred'));
            this.tripCurtailment.setDateActualDeparture(super.getValueInComponent('dateActualDeparture'));
            this.tripCurtailment.setReasonForCancel(super.getValueInComponent('reasonForCancel'));
            this.tripCurtailment.setReasonForCancelDesc(super.getTextInComponent('reasonForCancel'));
            this.tripCurtailment.setOtherReason(super.getValueInComponent('otherReason'));
            if (this.deTailOfCancelForm.get('wasLossDueToRobbery').value != null) {
                this.tripCurtailment.setWasLossDueToRobbery(this.deTailOfCancelForm.get('wasLossDueToRobbery').value == 'true');
            }
            if (this.deTailOfCancelForm.get('didYouReport').value != null) {
                this.tripCurtailment.setDidYouReport(this.deTailOfCancelForm.get('didYouReport').value == 'true');
            }
            this.tripCurtailment.setAuthorityType(super.getValueInComponent('authorityType'));
            this.tripCurtailment.setAuthorityTypeDesc(super.getTextInComponent('authorityType'));
            this.tripCurtailment.setOtherAuthorityType(super.getValueInComponent('otherAuthorityType'));
            this.tripCurtailment.setReportDate(super.getValueInComponent('reportDate'));

            this.validateClaimDetailsComplete();
        });

        // Stop validation of the other field when reason isn't other
        let self = this;
        setTimeout(function () {self.hideOthersField(self.tripCurtailment.getReasonForCancel()),10});
        this.deTailOfCancelForm.get('reasonForCancel').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideOthersField(data);
        });

        setTimeout(function () {self.showHgiFields(self.tripCurtailment.getWasLossDueToRobbery()),10});
        this.deTailOfCancelForm.get('wasLossDueToRobbery').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.showHgiFields(data);
        });

        setTimeout(function () {self.showReportFields(self.tripCurtailment.getDidYouReport()),10});
        this.deTailOfCancelForm.get('didYouReport').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.showReportFields(data);
        });

        setTimeout(function () {self.showOtherAuthorityField(self.tripCurtailment.getAuthorityType()),10});
        this.deTailOfCancelForm.get('authorityType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.showOtherAuthorityField(data);
        });
    }

    hideOthersField(data) {

        if (data && data.toLowerCase() === 'others') {
            this.deTailOfCancelForm.get('otherReason').enable({onlySelf: true, emitEvent: true});
            this.deTailOfCancelForm.get('wasLossDueToRobbery').reset();
            this.deTailOfCancelForm.get('wasLossDueToRobbery').disable({onlySelf: false, emitEvent: false});
            this.tripCurtailment.setWasLossDueToRobbery(false);
        } else if (data && data.toLowerCase() === 'lost travel documents') {
            this.deTailOfCancelForm.get('wasLossDueToRobbery').enable({onlySelf: true, emitEvent: true});
            this.deTailOfCancelForm.get('otherReason').disable({onlySelf: false, emitEvent: false});
        } else {
            this.deTailOfCancelForm.get('otherReason').disable({onlySelf: false, emitEvent: false});
            this.deTailOfCancelForm.get('wasLossDueToRobbery').reset();
            this.deTailOfCancelForm.get('wasLossDueToRobbery').disable({onlySelf: false, emitEvent: false});
            this.tripCurtailment.setWasLossDueToRobbery(false);
        }

    }

    showHgiFields(data) {
        if (String(data) === 'true') {
            this.deTailOfCancelForm.get('didYouReport').enable({onlySelf: true, emitEvent: true});
        } else {
            this.deTailOfCancelForm.get('didYouReport').reset();
            this.deTailOfCancelForm.get('didYouReport').disable({onlySelf: false, emitEvent: false});
            this.tripCurtailment.setDidYouReport(false);
        }
    }

    showOtherAuthorityField(data) {
        if (data && data.toLowerCase() === 'others') {
            this.deTailOfCancelForm.get('otherAuthorityType').enable({onlySelf: true, emitEvent: true});
        } else {
            this.deTailOfCancelForm.get('otherAuthorityType').reset();
            this.deTailOfCancelForm.get('otherAuthorityType').disable({onlySelf: false, emitEvent: false});
        }

    }

    showReportFields(data) {
        if (String(data) === 'true') {
            this.deTailOfCancelForm.get('authorityType').enable({onlySelf: true, emitEvent: true});
            this.deTailOfCancelForm.get('reportDate').enable({onlySelf: true, emitEvent: true});
        } else {
            this.deTailOfCancelForm.get('authorityType').reset();
            this.deTailOfCancelForm.get('authorityType').disable({onlySelf: false, emitEvent: false});
            this.deTailOfCancelForm.get('reportDate').reset();
            this.deTailOfCancelForm.get('reportDate').disable({onlySelf: false, emitEvent: false});
        }
    }

    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/claimDetail"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 8, subMenuIndex: 0});
            this.router.navigate(["/claimform/tripCurtailment/lossCauseByCurtailment"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.deTailOfCancelForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.deTailOfCancelForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitClaimComplete({claimTypeId: 8, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 8, subMenuIndex: 0});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewCurtailmentDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Trip Curtailment – Curtailment Details',
            'vPath': '/claim/trip-curtailment/details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }

}
