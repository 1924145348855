import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { ClaimPersonalAccident } from 'src/app/model/claim-personal-accident.model';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { takeUntil } from 'rxjs/operators';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { HospitalExpenseDetails } from 'src/app/model/personal-accident/hospital-expense-details.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
    selector: 'app-icu-details-details',
    templateUrl: './icu-details.component.html'
})
export class ICUDetailsComponent extends ClaimsBaseComponent  implements OnInit {

    showTotalBox = false;
    icuForm: FormGroup;
    transactionInfo: TransactionInfo;
    claimPA: ClaimPersonalAccident;
    currency: string;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private sideMenuService: SideMenuService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimPA = this.claim.getClaimPersonalAccident();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {
        this.pushGAPageView();
        this.icuForm = this.fb.group({
            stayedIn: [super.getBooleanString(this.claimPA.getAccidentalMedicalExpenses().getStayedIn())],
            dateFrom: [this.claimPA.getAccidentalMedicalExpenses().getDateFrom()],
            dateTo: [this.claimPA.getAccidentalMedicalExpenses().getDateTo()]
        });
    }

    ngAfterViewInit() {
        this.icuForm.valueChanges.subscribe(data => {
            if (this.icuForm.get('stayedIn').value != null) {
                this.claimPA.getAccidentalMedicalExpenses().setStayedIn(this.icuForm.get('stayedIn').value == 'true');
            }
            this.claimPA.getAccidentalMedicalExpenses().setDateFrom(super.getValueInComponent('dateFrom'));
            this.claimPA.getAccidentalMedicalExpenses().setDateTo(super.getValueInComponent('dateTo'));
            });

            let self = this;
            // Stop validation of fields when not required.
            setTimeout(() => {self.hideFields(self.getBooleanString(self.claimPA.getAccidentalMedicalExpenses().getStayedIn()))},10);
            this.icuForm.get('stayedIn').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.hideFields(data);
            });
    }

    hideFields(show) {
        if (show === "true") {
            this.icuForm.get('dateFrom').enable({onlySelf: false, emitEvent: false});
            this.icuForm.get('dateTo').enable({onlySelf: false, emitEvent: false});
        } else {
            this.icuForm.get('dateFrom').reset();
            this.icuForm.get('dateFrom').disable({onlySelf: false, emitEvent: false});
            this.icuForm.get('dateTo').reset();
            this.icuForm.get('dateTo').disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 1});
            this.router.navigate(["/claimform/accidentalMedicalExpenses/outpatient"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.icuForm);
    }

    back() {
        this.router.navigate(["/claimform/accidentalMedicalExpenses/hospital"], {
            relativeTo: this.activatedRoute
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAPageView() {
        let pageStep = this.transactionInfo.getGaPageStep() + ' – Intensive Care or Quarantine';
        let vPath =  this.transactionInfo.getGaVPath() + '/intensive-care-or-quarantine';
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': pageStep,
            'vPath': vPath,
            'event': 'vpageview'
        });
    }

    getHospitalDate(filter: string): Date {
        let hospitalExpenses: HospitalExpenseDetails[] = this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getHospitalExpenses();
        if (UtilitiesService.isNullOrUndefined(hospitalExpenses)) {
            return null;
        } else {
            hospitalExpenses = hospitalExpenses.slice();
            if (filter === 'a') {
                // returns the oldest hospital admission date
                hospitalExpenses.sort((a, b) => +(a.getDateAdmission()) - +(b.getDateAdmission()));
                return hospitalExpenses[0].getDateAdmission();
            } else {
                // returns the latest hospital discharge date
                hospitalExpenses.sort((a, b) => +(b.getDateDischarge()) - +(a.getDateDischarge()));
                return hospitalExpenses[0].getDateDischarge();
            }
        }
    }

    getDatesForValidation(filter: string): [Date, Date[][], Date] {
        if (filter === 'a') {
            return [this.getHospitalDate('a'), null, this.getHospitalDate('d')];
        }
       return [this.claimPA.getAccidentalMedicalExpenses().getDateFrom(), null, this.getHospitalDate('d')];
    }

}
