import {Component, Injector, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../services/side-menu.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ClaimService} from "../../services/claim.service";
import {Claim} from "../../model/claim.model";
import {ClaimsBaseComponent} from "../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TransactionInfoService} from "../../services/transaction-info.service";
import {takeUntil} from "rxjs";
import {Notifications} from "../../utilities/components/notification-messages/notifications.model";
import {DatePipe} from "@angular/common";
import * as FileSaver from "file-saver";
import {SummaryDeclarationsComponent} from "src/app/claim-pages/summary-of-claim/terms-and-conditions/summary-declarations/summary-declarations.component";
import {TransactionInfo} from "../../model/transaction-info.model";
import {ClaimTypeItem} from "../../model/claim-type-item.model";
import {ClaimTypeItemHelperService} from "../../services/claim-type-item-helper.service";
import { DocumentDetails } from "src/app/model/document-details.model";
import * as cloneDeep from "lodash/cloneDeep";
import {ClaimResponse} from "../../model/claim-response-model";
import { UtilitiesService } from "src/app/utilities/utilities.service";

@Component({
    selector: 'app-summary-of-claim',
    templateUrl: './summary-of-claim.component.html',
    styleUrls: ['./summary-of-claim.component.css']
})
export class SummaryOfClaimComponent extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    claim: Claim;
    transactionInfo: TransactionInfo;
    claimTypeItem: ClaimTypeItem[];
    showPdfDownloadButton = false;
    isMultiClaimant: boolean = false;
    notifications: Notifications = new Notifications();
    claimList: Claim[] = [];  // claim holder for multiple claimants
    @ViewChild('summaryDeclarationComponent', {static: true}) summaryDeclarationComponent: SummaryDeclarationsComponent;
    @ViewChild('doc_error_modal', {static: true}) private errorModal: TemplateRef<any>;

    constructor(private fb: FormBuilder, 
                public sideMenuService: SideMenuService,
                private modalService: NgbModal,
                private claimService: ClaimService,
                public transactionService : TransactionInfoService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private claimTypeItemHelperService: ClaimTypeItemHelperService,
                private transactionInfoService: TransactionInfoService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.transactionInfo = this.transactionService.getTransactionInfo();
        this.notifications = new Notifications();
        this.notifications.showPreamble = false;
    }

    ngOnInit() {

        // Set DOB Authenticate 
        this.claim.setAuthenticateDOB(this.transactionInfo.getDateOfBirth());

        this.pushGASummaryView();

        //get completion of all selected claim types - details
        this.claimTypeItem  = this.transactionInfoService.getTransactionInfo().getClaimTypeItemList();

        // check for multiple claimants
        if (this.transactionInfo.isTravelProduct()) {
            let claimantListTravel = this.claim.getClaimantList();

            // list is multi claimant
            if (claimantListTravel.length > 0)  {

                this.isMultiClaimant = true;
                let self = this;

                // add primary claimant to claim list
                let primaryClaim = cloneDeep(self.claim);
                let primaryDocs = cloneDeep(primaryClaim.documentForm.documents);
                primaryClaim.documentForm.documents = self.getPrimaryFilteredDocuments(primaryDocs);
                self.claimList.push(primaryClaim);

                let claimantNameList = self.claim.getClaimantList();
                claimantNameList.forEach(function (claimantItem , index) {
                    let newClaim = cloneDeep(self.claim);

                        let newClaimantName: string = claimantItem.getClaimantName();
                        let newClaimantDob: Date = !UtilitiesService.isNullOrUndefined(claimantItem.getClaimantDateOfBirth()) ? claimantItem.getClaimantDateOfBirth() : null;
                        let newClaimantIdNumber: string = !UtilitiesService.isNullOrUndefined(claimantItem.getClaimantIdNumber()) ? claimantItem.getClaimantIdNumber() : "";
                        let newClaimantClaimTypeSelected: string[] = claimantItem.getSelectedClaimTypesList();

                        if(!UtilitiesService.isNullOrUndefined(newClaimantName)){
                            newClaim.setClaimantName(newClaimantName);
                            newClaim.setClaimantIdNumber(newClaimantIdNumber);
                            newClaim.setClaimantDateOfBirth(newClaimantDob);
                            newClaim.setClaimantIdentifier(claimantItem.getClaimantIdentifier());
                        }
                        newClaim.setSelectedClaimTypesList(newClaimantClaimTypeSelected);

                    // update document list from selected claimant
                    let docList = cloneDeep(newClaim.documentForm.documents);
                    newClaim.documentForm.documents = self.getFilteredDocuments(docList, claimantItem.getClaimantIdentifier());

                    self.claimList.push(newClaim);
                });

            }
        }

        this.scrollToTop();
    }

    getFilteredDocuments(documents:DocumentDetails[], claimantIdentifier: string): DocumentDetails[]{
        return documents.filter(doc => (doc.claimant != undefined && doc.claimant != null) && doc.claimant.getClaimantIdentifier() == claimantIdentifier);
    }

    getPrimaryFilteredDocuments(documents:DocumentDetails[]): DocumentDetails[]{
        return documents.filter(doc => doc.claimant == undefined || doc.claimant == null);
    }

    goTonext() {

        this.pushGASummarySubmit();
        this.notifications.clearMessages();

        //validate claim type - details completion
        let isClaimDetailsComplete: boolean = this.isClaimDetailsCompleted(this.claim.getSelectedClaimTypesList(), this.claimTypeItem);

        //validate terms and conditions checkbox
        if(isClaimDetailsComplete && this.summaryDeclarationComponent.validateForm()){

            // check claim type details date of incident/visit if valid - for domestic helper product only
            if (this.transactionInfo.isDomesticHelperProduct()) {
                let isDateOfVisitAllowed = this.claimTypeItemHelperService.isAllowedDomesticClaimDetailsGroup(0, this.claim);
                if (isDateOfVisitAllowed) {
                   this.submitClaim();
                } else {
                    this.showErrorMsg("error_messages.domesticClaimGroup.notAllowedDateOfVisit");
                }
            } else if (this.transactionInfo.isMotorProduct()) {
               this.submitMotorClaim();
            } else {
                this.submitClaim();
            }
        }

    }

    submitMotorClaim() {
        let isMotorValidForClaim = this.claimTypeItemHelperService.isMotorValidForClaim(this.claim);
         if (isMotorValidForClaim) {
             this.submitClaim();
         } else {
             this.showErrorMsg("motorClaim.error_messages.collisionNotValid");
         }
    }

    submitClaim () {

        let integrationToken : string = this.transactionService.getTransactionInfo().getIntegrationToken();

        if (integrationToken != null && integrationToken != '') {
            let currentLanguage = this.translate.currentLang;
            if (currentLanguage == 'zh') {
                currentLanguage = 'cn';
            }

            let claimToSubmit = this.claimService.getClaim();
            claimToSubmit.setLanguage(currentLanguage);
            claimToSubmit.setClaimResponseList([] as ClaimResponse[]);
            claimToSubmit.setErrorMessage(null);

            this.claimService.saveClaim(claimToSubmit, integrationToken)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (claim: Claim) => {
                        let errorMessage: string = claim.getErrorMessage();
                        if (errorMessage != null && errorMessage.length > 0) {
                            this.displayGenericError();
                        }
                        else {

                            this.sideMenuService.emitComplete('finishReview');
                            this.sideMenuService.emitProcess(1, 0);
                            this.router.navigate(["/complete"], {
                                relativeTo: this.activatedRoute
                            });
                        }
                    },
                    error: () => {
                        this.displayGenericError();
                    }
            });
        } else {
            this.sideMenuService.emitComplete('finishReview');
            this.sideMenuService.emitProcess(1, 0);
            this.router.navigate(["/complete"], {
                relativeTo: this.activatedRoute
            });
        }
    }

     private handleError(error: Response | any) {
       this.displayGenericError();
    }

    isClaimDetailsCompleted(selectedClaimTypes: string[], claimTypeItemList: ClaimTypeItem[]): boolean {
        if (selectedClaimTypes && selectedClaimTypes.length > 0) {
            for(var i=0 ; i < selectedClaimTypes.length; i++){
                if (claimTypeItemList && claimTypeItemList.length > 0) {
                    for (var j =0;  j < claimTypeItemList.length; j++) {
                        if(selectedClaimTypes[i] == claimTypeItemList[j].claimTypeCode && claimTypeItemList[j].completed == false){
                            this.showErrorMsg("error_messages.notificationsErrMsg.incompleteClaimDetails");
                            return false;
                        }
                    }
                }
            }
        } else {
            if (!this.transactionInfo.isGenericClaim()) {
                this.showErrorMsg("error_messages.notificationsErrMsg.incompleteClaimDetails");
                return false;
            }
        }

        return true;
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        console.log("getData(msg) : ", msg);
        this.showTotalBox = msg;
    }

    back() {
        this.router.navigate(['/claimform/paymentAndContact/provideContactInfo']);
    }

    open(content, name) {
        this.modalService.open(content, {size: 'lg'});
    }

    returnYesOrNo(value: boolean) {
        if (true === value) {
            return 'Yes';
        } else {
            if (false === value) {
                return 'No';
            }
        }

        return '';
    }

    returnYesOrNoString(value: string) {
        if ('Y' === value) {
            return 'Yes';
        } else {
            if ('N' === value) {
                return 'No';
            }
        }

        return '';
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

    getClaimTypeLabel(){
       return this.transactionInfo.getClaimTypeByCode(this.transactionInfo.getCurrentClaimType())?.claimTypeIdName;
    }

    viewPDF() {
        let integrationToken = this.transactionService.getTransactionInfo().getIntegrationToken();
        let filename = 'ClaimsDetails_test.pdf';
        this.claimService.downloadPdfDocument(integrationToken, this.claim, 'ECFORM')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: data => {
                    let fileURL = URL.createObjectURL(data);
                    window.open(fileURL, '_blank');
                },
                error: () => {
                    this.injector.get(NgbModal).open(this.errorModal, {size: 'md', backdrop: 'static', keyboard: false});
                }
            });

    }

    showErrorMsg(errorMsg: string) {
        this.notifications = new Notifications();
        this.notifications.showPreamble = false;
        let errorMessage = errorMsg;
        this.notifications.addErrorMessage(errorMessage);
        this.scrollToTop();
    }

    displayGenericError() {
        this.notifications = new Notifications();
        this.notifications.showPreamble = false;
        const errorMessage1 = "error_messages.notificationsErrMsg.genericError";
        this.notifications.addErrorMessage(errorMessage1);
        const errorMessage2 = "error_messages.notificationsErrMsg.genericError" + this.transactionInfo.getRealCountry()?.toUpperCase();
        this.notifications.addErrorMessage(errorMessage2);
        this.scrollToTop();
    }

    scrollToTop() {
        (function smoothScroll() {
            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 2) {
                window.requestAnimationFrame(smoothScroll);
                window.scrollTo(0, currentScroll - (currentScroll / 5));
            }
        })();
    }


    //START - Google Analytics
    pushGASummaryView() {
        let gaStepNum = this.getGaStep();
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Summary of claim submission',
            'vPath': '/summary-claim',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': gaStepNum}
                }
            }
        });
    }

    pushGASummarySubmit() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({'event': 'submit'});
    }

    getGaStep(): number {
        if (this.transactionInfo.isGenericClaim()) {
            return 5;
        } else if (this.transactionInfo.isHomeProduct()) {
            return 7;
        } else if (this.transactionInfo.isAccidentProduct()) {
            return !this.claim.getClaimPersonalAccident().getHasEligibleClaimTypes() ?  8 : 9;
        }
        return 8;
    }
    //END - Google Analytics

}
