import {Injectable, Injector} from '@angular/core';

import {environment} from '../../environments/environment';
import {UtilitiesService} from "../utilities/utilities.service";
import {TransactionInfo} from "../model/transaction-info.model";
import {BehaviorSubject} from "rxjs";
import {Claim} from "../model/claim.model";
import {InsuredPerson} from "../model/insured-person.model";
import {AutoSaveForm} from "../model/autosave-form.model";
import {IntegrationRiskJson} from "../model/integration-risk-data";
import {PolicyAuthJson} from "../model/policy.auth-model";
import {ClaimTypeItemService} from "./claim-type-item-service";
import {NavigationMenuService} from "./navigation-menu.service";
import {ClaimTravel} from "../model/claim-travel.model";
import {ClaimDomesticHelper} from "../model/claim-domestic-model";
import {PolicyRisks} from "../model/policy-risks.model";
import {ClaimMotor} from '../model/claim-motor.model';
import { GenericDocumentService } from './generic-document-service';
import { ClaimTypes } from '../model/claim-type.model';
import {TranslateService} from "@ngx-translate/core";
import {ClaimProductService} from "./claim-product.service";
import { ClaimHome } from '../model/claim-home.model';
import { DynamicRoutingService } from './dynamic-routing.service';
import { OverseasBankPayment } from '../model/overseas-bank-payment.model';
import { ClaimPersonalAccident } from '../model/claim-personal-accident.model';
import {ClaimEmployeeCompensation} from '../model/claim-employee-compensation.model';
import {PLAN_TYPES} from '../model/travel/plan-types.constants';
import {PlanHelperService} from './plan-helper.service';

@Injectable()
export class TransactionInfoService {

    private debuggingMode = false;
    private tranInfo: TransactionInfo = new TransactionInfo();
    private countrySubject: BehaviorSubject<string> = new BehaviorSubject(this.tranInfo.getCountry());
    private language: string = 'en';

    constructor(private claimTypeItemService: ClaimTypeItemService,
                private navigationMenuService: NavigationMenuService,
                private genDocService: GenericDocumentService,
                public  translate: TranslateService,
                private  claimProductService: ClaimProductService,
                private dynamicRoutingService: DynamicRoutingService,
                private injector : Injector) {
        this.debuggingMode = !environment.production;
    }

    public getTransactionInfo(): TransactionInfo {
        return this.tranInfo;
    }

    public setTransactionInfo(transactionInfo: TransactionInfo) {
        this.tranInfo = transactionInfo;
    }

    public isDebugMode(): boolean {
        return this.debuggingMode;
    }

    getLanguage(): string {
        return this.language;
    }

    setLanguage(value: string) {
        this.language = value;
    }

    // Setup the transaction based on the product type.
    setupTransaction(policyAuthJson: PolicyAuthJson, claim: Claim) {

        let policyNumber = policyAuthJson.getPolicyNumber();
        let productType = policyAuthJson.getProductType();
        let country = policyAuthJson.getCountry();
        let policyHolderName = policyAuthJson.getClientName();
        let policyHolderType = policyAuthJson.getClientType();
        let policyHolderId = policyAuthJson.getClientNumber();
        let numberOfRisks = policyAuthJson.getNumberOfRisks();
        let integrationRiskList: IntegrationRiskJson[] = policyAuthJson.getIntegrationRiskList();
        let integrationToken: string = policyAuthJson.getIntegrationToken();
        if (integrationToken) {
            integrationToken = integrationToken.replace('Bearer ', '');
        }

        let clientEmail: string = policyAuthJson.getClientEmail();
        let clientMobile: string = policyAuthJson.getClientMobile();
        let catalogueCode: string = policyAuthJson.getCatalogueCode();
        let isManulife: boolean = policyAuthJson.getIsManulife();
        let addressLine1: string = policyAuthJson.getAddressLine1();
        let addressLine2: string = policyAuthJson.getAddressLine2();
        let addressLine3: string = policyAuthJson.getAddressLine3();
        let addressLine4: string = policyAuthJson.getAddressLine4();
        let policyStartDate: Date = policyAuthJson.getInceptionDate();
        let policyEndDate: Date = policyAuthJson.getExpiryDate();
        let agentCode: string = policyAuthJson.getAgentCode();
        let staffCode: string = policyAuthJson.getStaffCode();
        let serviceUnit: string = policyAuthJson.getServiceUnit();
        let clientIdNumber: string = policyAuthJson.getClientIdNumber();
        let policyRisksList : PolicyRisks[] = [];
        let periodOfInsurance: string = '';
        let areaCode: string =  '';
        let isGeneric: boolean = policyAuthJson.getIsGeneric();
        let agentEmail: string = policyAuthJson.getAgentEmail();
        let agentBranch: string = policyAuthJson.getAgentBranch();
        let selectedRiskNumber: number = policyAuthJson.getSelectedRiskNumber();

        // loop integrationRiskList and get insured persons and risk number
        let defaultPremiumClass = "";
        let insuredPersonsList: InsuredPerson[] = [];
        for (const insured of integrationRiskList) {
            const riskNumber = insured.getRiskNumber();
            const riskClass = insured.getRiskClass();
            const planCode = insured.getPlanCode();
            const premiumClassList: string[] = insured.getPremiumClassesList();
            const insuredPList: InsuredPerson[] = insured.getInsuredPersonsList();

            const clausesList: string[] = insured.getClausesList();
            let index = 0;

            // get first item of premium class list
            const premiumClass: string = premiumClassList[0];
            defaultPremiumClass = premiumClass;

            for (const person of insuredPList) {
                const personObj = new InsuredPerson();
                personObj.setRiskNumber(riskNumber);
                personObj.setPremiumClass(premiumClass);
                personObj.setPlanCode(planCode);
                personObj.setInsuredName(person.getInsuredName());
                personObj.setInsuredPersonNumber(person.getInsuredPersonNumber());
                personObj.setInsuredPersonNationalIdNumber(person.getInsuredPersonNationalIdNumber());
                personObj.setCoverDetailsList(person.getCoverDetailsList());
                if (person.getInsuredDateOfBirth()) {
                    personObj.setInsuredDateOfBirth(new Date(person.getInsuredDateOfBirth()));
                }
                insuredPersonsList.push(personObj);
                index++;
            }

               // populate risk info from integration list
               let riskInfo = new PolicyRisks();
               riskInfo.setRiskNumber(riskNumber);
               riskInfo.setRiskType(riskClass);
               riskInfo.setPremiumClasses(premiumClassList);
               riskInfo.setClauses(clausesList);
               policyRisksList.push(riskInfo);
        }

        // if transaction is auto save - check if there is other insured person info
        let storedInsuredPersonLists = this.getTransactionInfo().getInsuredPersonsList();
        if (!UtilitiesService.isNullOrUndefined(storedInsuredPersonLists)  && storedInsuredPersonLists.length > 0) {
           // add other insured person to insured person list
            storedInsuredPersonLists.forEach(function (storedInsurdPerson) {
                if (UtilitiesService.isNullOrUndefined(storedInsurdPerson.getRiskNumber())) {
                    insuredPersonsList.push(storedInsurdPerson);
               }
            });

        }

        // Defaults and transaction setup.
        this.getTransactionInfo().setPolicyNumber(policyNumber);
        this.getTransactionInfo().setProductByString(productType);
        this.setCountry(country);
        this.getTransactionInfo().setNumberOfRisks(numberOfRisks);
        this.getTransactionInfo().setInsuredPersonsList(insuredPersonsList);
        this.getTransactionInfo().setCustomerVerified(true);
        this.getTransactionInfo().setPolicyHolderType(policyHolderType);
        this.getTransactionInfo().setRouteToGeneric(isGeneric);
        this.getTransactionInfo().setCatalogueCode(catalogueCode);

        // If an integration token was returned then use this one
        if (integrationToken && integrationToken != '') {
            this.getTransactionInfo().setIntegrationToken(integrationToken);
        }

        claim.setPolicyNumber(policyNumber);
        claim.setPolicyHolderName(policyHolderName);
        claim.setPolicyHolderType(policyHolderType);
        claim.setPolicyHolderId(clientIdNumber);
        claim.setProduct(productType);
        claim.setCountry(country);
        claim.setChannel(this.getTransactionInfo().getChannel());
        claim.setCatalogueCode(catalogueCode);
        claim.setIsManulife(isManulife);
        claim.setClaimantClientKey(policyHolderId);
        claim.setPolicyRisks(policyRisksList);
        claim.setAgentCode(agentCode);
        claim.setStaffCode(staffCode);
        claim.setServiceUnit(serviceUnit);
        claim.setAgentEmail(agentEmail);
        claim.setAgentBranch(agentBranch);

        if(this.getTransactionInfo().isPersonalLineProduct()){
            if(claim.getAddressLine1() == null && addressLine1){
                claim.setAddressLine1(addressLine1);
            }
            if(claim.getAddressLine2() == null && addressLine2){
                claim.setAddressLine2(addressLine2);
            }
            if(claim.getAddressLine3() == null && addressLine3){
                claim.setAddressLine3(addressLine3);
            }
            if(claim.getAddressLine4() == null && addressLine4){
                claim.setAddressLine4(addressLine4);
            }

            // Default the contact and payment info
            if (claim.getContactEmail() == null && clientEmail) {
                claim.setContactEmail(clientEmail);
            }
            if (claim.getContactNumber() == null && clientMobile) {
                claim.setContactNumber(clientMobile);
            }
        }

        if(UtilitiesService.isNullOrUndefined(claim.getDocumentForm().identifier) || (!UtilitiesService.isNullOrUndefined(claim.getDocumentForm().identifier) && claim.getDocumentForm().identifier == '')){
            claim.getDocumentForm().identifier = policyAuthJson.getDocIdentifier();
        }

        // Set the default as the first risk and also the head office major classes
        if (integrationRiskList && integrationRiskList.length > 0) {
            claim.setRiskType(integrationRiskList[0].getRiskClass());
            claim.setRiskNumber(integrationRiskList[0].getRiskNumber());
            claim.setHeadOfficeClassesList(integrationRiskList[0].getHeadOfficeClassesList());
            claim.setPlanCode(integrationRiskList[0]?.getPlanCode());
            claim.setPremiumClass(integrationRiskList[0]?.getPremiumClassesList()?.[0]);
            periodOfInsurance = integrationRiskList[0].getPeriodOfInsurance();
            areaCode = integrationRiskList[0].getAreaCode();
            if (selectedRiskNumber !=0 && integrationRiskList[0]?.getRiskNumber() == selectedRiskNumber) {
               this.getTransactionInfo().setRiskType(integrationRiskList[0].getRiskClass())
            }
        }

        let matchedInsuredPerson: InsuredPerson = null;
        if (!UtilitiesService.isNullOrUndefined(policyAuthJson.getMatchedInsuredPerson())) {
            matchedInsuredPerson = Object.assign(new InsuredPerson(), policyAuthJson.getMatchedInsuredPerson());
            this.getTransactionInfo().setMatchedInsuredPerson(matchedInsuredPerson);
        }

        // If from autosave and is travel corporate client or group accident product, set insured list to others only
        if ((this.getTransactionInfo().isTravelCorporatePolicyHolder() || this.getTransactionInfo().isGroupAccidentProduct()) &&
            this.getTransactionInfo().getAction() === TransactionInfo.AUTO_SAVE_RESUME) {
            this.getTransactionInfo().setInsuredPersonsList(storedInsuredPersonLists);
        }

        //populate claim type item in trans info based on country and product
        let countryInfo =  this.getTransactionInfo().getCountry();
        let productInfo = this.getTransactionInfo().getProduct();
        if (this.getTransactionInfo().isTravelProduct() && claim.getClaimTravel() == null) {
            claim.setClaimTravel(new ClaimTravel());

            let inceptionDate = new Date(policyStartDate);
            let expiryDate = new Date(policyEndDate);

            // check policy if annual or single trip - if annual blank the period of insurance info
            let diffDays = UtilitiesService.getDiffDays(inceptionDate, expiryDate);
            if (diffDays > 90) {
                inceptionDate = null;
                expiryDate = null;
                claim.getClaimTravel().setCoverType("A");
            }

            if (policyStartDate != null && claim.getClaimTravel().getOriginalDepartureDate() == null) {
                claim.getClaimTravel().setOriginalDepartureDate(inceptionDate);
                claim.getClaimTravel().setPolicyStartDate(new Date(policyStartDate));
            }
            if (policyEndDate != null && claim.getClaimTravel().getOriginalReturnDate() == null) {
                claim.getClaimTravel().setOriginalReturnDate(expiryDate);
            }
            if (periodOfInsurance != "" && !UtilitiesService.isNullOrUndefined(periodOfInsurance)) {
                claim.getClaimTravel().setPeriodOfInsurance(periodOfInsurance);
            }
            if (areaCode != "" && !UtilitiesService.isNullOrUndefined(areaCode)) {
                claim.getClaimTravel().setAreaCode(areaCode);
            }

            if (this.getTransactionInfo().isTravelCorporateInsuredPerson() && !UtilitiesService.isNullOrUndefined(matchedInsuredPerson)) {
                insuredPersonsList = insuredPersonsList.filter(insuredPerson =>
                    insuredPerson.getInsuredName() === matchedInsuredPerson.getInsuredName()
                    && insuredPerson.getInsuredPersonNationalIdNumber() === matchedInsuredPerson.getInsuredPersonNationalIdNumber())
                this.getTransactionInfo().setInsuredPersonsList(insuredPersonsList);
            }

            if (insuredPersonsList?.length == 0) {
                let client: InsuredPerson = new InsuredPerson();
                client.setInsuredName(policyHolderName);
                client.setInsuredPersonNationalIdNumber(policyHolderId);
                insuredPersonsList.push(client);
            }

            // HKG - GBA plans
            if (countryInfo?.toUpperCase() == "HKG" && ["PTRHCS", "PTRMTC"].indexOf(catalogueCode) !== -1 && claim.getPlanCode()) {
                if (["HM", "HN", "BM", "BN"].indexOf(claim.getPlanCode()) !== -1) {
                    claim.getClaimTravel().setGBAPlanType(PLAN_TYPES.BASIC);
                } else if (["HO", "HP", "BO", "BP"].indexOf(claim.getPlanCode()) !== -1) {
                    claim.getClaimTravel().setGBAPlanType(PLAN_TYPES.DELUXE);
                }
            }

        } else if (this.getTransactionInfo().isDomesticHelperProduct()) {
            if(claim.getClaimDomesticHelper() == null) {
                claim.setClaimDomesticHelper(new ClaimDomesticHelper());
            }
            // populate plan code for domestic helper based on priority rules per country
            claim.setPlanCode(this.getDomesticPlanCodePriority(countryInfo, integrationRiskList));
        } else if (this.getTransactionInfo().isMotorProduct()) {
            if (claim.getClaimMotor() == null) {
                claim.setClaimMotor(new ClaimMotor());
            }

            let vehicleRegistrationNumber = integrationRiskList[0].getVehicleRegistrationNumber();
            let makeAndModel = integrationRiskList[0].getMakeAndModel();
            let yearOfManufacture =  integrationRiskList[0].getYearOfManufacture();
            let coverType = integrationRiskList[0].getCoverType();
            let engineNumber = integrationRiskList[0].getEngineNumber();
            let chassisNumber = integrationRiskList[0].getChassisNumber();
            let isCoverNotePreNumbered = claim.getIsCoverNotePreNumbered();
            
            if (!UtilitiesService.isNullOrUndefined(vehicleRegistrationNumber)) {
                claim.getClaimMotor().setVehicleNumber(vehicleRegistrationNumber);
            }
            if (!UtilitiesService.isNullOrUndefined(makeAndModel)) {
                claim.getClaimMotor().setMake(makeAndModel);
            }
            if (!UtilitiesService.isNullOrUndefined(yearOfManufacture)) {
                claim.getClaimMotor().setYearManufacture(yearOfManufacture)
            }
            if (!UtilitiesService.isNullOrUndefined(coverType)) {
                claim.getClaimMotor().setCoverType(coverType);
            }
            if (!UtilitiesService.isNullOrUndefined(engineNumber)) {
                claim.getClaimMotor().setEngineNumber(engineNumber);
            }
            if (!UtilitiesService.isNullOrUndefined(chassisNumber)) {
                claim.getClaimMotor().setChassisNumber(chassisNumber);
            }
            if (this.getTransactionInfo().getIsTransactionOrigin() && isCoverNotePreNumbered === '') {
               claim.setIsCoverNotePreNumbered(policyAuthJson.getIsCoverNotePreNumbered());
               isCoverNotePreNumbered = policyAuthJson.getIsCoverNotePreNumbered()
            }
            if (UtilitiesService.isNullOrUndefined(isCoverNotePreNumbered) || isCoverNotePreNumbered === '') {
                if (numberOfRisks > 1) {
                    claim.setRiskNumber(policyAuthJson.getSelectedRiskNumber());
                }
            }
        } else if (this.getTransactionInfo().isGenericClaim()) {
            claim.setRiskNumber(0);
            claim.setForNotificationReason(this.getNotificationReason((numberOfRisks > 1), (['SGP', 'MYS'].indexOf(country) !== -1 && !clientIdNumber), (serviceUnit === 'GP')));
            if(UtilitiesService.isEmpty(claim.getForNotificationReason())) {
                claim.setRiskNumber(1);
            }
            claim.setAccountNameDirect(policyHolderName);
            claim.setPaymentClaimantName(policyHolderName);
            claim.setContactName(policyHolderName);
            claim.setClaimantIdNumber(this.getTransactionInfo().getAuthIdNumber());
            claim.setIsGeneric(true);
            let claimTypeList: string[] = [];
            claimTypeList.push(ClaimTypes[ClaimTypes.CLAIM_TYPE_GENERIC]);
            claim.setSelectedClaimTypesList(claimTypeList);
            this.getTransactionInfo().setDocumentCategory(policyAuthJson.getDocumentCategory());
            this.getTransactionInfo().setGenDocList(
                this.genDocService.getGenericUploadDocList(
                    policyAuthJson.getDocumentCategory()));
            // for group policies, set authenticated member name as default contact name
            if (serviceUnit === 'GP') {
                claim.setContactName(this.getTransactionInfo().getName());
                claim.setClaimantGivenName(this.getTransactionInfo().getName());
            }
        } else if (this.getTransactionInfo().isHomeProduct()) {
            if(claim.getClaimHome() == null) {
                claim.setClaimHome(new ClaimHome());
            }
            let risks: PolicyRisks[] = claim.getPolicyRisks();
            let hasHSRRisk = this.hasRisk(["HSR"], risks);
            let hasHPLHHLRisk = this.hasRisk(["HPL", "HHL"] , risks);
            let hasHPWRisk = this.hasRisk(["HPW"], risks);
            let hasHPPRisk = this.hasRisk(["HPP"], risks);

            let contentPremClasses: string[] = ["HHC", "DHC", "HOC"];
            let buildingPremClass: string[] = ["HHB", "DHB", "HOB"];
            let isContentPremClassExist: boolean = this.isPremiumClassAvailableInPolicy(claim, contentPremClasses);
            let isBuildingPremClassExist: boolean = this.isPremiumClassAvailableInPolicy(claim, buildingPremClass);
            let riskLocation = this.getInfoByRiskType(productType, integrationRiskList).getRiskLocation();
            let fixFitFlag = this.getInfoByRiskType(productType, integrationRiskList).getFixFitFlag() == "Y" ? true : false;

            claim.getClaimHome().setRiskAddress(riskLocation);
            claim.setAccountNameDirect(policyHolderName);
            claim.setPaymentClaimantName(policyHolderName);
            claim.setContactName(policyHolderName);
            claim.getClaimHome().setHasHSRRisk(hasHSRRisk);
            claim.getClaimHome().setHasHPLHHLRisk(hasHPLHHLRisk);
            claim.getClaimHome().setHasHPWRisk(hasHPWRisk);
            claim.getClaimHome().setHasHPPRisk(hasHPPRisk);
            claim.getClaimHome().setHasFixFitFlag(fixFitFlag);
            claim.getClaimHome().setIsContentPremClassExist(isContentPremClassExist);
            claim.getClaimHome().setIsBuildingPremClassExist(isBuildingPremClassExist);

        } else if (this.getTransactionInfo().isEmployeeCompensationProduct()) {
            if(claim.getClaimEmployeeCompensation() == null) {
                claim.setClaimEmployeeCompensation(new ClaimEmployeeCompensation());
            }
            claim.getClaimEmployeeCompensation().setCurrency(UtilitiesService.getCountryCurrency(claim.getCountry()));
        } else if (this.getTransactionInfo().isAccidentProduct()) {
            if (claim.getClaimPersonalAccident() == null) {
                claim.setClaimPersonalAccident(new ClaimPersonalAccident());
            }
            
            if (this.getTransactionInfo().isGroupAccidentProduct() && numberOfRisks > 1) {
                    claim.setRiskNumber(0);
                    claim.setForNotificationReason("Multi-risk policy");
                }

            // if its not group pa and policy has only one insured, pre-populate claimant details
            if (this.getTransactionInfo().hasSingleInsured()) {
                let insured = this.getTransactionInfo().getInsuredPersonsList()[0];
                let insuredName = insured.getInsuredName();
                claim.setClaimantName(insuredName);
                claim.setClaimantIdNumber(insured.getInsuredPersonNationalIdNumber());
                claim.setClaimantDateOfBirth(insured.getInsuredDateOfBirth());
                claim.setContactName(insuredName);
                claim.setAccountNameDirect(insuredName);
                claim.setPaymentClaimantName(insuredName);

                // eligible claim types for insured
                let claimTypes = this.claimTypeItemService.getClaimTypeList(country, this.getTransactionInfo(), null);
                let panClaimTypes = this.injector.get(PlanHelperService).getEligiblePAClaimTypesByInsured(productType, claimTypes, insured, claim);
                this.getTransactionInfo().setClaimTypeItemList(panClaimTypes);
                this.navigationMenuService.setupClaimNavigationMenu(this.getTransactionInfo(), country);
            }

            this.getTransactionInfo().setPolicyStartDate(policyStartDate);
            this.getTransactionInfo().setPolicyEndDate(policyEndDate);
        }

        //OverseasBankPayment
        if(claim.getOverseasBankPayment() == null) {
            claim.setOverseasBankPayment(new OverseasBankPayment());
            claim.getOverseasBankPayment().setBeneficiaryName(policyHolderName);
            claim.getOverseasBankPayment().setIntermediaryAccountholderName(policyHolderName);
        }

        if(countryInfo && productInfo) {
            //check if tran info - claim type list have entries - if not bypass it
            let claimTypeItemListData = this.getTransactionInfo().getClaimTypeItemList();
            if(UtilitiesService.isNullOrUndefined(claimTypeItemListData) || claimTypeItemListData.length == 0) {
                let claimTypeItemList = this.claimTypeItemService.getClaimTypeList(countryInfo, this.getTransactionInfo(),
                    claim.getClaimTravel()?.getGBAPlanType());
                this.getTransactionInfo().setClaimTypeItemList(claimTypeItemList);

                // Setup the navigation side menu data
                this.navigationMenuService.setupClaimNavigationMenu(this.getTransactionInfo(), countryInfo);
            }
        }

        claim.setProductDescription(this.claimProductService.getProductDescription(claim.getProduct(), claim.getCatalogueCode()));
        claim.setTransactionOrigin(this.getTransactionInfo().getTransactionOrigin());
    }

    getNotificationReason(multiRisk, singaporeNoId, isG400Policy): string {
        let notificationReasons = [];
        if(multiRisk) {
            notificationReasons.push("Multi-risk policy");
        }
        if(singaporeNoId) {
            notificationReasons.push("ID doesn't exist in the system");
        }
        if(isG400Policy) {
            notificationReasons.push("G400 policy");
        }

        let notificationReason = "";

        notificationReasons.forEach((value, index)=>{ 
            notificationReason = notificationReason + value;
            if(index + 1 < notificationReasons.length) {
                notificationReason = notificationReason + ", ";
            }
        }); 

        return notificationReason;

    }

    getDomesticPlanCodePriority(countryInfo: string, integrationRiskList: IntegrationRiskJson[]): string {
        let dmhPlanCode: string =  '';
        if (countryInfo && countryInfo.toLowerCase() == "hkg") {
            dmhPlanCode = this.getInfoByRiskType("DHH", integrationRiskList).getPlanCode();
            if(UtilitiesService.isNullOrUndefined(dmhPlanCode)){
                dmhPlanCode = this.getInfoByRiskType("DHP", integrationRiskList).getPlanCode();
                if(UtilitiesService.isNullOrUndefined(dmhPlanCode)) {
                    dmhPlanCode = this.getInfoByRiskType("DHL", integrationRiskList).getPlanCode();
                    if(UtilitiesService.isNullOrUndefined(dmhPlanCode)) {
                        dmhPlanCode = this.getInfoByRiskType("DHW", integrationRiskList).getPlanCode();
                    }
                }
            }
        } else if (countryInfo && countryInfo.toLowerCase() == "hgi") {
            dmhPlanCode = this.getInfoByRiskType("DHP", integrationRiskList).getPlanCode();
            if (UtilitiesService.isNullOrUndefined(dmhPlanCode)) {
                dmhPlanCode = this.getInfoByRiskType("DHW", integrationRiskList).getPlanCode()
            }
        }
        return dmhPlanCode;
    }

    getInfoByRiskType(riskCode: string, integrationRiskList: IntegrationRiskJson[]): IntegrationRiskJson {
        const riskData: IntegrationRiskJson[] = integrationRiskList.filter(risk => risk.getRiskClass() === riskCode);
        if (riskData.length > 0) {
           return riskData[0];
        }
        return new IntegrationRiskJson();
    }


    setupAutoSaveTransaction(autoSaveForm: AutoSaveForm, integrationToken: string) {
        // set transactionInfo from autoSaveResume
        let transactionInfo = TransactionInfo.jsonConvertTransInfo(autoSaveForm.getTransactionInfo());
        transactionInfo.setProductByString(autoSaveForm.getProduct());

        // set new Integration token to transactionInfo
        transactionInfo.setIntegrationToken(integrationToken);
        transactionInfo.setAction(TransactionInfo.AUTO_SAVE_RESUME);

        this.setTransactionInfo(transactionInfo);
    }

    setupAutoSaveTransactionAfterAuth(policyAuth: PolicyAuthJson, claim: Claim, integToken: string) {
        // set transactionInfo from autoSaveResume
        let transactionInfo = TransactionInfo.jsonConvertTransInfo(policyAuth.getTransactionInfo());
        transactionInfo.setProductByString(policyAuth.getProductType());
        transactionInfo.setAction(TransactionInfo.AUTO_SAVE_RESUME);
        transactionInfo.setIntegrationToken(integToken);

        if(!UtilitiesService.isNullOrUndefined(transactionInfo.getDocumentCategory()) &&
            transactionInfo.getDocumentCategory()) {
            transactionInfo.setGenDocList(
                this.genDocService.getGenericUploadDocList(
                    transactionInfo.getDocumentCategory()));
        }

        //setup routing service for home
        if(transactionInfo.isHomeProduct()){
            transactionInfo.setDynamicRoutes(this.dynamicRoutingService.getDynamicHomeRoutes(claim.getSelectedClaimTypesList()[0]));
        }

        transactionInfo.setAutoSaveId(null);

        this.setTransactionInfo(transactionInfo);
    }

    setCountry(country: string) {
        this.tranInfo.setCountry(country);
        this.countrySubject.next(this.tranInfo.getCountry());
    }

    getCountrySubject(): BehaviorSubject<string> {
        return this.countrySubject;
    }

    setUpTransactionOrigin(utmSource: string, utmMedium: string) {
        let transactionOrigin = "";
        if(!UtilitiesService.isNullOrUndefined(utmSource)){
            transactionOrigin = utmSource;
        }
        if(!UtilitiesService.isNullOrUndefined(utmMedium)){
            transactionOrigin += !UtilitiesService.isNullOrUndefined(utmSource) ? "-" + utmMedium : "" + utmMedium;
        }
        this.tranInfo.setOrigin(transactionOrigin)
    }

    getDocumentTitle(policyType: string) {
        let docTitle = 'home.heading.genericInsuranceHeading';
        if (policyType === 'domesticHelper') {
            docTitle = 'domesticHelperClaim.documentTitle';
        } else if (policyType === 'motor') {
            docTitle = 'motorClaim.title';
        } else if (policyType === 'travel') {
            docTitle = 'home.documentTitle';
        } else if (policyType === 'home') {
            docTitle = 'homeProduct.documentTitle';
        } else if (policyType === 'accident') {
            docTitle = 'paClaim.documentTitle';
        }
        this.translate.stream(docTitle).subscribe((res:string) => {
            document.title = res;
        });
    }

    getDynamicRoutingService(): DynamicRoutingService {
        return this.dynamicRoutingService;
    }

    isPremiumClassAvailableInPolicy(claim: Claim, arr: string[]) {
        let risks: PolicyRisks[] = claim.getPolicyRisks();
        let isPremClassAvailable: boolean = false;

        loop1: for (let x of arr) {
            for (let risk of risks) {
                if (risk.getPremiumClasses().indexOf(x) !== -1) {
                    isPremClassAvailable = true;
                    break loop1;
                }
            }
        }
        
        return isPremClassAvailable;
    }

    hasRisk(riskTypes: string[], risks: PolicyRisks[]): boolean {
        let hasRisk: boolean = false;

        loop1: for (let x of riskTypes) {
            for (let risk of risks) {
                if (risk.getRiskType().indexOf(x) !== -1) {
                    hasRisk = true;
                    break loop1;
                }
            }
        }
        
        return hasRisk;
    }

    getExitUrl(): string {
        let exitUrl = '';
        if(this.getTransactionInfo().getTransactionApplicationOrigin() == TransactionInfo.EBUSINESS && this.getTransactionInfo().getAction() != TransactionInfo.AUTO_SAVE_RESUME){
            let returnHostname = this.getTransactionInfo().getReturnUrl();
            exitUrl = returnHostname + '/product/controller/transaction/PortalController/displayPortal.do';
        } else {
            exitUrl = environment.eClaims[this.getTransactionInfo().getRealCountry()?.toLowerCase()];
        }
        return exitUrl;
    }
}

