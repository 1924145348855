import {Component, Injector, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { ClaimsBaseComponent } from '../../claim-base.component';
import { ClaimPersonalAccident } from 'src/app/model/claim-personal-accident.model';
import { takeUntil } from 'rxjs/operators';
import {ClaimTypes} from '../../../model/claim-type.model';
import {ClaimTypeItem} from '../../../model/claim-type-item.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClaimTypeItemHelperService } from 'src/app/services/claim-type-item-helper.service';
import { UtilitiesService } from 'src/app/utilities/utilities.service';
import { PaBodyPartsComponent } from '../body-parts/pa-body-parts.component';

declare var $: any;

@Component({
    selector: 'app-injury-details',
    templateUrl: './injury-details.component.html',
    styleUrls: ['./injury-details.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class InjuryDetailsComponent extends ClaimsBaseComponent  implements OnInit {

    showTotalBox = false;
    injuryDetailsForm: FormGroup;
    transactionInfo: TransactionInfo;
    claimPA: ClaimPersonalAccident;
    deleteClaimTypeModalRef: any;
    modalService: NgbModal;
    claimTypeItemHelperService: ClaimTypeItemHelperService;
    selectedClaimTypeId: number;
    hasBeenInjuredInit: boolean;
    claimTypeData: ClaimTypeItem[] = [];

    @ViewChild('confirm_delete_claim_type', {static: true}) deleteClaimTypeModal: TemplateRef<any>;
    @ViewChild(PaBodyPartsComponent) paBodyParts;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private claimService: ClaimService,
                private sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimPA = this.claim.getClaimPersonalAccident();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
        this.modalService = this.injector.get(NgbModal);
        this.claimTypeItemHelperService = this.injector.get(ClaimTypeItemHelperService);
        this.hasBeenInjuredInit = this.claimPA.getInjuryDetails().getHasBeenInjured();
        this.claimTypeData = this.transactionInfo.getClaimTypeItemList();
    }

    ngOnInit() {
        this.pushGAView();
        this.injuryDetailsForm = this.fb.group({
            hasBeenInjured: [super.getBooleanString(this.claimPA.getInjuryDetails().getHasBeenInjured())],
            natureInjury: [this.claimPA.getInjuryDetails().getNatureInjury()],
            natureInjuryDesc: [this.claimPA.getInjuryDetails().getNatureInjuryDesc()],
            natureInjuryOthers: [this.claimPA.getInjuryDetails().getNatureInjuryOthers()],
            hasEncounteredSimilarIncident: [super.getBooleanString(this.claimPA.getInjuryDetails().getHasEncounteredSimilarIncident())],
            isInternalInjury: [super.getBooleanString(this.claimPA.getInjuryDetails().getIsInternalInjury())],
            placeOfIncident: [this.claimPA.getInjuryDetails().getPlaceOfIncident()]
        });
    }

    ngAfterViewInit() {
        this.injuryDetailsForm.valueChanges.subscribe(data => {
            if (this.injuryDetailsForm.get('hasBeenInjured').value !== null) {
                this.claimPA.getInjuryDetails().setHasBeenInjured(this.injuryDetailsForm.get('hasBeenInjured').value === 'true');
            }
            this.claimPA.getInjuryDetails().setNatureInjury(super.getValueInComponent('natureInjury'));
            if (this.injuryDetailsForm.get('natureInjury').value !== null) {
                this.claimPA.getInjuryDetails().setNatureInjuryDesc(super.getTextInComponent('natureInjury'));
            } else {
                this.claimPA.getInjuryDetails().setNatureInjuryDesc("");
            }
            this.claimPA.getInjuryDetails().setNatureInjuryOthers(super.getValueInComponent('natureInjuryOthers'));
            this.claimPA.getInjuryDetails().setIsInternalInjury(this.injuryDetailsForm.get('isInternalInjury')?.value === 'true');
            this.claimPA.getInjuryDetails().setHasEncounteredSimilarIncident(this.injuryDetailsForm.get('hasEncounteredSimilarIncident')?.value === 'true');
            this.claimPA.getInjuryDetails().setPlaceOfIncident(super.getValueInComponent('placeOfIncident'));
        });

        let self = this;

       // Stop validation of fields when not required.
        setTimeout(function () {
            self.hideAllFields(self.getBooleanString(self.claimPA.getInjuryDetails().getHasBeenInjured()))},10);
            this.injuryDetailsForm.get('hasBeenInjured').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.hideAllFields(data);
        });
   
       setTimeout(function () {
            self.hideFields(self.getBooleanString(self.claimPA.getInjuryDetails().getHasEncounteredSimilarIncident()))},10);
            this.injuryDetailsForm.get('hasEncounteredSimilarIncident').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.hideFields(data);
       });

       
       setTimeout(function () {
            self.hideShowOthersField(self.claimPA.getInjuryDetails().getNatureInjury())},10);
            this.injuryDetailsForm.get('natureInjury').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.hideShowOthersField(data);
       });

       setTimeout(function () {
            self.checkForInternalDamage(self.getBooleanString(self.claimPA.getInjuryDetails().getIsInternalInjury()))},10);
            this.injuryDetailsForm.get('isInternalInjury').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.checkForInternalDamage(data);
       });
    }

    hideAllFields(hasbeenInjured) {
        if (hasbeenInjured === "true") {
            this.injuryDetailsForm.get('natureInjury').enable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('natureInjuryOthers').enable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('hasEncounteredSimilarIncident').enable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('placeOfIncident').enable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('isInternalInjury').enable({onlySelf: false, emitEvent: false});

            this.paBodyParts.selectBodyPartStatus = 'init';
            this.paBodyParts.buildExistingInjuredBodyList();
            this.paBodyParts.setRightLeftLabel();
        } else {
            this.injuryDetailsForm.get('natureInjury').reset();
            this.injuryDetailsForm.get('natureInjury').disable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('natureInjuryOthers').reset();
            this.injuryDetailsForm.get('natureInjuryOthers').disable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('hasEncounteredSimilarIncident').reset();
            this.injuryDetailsForm.get('hasEncounteredSimilarIncident').disable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('placeOfIncident').reset();
            this.injuryDetailsForm.get('placeOfIncident').disable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('isInternalInjury').reset();
            this.injuryDetailsForm.get('isInternalInjury').disable({onlySelf: false, emitEvent: false});

            this.paBodyParts.selectBodyPartStatus = 'none';
            this.paBodyParts.resetSelectedBodyParts();
        }
    }

    hideShowOthersField(natureInjury) {

        if (natureInjury && this.showOthers(natureInjury)) {
            this.injuryDetailsForm.get('natureInjuryOthers').enable({onlySelf: false, emitEvent: false});
        } else {
            this.injuryDetailsForm.get('natureInjuryOthers').reset();
            this.injuryDetailsForm.get('natureInjuryOthers').disable({onlySelf: false, emitEvent: false});
        }
    }
    
    showOthers(val: string): boolean {
        if(['Other', 'Multiple'].indexOf(val) != -1){
            return true;
        }
        return false;
    }

    hideFields(hasExperienced) {
        if (hasExperienced === "true") {
            this.injuryDetailsForm.get('placeOfIncident').enable({onlySelf: false, emitEvent: false});
        } else {
            this.injuryDetailsForm.get('placeOfIncident').reset();
            this.injuryDetailsForm.get('placeOfIncident').disable({onlySelf: false, emitEvent: false});
        }
    }

    checkForInternalDamage(isInternalDamage) {
        if (isInternalDamage === "true") {
            this.paBodyParts.addInternalDamage();
        } else {
            this.paBodyParts.removeInternalDamage();
        }
    }

    goToNext() {
        this.setEligibleClaimTypes();
        this.paBodyParts.checkSelectBodyPartStatus();
        if (this.validateForm()) {
            if (this.isRemoveCurrentClaimType()) {
                if (this.hasBeenInjuredInit) {
                    this.openDeleteModal();
                } else {
                    this.deleteClaimType();
                }
            } else {
                this.sideMenuService.emitComplete('finishInjuryDetails');
                this.sideMenuService.emitProgress(1);
                this.transactionInfo.setIsClaimDetailsLabel(!this.claimPA.getHasEligibleClaimTypes());
                this.goToNextPage();
            }
        }
    }

    goToNextPage() {
        if ((this.claimPA.getInjuryDetails().getHasBeenInjured() && this.paBodyParts.selectBodyPartStatus === 'selected') ||
            !this.claimPA.getInjuryDetails().getHasBeenInjured()) {
            if (!this.claimPA.getHasEligibleClaimTypes()) {
                this.goToOthersClaimDetails();
            } else {
                this.transactionInfo.setIsClaimDetailsLabel(false);
                this.sideMenuService.emitSelectClaimType(true);
                this.router.navigate(['/claimform/claimDetail'], {
                    relativeTo: this.activatedRoute
                });
            }
        }
    }

    goToOthersClaimDetails() {
        this.sideMenuService.emitSelectClaimType(false);
        let chooseIndex:number = 2;
        let selectedClaimCode:string = ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS];
        let claimTypeDetailMenu = this.transactionInfo.getNavigationMenu().getClaimDetailMenu();
        const selectedClaimItemList: string[] = this.claim.getSelectedClaimTypesList();

        let claimTypeSelected =  this.claimTypeData.find(claimTypeItem =>
            claimTypeItem.claimTypeCode === selectedClaimCode);
        if(!UtilitiesService.isNullOrUndefined(claimTypeSelected)){
            claimTypeSelected.selected = true;
            if(selectedClaimItemList.indexOf(claimTypeSelected.claimTypeCode) < 0){
                selectedClaimItemList.push(claimTypeSelected.claimTypeCode);
            }
            this.transactionInfo.setGaPageStep(claimTypeSelected.gaPageStep);
            this.transactionInfo.setGaVPath(claimTypeSelected.gaVPath);
        }

        if (Object.keys(claimTypeDetailMenu[chooseIndex]['subMenus']).length !== 0) {
            this.claim.setSelectedClaimTypesList(selectedClaimItemList);
            this.transactionInfoService.getTransactionInfo().setClaimTypeItemList(this.claimTypeData);
            this.sideMenuService.emitCliamType(chooseIndex);
            this.sideMenuService.emitComplete('finishDetail');
            this.sideMenuService.emitProgress(1);

            let nextLinkUrl: string = claimTypeDetailMenu[chooseIndex]['subMenus'][0]['url'];
            this.router.navigate([nextLinkUrl]);

        }
    }

    validateForm(): boolean {
        return super.validateForm(this.injuryDetailsForm);
    }

    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/paAccidentDetails"], {
            relativeTo: this.activatedRoute
        });
    }

    setEligibleClaimTypes() {
        // set flag to determine if user has eligible claim type aside from others
        let hasPAClaimTypes = false;

        if (this.claimPA.getInjuryDetails().getHasBeenInjured()) {
            hasPAClaimTypes = this.claimTypeData.filter(y => y.show === true &&
                y.claimTypeCode.indexOf("OTHERS") === -1).length > 0;
        }

        this.claimPA.setHasEligibleClaimTypes(hasPAClaimTypes);
    }
    
    isRemoveCurrentClaimType() {
        //check if there is any selected claimtype, remove them
        let hasBeenInjuredForm = this.injuryDetailsForm.get('hasBeenInjured').value;
        let selectedClaimCode: string = ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS];
        let claimTypeSelected: ClaimTypeItem = this.claimTypeData.find(claimTypeItem =>
            claimTypeItem.selected === true);

        if ((!this.claimPA.getHasEligibleClaimTypes() || hasBeenInjuredForm === 'false') &&
            (!UtilitiesService.isNullOrUndefined(claimTypeSelected) && claimTypeSelected.claimTypeCode === selectedClaimCode)) {
            return false;
        } else if (hasBeenInjuredForm !== super.getBooleanString(this.hasBeenInjuredInit) && !UtilitiesService.isNullOrUndefined(claimTypeSelected)) {
            this.selectedClaimTypeId = claimTypeSelected.claimTypeId;
            return true;
        }

        return false;
    }

    openDeleteModal() {
        this.deleteClaimTypeModalRef = this.modalService.open(this.deleteClaimTypeModal, {size: 'sm',
            backdrop: 'static',
            keyboard: false
        });
    }

    deleteClaimType() {
        this.claimTypeItemHelperService.deleteClaimType(this.selectedClaimTypeId);
        if (this.hasBeenInjuredInit) {
            this.deleteClaimTypeModalRef.close();
        }
        this.transactionInfo.setIsClaimDetailsLabel(!this.claimPA.getHasEligibleClaimTypes());

        setTimeout(() => {
            this.sideMenuService.emitComplete('finishInjuryDetails');
            if (this.claimPA.getInjuryDetails().getHasBeenInjured()) {
                this.transactionInfo.setIsClaimDetailsLabel(false);
                this.sideMenuService.emitSelectClaimType(true);
                this.sideMenuService.setStepNumber(4);
                this.sideMenuService.emitProgress(0);
                let url = "/claimform/claimDetail";
                this.router.navigate([url], {
                    relativeTo: this.activatedRoute
                });
            } else {
                this.sideMenuService.setStepNumber(3);
                this.sideMenuService.emitProgress(0);
                this.goToOthersClaimDetails();
            }
        }, 100);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAView() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Injury Details',
            'vPath': '/injury-details',
            'event': 'vpageview',
        });
    }
}
