import {Component, ElementRef, Injector, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {LostPersonalItem} from "../../../../model/travel/lost-personal-item.model";
import {takeUntil} from "rxjs";

@Component({
    selector: 'app-detail-of-loss',
    templateUrl: './detail-of-loss.component.html',
    styleUrls: ['./detail-of-loss.component.css']
})
export class DetailOfLossComponent extends ClaimsBaseComponent implements OnInit {

    @ViewChild('lossType', {static: true}) el: ElementRef;

    detailOfDamageForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    lostPersonalitem: LostPersonalItem;

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.lostPersonalitem = this.claim.getClaimTravel().getLostPersonalItem();
        if(this.lostPersonalitem.getDateTheDamageOccurred() == null){
            this.lostPersonalitem.setDateTheDamageOccurred(this.claim.getDateOfLoss());
        }
    }

    ngOnInit() {

        this.pushGAViewLostItemDetails();

        this.detailOfDamageForm = this.fb.group(
            {
                dateTheDamageOccurred: [this.claim.getClaimTravel().getLostPersonalItem().getDateTheDamageOccurred()],
                countryTheDamageOccurred: [this.claim.getClaimTravel().getLostPersonalItem().getCountryTheDamageOccurred()],
                placeOfLoss: [this.claim.getClaimTravel().getLostPersonalItem().getPlaceOfLoss()],
                howDamageOccurred: [this.claim.getClaimTravel().getLostPersonalItem().getHowDamageOccurred()],
                otherDamageOccurred: [this.claim.getClaimTravel().getLostPersonalItem().getOtherDamageOccurred()]
            }
        );
    }

    ngAfterViewInit() {

        let lostPersonalItem: LostPersonalItem = this.claim.getClaimTravel().getLostPersonalItem();

        this.detailOfDamageForm.valueChanges.subscribe(data => {
            lostPersonalItem.setDateTheDamageOccurred(super.getValueInComponent('dateTheDamageOccurred'));
            lostPersonalItem.setCountryTheDamageOccurred(super.getValueInComponent('countryTheDamageOccurred'));
            lostPersonalItem.setCountryTheDamageOccurredDesc(super.getTextInComponent('countryTheDamageOccurred'));
            lostPersonalItem.setPlaceOfLoss(super.getValueInComponent('placeOfLoss'));
            lostPersonalItem.setPlaceOfLossDesc(super.getTextInComponent('placeOfLoss'));
            lostPersonalItem.setHowDamageOccurred(super.getValueInComponent('howDamageOccurred'));
            lostPersonalItem.setHowDamageOccurredDesc(super.getTextInComponent('howDamageOccurred'));
            lostPersonalItem.setOtherDamageOccurred(super.getValueInComponent('otherDamageOccurred'));

            this.validateClaimDetailsComplete();

        });
        // Stop validation of the other field when reason isn't other
        let self = this;
        setTimeout(function () {self.hideOthersField(lostPersonalItem.getHowDamageOccurred()),10});
        this.detailOfDamageForm.get('howDamageOccurred').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideOthersField(data);
        });
    }

    hideOthersField(howDamageOccurred) {

        if (howDamageOccurred && howDamageOccurred.toLowerCase() === 'others') {
            this.detailOfDamageForm.get('otherDamageOccurred').enable({onlySelf: false, emitEvent: false});
        } else {
            this.detailOfDamageForm.get('otherDamageOccurred').reset();
            this.detailOfDamageForm.get('otherDamageOccurred').disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 0});

            this.router.navigate(["/claimform/lostPersonalItem/detailsOfLossItems"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/claimDetail"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.detailOfDamageForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.detailOfDamageForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 1, subMenuIndex: 0});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewLostItemDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Lost Item – Loss Details',
            'vPath': '/claim/lost-item/details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }
}
