import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { ClaimsBaseComponent } from '../../claim-base.component';
import { ClaimPersonalAccident } from 'src/app/model/claim-personal-accident.model';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { takeUntil } from 'rxjs/operators';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

declare var $: any;

@Component({
    selector: 'app-accident-details',
    templateUrl: './accident-details.component.html',
    styleUrls: ['./accident-details.component.css'],
})
export class AccidentDetailsComponent extends ClaimsBaseComponent  implements OnInit {

    showTotalBox = false;
    accidentDetailsForm: FormGroup;
    transactionInfo: TransactionInfo;
    claimPA: ClaimPersonalAccident;
    
    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private sideMenuService: SideMenuService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimPA = this.claim.getClaimPersonalAccident();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {
        this.pushGAView();
        let dateOfLoss = this.claimPA.getAccidentDetails().getAccidentDate() != null ? this.claimPA.getAccidentDetails().getAccidentDate() : this.claim.getDateOfLoss();

        if(this.claimPA.getAccidentDetails().getCountryIncidentOccurDesc() == null || this.claimPA.getAccidentDetails().getCountryIncidentOccurDesc() == ""){
            this.claimPA.getAccidentDetails().setCountryIncidentOccur(this.claim.getCountry());
            this.claimPA.getAccidentDetails().setCountryIncidentOccurDesc(UtilitiesService.getCountryDesc(this.claim.getCountry()));
        }        

        this.accidentDetailsForm = this.fb.group({
            accidentDate: [dateOfLoss],
            accidentAddress: [this.claimPA.getAccidentDetails().getAccidentAddress()],
            accidentDescription: [this.claimPA.getAccidentDetails().getAccidentDescription()],
            occupation: [this.claimPA.getAccidentDetails().getOccupation()],
            occupationDesc: [this.claimPA.getAccidentDetails().getOccupationDesc()],
            occupationOthers: [this.claimPA.getAccidentDetails().getOccupationOthers()],
            jobTitle: [this.claimPA.getAccidentDetails().getJobTitle()],
            countryIncidentOccur: [this.claimPA.getAccidentDetails().getCountryIncidentOccur()]
        });
    }

    ngAfterViewInit() {
        this.accidentDetailsForm.valueChanges.subscribe(data => {
            this.claimPA.getAccidentDetails().setAccidentDate(super.getValueInComponent('accidentDate'));
            this.claimPA.getAccidentDetails().setAccidentAddress(super.getValueInComponent('accidentAddress'));
            this.claimPA.getAccidentDetails().setAccidentDescription(super.getValueInComponent('accidentDescription'));
            this.claimPA.getAccidentDetails().setOccupation(super.getValueInComponent('occupation'));
            this.claimPA.getAccidentDetails().setOccupationDesc(super.getTextInComponent('occupation'));
            this.claimPA.getAccidentDetails().setOccupationOthers(super.getValueInComponent('occupationOthers'));
            this.claimPA.getAccidentDetails().setJobTitle(super.getValueInComponent('jobTitle'));
            this.claimPA.getAccidentDetails().setCountryIncidentOccur(this.accidentDetailsForm.get('countryIncidentOccur').value);
            this.claimPA.getAccidentDetails().setCountryIncidentOccurDesc(super.getTextInComponent('countryIncidentOccur'));
        });

        // Stop validation of the other field when reason isn't other
        let self = this;
        
        setTimeout(function () {self.hideShowOthersField(self.claimPA.getAccidentDetails().getOccupation())},10);

        this.accidentDetailsForm.get('occupation').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowOthersField(data);
        });
    }

    hideShowOthersField(occupation) {

        if (occupation && occupation.toLowerCase() == 'others') {
            this.accidentDetailsForm.get('occupationOthers').enable({onlySelf: false, emitEvent: false});
        } else {
            this.accidentDetailsForm.get('occupationOthers').reset();
            this.accidentDetailsForm.get('occupationOthers').disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitComplete('finishAccidentDetails');
            this.sideMenuService.emitProgress(1);
            this.router.navigate(["/claimform/paInjuryDetails"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.accidentDetailsForm);
    }

    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/selectInsured"], {
            relativeTo: this.activatedRoute
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAView() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Confirm Accident Details',
            'vPath': '/confirm-accident-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 4}
                }
            }
        });
    }

}
