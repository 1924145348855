import {Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {ClaimDocumentsComponent} from "../../../claim-documents/claim-documents.component";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {DocumentHelperService} from "../../../../services/document-helper.service";
import {TransactionInfoService} from "../../../../services/transaction-info.service";
import {DocumentFormFactoryService} from "../../../../services/document-form-factory.service";
import {SpinnerService} from "../../../../core/spinner/spinner.service";
import {ClaimTypes} from "../../../../model/claim-type.model";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {DocumentField} from "../../../../model/document-field";
import {ActivatedRoute, Router} from "@angular/router";

declare var $: any;

@Component({
  selector: 'app-clinical-expense-upload-document',
  templateUrl: './clinical-expense-upload-document.component.html',
  styleUrls: ['./clinical-expense-upload-document.component.css']
})

export class ClinicalExpenseUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {

    public upLoadFileModel: FormGroup;
    showTotalBox = false;
    requiredDocuments: DocumentField[];
    @ViewChild('alert_unuploaded_docs', {static: true}) docAlertModal: TemplateRef<any>;
    docAlertModalRef: any;
    listOfUnuploadedDocs: any;
    
    constructor(private fb: FormBuilder,
        public sideMenuService: SideMenuService,
        public translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        claimService: ClaimService,
        documentHelperService: DocumentHelperService,
        transactionInfoService: TransactionInfoService,
        http: HttpClient,
        documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
                super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
                this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_CLINICAL]);
    }

    ngOnInit() {

        this.pushGAViewClinicalExpensesUpload();
        this.upLoadFileModel = this.fb.group(
            {
                airTicket: [],
                carrierCertificate: [],
                otherItems: this.fb.array([this.createItem()])

            });
            super.ngOnInit();

        this.requiredDocuments = this.formBuilder.getRequiredDocuments();
    }

    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: [],
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        this.listOfUnuploadedDocs = "";
        let stpDocIdList = this.requiredDocuments.reduce((result, { id, stpRequired }) => stpRequired == true ?
            result.push(id) && result : result, []);

        if (this.buildSTPAlertMessage(this.claimService.getClaim(), this.translate, stpDocIdList)) {
            this.showDocAlertModal();
        } else {
            this.continueToNext();
        }
    }

    continueToNext(){
        this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 3});
        this.sideMenuService.emitProgress(1);
        this.router.navigate(["/claimform/anotherInsurance"], {
            relativeTo: this.activatedRoute
        });
        this.docAlertModalRef?.close();
    }

    showDocAlertModal() {
        this.docAlertModalRef = this.modalService.open(this.docAlertModal, {size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
    }

    //Google Analytics
    pushGAViewClinicalExpensesUpload() {
        (<any>window).dataLayer.push({
            'pageStep': 'Clinical Expenses – Document Upload',
            'vPath': '/claim/clinical-expenses/documents',
            'event': 'vpageview',
            'ecommerce': {
              'checkout': {
                  'actionField': {'step': 6},	//Step6-DocumentUpload
              }
            }
        });
    }

}
