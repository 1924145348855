import {Component, Injector, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimPersonalAccident } from 'src/app/model/claim-personal-accident.model';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { HospitalExpenseDetails } from 'src/app/model/personal-accident/hospital-expense-details.model';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { HospitalExpenseHelperService } from 'src/app/services/hospital-expense-helper.service';
import { UtilitiesService } from 'src/app/utilities/utilities.service';
import { DateService } from 'src/app/ui/ui-datepicker/date.service';

@Component({
    selector: 'app-hospital-cash-details-details',
    templateUrl: './hospital-cash-details.component.html'
})
export class HospitalCashDetailsComponent extends ClaimsBaseComponent  implements OnInit {

    showTotalBox = false;
    hospitalCashForm: FormGroup;
    transactionInfo: TransactionInfo;
    claimPA: ClaimPersonalAccident;
    currency: string;
    hospitalExpenseArray: HospitalExpenseDetails[] = [];
    sideMenuService: SideMenuService;
    hospitalExpenseHelperService: HospitalExpenseHelperService;
    
    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claimPA = this.claim.getClaimPersonalAccident();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
        this.sideMenuService = this.injector.get(SideMenuService);
        this.hospitalExpenseHelperService = this.injector.get(HospitalExpenseHelperService);
    }

    ngOnInit() {
        this.pushGAPageView();
        this.currency = UtilitiesService.getCountryCurrency(this.claim.getCountry());

        this.hospitalExpenseArray = this.claim.getClaimPersonalAccident().getHospitalCash().getHospitalExpenses();

        this.hospitalCashForm = this.fb.group({
            items: this.fb.array(this.buildStoredItems(this.hospitalExpenseArray)),
            requiredSurgery: [this.claimPA.getHospitalCash().getRequiredSurgery()],
            requiredSurgeryDesc: [this.claimPA.getHospitalCash().getRequiredSurgeryDesc()]
        });

        this.initializeArrays();
    }

    ngAfterViewInit() {
        this.hospitalCashForm.valueChanges.subscribe(data => {
            this.hospitalExpenseArray = [];
            data.items.forEach((hospitalExpense, index)=>{

                let itemDetail : HospitalExpenseDetails = new HospitalExpenseDetails();

                itemDetail.setHospitalName(hospitalExpense.hospitalName)
                itemDetail.setDateAdmission(DateService.convertStringToDatetime(hospitalExpense.dateAdmission, 'dd/MM/yyyy HH:mm'));
                itemDetail.setDateDischarge(DateService.convertStringToDatetime(hospitalExpense.dateDischarge, 'dd/MM/yyyy HH:mm'));
                itemDetail.setHospitalExpenseCurrency(hospitalExpense.hospitalExpenseCurrency);
                itemDetail.setHospitalExpense(hospitalExpense.hospitalExpense);
                this.hospitalExpenseArray.push(itemDetail);
            });
            this.claimPA.getHospitalCash().setHospitalExpenses(this.hospitalExpenseArray);
            
            this.claimPA.getHospitalCash().setRequiredSurgery(super.getValueInComponent('requiredSurgery'));
            this.claimPA.getHospitalCash().setRequiredSurgeryDesc(super.getSelectedRadioText('requiredSurgery'));
        });
    }

    initializeArrays() {
        if (UtilitiesService.isNullOrUndefined(this.hospitalExpenseArray)) {
            this.hospitalExpenseArray = [];
            this.claimPA.getHospitalCash().setHospitalExpenses(this.hospitalExpenseArray);
        }

        if (this.hospitalExpenseArray.length === 0) {
            this.hospitalExpenseArray.push(new HospitalExpenseDetails());
        }
    }

    buildStoredItems(hospitalExpenseDetails: HospitalExpenseDetails[]): FormGroup[] {

        let formGroupArray : FormGroup[] = [];

        if(hospitalExpenseDetails != null && hospitalExpenseDetails.length > 0 ) {

            hospitalExpenseDetails.forEach((hospitalExpense)=>{
                let rowFormGroup = this.fb.group({
                    hospitalName: [hospitalExpense.getHospitalName()],
                    dateAdmission: [hospitalExpense.getDateAdmission()],
                    dateDischarge: [hospitalExpense.getDateDischarge()],
                    hospitalExpense: [hospitalExpense.getHospitalExpense()],
                    hospitalExpenseCurrency: [hospitalExpense.getHospitalExpenseCurrency()]
                });

                formGroupArray.push(rowFormGroup);
            });
        }
        else {
            formGroupArray.push(this.createDefaultItems());
        }

        return formGroupArray;
    }

    createDefaultItems(): FormGroup {
        return this.fb.group({
            hospitalName: '',
            dateAdmission: null,
            dateDischarge: null,
            hospitalExpense: '',
            hospitalExpenseCurrency: this.currency
        });
    }

    addItem(): void {
        let self = this;
        setTimeout(function () {
            let items = self.hospitalCashForm.get('items') as FormArray;
            let newItem : FormGroup = self.createDefaultItems();
            
            items.push(newItem);

        }, 10);
    }

    removeItem(i) {
        let items = this.hospitalCashForm.get('items') as FormArray;
        items.removeAt(i);
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 0});
            this.router.navigate(["/claimform/hospitalCash/supportDoc"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateMoneyFields(): boolean {
        let formArray : FormArray =  <FormArray> this.hospitalCashForm.controls["items"];
        if(formArray != null) {
            formArray.controls.forEach((formControl, index) => {
                if ((UtilitiesService.isNullOrUndefined(formControl.get('hospitalExpense').value) || formControl.get('hospitalExpense').value == "")) {
                return true;
            }
            });
        }
        return false;
    }

    validateForm(): boolean {
        return super.validateForm(this.hospitalCashForm);
    }

    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    getDatesForValidation(i, fieldType): [Date, Date[][], Date] {
        return this.hospitalExpenseHelperService.getDatesForValidation(i, fieldType, this.claimPA.getHospitalCash().getHospitalExpenses());
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAPageView() {
        let pageStep = this.transactionInfo.getGaPageStep() + ' – Hospital Cash Details';
        let vPath = this.transactionInfo.getGaVPath() + '/hospital-cash-details';
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': pageStep,
            'vPath': vPath,
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 6}
                }
            }
        });
    }

}
