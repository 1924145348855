import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {Claim} from "../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import {HelpersLiability} from "../../../../model/domestic-helper/helpers-liability.model";

@Component({
  selector: 'app-helpers-liability-detail',
  templateUrl: './helpers-liability-detail.component.html',
  styleUrls: ['./helpers-liability-detail.component.css']
})

export class HelpersLiabilityDetailComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    helpersLiabilityForm: FormGroup;
    claim: Claim;
    helpersLiability : HelpersLiability;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.helpersLiability = this.claim.getClaimDomesticHelper().getHelpersLiability();
    }

    ngOnInit() {

        this.pushGAViewHelpersLiabilityDetails();

        if(this.helpersLiability.getCountryIncidentOccurDesc() == null || this.helpersLiability.getCountryIncidentOccurDesc() == ""){
            this.helpersLiability.setCountryIncidentOccurDesc('HONG KONG')
            this.helpersLiability.setCountryIncidentOccur('HKG');
        }

        if (!this.helpersLiability.getDateOfIncident()) {
            this.helpersLiability.setDateOfIncident(this.claim.getDateOfLoss());
        }

        this.helpersLiabilityForm = this.fb.group({            
            dateOfIncident: [this.helpersLiability.getDateOfIncident()],
            countryIncidentOccur: [this.helpersLiability.getCountryIncidentOccur()],
            countryIncidentOccurDesc: [this.helpersLiability.getCountryIncidentOccurDesc()],
            natureOfIncident: [this.helpersLiability.getNatureOfIncidentCode()]
        });
    }

    ngAfterViewInit() {
        this.helpersLiabilityForm.valueChanges.subscribe(data => {
            this.helpersLiability.setCountryIncidentOccur(this.helpersLiabilityForm.get('countryIncidentOccur').value);
            this.helpersLiability.setCountryIncidentOccurDesc(this.getTextInComponent('countryIncidentOccur'));
            this.helpersLiability.setDateOfIncident(super.getValueInComponent('dateOfIncident'));
            this.helpersLiability.setNatureOfIncidentCode(this.helpersLiabilityForm.get('natureOfIncident').value);
            this.helpersLiability.setNatureOfIncident(this.getTextInComponent('natureOfIncident'));
        });

    }



    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 5, subMenuIndex: 0});
            this.router.navigate(["/claimform/helpersLiability/descriptionOfIncident"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.helpersLiabilityForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewHelpersLiabilityDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Helpers Liability – Incident Details',
            'vPath': '/claim/helpers-liability/incident-details',
            'event': 'vpageview',
            'ecommerce': {
              'checkout': {
                  'actionField': {'step': 5},	//Step5-ClaimDetailsStart
              }
            }                                                                               
        });
    }


}
