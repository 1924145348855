import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {Claim} from "../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import {HelpersLiability} from "../../../../model/domestic-helper/helpers-liability.model";

@Component({
  selector: 'app-helpers-liability-reporting-policy',
  templateUrl: './helpers-liability-reporting-policy.component.html',
  styleUrls: ['./helpers-liability-reporting-policy.component.css']
})

export class HelpersLiabilityReportingPolicyComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    helpersLiabilityForm: FormGroup;
    claim: Claim;
    helpersLiability: HelpersLiability;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.helpersLiability = this.claim.getClaimDomesticHelper().getHelpersLiability();
    }

    ngOnInit() {

        this.pushGAViewHelpersLiabilityPoliceReport();

        this.helpersLiabilityForm = this.fb.group({
            isIncidentReport: [super.getBooleanString(this.helpersLiability.getIsIncidentReport())],
            dateOfReport: [this.helpersLiability.getDateOfReport()],
            policeStationNameAndAddress: [this.helpersLiability.getPoliceStationNameAndAddress()],
            referenceNumber: [this.helpersLiability.getReferenceNumber()]
        });
    }

    ngAfterViewInit() {



        this.helpersLiabilityForm.valueChanges.subscribe(data => {
            let helpersLiability: HelpersLiability = this.claim.getClaimDomesticHelper().getHelpersLiability();
            if (this.helpersLiabilityForm.get('isIncidentReport').value != null) {
                helpersLiability.setIsIncidentReport(this.helpersLiabilityForm.get('isIncidentReport').value == 'true');
            }
            helpersLiability.setDateOfReport(super.getValueInComponent('dateOfReport'));
            helpersLiability.setPoliceStationNameAndAddress(super.getValueInComponent('policeStationNameAndAddress'));
            helpersLiability.setReferenceNumber(super.getValueInComponent('referenceNumber'));
        });

        // Stop validation of the other field when reason isn't other
        let self = this;

        setTimeout(function () {self.hideFields(self.getBooleanString(self.helpersLiability.getIsIncidentReport())),10});
            this.helpersLiabilityForm.get('isIncidentReport').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.hideFields(data);
        });
    }

    hideFields(value) {

        if (value == 'true') {
            this.helpersLiabilityForm.get('dateOfReport').enable({onlySelf: false, emitEvent: false});
            this.helpersLiabilityForm.get('policeStationNameAndAddress').enable({onlySelf: false, emitEvent: false});
            this.helpersLiabilityForm.get('referenceNumber').enable({onlySelf: false, emitEvent: false});
        } else {
            this.helpersLiabilityForm.get('dateOfReport').reset();
            this.helpersLiabilityForm.get('dateOfReport').disable({onlySelf: false, emitEvent: false});
            this.helpersLiabilityForm.get('policeStationNameAndAddress').reset();
            this.helpersLiabilityForm.get('policeStationNameAndAddress').disable({onlySelf: false, emitEvent: false});
            this.helpersLiabilityForm.get('referenceNumber').reset();
            this.helpersLiabilityForm.get('referenceNumber').disable({onlySelf: false, emitEvent: false});
        }
    }



    back() {
        this.router.navigate(["/claimform/helpersLiability/descriptionOfIncident"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 5, subMenuIndex: 2});
            this.router.navigate(["/claimform/helpersLiability/supportDoc"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.helpersLiabilityForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }


    //Google Analytics
    pushGAViewHelpersLiabilityPoliceReport() {
        (<any>window).dataLayer.push({
            'pageStep': 'Helpers Liability – Police Report',
            'vPath': '/claim/helpers-liability/police-report',
            'event': 'vpageview',                                                                                                
        });
    }

}
