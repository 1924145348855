import {Component, Injector, OnInit} from "@angular/core";
import {SideMenuService} from "../../../../services/side-menu.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ClaimService} from "../../../../services/claim.service";
import {TripCurtailment} from "../../../../model/travel/trip-curtailment.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import {UtilitiesService} from "../../../../utilities/utilities.service";

@Component({
    selector: 'app-loss-caused-by-curtailment',
    templateUrl: './loss-caused-by-curtailment.component.html',
    styleUrls: ['./loss-caused-by-curtailment.component.css']
})
export class LossCausedByCurtailmentComponent extends ClaimsBaseComponent implements OnInit {

    lossCausedForm: FormGroup;
    showTotalBox = false;
    tripCurtailment: TripCurtailment;
    currency: string;

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.tripCurtailment = this.claim.getClaimTravel().getTripCurtailment();
        this.currency = UtilitiesService.getCountryCurrency(this.claim.getCountry());
    }

    ngOnInit() {

        this.pushGAViewCurtailmentLoss();

        if(this.tripCurtailment.getTotalCostTypeOfTrip() == null){
            this.tripCurtailment.setTotalCostTypeOfTrip(this.currency);
        }

        this.lossCausedForm = this.fb.group({
            totalCostTypeOfTrip: [this.tripCurtailment.getTotalCostTypeOfTrip()],
            totalCost: [this.tripCurtailment.getTotalCost()],
            hasRefund: [this.tripCurtailment.getHasRefund()],
            totalAmountRefundedType: [this.tripCurtailment.getTotalAmountRefundedType()],
            totalAmountRefunded: [this.tripCurtailment.getTotalAmountRefunded()],
            didUseMiles: [super.getBooleanString(this.tripCurtailment.getDidUseMiles())]
        });
    }

    ngAfterViewInit() {

        this.lossCausedForm.valueChanges.subscribe(data => {
            if (this.lossCausedForm.get('didUseMiles').value != null) {
                this.tripCurtailment.setDidUseMiles(this.lossCausedForm.get('didUseMiles').value == 'true');
            }
            this.tripCurtailment.setTotalCost(super.getValueInComponent('totalCost'));
            this.tripCurtailment.setTotalCostTypeOfTrip(this.lossCausedForm.get('totalCostTypeOfTrip').value);
            this.tripCurtailment.setHasRefund(super.getValueInComponent('hasRefund'));
            this.tripCurtailment.setHasRefundDesc(super.getSelectedRadioText('hasRefund'));
            this.tripCurtailment.setTotalAmountRefundedType(this.lossCausedForm.get('totalAmountRefundedType').value);
            this.tripCurtailment.setTotalAmountRefunded(super.getValueInComponent('totalAmountRefunded'));

            this.validateClaimDetailsComplete();
        });

        // Stop validation of fields when not required.
        let self = this;
        setTimeout(function () {self.hideRefundAmount(self.tripCurtailment.getHasRefund()), 10});

        this.lossCausedForm.get('hasRefund').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideRefundAmount(data);
        });

    }

    hideRefundAmount(hasRefund) {
        if (hasRefund == 'true') {
            this.lossCausedForm.get('totalAmountRefunded').enable({onlySelf: false, emitEvent: false});
            this.lossCausedForm.get('totalAmountRefundedType').enable({onlySelf: false, emitEvent: false});
        } else {
            this.lossCausedForm.get('totalAmountRefunded').reset();
            this.lossCausedForm.get('totalAmountRefundedType').setValue(this.currency);
            this.lossCausedForm.get('totalAmountRefunded').disable({onlySelf: false, emitEvent: false});
            this.lossCausedForm.get('totalAmountRefundedType').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideDidUseMiles(didUseMiles) {
        if (didUseMiles == 'true') {
            this.lossCausedForm.get('totalCost').enable({onlySelf: false, emitEvent: false});
            this.lossCausedForm.get('totalCostTypeOfTrip').enable({onlySelf: false, emitEvent: false});
        } else {
            this.lossCausedForm.get('totalCost').reset();
            this.lossCausedForm.get('totalCostTypeOfTrip').setValue(this.currency);
            this.lossCausedForm.get('totalCost').disable({onlySelf: false, emitEvent: false});
            this.lossCausedForm.get('totalCostTypeOfTrip').disable({onlySelf: false, emitEvent: false});
        }
    }


    back() {
        this.router.navigate(["/claimform/tripCurtailment/detailOfCurtailmentTrip"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 8, subMenuIndex: 1});
            this.router.navigate(["/claimform/tripCurtailment/extraCost"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.lossCausedForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.lossCausedForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitClaimComplete({claimTypeId: 8, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 8, subMenuIndex: 1});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewCurtailmentLoss() {
        (<any>window).dataLayer.push({
            'pageStep': 'Trip Curtailment – Loss Caused',
            'vPath': '/claim/trip-curtailment/loss-caused',
            'event': 'vpageview',
        });
    }

}
