<div class="bank-account-body" [ngClass]="{'mox-style': claim.isMox()}">
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{'pagePaymentAndContact.bankAcct.back' | translate}} </a>
  </div>
  <ng-container *ngIf="claim.getServiceUnit() === 'GP'">
    <div class="col-12">
      <h5>{{'pagePaymentAndContact.bankAcct.title' | translate}}</h5>
      <div><p>{{'pagePaymentAndContact.bankAcct.acctNoticeG400' | translate}}</p></div>
    </div>
  </ng-container>
  <ng-container *ngIf="claim.getServiceUnit() !== 'GP'">
      <div class="col-12">
        <h5>{{'pagePaymentAndContact.bankAcct.title' | translate}}</h5>
        <div *ngIf="transactionInfo.isDomesticHelperProduct() || transactionInfo.isTravelProduct() || transactionInfo.isHomeProduct() ||
            transactionInfo.isEmployeeCompensationProduct()"><p>{{'pagePaymentAndContact.bankAcct.acctNotice' | translate}}</p></div>
        <div *ngIf="transactionInfo.isMotorProduct() || transactionInfo.isGenericClaim()"><p>{{'pagePaymentAndContact.bankAcct.acctNoticeMotor' | translate}}</p></div>
      </div>
      <div class="row col-12">
        <div class="col-lg-8 col-xl-8 col-md-12">
          <form>
            <div class="form-group">
              <qnect-ui #PYM01 [fieldId]="'PYM01'" [formGroup]="bankAccountForm" name="accountNameDirect" (focusout)="applyP400Format($event, 'accountNameDirect')" [disabled]="isMotorLodgedBy != ''" [dynamicOptions]="accountNameDirectList"></qnect-ui>
            </div>
            <div class="form-group">
              <qnect-ui #PYM03 [fieldId]="'PYM03'" [formGroup]="bankAccountForm" name="bankDirect" (focusout)="validateBankInput()"></qnect-ui>
            </div>
            <ng-container *ngIf="isNotNullOrUndefinedStr(claim.getBankDirect())">
              <ng-container *ngIf="showBranchCodeInstruction==true">
                <div class="form-group">
                  <small id="how-policy-number" (click)="showBranchCodeMessage(true)">{{'home.howToFindBranchCode.findingBranchCode' | translate}}</small>
                </div>
                <div id="branchCodeMessage" class="col-lg-7 col-xl-7 col-md-7 col-sm-12" *ngIf="showBranchCodeMsg==true">
                  <div class="closePanel">
                    <span (click)="showBranchCodeMessage(false)">&times;</span>
                  </div>
                  <div>
                    <img class="bank-select-img" src="{{bankSelectImg}}">
                  </div>
                  <BR>
                  <div class="bank-select-desc" [innerHTML]="bankDescription"></div>
                </div>
              </ng-container>
              <div class="form-group" *ngIf="country !== 'MYS'">
                <qnect-ui #PYM18 [fieldId]="'PYM18'" [formGroup]="bankAccountForm" name="branchCode"></qnect-ui>
              </div>
              <div class="form-group">
                <qnect-ui #PYM04 [fieldId]="'PYM04'" [formGroup]="bankAccountForm" name="accountNumberDirect"></qnect-ui>
              </div>
            </ng-container>
          </form>
          <ng-container *ngIf="!(isMotorLodgedBy != '' && isMotorLodgedBy == 'repairer') && country?.toLowerCase() !== 'sgp'">
            <div>
              <span>{{ 'pagePaymentAndContact.bankAcct.bankHelpText.desc2' | translate}} <a href="javascript:;" (click)="goToCheque()">{{ 'pagePaymentAndContact.bankAcct.bankHelpText.desc1' | translate}}</a></span>
            </div>
          </ng-container>
          <ng-container *ngIf="transactionInfo.isGenericClaim()">
            <div>
              <span>{{ 'pagePaymentAndContact.bankAcct.bankHelpText.desc3' | translate}} <a href="javascript:;" (click)="goToOverseasBank()">{{ 'pagePaymentAndContact.bankAcct.bankHelpText.desc1' | translate}}</a></span>
            </div>
          </ng-container>
        </div>
        <div class="col-lg-4 col-md-12">
          &nbsp;
        </div>
      </div>
  </ng-container>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{'pagePaymentAndContact.bankAcct.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{'pagePaymentAndContact.bankAcct.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
