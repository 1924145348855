<div *ngIf="!claim.isMox(); else moxSideMenu">
    <h4 class="ff-stag policy-info">{{ 'sideMenu.heading.title' | translate }} {{selectInsuredName}}<BR>
        <span>{{ this.productDescription | translate}} {{claim.getPolicyNumber()}}</span>
    </h4>
    <ul class="processing-bar ff-stag">
        <li *ngIf="transactionInfo.getNavigationMenu().getGetStartedState()?.toLowerCase() !== 'finishgetstarted'"
            [ngClass]="{'current': transactionInfo.getNavigationMenu().getGetStartedState() == 'current',
                'notFinish': transactionInfo.getNavigationMenu().getGetStartedState() == 'not started', 
                'active': transactionInfo.getNavigationMenu().getGetStartedState() == 'finish'}">
                <span>{{ 'pageGetStarted.heading.title' | translate }}</span>
        </li>
        <li *ngIf="transactionInfo.isTravelProduct()" [routerLink]="transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish'?'/claimform/confirmTravelPeriod':null"
            [ngClass]="{'current':  transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'current',
                'notFinish': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                'active': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState().startsWith('finish')}"
                (click)="closeMenu();">
                <span>{{ 'sideMenu.confirmTravelPeriod' | translate }}</span>
        </li> 
         <li *ngIf="transactionInfo.isHomeProduct()" [routerLink]="transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish'?'/claimform/confirmIncidentDetails':null"
            [ngClass]="{'current':  transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'current',
                'notFinish': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                'active': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                (click)="closeMenu();">
                <span>{{ 'homeProduct.confirmIncidentDetails.title' | translate }}</span>
        </li>  
        <li *ngIf="transactionInfo.isMotorProduct()" [routerLink]="transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish'?'/claimform/confirmVehicleDetails':null"
            [ngClass]="{'current':  transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'current',
                'notFinish': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                'active': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                (click)="closeMenu();">
                <span>{{ 'motorClaim.confirmVehicle.heading' | translate }}</span>
        </li>
        <li *ngIf="transactionInfo.isEmployeeCompensationProduct()" [routerLink]="transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish'?'/claimform/employerDetails':null"
            [ngClass]="{'current':  transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'current',
                'notFinish': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current',
                'active': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
            (click)="closeMenu();">
                <span>{{ 'ecClaim.employerDetails.title' | translate }}</span>
        </li>
        <li *ngIf="transactionInfo.isTravelProduct() || transactionInfo.isDomesticHelperProduct() || (transactionInfo.isAccidentProduct() && !transactionInfo.hasSingleInsured())" [routerLink]="transactionInfo.getNavigationMenu().getSelectInsuredState() == 'finish'?'/claimform/selectInsured':null"
            [ngClass]="{'current':  transactionInfo.getNavigationMenu().getSelectInsuredState() == 'current',
                'notFinish': transactionInfo.getNavigationMenu().getSelectInsuredState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                'active': transactionInfo.getNavigationMenu().getSelectInsuredState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState().startsWith('finish')}"
                (click)="closeMenu();">
            <span *ngIf="transactionInfo.isTravelProduct() || transactionInfo.isAccidentProduct()">{{ 'sideMenu.selectInsured' | translate}}</span>
            <span *ngIf="transactionInfo.isDomesticHelperProduct()">{{ this.transactionInfo.getIsTransactionOrigin() && this.claim.isMILDMHProductPlan() ?
                ('sideMenu.basicInfo' | translate) : ('sideMenu.selectHelper' | translate)}}
            </span>
        </li>

        <ng-container *ngIf="transactionInfo.isAccidentProduct()">
            <li [routerLink]="transactionInfo.getNavigationMenu().getAccidentDetailsState() == 'finish' ? paAccidentDetailsUrl : null"
                [ngClass]="{'current':  transactionInfo.getNavigationMenu().getAccidentDetailsState() == 'current',
                    'notFinish': transactionInfo.getNavigationMenu().getAccidentDetailsState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                    'active': transactionInfo.getNavigationMenu().getAccidentDetailsState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                    (click)="closeMenu();">
                <span>{{ 'sideMenu.accidentDetails' | translate }}</span>
            </li>
            <ng-container *ngIf="!claim.isHKGorHGI()">
                <li [routerLink]="transactionInfo.getNavigationMenu().getInjuryDetailsState() == 'finish'?'/claimform/paInjuryDetails':null" 
                    [ngClass]="{'current':  transactionInfo.getNavigationMenu().getInjuryDetailsState() == 'current',
                        'notFinish': transactionInfo.getNavigationMenu().getInjuryDetailsState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                        'active': transactionInfo.getNavigationMenu().getInjuryDetailsState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                        (click)="closeMenu();">
                        <span>{{ 'sideMenu.injuryDetails' | translate }}</span>
                </li>
            </ng-container>
        </ng-container>
        
        <ng-container *ngIf="transactionInfo.isGenericClaim()">
            <li [routerLink]="transactionInfo.getNavigationMenu().getGenericClaimDetailState() == 'finish'?'/claimform/genericClaimDetail':null"
                [ngClass]="{'current':  transactionInfo.getNavigationMenu().getGenericClaimDetailState() == 'current',
                    'notFinish': transactionInfo.getNavigationMenu().getGenericClaimDetailState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                    'active': transactionInfo.getNavigationMenu().getGenericClaimDetailState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                    (click)="closeMenu();">
                {{ 'sideMenu.claimDetails' | translate }}
            </li>
            <li [routerLink]="transactionInfo.getNavigationMenu().getGenericUploadDocumentState() == 'finish'?'/claimform/genericUploadDocument':null" 
            [ngClass]="{'current':  transactionInfo.getNavigationMenu().getGenericUploadDocumentState() == 'current',
                    'notFinish': transactionInfo.getNavigationMenu().getGenericUploadDocumentState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                    'active': transactionInfo.getNavigationMenu().getGenericUploadDocumentState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                    (click)="closeMenu();">
                <span>{{ 'sideMenu.supportingDocuments' | translate }}</span>
            </li>
        </ng-container>

        <ng-container *ngIf="!transactionInfo.isGenericClaim()">
            <li [routerLink]="transactionInfo.getNavigationMenu().getSelectClaimState() == 'finish' ? '/claimform/claimDetail' : null" 
                [hidden]="transactionInfo.getNavigationMenu().getClaimDetailsState() == 'false'"
                [ngClass]="{'current': transactionInfo.getNavigationMenu().getSelectClaimState() == 'current' && transactionInfo.getNavigationMenu().getGetStartedState().startsWith('finish'),
                    'notFinish': transactionInfo.getNavigationMenu().getSelectClaimState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current',  
                    'active':transactionInfo.getNavigationMenu().getSelectClaimState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState().startsWith('finish')}">
                <span>{{ 'sideMenu.claimDetails' | translate }}</span>
            </li>
            <ng-container  *ngIf="!transactionInfo.isAccidentProduct() || (transactionInfo.isAccidentProduct() && !claim.isHKGorHGI())" >
                <li *ngFor="let subMenu of subMenuList; let i = index" [hidden]="subMenu.hidden"
                    [ngClass]="{'active': subMenu.complete && transactionInfo.getNavigationMenu().getGetStartedState().startsWith('finish'),
                        'notFinish':!subMenu.complete || transactionInfo.getNavigationMenu().getGetStartedState() === 'current'}">
                    <span *ngIf="this.transactionInfo.getIsClaimDetailsLabel()">{{'sideMenu.claimDetails' | translate}}&nbsp;</span>
                    <span *ngIf="!this.transactionInfo.getIsClaimDetailsLabel()">{{subMenu.claimTypeIdName | translate}}&nbsp;</span>
                    <button *ngIf="transactionInfo.getNavigationMenu().getGetStartedState() === 'finish' && !this.transactionInfo.getIsClaimDetailsLabel()"
                                class="removeBtn one" (click)="open(confirm_delete_claim_type, subMenu.claimTypeId)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button>
                    <ul>
                        <li [hidden]="subTitle.hidden" [ngClass]="{'active':subTitle.complete && transactionInfo.getNavigationMenu().getGetStartedState().startsWith('finish'), 'current':this.router.url === subTitle.url}"
                            [routerLink]="subTitle.complete && transactionInfo.getNavigationMenu().getGetStartedState().startsWith('finish') ? subTitle.url : null" *ngFor="let subTitle of subMenuList[i].subMenus"
                            (click)="closeMenu();">
                            <span>{{subTitle.name | translate}}</span>
                        </li>
                    </ul>
                </li>
            </ng-container>
            <ng-container  *ngIf="transactionInfo.isAccidentProduct() && claim.isHKGorHGI()" >
                <li *ngFor="let subMenu of subMenuList; let i = index" [hidden]="subMenu.hidden"
                    [ngClass]="{'active': subMenu.complete && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish',
                        'notFinish':!subMenu.complete || transactionInfo.getNavigationMenu().getGetStartedState() === 'current',
                        'current':this.router.url === subMenu.url}"
                    [routerLink]="subMenu.complete && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish' ? subMenu.url : null"
                    (click)="closeMenu();">
                    <span *ngIf="this.transactionInfo.getIsClaimDetailsLabel()">{{'sideMenu.claimDetails' | translate}}</span>
                    <span *ngIf="!this.transactionInfo.getIsClaimDetailsLabel()">{{subMenu.claimTypeIdName | translate}}</span>
                    <button *ngIf="transactionInfo.getNavigationMenu().getGetStartedState() === 'finish' && !this.transactionInfo.getIsClaimDetailsLabel()"
                            class="removeBtn two" (click)="open(confirm_delete_claim_type, subMenu.claimTypeId)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button>
                </li>
            </ng-container>
        </ng-container>

        <li [routerLink]="transactionInfo.getNavigationMenu().getPaymentAndContactState() == 'finish'?'/claimform/paymentAndContact':null"
            [ngClass]="{'current': transactionInfo.getNavigationMenu().getPaymentAndContactState() == 'current' && transactionInfo.getNavigationMenu().getGetStartedState().startsWith('finish'),
                'notFinish': transactionInfo.getNavigationMenu().getPaymentAndContactState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                'active': transactionInfo.getNavigationMenu().getPaymentAndContactState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState().startsWith('finish')}"
                (click)="closeMenu();">
                <span>{{claim.getPaymentRefundToBank() != '' ? ('sideMenu.paymentContact' | translate ): ('sideMenu.contact' | translate)}}</span>
        </li>
        <li [routerLink]="transactionInfo.getNavigationMenu().getReviewSummaryState() == 'finish'?'/claimform/summary':null"
            [ngClass]="{'current': transactionInfo.getNavigationMenu().getReviewSummaryState() == 'current' && transactionInfo.getNavigationMenu().getGetStartedState().startsWith('finish'),
                'notFinish': transactionInfo.getNavigationMenu().getReviewSummaryState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                'active': transactionInfo.getNavigationMenu().getReviewSummaryState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState().startsWith('finish')}"
                (click)="closeMenu();">
            <span>{{'sideMenu.reviewSubmit' | translate }}</span>
        </li>
    </ul>
    <div class="progress-line"></div>
</div>

<ng-template #confirm_delete_claim_type let-c="close" let-d="dismiss">
    <div [ngClass]="{'mox-style': claim.isMox()}">
        <div class="modal-header">
            <h5 class="modal-title">{{'sideMenu.deleteClaim.header' | translate }}</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p *ngIf="!claim.isMox()">{{'sideMenu.deleteClaim.question1' | translate }}</p>
            <p *ngIf="claim.getSelectedClaimTypesList().length == 1 && !claim.isMox()">{{'sideMenu.deleteClaim.question2' | translate }}</p>
            <p *ngIf="claim.isMox()">{{'sideMenu.deleteClaim.question-mox' | translate }}</p>
        </div>
        <div class="modal-footer">
            <div class="col-md-6 text-right">
                <button type="button" class="btn btn-primary btn-sm" (click)="deleteClaimType(selectedClaimTypeNumber)">
                    <span *ngIf="!claim.isMox()">{{'sideMenu.deleteClaim.yes' | translate }}</span>
                    <span *ngIf="claim.isMox()">{{'sideMenu.deleteClaim.yes-mox' | translate }}</span>
                </button>
            </div>
            <div class="col-md-6 text-left">
                <button type="button" class="btn btn-default btn-sm modal-btn-secondary" (click)="c('Close click')">
                    <span *ngIf="!claim.isMox()">{{'sideMenu.deleteClaim.no' | translate }}</span>
                    <span *ngIf="claim.isMox()">{{'sideMenu.deleteClaim.no-mox' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #moxSideMenu>
    <div class="'mox-style" [ngClass]="{'lang-zh': (currentLang === 'zh')}">
        <h4 class="ff-stag policy-info">{{ 'sideMenu.heading.title' | translate }} {{selectInsuredName ? selectInsuredName : policyHolder}}<BR>
            <span>{{ this.productDescription | translate}} {{claim.getPolicyNumber()}}</span>
        </h4>
        <div class="progress-list-container">
            <ul class="processing-bar ff-stag">
                <li *ngIf="!transactionInfo.hasSingleInsured()" 
                    [routerLink]="transactionInfo.getNavigationMenu().getSelectInsuredState() == 'finish'?'/claimform/selectInsured':null"
                    [ngClass]="{'current':  transactionInfo.getNavigationMenu().getSelectInsuredState() == 'current',
                        'notFinish': transactionInfo.getNavigationMenu().getSelectInsuredState() == 'not started',
                        'active': this.isFinishSelectInsured}"
                    (click)="closeMenu();">
                    <span>{{ 'sideMenu.selectInsured' | translate}}</span>
                </li>
                <li [routerLink]="transactionInfo.getNavigationMenu().getAccidentDetailsState() == 'finish' ? paAccidentDetailsUrl : null"
                    [ngClass]="{'current':  transactionInfo.getNavigationMenu().getAccidentDetailsState() == 'current',
                        'notFinish': transactionInfo.getNavigationMenu().getAccidentDetailsState() == 'not started',
                        'active': this.isFinishAccidentDetails}"
                    (click)="closeMenu();">
                    <span>{{ 'sideMenu.accidentDetails' | translate }}</span>
                </li>
                <li [routerLink]="transactionInfo.getNavigationMenu().getSelectClaimState() == 'finish' ? '/claimform/claimDetail' : null"
                    [hidden]="transactionInfo.getNavigationMenu().getClaimDetailsState() == 'false'"
                    [ngClass]="{'current': transactionInfo.getNavigationMenu().getSelectClaimState() == 'current',
                        'notFinish': transactionInfo.getNavigationMenu().getSelectClaimState() == 'not started',
                        'active': this.isFinishSelectClaim}">
                    <span>{{ 'sideMenu.claimDetails' | translate }}</span>
                </li>
                <li *ngFor="let subMenu of subMenuList; let i = index" [hidden]="subMenu.hidden"
                        [routerLink]="subMenu.complete ? subMenu.url : null"
                        [ngClass]="{'active': subMenu.complete,
                            'notFinish':!subMenu.complete,
                            'current':this.router.url === subMenu.url}"
                        (click)="closeMenu();">
                        <span *ngIf="this.transactionInfo.getIsClaimDetailsLabel()">{{'sideMenu.claimDetails' | translate}}</span>
                        <span *ngIf="!this.transactionInfo.getIsClaimDetailsLabel()">{{subMenu.claimTypeIdName | translate}}</span>
                        <button *ngIf="transactionInfo.getNavigationMenu().getAccidentDetailsState() === 'finish' && !this.transactionInfo.getIsClaimDetailsLabel()"
                                class="removeBtn two" (click)="open(confirm_delete_claim_type, subMenu.claimTypeId)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button>
                </li>
                <li [routerLink]="transactionInfo.getNavigationMenu().getReviewSummaryState() == 'finish'?'/claimform/summary':null"                    
                    [ngClass]="{'current': transactionInfo.getNavigationMenu().getReviewSummaryState() == 'current',
                        'notFinish': transactionInfo.getNavigationMenu().getReviewSummaryState() == 'not started',
                        'active': this.isFinishReviewSummary}"
                    (click)="closeMenu();">
                    <span>{{'sideMenu.reviewSubmit' | translate }}</span>
                </li>
            </ul>
            <div class="progress-line"></div>
        </div>
    </div>
</ng-template>
