<section class="error-section bg-grey text-left">
    <div class="container" *ngIf="routeParams.errorType == 'ResumeLinkExpired'">
        <ng-container [ngTemplateOutlet]="resumeLinkExpired"></ng-container>
    </div>
    <div class="container" *ngIf="routeParams.errorType == 'SessionExpired'">
        <ng-container [ngTemplateOutlet]="sessionExpired"></ng-container>
    </div>
</section>

<ng-template #resumeLinkExpired let-c="close" let-d="dismiss">
<div style="display: flex;">
  <img class="img-responsive" class="box-icon" src="assets/images/exclamation-mark-icon.svg">
  <p class="error-label">
    {{ 'resumeLinkExpired.linkExpired' | translate }}</p>
</div>
<div class="home-form" style="padding: 20px;">
  <h3 style="font-family:'Verdana', sans-serif; font-size: 20px">{{ 'resumeLinkExpired.linkNoLongerValid' | translate }}</h3><br/>
  <p style="font-size: 15px;">{{ 'resumeLinkExpired.pleaseReturn' | translate }}</p><br/>
  <a href="{{geteClaimsUrl('hk')}}" target="_blank" rel="noopener" class="new-btn btn-primary btn btnblock">{{ 'resumeLinkExpired.hkClaim' | translate }}</a>
  &nbsp;&nbsp;&nbsp;
  <a href="{{geteClaimsUrl('sg')}}" target="_blank" rel="noopener" class="new-btn btn-primary btn btnblock">{{ 'resumeLinkExpired.sgClaim' | translate }}</a>
    &nbsp;&nbsp;&nbsp;
  <a href="{{geteClaimsUrl('my')}}" target="_blank" rel="noopener" class="new-btn btn-primary btn btnblock">{{ 'resumeLinkExpired.myClaim' | translate }}</a>
</div>
</ng-template>

<ng-template #sessionExpired let-c="close" let-d="dismiss">
<div style="display: flex;">
  <div><img class="img-responsive" class="box-icon" src="assets/images/exclamation-mark-icon.svg"></div>
  <div class="div-session-label">
    <p class="error-label">SESSION EXPIRED 連線逾時</p>
  </div>
  <div class="div-session-label-mob">
    <p class="error-label">
      <span>SESSION EXPIRED</span>
      <span>連線逾時</span>
    </p>
  </div>
</div>
<div class="home-form" style="padding: 20px;">
  <h3 style="font-family:'Verdana', sans-serif; font-size: 20px">Your session has expired.</h3><br/>
  <p style="font-size: 15px;">Please return to QBE eClaims and start a new claim.</p><br/>
  <h3 style="font-family:'Verdana';font-size: 20px">你的連線已逾時。</h3><br/>
  <p style="font-size: 15px;">請返回QBE網上理賠並重新提交索償。</p><br/>
  <a href="{{geteClaimsUrl('hk')}}" target="_blank" rel="noopener" class="new-btn btn-primary btn btnblock">{{ 'resumeLinkExpired.hkClaim' | translate }}</a>
  &nbsp;&nbsp;&nbsp;
  <a href="{{geteClaimsUrl('sg')}}" target="_blank" rel="noopener" class="new-btn btn-primary btn btnblock">{{ 'resumeLinkExpired.sgClaim' | translate }}</a>
  &nbsp;&nbsp;&nbsp;
  <a href="{{geteClaimsUrl('my')}}" target="_blank" rel="noopener" class="new-btn btn-primary btn btnblock">{{ 'resumeLinkExpired.myClaim' | translate }}</a>
</div>
 </ng-template>