<div class="others-notSure-container" [ngClass]="{'mox-style': claim.isMox()}">
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><em class="fas fa-arrow-left"></em> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
        <a (click)="back()" class="back-chevron"></a>
    </div>
    <div class="col-12">
        <h5 *ngIf="claim.getClaimPersonalAccident().getHasEligibleClaimTypes()">
            <span *ngIf="!claim.isMox()">{{ 'paClaim.others.title' | translate}}</span>
            <span *ngIf="claim.isMox()">{{ 'paClaim.others.title-mox' | translate}}</span>
        </h5>
        <h5 *ngIf="!claim.getClaimPersonalAccident().getHasEligibleClaimTypes()">{{ 'claimDetails.body.claimDetails' | translate}}</h5>
        <br *ngIf="claim.isMox()" />
        <p>{{ 'homeProduct.others.detailsIncident' | translate}}</p>
    </div>
    <br/>
    <div class="row col-12">
      <div class="col-lg-9 col-md-12 mox-dt-extend">
        <form>
          <div class="form-group home-estimated-amount-loss">
            <qnect-ui #HOME_EST_AMT_LOSS [fieldId]="'HOME_EST_AMT_LOSS'" [formGroup]="othersForm" name="estAmountLoss"></qnect-ui>
          </div>
        </form>
      </div>
      <div class="col-lg-3 col-xl-3 col-md-12 hide"> &nbsp; </div>
    </div>
  
    <br/><div class="divider"></div><br/>
  
    <div class="row col-12">
      <div class="col-lg-9 col-md-12 mox-dt-extend">
        <div><h5>{{ 'homeProduct.authorityReport.title' | translate }}</h5></div><br>
        <form>
          <div class="form-group">
            <qnect-ui #HOME_AUTHORITY_INCIDENT_REPORT [fieldId]="'HOME_AUTHORITY_INCIDENT_REPORT'" [formGroup]="policeReportForm" name="hasReportedIncidentToPolice"></qnect-ui>
          </div>
          <ng-container  *ngIf="this.policeReport.getHasReportedIncidentToPolice()">
            <div class="form-group">
              <qnect-ui #HOME_AUTHORITY_REPORT_DATE [fieldId]="'HOME_AUTHORITY_REPORT_DATE'" [formGroup]="policeReportForm" [claimsDateValidator]="this.claim.getClaimPersonalAccident().getAccidentDetails().getAccidentDate()" name="dateOfReport"></qnect-ui>
            </div>
            <div class="form-group"> 
              <qnect-ui #HOME_AUTHORITY_REFERENCE_NO [fieldId]="'HOME_AUTHORITY_REFERENCE_NO'" [formGroup]="policeReportForm" name="referenceNumber"></qnect-ui>
            </div>
          </ng-container>
        </form>
      </div>
      <div class="col-lg-3 col-xl-3 col-md-12 hide"> &nbsp; </div>
    </div>
  
    <br/><div class="divider"></div><br/>
  
    <div class="upload-doc-body">
      <div class="col-12 upload-doc-info" [ngClass]="{'chUI': this.translate.currentLang == 'zh'}">
        <div><h5>{{ 'claimSection.generalLabels.uploadDocuments.uploadYourDocuments' | translate }}</h5></div>
          <p *ngIf="!claim.isMox()" class="row-info">{{ 'claimSection.generalLabels.uploadDocuments.documentsNeeded1' | translate}}&nbsp;{{ 'claimSection.generalLabels.uploadDocuments.documentsNeeded2' | translate}}&nbsp;{{ 'pageSummaryClaim.claimItem.pleaseRetain' | translate}}</p>
          <p *ngIf="claim.isMox()" class="row-info">{{ 'pageSummaryClaim.claimItem.pleaseRetain' | translate}}</p>
      </div>
      <br>
      
      <app-pa-upload-doc></app-pa-upload-doc>
  
      <div class="col-lg-3 col-xl-3 col-md-12 hide"> &nbsp; </div>
    </div>
  
  
    <div class="submit-bar">
      <abandon-button></abandon-button>
      <a href="javascript:;" (click)="showModal()" class="Tertiary" [hidden]="claim.isMox()"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
      <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<em class="fas fa-arrow-right"></em></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
  </div>
