import {Component, Injector, OnInit} from "@angular/core";
import {SideMenuService} from "../../../../services/side-menu.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {TripCancellation} from "../../../../model/travel/trip-cancellation.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import {UtilitiesService} from "../../../../utilities/utilities.service";

@Component({
  selector: 'app-loss-caused-by-cancellation',
  templateUrl: './loss-caused-by-cancellation.component.html',
  styleUrls: ['./loss-caused-by-cancellation.component.css']
})
export class LossCausedByCancellationComponent extends ClaimsBaseComponent implements OnInit {

    lossCausedForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    tripCancellation: TripCancellation;
    currency = '';

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.tripCancellation = this.claim.getClaimTravel().getTripCancellation();
        this.currency = UtilitiesService.getCountryCurrency(this.claim.getCountry());
    }

    ngOnInit() {

        this.pushGAViewTripCancelLoss();
        if(this.tripCancellation.getTotalAmountPaidCurrency() == null){
            this.tripCancellation.setTotalAmountPaidCurrency(this.currency);
        }

        this.lossCausedForm = this.fb.group({
            hasRefund: [this.tripCancellation.getHasRefund()],
            hasRefundDesc: [this.tripCancellation.getHasRefundDesc()],
            didUseMiles: [super.getBooleanString(this.tripCancellation.getDidUseMiles())],
            totalAmountPaidCurrency: [this.tripCancellation.getTotalAmountPaidCurrency()],
            totalAmountRefundedCurrency: [this.tripCancellation.getTotalAmountRefundedCurrency()],
            totalAmountPaidCost: [this.tripCancellation.getTotalAmountPaidCost()],
            totalAmountRefundedCost : [this.tripCancellation.getTotalAmountRefundedCost()],
            extraTransportationCost: [this.tripCancellation.getExtraTransportationCost()],
            extraAccommodationCost: [this.tripCancellation.getExtraAccommodationCost()],
            extraTransportationCurrency: [this.tripCancellation.getExtraTransportationCurrency()],
            extraAccommodationCurrency: [this.tripCancellation.getExtraAccommodationCurrency()],
            hasExtAccomCost: [super.getBooleanString(this.tripCancellation.getHasExtAccomCost())],
            hasExtTransCost: [super.getBooleanString(this.tripCancellation.getHasExtTransCost())]
        }); 
    }

    ngAfterViewInit() {

        this.lossCausedForm.valueChanges.subscribe(data => {
            this.tripCancellation.setTotalAmountPaidCost(super.getValueInComponent('totalAmountPaidCost'));
            this.tripCancellation.setTotalAmountRefundedCost(super.getValueInComponent('totalAmountRefundedCost'));
            this.tripCancellation.setTotalAmountPaidCurrency(this.lossCausedForm.get('totalAmountPaidCurrency').value);
            this.tripCancellation.setTotalAmountRefundedCurrency(this.lossCausedForm.get('totalAmountRefundedCurrency').value);
            this.tripCancellation.setHasRefund(super.getValueInComponent('hasRefund'));
            this.tripCancellation.setHasRefundDesc(super.getSelectedRadioText('hasRefund'));
            if (this.lossCausedForm.get('didUseMiles').value != null) {
                this.tripCancellation.setDidUseMiles(this.lossCausedForm.get('didUseMiles').value == 'true');
            }
            this.tripCancellation.setExtraTransportationCost(super.getValueInComponent('extraTransportationCost'));
            this.tripCancellation.setExtraAccommodationCost(super.getValueInComponent('extraAccommodationCost'));
            this.tripCancellation.setExtraTransportationCurrency(this.lossCausedForm.get('extraTransportationCurrency').value);
            this.tripCancellation.setExtraAccommodationCurrency(this.lossCausedForm.get('extraAccommodationCurrency').value);
            if (this.lossCausedForm.get('hasExtAccomCost').value != null) {
                this.tripCancellation.setHasExtAccomCost(this.lossCausedForm.get('hasExtAccomCost').value == 'true');
            }
            if (this.lossCausedForm.get('hasExtTransCost').value != null) {
                this.tripCancellation.setHasExtTransCost(this.lossCausedForm.get('hasExtTransCost').value == 'true');
            }

            this.validateClaimDetailsComplete();
        });

        let self = this;

        setTimeout(function () {
            self.hideRefundAmountField(self.tripCancellation.getHasRefund()), 10
        });
        this.lossCausedForm.get('hasRefund').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideRefundAmountField(data);
        });
        
        setTimeout(function () {self.hideExtraTransCost(self.getBooleanString(self.tripCancellation.getHasExtTransCost())),10});
        this.lossCausedForm.get('hasExtTransCost').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideExtraTransCost(data);
        });

        // Stop validation of fields when not required.
        setTimeout(function () {self.hideAccomExtraCost(self.getBooleanString(self.tripCancellation.getHasExtAccomCost())),10});
        this.lossCausedForm.get('hasExtAccomCost').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideAccomExtraCost(data);
        });

        }

        hideExtraTransCost(hasTranExtraCost) {
        if (hasTranExtraCost == 'true') {
            this.lossCausedForm.get('extraTransportationCost').enable({onlySelf: false, emitEvent: false});
            this.lossCausedForm.get('extraTransportationCurrency').enable({onlySelf: false, emitEvent: false});
        } else {
            this.lossCausedForm.get('extraTransportationCost').reset();
            this.lossCausedForm.get('extraTransportationCurrency').setValue(this.currency);
            this.lossCausedForm.get('extraTransportationCost').disable({onlySelf: false, emitEvent: false});
            this.lossCausedForm.get('extraTransportationCurrency').disable({onlySelf: false, emitEvent: false});
        }
        }

        hideAccomExtraCost(hasAccomExtraCost) {
        if (hasAccomExtraCost == 'true') {
            this.lossCausedForm.get('extraAccommodationCost').enable({onlySelf: false, emitEvent: false});
            this.lossCausedForm.get('extraAccommodationCurrency').enable({onlySelf: false, emitEvent: false});
        } else {
            this.lossCausedForm.get('extraAccommodationCost').reset();
            this.lossCausedForm.get('extraAccommodationCurrency').setValue(this.currency);
            this.lossCausedForm.get('extraAccommodationCost').disable({onlySelf: false, emitEvent: false});
            this.lossCausedForm.get('extraAccommodationCurrency').disable({onlySelf: false, emitEvent: false});
        }
        }


    hideTotalAmountField(didUseMile) {
        if (didUseMile == 'true') {
            this.lossCausedForm.get('totalAmountPaidCost').enable({onlySelf: false, emitEvent: false});
            this.lossCausedForm.get('totalAmountPaidCurrency').enable({onlySelf: false, emitEvent: false});
        } else {
            this.lossCausedForm.get('totalAmountPaidCost').reset();
            this.lossCausedForm.get('totalAmountPaidCurrency').setValue(this.currency);
            this.lossCausedForm.get('totalAmountPaidCost').disable({onlySelf: false, emitEvent: false});
            this.lossCausedForm.get('totalAmountPaidCurrency').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideRefundAmountField(hasRefund) {
        if (hasRefund == 'true') {
            this.lossCausedForm.get('totalAmountRefundedCost').enable({onlySelf: false, emitEvent: false});
            this.lossCausedForm.get('totalAmountRefundedCurrency').enable({onlySelf: false, emitEvent: false});
        } else {
            this.lossCausedForm.get('totalAmountRefundedCost').reset();
            this.lossCausedForm.get('totalAmountRefundedCurrency').setValue(this.currency);
            this.lossCausedForm.get('totalAmountRefundedCost').disable({onlySelf: false, emitEvent: false});
            this.lossCausedForm.get('totalAmountRefundedCurrency').disable({onlySelf: false, emitEvent: false});
        }
    }


    back() {
        this.router.navigate(["/claimform/tripCancellation/detailOfCancelTrip"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 6, subMenuIndex: 1});

            this.router.navigate(["/claimform/tripCancellation/cancelSupportDoc"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.lossCausedForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.lossCausedForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitClaimComplete({claimTypeId: 6, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 6, subMenuIndex: 1});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewTripCancelLoss() {
        (<any>window).dataLayer.push({
            'pageStep': 'Trip Cancellation – Loss Caused',
            'vPath': '/claim/trip-cancellation/loss-caused',
            'event': 'vpageview',
        });
    }
}
