import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { SideMenuService } from 'src/app/services/side-menu.service';
import {ClaimTypes} from '../../../model/claim-type.model';
import {TransactionInfoService} from '../../../services/transaction-info.service';
import { OccupationalDisease } from 'src/app/model/employee-compensation/occupational-disease.model';
import {FatalCaseComponent} from '../fatal-case/fatal-case.component';
import {CompensationDetails} from '../../../model/employee-compensation/compensation-details.model';
import {FatalCase} from '../../../model/employee-compensation/fatal-case.model';

@Component({
    selector: 'app-occupational-disease',
    templateUrl: './occupational-disease.component.html'
})
export class OccupationalDiseaseComponent extends ClaimsBaseComponent implements OnInit {

    occupationalDiseaseForm: FormGroup;
    showTotalBox = false;
    occupationalDisease: OccupationalDisease;
    currentClaimType: string;
    @ViewChild('fatalCase', {static: false}) fatalCaseComponent: FatalCaseComponent;

    constructor(private fb: FormBuilder,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private sideMenuService: SideMenuService) {
        super(injector);
        this.occupationalDisease = this.claim.getClaimEmployeeCompensation().getOccupationalDisease();
        this.currentClaimType = this.injector.get(TransactionInfoService).getTransactionInfo()?.getCurrentClaimType();
    }

    ngOnInit() {
        this.occupationalDiseaseForm = this.fb.group({
            hospitalName: [this.occupationalDisease.getHospitalName()],
            commencementDate: [this.occupationalDisease.getCommencementDate()],
            suffering: [this.occupationalDisease.getSuffering()],
            workType: [this.occupationalDisease.getWorkType()],
            result: [this.occupationalDisease.getResult()],
            resultDesc: [this.occupationalDisease.getResultDesc()],
            temporaryDate: [this.occupationalDisease.getResultDate()],
            permanentDate: [this.occupationalDisease.getResultDate()],
            deathDate: [this.occupationalDisease.getResultDate()]
        });
    }

    ngAfterViewInit() {
        this.occupationalDiseaseForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.occupationalDisease.setHospitalName(this.occupationalDiseaseForm.get('hospitalName').value);
                this.occupationalDisease.setCommencementDate(super.getValueInComponent('commencementDate'));
                this.occupationalDisease.setSuffering(this.occupationalDiseaseForm.get('suffering').value);
                this.occupationalDisease.setWorkType(this.occupationalDiseaseForm.get('workType').value);
                this.occupationalDisease.setResult(this.occupationalDiseaseForm.get('result').value);
                this.occupationalDisease.setResultDesc(super.getSelectedRadioText('result'));
                if (this.isNotNullOrUndefined(this.occupationalDiseaseForm.get('temporaryDate').value)) {
                    this.occupationalDisease.setResultDate(super.getValueInComponent('temporaryDate'));
                } else if (this.isNotNullOrUndefined(this.occupationalDiseaseForm.get('permanentDate').value)) {
                    this.occupationalDisease.setResultDate(super.getValueInComponent('permanentDate'));
                } else if (this.isNotNullOrUndefined(this.occupationalDiseaseForm.get('deathDate').value)) {
                    this.occupationalDisease.setResultDate(super.getValueInComponent('deathDate'));
                }

            });

        this.occupationalDiseaseForm.get('result').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.validateResultFields(data);
        });
    }

    validateResultFields(data) {
        this.occupationalDiseaseForm.get('deathDate').reset();
        this.occupationalDiseaseForm.get('deathDate').disable({onlySelf: false, emitEvent: false});
        this.occupationalDiseaseForm.get('temporaryDate').reset();
        this.occupationalDiseaseForm.get('temporaryDate').disable({onlySelf: false, emitEvent: false});
        this.occupationalDiseaseForm.get('permanentDate').reset();
        this.occupationalDiseaseForm.get('permanentDate').disable({onlySelf: false, emitEvent: false});

        if (data?.toLowerCase() == 'temporary incapacity') {
            this.occupationalDiseaseForm.get('temporaryDate').enable({onlySelf: false, emitEvent: false});
            this.claim.getClaimEmployeeCompensation().setFatalCase(new FatalCase());
        } else {
            this.claim.getClaimEmployeeCompensation().setCompensationDetails(new CompensationDetails());
            this.claim.getClaimEmployeeCompensation().setPeriodOfSickLeaves([]);
            this.claim.getClaimEmployeeCompensation().setTotalSickLeaveDays(0);

            if (data?.toLowerCase() == 'death') {
                this.occupationalDiseaseForm.get('deathDate').enable({onlySelf: false, emitEvent: false});
            } else if (data?.toLowerCase() == 'permanent incapacity') {
                this.occupationalDiseaseForm.get('permanentDate').enable({onlySelf: false, emitEvent: false});
                this.claim.getClaimEmployeeCompensation().setFatalCase(new FatalCase());
            }
        }

        this.sideMenuService.emitClaimTypeSubMenuShowUsingUrl({claimTypeId: 2, routeIndex: 3,
            routeUrl: "/claimform/form2a/directSettlement", show: data?.toLowerCase() == 'temporary incapacity'});
    }

    validatePoliceStationField(data){
        if (data == 'true') {
            this.occupationalDiseaseForm.get('policeStation').enable({onlySelf: false, emitEvent: false});
        } else {
            this.occupationalDiseaseForm.get('policeStation').reset();
            this.occupationalDiseaseForm.get('policeStation').disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        let isFatalCaseFormValid = this.occupationalDisease.getResult()?.toLowerCase() == 'death' ? this.fatalCaseComponent.validateForm() : true;
        if (this.validateForm() && isFatalCaseFormValid) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 1});
            this.router.navigate(["/claimform/form2a/employeeEarnings"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        if (this.injector.get(TransactionInfoService).getTransactionInfo()?.getCurrentClaimType() == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2A]) {
            this.router.navigate(["/claimform/form2a/detailsOfEmployee"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.occupationalDiseaseForm);
    }

    showModal() {
        this.showTotalBox = true;
    }
  
    getData(msg) {
        this.showTotalBox = msg;
    }
}
