import {Component, ElementRef, Injector, OnInit, ViewChild} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {LostPersonalItemDetail} from "../../../../model/travel/lost-personal-detail.model";
import {takeUntil} from "rxjs";
import {UtilitiesService} from "../../../../utilities/utilities.service";
import { DateService } from "src/app/ui/ui-datepicker/date.service";

@Component({
    selector: 'app-details-of-lost-items',
    templateUrl: './details-of-lost-items.component.html',
    styleUrls: ['./details-of-lost-items.component.css']
})
export class DetailsOfLostItemsComponent extends ClaimsBaseComponent implements OnInit {

    @ViewChild('lossType', {static: true}) el: ElementRef;
    lossDetailForm: FormGroup;
    items: FormArray[];
    selectType: string;
    showTotalBox = false;
    arrayItems: any;
    detailsOfLostItemsArray: LostPersonalItemDetail[] = [];
    currency: string;
    country: string;

    constructor(private fb: FormBuilder, 
                public sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private dateService: DateService) {

            super(injector);
    }

    ngOnInit() {

        this.pushGAViewLostItemsDescription();

        this.currency = UtilitiesService.getCountryCurrency(this.claim.getCountry());
        this.detailsOfLostItemsArray = this.claim.getClaimTravel().getLostPersonalItem().getDetailsOfLostItemsArray();
        this.lossDetailForm = this.fb.group({
            items: this.fb.array(this.buildStoredItems(this.detailsOfLostItemsArray))
        });

        this.initializeArrays();
    }

    ngAfterViewInit() {
        this.lossDetailForm.valueChanges.subscribe(data => {
            this.detailsOfLostItemsArray = [];

            for (let i = 0; i < data.items.length; i++) {
                let itemDetail : LostPersonalItemDetail = new LostPersonalItemDetail();

                itemDetail.setLossType(data.items[i].lossType);
                itemDetail.setLossTypeDesc(super.getIndexedTextInComponent("lossType", i));
                itemDetail.setLossTypeDetail(data.items[i].lossTypeDetail);
                itemDetail.setPurchaseDate(this.dateService.stringToDate(data.items[i].purchaseDate, 'dd/MM/yyyy'));
                itemDetail.setPurchasePriceType(data.items[i].purchasePriceType);
                itemDetail.setPurchasePrice(data.items[i].purchasePrice);
                itemDetail.setCostOfRepalceCostType(data.items[i].costOfRepalceCostType);
                itemDetail.setCostOfRepalceCost(data.items[i].costOfRepalceCost);
                itemDetail.setCostOfTransCostType(data.items[i].costOfTransCostType);
                itemDetail.setCostOfTransCost(data.items[i].costOfTransCost);
                itemDetail.setCostOfAccomCostType(data.items[i].costOfAccomCostType);
                itemDetail.setCostOfAccomCost(data.items[i].costOfAccomCost);
                itemDetail.setHasExtraAccomCost(data.items[i].hasExtraAccomCost == 'true');
                itemDetail.setHasExtraTransCost(data.items[i].hasExtraTransCost == 'true');
                itemDetail.setLostMoneyType(data.items[i].lostMoneyType);
                itemDetail.setLostMoneyAmount(data.items[i].lostMoneyAmount);
                itemDetail.setUnauthorisedMoneyLossType(data.items[i].unauthorisedMoneyLossType);
                itemDetail.setUnauthorisedMoneyLoss(data.items[i].unauthorisedMoneyLoss);
                itemDetail.setOtherTypeBriefDesc(data.items[i].otherTypeBriefDesc);
                if (this.isNotNullOrUndefined(data.items[i].wasWatchBeingStored)) {
                    itemDetail.setWasWatchBeingStored(data.items[i].wasWatchBeingStored == 'true');
                }
                if (this.isNotNullOrUndefined(data.items[i].hasOriginalPurchasedReceipt)) {
                    itemDetail.setHasOriginalPurchasedReceipt(data.items[i].hasOriginalPurchasedReceipt == 'true');
                }
                this.detailsOfLostItemsArray.push(itemDetail);
            }
            this.claim.getClaimTravel().getLostPersonalItem().setDetailsOfLostItemsArray(this.detailsOfLostItemsArray);

            setTimeout(() => {
                this.validateClaimDetailsComplete();
            }, 200);
        });

        // Stop validation of fields when not required.
        let formArray : FormArray =  <FormArray> this.lossDetailForm.controls["items"];
        for (let i = 0; i < formArray.controls.length; i++) {
             formArray.controls[i].get('lossType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.lossTypeChanged(data, formArray.controls[i]);
             });
        }
    }

    initializeArrays() {
        if (UtilitiesService.isNullOrUndefined(this.detailsOfLostItemsArray)) {
            this.detailsOfLostItemsArray = [];
            this.claim.getClaimTravel().getLostPersonalItem().setDetailsOfLostItemsArray(this.detailsOfLostItemsArray);
        }

        if (this.detailsOfLostItemsArray.length === 0) {
            this.detailsOfLostItemsArray.push(new LostPersonalItemDetail());
        }
    }

    lossTypeChanged(lossType, formGroup) {
        formGroup.get('lossTypeDetail').disable({onlySelf: false, emitEvent: false});
        formGroup.get('purchaseDate').disable({onlySelf: false, emitEvent: false});
        formGroup.get('purchasePriceType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('purchasePrice').disable({onlySelf: false, emitEvent: false});
        formGroup.get('costOfRepalceCostType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('costOfRepalceCost').disable({onlySelf: false, emitEvent: false});
        formGroup.get('costOfTransCostType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('costOfTransCost').disable({onlySelf: false, emitEvent: false});
        formGroup.get('costOfAccomCostType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('costOfAccomCost').disable({onlySelf: false, emitEvent: false});
        formGroup.get('hasExtraAccomCost').disable({onlySelf: false, emitEvent: false});
        formGroup.get('hasExtraTransCost').disable({onlySelf: false, emitEvent: false});
        formGroup.get('lostMoneyType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('lostMoneyAmount').disable({onlySelf: false, emitEvent: false});
        formGroup.get('unauthorisedMoneyLossType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('unauthorisedMoneyLoss').disable({onlySelf: false, emitEvent: false});
        formGroup.get('otherTypeBriefDesc').disable({onlySelf: false, emitEvent: false});
        formGroup.get('wasWatchBeingStored').disable({onlySelf: false, emitEvent: false});
        formGroup.get('hasOriginalPurchasedReceipt').disable({onlySelf: false, emitEvent: false});

        if (lossType == 'Passport / HKID / China Re-entry card' || lossType == 'Passport / NRIC / FIN' ||
            lossType == 'Passport / NRIC') {
            formGroup.get('costOfRepalceCostType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('costOfRepalceCost').enable({onlySelf: false, emitEvent: false});
            formGroup.get('costOfTransCostType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('costOfTransCost').enable({onlySelf: false, emitEvent: false});
            formGroup.get('costOfAccomCostType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('costOfAccomCost').enable({onlySelf: false, emitEvent: false});
            formGroup.get('hasExtraAccomCost').enable({onlySelf: false, emitEvent: false});
            formGroup.get('hasExtraTransCost').enable({onlySelf: false, emitEvent: false});
        }
        else if (lossType == 'Personal Money') {
            formGroup.get('lostMoneyType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('lostMoneyAmount').enable({onlySelf: false, emitEvent: false});
        }
        else if (lossType == 'Credit Card') {
            formGroup.get('unauthorisedMoneyLossType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('unauthorisedMoneyLoss').enable({onlySelf: false, emitEvent: false});
        }
        else if (lossType == 'Others') {
            formGroup.get('lossTypeDetail').enable({onlySelf: false, emitEvent: false});
            formGroup.get('otherTypeBriefDesc').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchaseDate').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePriceType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePrice').enable({onlySelf: false, emitEvent: false});
            formGroup.get('hasOriginalPurchasedReceipt').enable({onlySelf: false, emitEvent: false});
        }
        else if (lossType == 'Watch'){
            formGroup.get('purchaseDate').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePriceType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePrice').enable({onlySelf: false, emitEvent: false});
            formGroup.get('wasWatchBeingStored').enable({onlySelf: false, emitEvent: false});
            formGroup.get('hasOriginalPurchasedReceipt').enable({onlySelf: false, emitEvent: false});
        } 
        else if (lossType == 'Mobile Phone') {
            formGroup.get('lossTypeDetail').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchaseDate').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePriceType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePrice').enable({onlySelf: false, emitEvent: false});
            formGroup.get('hasOriginalPurchasedReceipt').enable({onlySelf: false, emitEvent: false});
        } 
        else {
            formGroup.get('purchaseDate').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePriceType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePrice').enable({onlySelf: false, emitEvent: false});
            formGroup.get('hasOriginalPurchasedReceipt').enable({onlySelf: false, emitEvent: false});
        }
    }

    buildStoredItems(detailsOfLostItemsArray: LostPersonalItemDetail[]): FormGroup[] {

        let formGroupArray : FormGroup[] = [];

        if(detailsOfLostItemsArray != null && detailsOfLostItemsArray.length > 0 ) {

            for (let i = 0; i < detailsOfLostItemsArray.length; i++) {

                let rowFormGroup = this.fb.group({
                    lossType: [detailsOfLostItemsArray[i].getLossType()],
                    lossTypeDesc: [detailsOfLostItemsArray[i].getLossTypeDesc()],
                    lossTypeDetail: [detailsOfLostItemsArray[i].getLossTypeDetail()],
                    purchaseDate: [detailsOfLostItemsArray[i].getPurchaseDate()],
                    purchasePriceType: [detailsOfLostItemsArray[i].getPurchasePriceType()],
                    purchasePrice: [detailsOfLostItemsArray[i].getPurchasePrice()],
                    costOfRepalceCostType: [detailsOfLostItemsArray[i].getCostOfRepalceCostType()],
                    costOfRepalceCost: [detailsOfLostItemsArray[i].getCostOfRepalceCost()],
                    costOfTransCostType: [detailsOfLostItemsArray[i].getCostOfTransCostType()],
                    costOfTransCost: [detailsOfLostItemsArray[i].getCostOfTransCost()],
                    costOfAccomCostType: [detailsOfLostItemsArray[i].getCostOfAccomCostType()],
                    costOfAccomCost: [detailsOfLostItemsArray[i].getCostOfAccomCost()],
                    hasExtraAccomCost: [super.getBooleanString(detailsOfLostItemsArray[i].getHasExtraAccomCost())],
                    hasExtraTransCost: [super.getBooleanString(detailsOfLostItemsArray[i].getHasExtraTransCost())],
                    lostMoneyType: [detailsOfLostItemsArray[i].getLostMoneyType()],
                    lostMoneyAmount: [detailsOfLostItemsArray[i].getLostMoneyAmount()],
                    unauthorisedMoneyLossType: [detailsOfLostItemsArray[i].getUnauthorisedMoneyLossType()],
                    unauthorisedMoneyLoss: [detailsOfLostItemsArray[i].getUnauthorisedMoneyLoss()],
                    otherTypeBriefDesc: [detailsOfLostItemsArray[i].getOtherTypeBriefDesc()],
                    wasWatchBeingStored: [super.getBooleanString(detailsOfLostItemsArray[i].getWasWatchBeingStored())],
                    hasOriginalPurchasedReceipt: [super.getBooleanString(detailsOfLostItemsArray[i].getHasOriginalPurchasedReceipt())],
                });

                formGroupArray.push(rowFormGroup);
            }
        }
        else {
            formGroupArray.push(this.createDefaultItems());
        }

        return formGroupArray;
    }

    createDefaultItems(): FormGroup {
        return this.fb.group({
            lossType: null,
            lossTypeDesc: '',
            lossTypeDetail: '',
            purchaseDate: null,
            purchasePriceType: this.currency,
            purchasePrice: '',
            costOfRepalceCostType: this.currency,
            costOfRepalceCost: '',
            costOfTransCostType: this.currency,
            costOfTransCost: '',
            costOfAccomCostType: this.currency,
            costOfAccomCost: '',
            hasExtraAccomCost: null,
            hasExtraTransCost: null,
            lostMoneyType: this.currency,
            lostMoneyAmount: '',
            unauthorisedMoneyLossType: this.currency,
            unauthorisedMoneyLoss: '',
            otherTypeBriefDesc: '',
            wasWatchBeingStored: null,
            hasOriginalPurchasedReceipt: null
        });
    }

    addItem(): void {

        let self = this;
        setTimeout(function () {
            let items = self.lossDetailForm.get('items') as FormArray;
            let newItem : FormGroup = self.createDefaultItems();
            items.push(newItem);

            newItem.get('lossType').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.lossTypeChanged(data, newItem);
            });
        }, 10);
    }

    removeItem(i) {
        let items = this.lossDetailForm.get('items') as FormArray;
        items.removeAt(i);

        items.value.forEach(formItem =>{
            this.detailsOfLostItemsArray.forEach(item =>{
                if (item.getLossType() === formItem.lossType) {
                    item.setLossTypeDesc(formItem.lossTypeDesc);
                }
            });
        });
    }
    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 1});

            this.router.navigate(["/claimform/lostPersonalItem/reportTheLoss"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.router.navigate(["/claimform/lostPersonalItem/detailOfLoss"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.lossDetailForm);
    }

    validateMoneyFields(): boolean {
        let data = this.detailsOfLostItemsArray;
        for (let i = 0; i < data.length; i++) {
                let formArray : FormArray =  <FormArray> this.lossDetailForm.controls["items"];
                for (let i = 0; i < formArray.controls.length; i++) {                    

                    let lossType = formArray.controls[i].get('lossType').value



                    if (lossType == 'Passport / HKID / China Re-entry card' || lossType == 'Passport / NRIC / FIN' ||
                         lossType == 'Passport / NRIC') {

                        if (formArray.controls[i].get('costOfRepalceCost').value == '' || 
                        formArray.controls[i].get('costOfRepalceCost').value == null) { 
                            return true;
     
                        }else if((formArray.controls[i].get('costOfTransCost').value == '' || 
                            formArray.controls[i].get('costOfTransCost').value == null || 
                            formArray.controls[i].get('costOfTransCost').value == undefined) && 
                            formArray.controls[i].get('hasExtraTransCost').value === "true"){
                                return true;

                        }else if((formArray.controls[i].get('costOfAccomCost').value == '' || 
                            formArray.controls[i].get('costOfAccomCost').value == null || 
                            formArray.controls[i].get('costOfTransCost').value == undefined) && 
                            formArray.controls[i].get('hasExtraAccomCost').value === "true"){
                                return true;
                        }
            

                    }
                    else if (lossType == 'Personal Money') {

                        if ((formArray.controls[i].get('lostMoneyAmount').value == '' || 
                        formArray.controls[i].get('lostMoneyAmount').value == null)) { 
                            return true;
     
                        }

                    }
                    else if (lossType == 'Credit Card') {

                        if ((formArray.controls[i].get('unauthorisedMoneyLoss').value == '' || 
                        formArray.controls[i].get('unauthorisedMoneyLoss').value == null)) { 
                            return true;
     
                        }

                    }
                    else {

                        if ((formArray.controls[i].get('purchasePrice').value == '' || 
                        formArray.controls[i].get('purchasePrice').value == null)) { 
                            return true;
     
                        }

                    }
                }

        }
        return false;
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.lossDetailForm);
        let isMoneyFieldPopulated: boolean = this.validateMoneyFields();

        if(isFormValidOnChange && !isMoneyFieldPopulated){
            this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 1, subMenuIndex: 1});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewLostItemsDescription() {
        (<any>window).dataLayer.push({
            'pageStep': 'Lost Item – Item Details',
            'vPath': '/claim/lost-item/items',
            'event': 'vpageview',
        });
    }
}
