import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { DomesticEmployerLiability } from 'src/app/model/home/domestic-employer-liability.model';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import {SideMenuService} from "../../../services/side-menu.service";

@Component({
    selector: 'app-domestic-employer',
    templateUrl: './domestic-employer.component.html',
    styleUrls: ['./domestic-employer.component.css']
})
export class DomesticEmployerComponent extends ClaimsBaseComponent implements OnInit {

    domesticEmployerForm: FormGroup;
    domesticEmployerLiability: DomesticEmployerLiability;
    isDmhClaim = false;
    showTotalBox = false;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {
        super(injector);
        this.domesticEmployerLiability = this.claim.getClaimHome().getHomeDetails().getDomesticEmployerLiability();
        let currentClaimType = this.transactionInfoService.getTransactionInfo().getCurrentClaimType();
        if(([ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_DMH]].indexOf(currentClaimType) > -1)){
            this.isDmhClaim = true;
            this.domesticEmployerLiability.setWasHelperInjured(true);
        }
    }

    ngOnInit() {
        this.pushGADomesticEmployerView();

        this.domesticEmployerForm = this.fb.group({
            isHelperInjured: [super.getBooleanString(this.domesticEmployerLiability.getWasHelperInjured())],
            helperName: [this.domesticEmployerLiability.getHelperName()],
            helperHkidPassport: [this.domesticEmployerLiability.getHelperHkidPassport()],
            dmhDetails: [this.domesticEmployerLiability.getHelperDetails()]
        });
    }

    ngAfterViewInit() {
         this.domesticEmployerForm.valueChanges.subscribe(data => {
            if (this.domesticEmployerForm.get('isHelperInjured').value != null) {
                this.domesticEmployerLiability.setWasHelperInjured(this.domesticEmployerForm.get('isHelperInjured').value == 'true');
            }
            this.domesticEmployerLiability.setHelperName(this.domesticEmployerForm.get('helperName').value);
            this.domesticEmployerLiability.setHelperHkidPassport(this.domesticEmployerForm.get('helperHkidPassport').value);
            this.domesticEmployerLiability.setHelperDetails(this.domesticEmployerForm.get('dmhDetails').value);
        });

        let self = this;

        setTimeout(function () {self.hideIsHelperInjuredFields(self.getBooleanString(self.domesticEmployerLiability.getWasHelperInjured())),10});
        this.domesticEmployerForm.get('isHelperInjured').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideIsHelperInjuredFields(data);
        });
    }

    hideIsHelperInjuredFields(isHelperInjured) {
        if (isHelperInjured == "true") {
            this.domesticEmployerForm.get('helperName').enable({onlySelf: false, emitEvent: false});
            this.domesticEmployerForm.get('helperHkidPassport').enable({onlySelf: false, emitEvent: false});
            this.domesticEmployerForm.get('dmhDetails').enable({onlySelf: false, emitEvent: false});
        } else {
            this.domesticEmployerForm.get('helperName').reset();
            this.domesticEmployerForm.get('helperName').disable({onlySelf: false, emitEvent: false});
            this.domesticEmployerForm.get('helperHkidPassport').reset();
            this.domesticEmployerForm.get('helperHkidPassport').disable({onlySelf: false, emitEvent: false});
            this.domesticEmployerForm.get('dmhDetails').reset();
            this.domesticEmployerForm.get('dmhDetails').disable({onlySelf: false, emitEvent: false});
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.domesticEmployerForm);
    }

    goToNext() {
        if (this.validateForm()) {
            this.transactionInfoService.getDynamicRoutingService().goToNextRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
        }
    }

    back() {
        if (this.claim.getSelectedClaimTypesList().indexOf(ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_DMH]) !== -1) {
            this.injector.get(SideMenuService).emitProgress(-1);
        }
        this.transactionInfoService.getDynamicRoutingService().goToPreviousRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
    }

    pushGADomesticEmployerView() {
        let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep() + ' – Details of the Incident';
        let vPath = '/claim/' + this.transactionInfoService.getTransactionInfo().getGaVPath() + '/details';

        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': pageStep,
            'vPath': vPath,
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 4},	//Step4-SelectClaimType
                }
            }
        });
    }

    showModal() {
        console.log('showModel');
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }
}
