import {IntegrationRiskJson} from "./integration-risk-data";
import { InsuredPerson } from "./insured-person.model";
import { JsonProperty } from "../utilities/utils/json-property.decorator";
import { Claim } from "./claim.model";
import { TransactionInfo } from "./transaction-info.model";
export class PolicyAuthJson {

    private policyNumber: string;
    private integrationToken: string;
    private errorCode: string;
    private errorStr: string;
    private numberOfRisks: number;
    private productType: string;
    private country: string;
    private clientName: string;
    private clientMobile: string;
    private clientEmail: string;
    private clientType: string;
    private isManulife: boolean;
    private catalogueCode: string;
    private planCode: string;
    private addressLine1: string;
    private addressLine2: string;
    private addressLine3: string;
    private addressLine4: string;
    private inceptionDate: Date;
    private expiryDate: Date;
    private clientNumber: string;
    private staffCode: string;
    private agentCode: string;
    private agentName: string;
    private selectedRiskNumber: number;
    private isCoverNotePreNumbered: string = "";
    private documentCategory: string;
    private clientIdNumber: string;
    private serviceUnit: string;
    private integrationRiskList: IntegrationRiskJson[] = [];
    private isPolicyVerified: boolean = false;
    private matchedInsuredPerson: InsuredPerson = null;
    private action: string;
    private isGeneric: boolean;
    private docIdentifier: string;
    private agentEmail: string;
    private agentBranch: string;
    private productTypes: string[] = [];

    @JsonProperty({name: 'claim', clazz: Claim})
    private claim: Claim = new Claim();

    @JsonProperty({name: 'transactionInfo', clazz: TransactionInfo})
    private transactionInfo: TransactionInfo = new TransactionInfo();

    public getAgentName(): string {
        return this.agentName;
    }

    public setAgentName(value: string) {
        this.agentName = value;
    }

    public getPolicyNumber(): string {
        return this.policyNumber;
    }

    public setPolicyNumber(value: string) {
        this.policyNumber = value;
    }

    getIntegrationToken(): string {
        return this.integrationToken;
    }

    setIntegrationToken(value: string) {
        this.integrationToken = value;
    }

    getErrorCode(): string {
        return this.errorCode;
    }

    setErrorCode(value: string) {
        this.errorCode = value;
    }

    getErrorStr(): string {
        return this.errorStr;
    }

    setErrorStr(value: string) {
        this.errorStr = value;
    }

    public getCountry(): string {
        return this.country;
    }

    public setCountry(value: string) {
        this.country = value;
    }

    public getClientName(): string {
        return this.clientName;
    }

    public setClientName(value: string) {
        this.clientName = value;
    }

    getClientMobile(): string {
        return this.clientMobile;
    }

    setClientMobile(value: string) {
        this.clientMobile = value;
    }

    getClientEmail(): string {
        return this.clientEmail;
    }

    setClientEmail(value: string) {
        this.clientEmail = value;
    }

    getClientType(): string {
        return this.clientType;
    }

    setClientType(value: string) {
        this.clientType = value;
    }

    public getNumberOfRisks(): number {
        return this.numberOfRisks;
    }

    public setNumberOfRisks(value: number) {
        this.numberOfRisks = value;
    }

    public getProductType(): string {
        return this.productType;
    }

    public setProductType(value: string) {
        this.productType = value;
    }

    getIntegrationRiskList(): IntegrationRiskJson[] {
        return this.integrationRiskList;
    }

    setIntegrationRiskList(value: IntegrationRiskJson[]) {
        this.integrationRiskList = value;
    }

    getIsManulife(): boolean {
        return this.isManulife;
    }

    setIsManulife(value: boolean) {
        this.isManulife = value;
    }

    getCatalogueCode(): string {
        return this.catalogueCode;
    }

    setCatalogueCode(value: string) {
        this.catalogueCode = value;
    }

    getPlanCode(): string {
        return this.planCode;
    }

    setPlanCode(value: string) {
        this.planCode = value;
    }

    getAddressLine1(): string {
        return this.addressLine1;
    }

    setAddressLine1(value: string) {
        this.addressLine1 = value;
    }

    getAddressLine2(): string {
        return this.addressLine2;
    }

    setAddressLine2(value: string) {
        this.addressLine2 = value;
    }

    getAddressLine3(): string {
        return this.addressLine3;
    }

    setAddressLine3(value: string) {
        this.addressLine3 = value;
    }

    getAddressLine4(): string {
        return this.addressLine4;
    }

    setAddressLine4(value: string) {
        this.addressLine4 = value;
    }

    setExpiryDate(value: Date){
        this.expiryDate = value;
    }

    getExpiryDate(): Date {
        return this.expiryDate;
    }

    setInceptionDate(value: Date){
        this.inceptionDate = value;
    }

    getInceptionDate(): Date {
        return this.inceptionDate;
    }

    getClientNumber(): string {
        return this.clientNumber;
    }

    setClientNumber(value: string) {
        this.clientNumber = value;
    }

    getAgentCode(): string {
        return this.agentCode;
    }

    setAgentCode(value: string) {
        this.agentCode = value;
    }

    getStaffCode(): string {
        return this.staffCode;
    }

    setStaffCode(value: string) {
        this.staffCode = value;
    }

    getIsCoverNotePreNumbered(): string {
        return this.isCoverNotePreNumbered;
    }

    setIsCoverNotePreNumbered(value: string) {
        this.isCoverNotePreNumbered = value;
    }

    getSelectedRiskNumber(): number {
        return this.selectedRiskNumber;
    }

    setSelectedRiskNumber(value: number) {
        this.selectedRiskNumber = value;
    }

    getDocumentCategory(): string {
        return this.documentCategory;
    }

    setDocumentCategory(value: string) {
        this.documentCategory = value;
    }

    getClientIdNumber(): string {
        return this.clientIdNumber;
    }

    setClientIdNumber(value: string) {
        this.clientIdNumber = value;
    }

    getServiceUnit(): string {
        return this.serviceUnit;
    }

    setServiceUnit(value: string) {
        this.serviceUnit = value;
    }

    getIsPolicyVerified() {
		return this.isPolicyVerified;
	}

	setPolicyVerified(isPolicyVerified: boolean) {
		this.isPolicyVerified = isPolicyVerified;
    }
    
    getMatchedInsuredPerson() {
        return this.matchedInsuredPerson;
    }

    setMatchedInsuredPerson(matchedInsuredPerson: InsuredPerson) {
        this.matchedInsuredPerson = matchedInsuredPerson;
    }

    getAction() {
        return this.action;
    }

    setAction(value: string) {
        this.action = value;
    }

    getClaim(): Claim {
        return this.claim;
    }

    setClaim(value: Claim) {
        this.claim = value;
    }

    getTransactionInfo(): TransactionInfo {
        return this.transactionInfo;
    }

    setTransactionInfo(value: TransactionInfo) {
        this.transactionInfo = value;
    }

    getDocIdentifier() {
        return this.docIdentifier;
    }

    setDocIdentifier(value: string) {
        this.docIdentifier = value;
    }

    getIsGeneric() {
        return this.isGeneric;
    }

    setIsGeneric(isGeneric: boolean) {
        this.isGeneric = isGeneric;
    }

    getAgentEmail() {
        return this.agentEmail;
    }

    setAgentEmail(value: string) {
        this.agentEmail = value;
    }

    getProductTypes(): string[] {
        return this.productTypes;
    }

    setProductTypes(value: string[]) {
        this.productTypes = value;
    }

    getAgentBranch() {
        return this.agentBranch;
    }

    setAgentBranch(value: string) {
        this.agentBranch = value;
    }


    static jsonConvert(policyAuthJson: PolicyAuthJson): PolicyAuthJson {

         if (policyAuthJson != null) {

             if (policyAuthJson.getInceptionDate() != null) {
                 policyAuthJson.setInceptionDate(new Date(policyAuthJson.getInceptionDate()))
             }

             if (policyAuthJson.getExpiryDate() != null) {
                 policyAuthJson.setExpiryDate(new Date(policyAuthJson.getExpiryDate()));
             }

             if (policyAuthJson.getIntegrationRiskList()) {
                let integrationRiskJson: IntegrationRiskJson[] = [];
                for (const integrationRisk of policyAuthJson.getIntegrationRiskList()) {
                   let item = Object.assign(new IntegrationRiskJson(), integrationRisk);
                   item = IntegrationRiskJson.jsonConvert(item);
                    integrationRiskJson.push(item);
                }
                 policyAuthJson.setIntegrationRiskList(integrationRiskJson);
             }

             if (policyAuthJson.getMatchedInsuredPerson()) {
                 policyAuthJson.setMatchedInsuredPerson(Object.assign(new InsuredPerson(), policyAuthJson.getMatchedInsuredPerson()));
             }

          }

         return policyAuthJson;
    }
}
