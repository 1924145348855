import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SideMenuService {
    private emitCliamTypeId = new Subject<any>();
    changeCliamTypeId$ = this.emitCliamTypeId.asObservable();

    private emitConfirmPeriodOfTravel = new Subject<any>();
    changeConfirmTravelPeriod$ = this.emitConfirmPeriodOfTravel.asObservable();

    private emitConfirmVehicleDetail = new Subject<any>();
    changeConfirmVehicleDetail$ = this.emitConfirmVehicleDetail.asObservable();

    private emitConfirmIncidentDetails = new Subject<any>();
    changeConfirmIncidentDetails$ = this.emitConfirmIncidentDetails.asObservable();

    private emitSelectInsuredPerson = new Subject<any>();
    changeSelectInsuredPerson$ = this.emitSelectInsuredPerson.asObservable();

    private emitStepComplete = new Subject<any>();
    changeStepComplete$ = this.emitStepComplete.asObservable();

    private emitClaimDetailComplete = new Subject<any>();
    changeClaimDetailComplete$ = this.emitClaimDetailComplete.asObservable();

    private emitClaimDetailIncomplete = new Subject<any>();
    changeClaimDetailIncomplete$ = this.emitClaimDetailIncomplete.asObservable();

    //new
    private emitClaimTypeSubMenuHide = new Subject<any>();
    claimTypeSubMenuHide$ = this.emitClaimTypeSubMenuHide.asObservable();

     //new
     private claimTypeSubMenuShowByUrl = new Subject<any>();
     claimTypeSubMenuShowByUrl$ = this.claimTypeSubMenuShowByUrl.asObservable();

    private emitPctOfProcess = new Subject<any>();
    changePctOfProcessComplete$ = this.emitPctOfProcess.asObservable();

    private emitIsClaimDetailComplete = new Subject<any>();
    validateClaimDetailComplete$ = this.emitIsClaimDetailComplete.asObservable();

    private emitClaimMenuBuilt = new Subject<any>();
    claimMenuBuilt$ = this.emitClaimMenuBuilt.asObservable();

    private emitSelectClaim = new Subject<any>();
    changeSelectClaim = this.emitSelectClaim.asObservable();

    private stepNumber: number = 0;

    private totalStep: number = 0;
    constructor() {
    }

    getStepNumber(): number {
        return this.stepNumber;
    }
    
    setStepNumber(value: number) {
        this.stepNumber = value;
    }

    getTotalStep(): number {
        return this.totalStep;
    }
    
    setTotalStep(value: number) {
        this.totalStep = value;
    }

    emitClaimMenuDone(action: string) {
        this.emitClaimMenuBuilt.next(action);
    }

    emitCliamType(ClaimTypeId: any) {
        this.emitCliamTypeId.next(ClaimTypeId);
    }

    emitConfirmTravelPeriod(period: string) {
        this.emitConfirmPeriodOfTravel.next(period);
    }

    emitConfirmVehicleDetailsInfo(period: string) {
        this.emitConfirmVehicleDetail.next(period);
    }

    emitConfirmIncidentDetailsInfo(period: string) {
        this.emitConfirmIncidentDetails.next(period);
    }

    emitSelectInsured(name: string) {
        this.emitSelectInsuredPerson.next(name);
    }

    emitSelectClaimType(show: boolean) {
        this.emitSelectClaim.next(show);
    }

    emitComplete(stepName: string) {
        this.emitStepComplete.next(stepName);
    }

    emitClaimComplete(claimType: object) {
        this.emitClaimDetailComplete.next(claimType);
    }

    emitClaimIncomplete(claimType: object) {
        this.emitClaimDetailIncomplete.next(claimType);
    }

    emitClaimTypeSubMenuHideShow(claimType: object) {
        this.emitClaimTypeSubMenuHide.next(claimType);
    }

    emitClaimTypeSubMenuShowUsingUrl(routeData: object) {
        this.claimTypeSubMenuShowByUrl.next(routeData);
    }
    emitIsClaimComplete(claimType: object) {
        this.emitIsClaimDetailComplete.next(claimType);
    }

    emitProgress(data) {
        this.setStepNumber(this.getStepNumber() + data);
        const pctOfTotal = ( this.getStepNumber() / this.getTotalStep() * 100);
        this.emitPctOfProcess.next(pctOfTotal);
    }
}
