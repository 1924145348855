<div>
    <div class="prev-bar" *ngIf="transactionInfo.isTravelProduct()">
        <a (click)="back()" style="color: #0064c1" class="btn btn-secondary new-btn"><i class="fas fa-arrow-left"></i>&nbsp;{{'claimDetails.body.back' | translate}}&nbsp;</a>
    </div>
    <div class="col-12">
        <span *ngIf="transactionInfo.isTravelProduct() || transactionInfo.isAccidentProduct()">
        <h5>{{ 'pageSelectInsured.body.title' | translate}}</h5>
        <p *ngIf="transactionInfo.isTravelPersonal() || transactionInfo.isTravelCorporateInsuredPerson()">
            {{ 'pageSelectInsured.body.selectLabel' | translate}}</p>
        <p *ngIf="transactionInfo.isAccidentProduct() && !transactionInfo.isGroupAccidentProduct()">
            {{ 'pageSelectInsured.body.selectLabel' | translate}}</p>
        <p *ngIf="transactionInfo.isAccidentProduct() && transactionInfo.isGroupAccidentProduct()">
            {{ 'pageSelectInsured.body.selectLabelGroup' | translate}}</p>
       </span>
        <span *ngIf="transactionInfo.isDomesticHelperProduct()">
        <h5>{{ 'domesticHelperClaim.selectInsured.titleHelper' | translate}}</h5>
        <p>{{'domesticHelperClaim.selectInsured.title' | translate}}</p>
        </span>
        <qnect-notification-messages [notifications]="notifications"></qnect-notification-messages>
    </div>
    <div class="row add-claim-content insuredBox col-12">
        <ng-container *ngIf="transactionInfo.isDomesticHelperProduct() 
            || (transactionInfo.isTravelPersonal() || transactionInfo.isTravelCorporateInsuredPerson()) 
            || (transactionInfo.isAccidentProduct() && !transactionInfo.isGroupAccidentProduct())">
            <div class="col-lg-4 col-md-6 col-6" *ngFor="let person of insuredPersonList; let i = index"
                (click)="choosePerson(i, person)">
                <div class="cperson-wrapper text-center toggleActive" [class.active]="selectedPerson?.getInsuredName() === person.getInsuredName() && choosedPersonId === i">
                    <div class="cpreson-outter">
                        <h5 class="insuredNameWrap">{{person.getInsuredName()}}</h5>
                        <span *ngIf="transactionInfo.isTravelProduct() || transactionInfo.isDomesticHelperProduct()">{{person.getInsuredPersonNationalIdNumber() | formatHKID}}</span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="row">
        <div class="col-12" *ngIf="transactionInfo.isTravelPersonal() || (transactionInfo.isAccidentProduct() && !transactionInfo.isGroupAccidentProduct())">
            <div class="col-12">
                <a href="javascript:;" (click)="showContent()" class="click-more">{{ 'pageSelectInsured.body.searchPerson.desc1' | translate}}</a>
                <p class="searchPerson-desc" >{{ 'pageSelectInsured.body.searchPerson.desc2' | translate}}</p>
            </div>
            <div class="col-12" *ngIf="showAdditionalClaimantMessage">
                <small id="modalLink" (click)="open(includeMoreInsured,'includeMoreInsured')">{{'pageSelectInsured.body.includeMore.title' | translate}}</small>
                <BR><BR>
            </div>
        </div>
        <div class="col-12" *ngIf="transactionInfo.isDomesticHelperProduct() && insuredPersonList.length > 0">
            <div class="col-12">
                <a href="javascript:;" (click)="showContent()" class="click-more">{{ 'pageSelectInsured.body.searchPerson.desc1' | translate}}</a>
                <p class="searchPerson-desc">{{'domesticHelperClaim.selectInsured.otherHelperSection' | translate}}</p>
            </div>
        </div>
    </div>
    <div class="row col-12" [style.display]="showMoreContent ? 'block' : 'none'">
        <div [ngClass]="transactionInfo.isTravelCorporatePolicyHolder() ? 'col-lg-8 col-md-12' : 'col-lg-6 col-md-12'">
            <form class="add-claim-form" >
                <div *ngIf="transactionInfo.isTravelProduct() || transactionInfo.isAccidentProduct()">
                    <div class="form-group">
                        <qnect-ui #OTHER_INSURED_PERSON_NAME [fieldId]="'OTHER_INSURED_PERSON_NAME'" [formGroup]="otherInsuredPersonForm" name="otherPersonName"></qnect-ui>
                    </div>
                    <div class="form-group" *ngIf="transactionInfo.getRealCountry() === 'HKG'">
                        <qnect-ui #OTHER_INSURED_PERSON_HKID_PASSPORT [fieldId]="'OTHER_INSURED_PERSON_HKID_PASSPORT'" [formGroup]="otherInsuredPersonForm" name="otherPersonIdType"></qnect-ui>
                        <span *ngIf="this.otherIdType == 'hkid'"> <qnect-ui #OTHER_INSURED_PERSON_HKID_1 [fieldId]="'OTHER_INSURED_PERSON_HKID_1'" [formGroup]="otherInsuredPersonForm" [hkidValidator]="'hkid'" name="otherPersonHKID"></qnect-ui></span>
                        <span *ngIf="this.otherIdType == 'passport'"> <qnect-ui #OTHER_INSURED_PERSON_PASSPORT [fieldId]="'OTHER_INSURED_PERSON_PASSPORT'" [formGroup]="otherInsuredPersonForm" name="otherPersonHKID"></qnect-ui></span>
                    </div>
                    <div class="form-group" *ngIf="transactionInfo.getRealCountry() !== 'HKG'">
                        <qnect-ui #OTHER_INSURED_PERSON_HKID [fieldId]="'OTHER_INSURED_PERSON_HKID'" [formGroup]="otherInsuredPersonForm" name="otherPersonHKID"></qnect-ui>
                    </div>
                    <div class="form-group" (click)="scrollIntoView(insureddobsection)" >
                        <qnect-ui #OTHER_INSURED_PERSON_DOB [fieldId]="'OTHER_INSURED_PERSON_DOB'" [formGroup]="otherInsuredPersonForm" name="otherPersonDOB"></qnect-ui>
                    </div>
                </div>
                <div *ngIf="transactionInfo.isDomesticHelperProduct()">
                    <div class="form-group">
                        <qnect-ui #DMH_OTHER_HELPER_NAME [fieldId]="'DMH_OTHER_HELPER_NAME'" [formGroup]="otherInsuredPersonForm" name="otherPersonName"></qnect-ui>
                    </div>
                    <div class="form-group">
                        <qnect-ui #DMH_OTHER_HELPER_IDTYPE [fieldId]="'DMH_OTHER_HELPER_IDTYPE'" [formGroup]="otherInsuredPersonForm" name="otherPersonIdType"></qnect-ui>
                        <span *ngIf="this.otherIdType == 'hkid'"> <qnect-ui #DMH_OTHER_HELPER_HKID [fieldId]="'DMH_OTHER_HELPER_HKID'" [formGroup]="otherInsuredPersonForm" [hkidValidator]="'hkid'" name="otherPersonHKID"></qnect-ui></span>
                        <span *ngIf="this.otherIdType == 'passport'"> <qnect-ui #DMH_OTHER_HELPER_PASSPORT [fieldId]="'DMH_OTHER_HELPER_PASSPORT'" [formGroup]="otherInsuredPersonForm" name="otherPersonHKID"></qnect-ui></span>
                    </div>
                    <div class="form-group" (click)="scrollIntoView(insureddobsection)" >
                        <qnect-ui #DMH_OTHER_HELPER_DOB [fieldId]="'DMH_OTHER_HELPER_DOB'" [formGroup]="otherInsuredPersonForm" name="otherPersonDOB"
                                  [aboveMaxDateValidator]="this.claim.getDateOfLoss()"></qnect-ui>
                    </div>
                </div>

                <div class="form-group pad-top5" *ngIf="transactionInfo.isDomesticHelperProduct() ||  transactionInfo.isTravelPersonal() 
                    || (transactionInfo.isAccidentProduct() && !transactionInfo.isGroupAccidentProduct())">
                    <input type="button" (click)="addInsuredPerson()" class="btn btn-secondary new-btn add-claim-btn btn-save"
                        value="{{ 'pageSelectInsured.body.save' | translate }}">
                </div>
            </form>
        </div>
        <div class="col-lg-6 col-md-12">
            &nbsp;
        </div>
    </div>
    <div class="submit-bar" #insureddobsection>
        <abandon-button></abandon-button>
        <button (click)="goToNext()" class="new-btn btn btn-primary float-right">{{ 'pageSelectInsured.body.button' | translate}} <i class="fas fa-arrow-right"></i></button>
    </div>
</div>

<ng-template #selected_insured_change let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">{{'additionalClaimant.confirmDelete' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{'additionalClaimant.confirmDeleteDesc1' | translate }}</p>
        <p>{{'additionalClaimant.confirmDeleteDesc2' | translate }}</p>
    </div>
    <div class="modal-footer">
        <div class="col-md-6 text-right">
            <button type="button" class="btn btn-primary btn-sm" (click)="removeSelectedClaimTypes()">{{'sideMenu.deleteClaim.yes' | translate }}</button>
        </div>
        <div class="col-md-6 text-left">
            <button type="button" class="btn btn-default btn-sm" (click)="closeChangeInsuredModal()">{{'sideMenu.deleteClaim.no' | translate }}</button>
        </div>
    </div>
</ng-template>
<ng-template #multiClaimantInfo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">{{'additionalClaimant.confirmDelete' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{'additionalClaimant.confirmDeleteDesc1' | translate }}</p>
        <p>{{'additionalClaimant.confirmDeleteDesc2' | translate }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #includeMoreInsured let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title cursor-pointer">{{'pageSelectInsured.body.includeMore.title' | translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body modal-body-left">
        <p>{{'pageSelectInsured.body.includeMore.line1' | translate}}</p>
        <p><span [innerHtml]="'pageSelectInsured.body.includeMore.line2' | translate"></span></p>
        <p>{{'pageSelectInsured.body.includeMore.line3' | translate}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
  </ng-template>