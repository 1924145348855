import { Component, OnInit, TemplateRef, ViewChild  } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SideMenuService} from "../../../../../services/side-menu.service";
import { ClaimService } from '../../../../../services/claim.service';
import { ClaimDocumentsComponent } from '../../../../claim-documents/claim-documents.component';
import { DocumentHelperService } from '../../../../../services/document-helper.service';
import { TransactionInfoService } from '../../../../../services/transaction-info.service';
import { HttpClient } from '@angular/common/http';
import { DocumentFormFactoryService } from '../../../../../services/document-form-factory.service';
import { ClaimTypes } from '../../../../../model/claim-type.model';
import { SpinnerService } from '../../../../../core/spinner/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from "@angular/router";
import { DocumentField } from 'src/app/model/document-field';
import { Claim } from 'src/app/model/claim.model';
import {TranslateService} from "@ngx-translate/core";
import {UtilitiesService} from '../../../../../utilities/utilities.service';
declare var $: any;

@Component({
  selector: 'app-damage-upload-document',
  templateUrl: './damage-upload-document.component.html',
  styleUrls: ['./damage-upload-document.component.css']
})
export class DamageUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {

    public upLoadFileModel: FormGroup;
    showTotalBox = false;
    unuploadedDocs: string[][] = [];
    doc = "DOC";
    @ViewChild('alert_unuploaded_docs', {static: true}) docAlertModal: TemplateRef<any>;
    docAlertModalRef: any;
    requiredDocuments: DocumentField[];
    claim: Claim;
    listOfUnuploadedDocs: any;
    translateService: TranslateService;

    constructor(private fb: FormBuilder,
        public sideMenuService: SideMenuService,
        private activatedRoute: ActivatedRoute,
        private router: Router,  
        claimService: ClaimService,
        documentHelperService: DocumentHelperService,
        transactionInfoService: TransactionInfoService,
        private translate: TranslateService,
        http: HttpClient,
        documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
                super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
                this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]);

                this.claim = this.claimService.getClaim();
                this.translateService = this.translate;
    }

    ngOnInit() {
        this.pushGAViewDamageItemDocs();
        this.upLoadFileModel = this.fb.group(
            {
                airTicket: [],
                originalReceiptOfDamageItem: [],
                DamageReport: [],
                compensationBreakdown: [],
                photoShow: [],
                otherItems: this.fb.array([this.createItem()])

            });
            super.ngOnInit();
            //Modify required documents upload
            this.requiredDocuments = this.formBuilder.getRequiredDocuments();
    }

    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: [],
        });
    }
    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        this.listOfUnuploadedDocs = "";
        let stpDocIdList = this.requiredDocuments
            .reduce((result, { id, stpRequired }) => stpRequired == true ? result.push(id) && result : result, []);
        let stpAlertMessage = this.buildSTPAlertMessage(this.claimService.getClaim(), this.translate, stpDocIdList);

        if (!UtilitiesService.isEmpty(stpAlertMessage)) {
            this.showDocAlertModal();
        } else {
            this.sideMenuService.emitProgress(1);
            this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 3});
            this.router.navigate(["/claimform/moreClaimType"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    showDocAlertModal() {
        this.docAlertModalRef = this.modalService.open(this.docAlertModal, {size: 'lg',
             backdrop: 'static',
             keyboard: false
        });
    }

    continueToNext(){
        this.sideMenuService.emitProgress(1);
        this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 3});
        this.router.navigate(["/claimform/moreClaimType"], {
            relativeTo: this.activatedRoute
        });
        this.docAlertModalRef.close();
     }     

    //Google Analytics
    pushGAViewDamageItemDocs() {
        (<any>window).dataLayer.push({
            'pageStep': 'Damage Item - Document Upload',
            'vPath': '/claim/damage-item/documents',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 6},	//Step6-DocumentUpload
                }
            }
        });
    }
}
