import {Injectable, Injector} from "@angular/core";
import {Claim} from "../model/claim.model";
import {AutoSaveForm} from "../model/autosave-form.model";
import {Logger} from "../utilities/service/logger/logger";
import {TransactionInfoService} from "./transaction-info.service";
import {TransactionInfo} from "../model/transaction-info.model";
import {Observable, throwError, catchError, map} from "rxjs";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {SpinnerService} from "../core/spinner/spinner.service";
import { ErrorsService } from "../utilities/errors/errors.service";
import {finalize} from 'rxjs/operators';

@Injectable()
export class AutoSaveService {

    private autoSaveForm: AutoSaveForm = new AutoSaveForm();

    constructor(private logger: Logger,
                private http: HttpClient,
                private transactionInfoService: TransactionInfoService,
                private spinnerService: SpinnerService,
                private injector: Injector) {
    }

    public getAutoSaveForm(): AutoSaveForm {
        return this.autoSaveForm;
    }

    public setAutoSaveForm(autoSaveForm: AutoSaveForm) {
        this.autoSaveForm = autoSaveForm;
    }

    public setupAutoSaveForm(claim: Claim, transactionInfo: TransactionInfo) {
        this.getAutoSaveForm().setTransaction(transactionInfo.getTransactionType());
        this.getAutoSaveForm().setIdentifier(claim.getPolicyNumber());
        this.getAutoSaveForm().setPolicyNo(claim.getPolicyNumber());
        this.getAutoSaveForm().setProduct(claim.getProduct());
        this.getAutoSaveForm().setType('C');
        this.getAutoSaveForm().setTransactionInfo(transactionInfo);
        this.getAutoSaveForm().setClaim(claim);
        this.getAutoSaveForm().setSaveId(null);
    }

    claimAutoSave(claim: Claim): Observable<AutoSaveForm> {
        this.getAutoSaveForm().setClaim(claim);
        this.logger.log("claimAutoSave");

        let integrationToken = this.transactionInfoService.getTransactionInfo().getIntegrationToken();

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + integrationToken
            })
        };

        let autoSaveString : string = JSON.stringify(this.getAutoSaveForm());

        return this.http.post<AutoSaveForm>('/ms/claims-service/rest/autosave/save', autoSaveString, httpOptions)
            .pipe(map((autoSaveForm : AutoSaveForm) => {
                return autoSaveForm;
            }))
            //...errors if any
            .pipe(catchError<any, Observable<AutoSaveForm>>(this.handleError))
    }

    retrieveAutoSave(integrationToken: string): Observable<AutoSaveForm> {
        this.logger.log("retrieveAutoSave");

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + integrationToken
            })
        };
        let url = '/ms/claims-service/rest/autosave/efnol/get';
        
        return this.http.get<AutoSaveForm>(url, httpOptions)
            .pipe(map((autoSaveForm: AutoSaveForm) => {
                if (autoSaveForm != null) {
                    autoSaveForm = Object.assign(new AutoSaveForm, autoSaveForm);
                    // convert all the dates and objects coming back from json
                    let claim: Claim = Object.assign(new Claim(), autoSaveForm.getClaim());
                    autoSaveForm.setClaim(Claim.jsonConvertClaim(claim));
                }
                return autoSaveForm;
            }))
            //...errors if any
            .pipe(catchError<any, Observable<AutoSaveForm>>(this.handleError))
    }

    claimSaveAndSendMail(claim: Claim): Observable<boolean> {
        this.getAutoSaveForm().setClaim(claim);
        this.logger.log("claimAutoSave");

        let integrationToken = this.transactionInfoService.getTransactionInfo().getIntegrationToken();

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + integrationToken
            })
        };

        let autoSaveString : string = JSON.stringify(this.getAutoSaveForm());

        this.spinnerService.displayLoader(true);
        return this.http.post<boolean>('/ms/claims-service/rest/autosave/saveAndSendMail', autoSaveString, httpOptions)
            .pipe((map((res: boolean) => {
                this.spinnerService.displayLoader(false);
                return res;
            })))
            .pipe(catchError<any,Observable<boolean>>( this.handleError))
            .pipe(finalize( () => {this.spinnerService.displayLoader(false)}));
    }    

     private handleError(error: HttpResponse<any> | any) {
        // In a real world app, you might use a remote logging infrastructure
        const errorsService = this.injector.get(ErrorsService); 
        let errMsg: string;
        if (error instanceof HttpResponse) {
            errMsg = `${error.status} - ${error.statusText || ''}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        errMsg = 'Autosave Service Error handleError' + errMsg;
        errorsService.log(errMsg);
        return throwError(() => new Error(errMsg));
    }
}
