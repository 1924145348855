import { Component, OnInit, Injector } from '@angular/core';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ClaimMotor } from 'src/app/model/claim-motor.model';
import { PoliceReport } from 'src/app/model/motor/police-report.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-police-report',
  templateUrl: './police-report.component.html',
  styleUrls: ['./police-report.component.css']
})
export class MAPoliceReportComponent extends ClaimsBaseComponent  implements OnInit {

  policeReportForm: FormGroup;
  claimMotor: ClaimMotor;
  policeReport: PoliceReport;
  showTotalBox = false;

  constructor(private fb: FormBuilder,
              private transactionInfoService: TransactionInfoService,
              private claimService: ClaimService,
              private sideMenuService: SideMenuService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private injector : Injector) {
      super(injector);
      this.claim = this.claimService.getClaim();
      this.claimMotor = this.claim.getClaimMotor();
      this.policeReport = this.claimMotor.getPoliceReport();
  }

  ngOnInit() {

      this.pushGA();

      this.policeReportForm = this.fb.group({
        hasReportedIncidentToPolice: [super.getBooleanString(this.policeReport.getHasReportedIncidentToPolice())],
        dateOfReport: [this.policeReport.getDateOfReport()],
        policeReportNumber: [this.policeReport.getPoliceReportNumber()],
        policeStation: [this.policeReport.getPoliceStation()],
        hasComplainedAgainstOtherParty: [super.getBooleanString(this.policeReport.getHasComplainedAgainstOtherParty())]

      });
  }

  ngAfterViewInit() {

      this.policeReportForm.valueChanges.subscribe(data => {
        if(this.policeReportForm.get('hasReportedIncidentToPolice').value != null) {
          this.policeReport.setHasReportedIncidentToPolice(this.policeReportForm.get('hasReportedIncidentToPolice').value == 'true')
        }
        this.policeReport.setDateOfReport(super.getValueInComponent('dateOfReport'));
        this.policeReport.setPoliceReportNumber(this.policeReportForm.get('policeReportNumber').value);
        this.policeReport.setPoliceStation(this.policeReportForm.get('policeStation').value);
        if(this.policeReportForm.get('hasComplainedAgainstOtherParty').value != null) {
          this.policeReport.setHasComplainedAgainstOtherParty(this.policeReportForm.get('hasComplainedAgainstOtherParty').value == 'true')
        }
       });

       // Stop validation of fields when not required.
       let self = this;
       setTimeout(function () {self.hideFormFields(self.getBooleanString(self.policeReport.getHasReportedIncidentToPolice())),10});
       this.policeReportForm.get('hasReportedIncidentToPolice').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.hideFormFields(data);

      });
    }

   hideFormFields(showFormFields) {
       if (String(showFormFields) == 'true') {
           this.policeReportForm.get('dateOfReport').enable({onlySelf: false, emitEvent: false});
           this.policeReportForm.get('policeReportNumber').enable({onlySelf: false, emitEvent: false});
           this.policeReportForm.get('policeStation').enable({onlySelf: false, emitEvent: false});
           this.policeReportForm.get('hasComplainedAgainstOtherParty').enable({onlySelf: false, emitEvent: false});
       } else {
          this.policeReportForm.get('dateOfReport').reset();
          this.policeReportForm.get('policeReportNumber').reset();
          this.policeReportForm.get('policeStation').reset();
          this.policeReportForm.get('hasComplainedAgainstOtherParty').reset();
          this.policeReportForm.get('dateOfReport').disable({onlySelf: false, emitEvent: false});
          this.policeReportForm.get('policeReportNumber').disable({onlySelf: false, emitEvent: false});
          this.policeReportForm.get('policeStation').disable({onlySelf: false, emitEvent: false});
          this.policeReportForm.get('hasComplainedAgainstOtherParty').disable({onlySelf: false, emitEvent: false});
       }
   }

  back() {
      this.router.navigate(["/claimform/collission/thirdPartyDetails"], {
          relativeTo: this.activatedRoute
      });
  }

  goToNext() {
    if (this.validateForm()) {
      this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 4});
      this.router.navigate(["/claimform/collission/supportDoc"], {
          relativeTo: this.activatedRoute
      });
  }  
  }

  validateForm(): boolean {
      return super.validateForm(this.policeReportForm);
  }

  showModal() {
      this.showTotalBox = true;
  }

  getData(msg) {
      this.showTotalBox = msg;
  }

    // Google Analytics
    pushGA() {
        (<any>window).dataLayer.push({
            'pageStep': 'Motor Accident/Damage – Policy Report Details',
            'vPath': '/claim/motor-accident-damage/police-report-details',
            'event': 'vpageview',
        });
    }


}
