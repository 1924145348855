import {Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {ClaimDocumentsComponent} from "../../../claim-documents/claim-documents.component";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {DocumentHelperService} from "../../../../services/document-helper.service";
import {TransactionInfoService} from "../../../../services/transaction-info.service";
import {DocumentFormFactoryService} from "../../../../services/document-form-factory.service";
import {SpinnerService} from "../../../../core/spinner/spinner.service";
import {ClaimTypes} from "../../../../model/claim-type.model";
import {HttpClient} from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { DocumentField } from "src/app/model/document-field";
import { Claim } from "src/app/model/claim.model";
import { ActivatedRoute, Router } from "@angular/router";

declare var $: any;

@Component({
  selector: 'app-hospitalization-helper-upload-document',
  templateUrl: './hospitalization-helper-upload-document.component.html',
  styleUrls: ['./hospitalization-helper-upload-document.component.css']
})

export class HospitalizationHelperUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {

    public upLoadFileModel: FormGroup;
    showTotalBox = false;
    unuploadedDocs: string[][] = [];
    doc = "DOC";
    isDocUploadComplete: Boolean = false;
    popupAppeared = false;
    @ViewChild('alert_unuploaded_docs', {static: true}) docAlertModal: TemplateRef<any>;
    docAlertModalRef: any;
    requiredDocuments: DocumentField[];
    claim: Claim;
    listOfUnuploadedDocs: any;
    translateService: TranslateService;
    
    constructor(private fb: FormBuilder,
        public sideMenuService: SideMenuService, 
        claimService: ClaimService,
        private activatedRoute: ActivatedRoute,
        private router: Router,  
        documentHelperService: DocumentHelperService,
        transactionInfoService: TransactionInfoService,
        http: HttpClient,
        private translate: TranslateService,
        documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
                super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
                this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HOSPITAL]); 
                this.claim = this.claimService.getClaim();
                this.translateService = this.translate;
    }

    ngOnInit() {

        this.pushGAViewHospitalizationHelperUpload();

        this.upLoadFileModel = this.fb.group(
            {
                airTicket: [],
                carrierCertificate: [],
                otherItems: this.fb.array([this.createItem()])

            });
            super.ngOnInit();
            this.requiredDocuments = this.formBuilder.getRequiredDocuments();
    }

    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: [],
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        this.listOfUnuploadedDocs = ""; 
        this.verifyUploadedDocs();  
        if(this.isDocUploadComplete || this.popupAppeared){
            this.sideMenuService.emitProgress(1);
            this.sideMenuService.emitClaimComplete({claimTypeId: 3, subMenuIndex: 1});
            this.router.navigate(["/claimform/anotherInsurance"], {
                relativeTo: this.activatedRoute
            });
        }else{
            this.showDocAlertModal();
        }
        
    }

    verifyUploadedDocs(){

        let uploadedDocumentsChecker = this.claim.getDocumentForm().documents.filter(uploadedDoc => uploadedDoc.docClaimType == "CLAIM_TYPE_DMH_HOSPITAL" 
        && (["HOSPITALFORM", "PRIVATEHOSPITALFORM"].indexOf(uploadedDoc.docSubTypeId) > -1));

        this.isDocUploadComplete = uploadedDocumentsChecker != null && uploadedDocumentsChecker.length > 0;

        if(this.isDocUploadComplete){
            const documentsDetails = this.requiredDocuments;
            let uploadedDocuments = this.claim.getDocumentForm().documents;
            let previousClaimDoc: string = 'none';
            // we check all required document if uploaded
            for (let documentsDetailsValue of documentsDetails) {
                //check if there are already documents uploaded
                let docUploaded: boolean = false;
                if(uploadedDocuments.length < 1){
                    if(documentsDetailsValue.id == 'PRIVATEHOSPITALFORM'){
                        this.unuploadedDocs.push([this.doc, documentsDetailsValue.description]);
                    }
                }else{
                    for (let uploadedDoc of uploadedDocuments) {
                        //if required documents uploaded we skip then proceed to check the other details
                        if(uploadedDoc.docSubTypeId.startsWith(documentsDetailsValue.id) && uploadedDoc.docClaimType == this.getCurrentClaimType() && uploadedDoc.docSubTypeId == 'PRIVATEHOSPITALFORM'){
                            docUploaded = true;
                            previousClaimDoc = documentsDetailsValue.id;
                            break;  
                        }
                        
                    }
                    if(docUploaded == false && documentsDetailsValue.id == 'PRIVATEHOSPITALFORM'){
                        if(previousClaimDoc != documentsDetailsValue.id){
                            this.unuploadedDocs.push([this.doc, documentsDetailsValue.description]);
                            previousClaimDoc = documentsDetailsValue.id;
                        }
                        
                    }
                }
            }
            // we change isDocuploadComplete to true if all documents are uploaded
            if(this.unuploadedDocs.length < 1 && uploadedDocuments.length > 0){
                this.isDocUploadComplete = true;
            }
            // set unuploaded documents to display in alert message
            if(this.unuploadedDocs.length >= 1){

                let totalLength = this.unuploadedDocs.length;
                let maxSize = this.unuploadedDocs.length -1;
                let self = this;
                let currentLanguage = this.translate.currentLang;
                this.unuploadedDocs.forEach(function (description, index) {
                    self.translate.stream(description[1]).subscribe((res:string) => {
                        console.log("res:"+res)
                        if(self.listOfUnuploadedDocs == null || self.listOfUnuploadedDocs == "" || self.listOfUnuploadedDocs === undefined){
                            if(index > 0 || totalLength > 1){
                                self.listOfUnuploadedDocs = res + ", ";
                            }else{
                                if(currentLanguage == 'zh' || currentLanguage == 'cn'){
                                    self.listOfUnuploadedDocs = res + "。";
                                }else{
                                    self.listOfUnuploadedDocs = res + ".";
                                }                           
                            }
                        }else if(index == maxSize){
                            if(currentLanguage == 'zh' || currentLanguage == 'cn'){
                                self.listOfUnuploadedDocs += (res + "。"); 
                            }else{
                                self.listOfUnuploadedDocs += (res + "."); 
                            }                         
                        }else{
                            self.listOfUnuploadedDocs += (res + ", ");  
                        } 
                    });

                });
            }

            this.unuploadedDocs = [];   
        }   

    }

    showDocAlertModal() {
        // $('.menu-btn').toggleClass('opened');
        // $('.container-left').toggleClass('opened');
         //this.selectedClaimTypeNumber = claimTypeNumber;
         this.docAlertModalRef = this.modalService.open(this.docAlertModal, {size: 'lg',
             backdrop: 'static',
             keyboard: false
         });
 
     }

     continueToNext(){
        this.popupAppeared = true;
        this.docAlertModalRef.close();
     }     


    //Google Analytics
    pushGAViewHospitalizationHelperUpload() {
        (<any>window).dataLayer.push({
            'pageStep': 'Hospitalization of Helper – Document Upload',
            'vPath': '/claim/hospitalization-of-helper/documents',
            'event': 'vpageview',
            'ecommerce': {
              'checkout': {
                  'actionField': {'step': 6},	//Step6-DocumentUpload
              }
            }                                                     
        });
    }

}
