import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../services/side-menu.service";
import {ClaimService} from "../../../services/claim.service";
import {Claim} from "../../../model/claim.model";
import {ClaimsBaseComponent} from "../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import { TransactionInfo } from "src/app/model/transaction-info.model";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import {UtilitiesService} from "../../../utilities/utilities.service";
import { UiOptionsModel } from "src/app/ui/ui-options.model";
import { OverseasBankPayment } from "src/app/model/overseas-bank-payment.model";

@Component({
    selector: 'app-pay-overseas-bank',
    templateUrl: './pay-overseas-bank.component.html',
    styleUrls: ['./pay-overseas-bank.component.css']
})
export class PayOverseasBankComponent extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    payOverseasBankForm: FormGroup;
    insuredPerson: string;
    claim: Claim;
    isMotorLodgedBy: string = '';
    transactionInfo: TransactionInfo;
    accountNameDirectList: UiOptionsModel[] = [];
    overseasBankPayment: OverseasBankPayment;
    country: string;

    constructor(private fb: FormBuilder,
                private claimService: ClaimService,
                public sideMenuService: SideMenuService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private transactionInfoService: TransactionInfoService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.transactionInfo  = this.transactionInfoService.getTransactionInfo();
        this.claim.setPaymentRefundToBank("O");
        this.overseasBankPayment = this.claim.getOverseasBankPayment();
        this.country = this.claimService.getClaim().getCountry();
    }

    ngOnInit() {
        this.pushGAView();
        this.populateAccountNameList();

        this.payOverseasBankForm = this.fb.group(
            {
                beneficiaryName: [UtilitiesService.truncateValue(this.overseasBankPayment.getBeneficiaryName(), 80)],
                beneficiaryBankName: [this.overseasBankPayment.getBeneficiaryBankName()],
                beneficiaryBankAddress: [this.overseasBankPayment.getBeneficiaryBankAddress()],
                swiftCode: [this.overseasBankPayment.getSwiftCode()],
                beneficiaryAccountNumber: [this.overseasBankPayment.getBeneficiaryAccountNumber()],
                beneficiaryBankCode: [this.overseasBankPayment.getBeneficiaryBankCode()],
                beneficiaryBranchCode: [this.overseasBankPayment.getBeneficiaryBranchCode()],
                intermediaryAccountholderName: [UtilitiesService.truncateValue(this.overseasBankPayment.getIntermediaryAccountholderName(), 80)],
                intermediaryBankName: [this.overseasBankPayment.getIntermediaryBankName()],
                intermediaryBankAddress: [this.overseasBankPayment.getIntermediaryBankAddress()],
                intermediarySwiftCode: [this.overseasBankPayment.getIntermediarySwiftCode()],
                intermediaryBankAccountNumber: [this.overseasBankPayment.getIntermediaryBankAccountNumber()]
            }
        );
        
    }

    ngAfterViewInit() {

        this.payOverseasBankForm.valueChanges.subscribe(data => {
            this.overseasBankPayment.setBeneficiaryName(super.getValueInComponent('beneficiaryName'));
            this.overseasBankPayment.setBeneficiaryBankName(super.getValueInComponent('beneficiaryBankName'));
            this.overseasBankPayment.setBeneficiaryBankAddress(super.getValueInComponent('beneficiaryBankAddress'));
            this.overseasBankPayment.setSwiftCode(super.getValueInComponent('swiftCode'));
            this.overseasBankPayment.setBeneficiaryAccountNumber(super.getValueInComponent('beneficiaryAccountNumber'));
            this.overseasBankPayment.setBeneficiaryBankCode(super.getValueInComponent('beneficiaryBankCode'))
            this.overseasBankPayment.setBeneficiaryBranchCode(super.getValueInComponent('beneficiaryBranchCode'));
            this.overseasBankPayment.setIntermediaryAccountholderName(super.getValueInComponent('intermediaryAccountholderName'));
            this.overseasBankPayment.setIntermediaryBankName(super.getValueInComponent('intermediaryBankName'));
            this.overseasBankPayment.setIntermediaryBankAddress(super.getValueInComponent('intermediaryBankAddress'));
            this.overseasBankPayment.setIntermediarySwiftCode(super.getValueInComponent('intermediarySwiftCode'));
            this.overseasBankPayment.setIntermediaryBankAccountNumber(super.getValueInComponent('intermediaryBankAccountNumber'));
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    back() {
       if(this.transactionInfo.isMotorProduct() && this.transactionInfo.getCurrentClaimType() == 'CLAIM_TYPE_MVA_WINDSCREEN'){
            this.sideMenuService.emitProgress(-1);
            this.router.navigate(["/claimform/windscreen/supportDoc"], {
                relativeTo: this.activatedRoute
            });
        } else {
            this.router.navigate(["/claimform/anotherInsurance"], {
                relativeTo: this.activatedRoute
            });
        }

    }

    applyP400Format(event: any, fieldName: string) {
        let newStr = UtilitiesService.p400Format(event.target.value);
        event.target.value = UtilitiesService.p400Format(event.target.value);

        if(fieldName == 'beneficiaryName'){
            this.overseasBankPayment.setBeneficiaryName(newStr);
        } else if (fieldName == 'intermediaryAccountholderName') {
            this.overseasBankPayment.setIntermediaryAccountholderName(newStr);
        }
        this.payOverseasBankForm.get(fieldName).setValue(newStr);
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 2});

            this.router.navigate(["/claimform/paymentAndContact/provideContactInfo"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.payOverseasBankForm);
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToBank() {
        this.resetOverseasBankPayment();
        this.pushGASwitchBank();
        this.router.navigate(["/claimform/paymentAndContact/bankAccount"], {
            relativeTo: this.activatedRoute
        });
    }

    //START - Google Analytics
    pushGAView() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Payment - Overseas Bank Account Details',
            'vPath': '/payment-overseas-bank-account-details',
            'event': 'vpageview'
        });
    }

    pushGASwitchBank() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({'event': 'switchtobank'});
    }

    //END - Google Analytics

    populateAccountNameList() {

        if(this.transactionInfo.isMotorProduct()) {
            if(this.claim.getClaimMotor().getIsLodgeByPolicyHolder()){
                let policyholder = UtilitiesService.truncateValue(this.claim.getPolicyHolderName(), 80);
                this.overseasBankPayment.setBeneficiaryName(policyholder);
                this.overseasBankPayment.setIntermediaryAccountholderName(policyholder);
                this.isMotorLodgedBy = "customer";
            } else {
                let repairerName = UtilitiesService.truncateValue(this.claim.getClaimMotor().getRepairerName(), 80);
                this.overseasBankPayment.setBeneficiaryName(repairerName);
                this.overseasBankPayment.setIntermediaryAccountholderName(repairerName);
                this.isMotorLodgedBy = "repairer";
            }
        } else if (this.transactionInfo.isDomesticHelperProduct() || this.transactionInfo.isTravelProduct()) {
            let policyholder = UtilitiesService.truncateValue(this.claim.getPolicyHolderName(), 80);
            const policyHolderOption = new UiOptionsModel(policyholder, policyholder);
            if (this.transactionInfo.isDomesticHelperProduct() || this.transactionInfo.isTravelPersonal()) {
                this.accountNameDirectList.push(policyHolderOption);
            }

            if (this.transactionInfo.isTravelProduct()) {
                if (this.transactionInfo.isTravelCorporatePolicyHolder()) {
                    this.accountNameDirectList.push(policyHolderOption);
                } else {
                    let storedInsuredPersonLists = this.transactionInfo.getInsuredPersonsList();
                    if (!UtilitiesService.isNullOrUndefined(storedInsuredPersonLists)  && storedInsuredPersonLists.length > 0) {
                        for (var i = 0; i < storedInsuredPersonLists.length; i++) {
                            let insuredPerson = storedInsuredPersonLists[i].getInsuredName();
                            if (storedInsuredPersonLists.find(a => a.valueOf() === insuredPerson) == undefined) {
                                insuredPerson =  UtilitiesService.truncateValue(insuredPerson, 80);
                                const insuredPersonOption = new UiOptionsModel(insuredPerson, insuredPerson);
                                this.accountNameDirectList.push(insuredPersonOption);
                            }
                        }
                    }
                }
            }  
        }        
    }

    showIntermediary(showFields: boolean) {
        this.transactionInfo.setHasOverseasBankIntermediary(showFields);
    }

    resetOverseasBankPayment(){
        let overseasBankPayment = new OverseasBankPayment();
        overseasBankPayment.setBeneficiaryName(this.claim.getPolicyHolderName());
        overseasBankPayment.setIntermediaryAccountholderName(this.claim.getPolicyHolderName());
        this.claim.setOverseasBankPayment(overseasBankPayment);
        this.transactionInfo.setHasOverseasBankIntermediary(false);
    }

}
