import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {UtilitiesService} from "../../../utilities/utilities.service";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import {ClaimTypes} from "../../../model/claim-type.model";

export class ClinicalExpenseFormBuilder extends DocumentFormBuilderComponent implements OnInit {

    constructor(claim: Claim){
        super(claim);
       this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_CLINICAL];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('MEDICALBILL', 'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.subSupportingDocs.medicalBill', SmartqDocuments.DMH_HOSPITALBILLS, true), true));

        if (this.claim.getClaimDomesticHelper().getIsNewArrangement() &&
            ['SC', 'PT'].indexOf(this.claim.getClaimDomesticHelper().getClinicalExpenses()?.getTypeOfClinicalExpenses()) !== -1) {
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('REFLETTER', 'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.subSupportingDocs.refLetter', SmartqDocuments.DMH_MEDICAL, true), true));
        }

        this.requiredDocuments = requiredDocuments;
    }
}
