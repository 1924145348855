import {Component, Injector, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import {takeUntil} from 'rxjs/operators';
import {OutpatientTreatment} from '../../../model/employee-compensation/outpatient-treatment.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';
import { DateService } from 'src/app/ui/ui-datepicker/date.service';

@Component({
    selector: 'app-outpatient-treatment',
    templateUrl: './outpatient-treatment.component.html'
})
export class OutpatientTreatmentComponent extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    treatmentForm: FormGroup;
    outpatientTreatments: OutpatientTreatment[] = [];
    incidentDate: Date;

    constructor(private fb: FormBuilder,
                private injector: Injector,
                private dateService: DateService) {

        super(injector);
        this.incidentDate = this.claim.getClaimEmployeeCompensation().getAccidentDetails().getAccidentDate();
    }

    ngOnInit() {
        this.outpatientTreatments = this.claim.getClaimEmployeeCompensation().getOutpatientTreatments();
        this.treatmentForm = this.fb.group({
            items: this.fb.array(this.buildVisitDetails(this.outpatientTreatments))
        });

        this.initializeArrays();
    }

    ngAfterViewInit() {
        this.treatmentForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data) => {
                this.outpatientTreatments = [];

                for (let item of data.items) {

                    let visitDetail: OutpatientTreatment = new OutpatientTreatment();
                    visitDetail.setTreatmentDate(this.dateService.stringToDate(item.treatmentDate, 'dd/MM/yyyy'));
                    visitDetail.setMedicalExpense(item.medicalExpense);
                    visitDetail.setIsUnderChineseMedPractitioner(item.isUnderChineseMedPractitioner == 'true');
                    this.outpatientTreatments.push(visitDetail);
                }
                this.claim.getClaimEmployeeCompensation().setOutpatientTreatments(this.outpatientTreatments);
            });
    }

    initializeArrays() {
        if (UtilitiesService.isNullOrUndefined(this.outpatientTreatments)) {
            this.outpatientTreatments = [];
            this.claim.getClaimEmployeeCompensation().setOutpatientTreatments(this.outpatientTreatments);
        }

        if (this.outpatientTreatments.length === 0) {
            this.outpatientTreatments.push(new OutpatientTreatment());
        }
    }

    buildVisitDetails(outpatientTreatments: OutpatientTreatment[]): FormGroup[] {
        let formGroupArray: FormGroup[] = [];

        if (outpatientTreatments !== null && outpatientTreatments.length > 0) {
            for (let outpatientTreatment of outpatientTreatments) {
                let rowFormGroup = this.fb.group({
                    treatmentDate: [outpatientTreatment.getTreatmentDate()],
                    medicalExpense: [outpatientTreatment.getMedicalExpense()],
                    isUnderChineseMedPractitioner: [super.getBooleanString(outpatientTreatment.getIsUnderChineseMedPractitioner())]
                });
                formGroupArray.push(rowFormGroup);
            }
        } else {
            formGroupArray.push(this.createDefaultVisitDetails());
        }

        return formGroupArray;
    }

    createDefaultVisitDetails(): FormGroup {
        return this.fb.group({
            treatmentDate: null,
            medicalExpense: '',
            isUnderChineseMedPractitioner: null
        });
    }

    addVisit(): void {
        let self = this;
        setTimeout(function () {
            let visits = self.treatmentForm.get('items') as FormArray;
            if (visits.length < 5) {
                visits.push(self.createDefaultVisitDetails());
            }
        }, 10);
    }

    removeVisit(i) {
        let visits = this.treatmentForm.get('items') as FormArray;
        visits.removeAt(i);
    }

    validateForm(): boolean {
        return super.validateForm(this.treatmentForm);
    }

}
