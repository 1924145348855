import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { ClaimPersonalAccident } from 'src/app/model/claim-personal-accident.model';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { takeUntil } from 'rxjs/operators';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { HospitalExpenseDetails } from 'src/app/model/personal-accident/hospital-expense-details.model';
import { ClinicalExpenseDetails } from 'src/app/model/personal-accident/clinical-expense-details.model';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { HospitalExpenseHelperService } from 'src/app/services/hospital-expense-helper.service';
import { UtilitiesService } from 'src/app/utilities/utilities.service';
import { DateService } from 'src/app/ui/ui-datepicker/date.service';

declare var $: any;

@Component({
    selector: 'app-hospital-expenses',
    templateUrl: './hospital-expenses.component.html',
    styleUrls: ['./hospital-expenses.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class HospitalExpensesComponent extends ClaimsBaseComponent  implements OnInit {

    showTotalBox = false;
    ameForm: FormGroup;
    hospitalExpenseForm: FormGroup;
    clinicalExpenseForm: FormGroup;
    transactionInfo: TransactionInfo;
    claimPA: ClaimPersonalAccident;
    currency: string;
    hospitalExpenseItems: HospitalExpenseDetails[];
    clinicalExpenseItems: ClinicalExpenseDetails[];
    hospitalExpenseHelperService: HospitalExpenseHelperService;
    hospitalCashFound: boolean = false;
    
    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private sideMenuService: SideMenuService,
                private dateService: DateService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimPA = this.claim.getClaimPersonalAccident();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
        this.hospitalExpenseHelperService = this.injector.get(HospitalExpenseHelperService)
    }

    ngOnInit() {

        this.pushGAPageView();
        this.checkForHospitalCash();
        this.currency = UtilitiesService.getCountryCurrency(this.claim.getCountry());
        
        this.ameForm = this.fb.group({
            wasAdmitted: [super.getBooleanString(this.claimPA.getAccidentalMedicalExpenses().getWasAdmitted())]
        });

        this.hospitalExpenseItems = this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getHospitalExpenses();
        this.hospitalExpenseForm = this.fb.group({
            items: this.fb.array(this.buildHospitalExpenseItems(this.hospitalExpenseItems))
        });

        this.clinicalExpenseItems = this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getClinicalExpenses();
        this.clinicalExpenseForm = this.fb.group({
            fullyRecovered: [super.getBooleanString(this.claimPA.getAccidentalMedicalExpenses().getFullyRecovered())],
            treatment: [this.claimPA.getAccidentalMedicalExpenses().getTreatment()],
            items: this.fb.array(this.buildClinicalExpenseItems(this.clinicalExpenseItems))
        });

        setTimeout(() => {
            this.showSvgPath();
        }, 10);
    }

    ngAfterViewInit() {


        this.ameForm.valueChanges.subscribe(data => {
            if (this.ameForm.get('wasAdmitted').value !== null) {
                this.claimPA.getAccidentalMedicalExpenses().setWasAdmitted(this.ameForm.get('wasAdmitted').value == 'true');
            }
        });

        this.hospitalExpenseForm.valueChanges.subscribe(data => {

            let hospitalExpenses: HospitalExpenseDetails[] = [];

            for (let item of data.items) {

                let itemDetail : HospitalExpenseDetails = new HospitalExpenseDetails();

                itemDetail.setHospitalName(item.hospitalName)
                itemDetail.setDateAdmission(DateService.convertStringToDatetime(item.dateAdmission, 'dd/MM/yyyy HH:mm'));
                itemDetail.setDateDischarge(DateService.convertStringToDatetime(item.dateDischarge, 'dd/MM/yyyy HH:mm'));
                
                itemDetail.setHospitalExpenseCurrency(item.hospitalExpenseCurrency);
                itemDetail.setHospitalExpense(item.hospitalExpense);
            
                hospitalExpenses.push(itemDetail);
            }

            this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().setHospitalExpenses(hospitalExpenses);
        });

        this.clinicalExpenseForm.valueChanges.subscribe(data => {

            this.clinicalExpenseItems = [];

            data.items.forEach((details, index) => {
                let itemDetail : ClinicalExpenseDetails = new ClinicalExpenseDetails();

                itemDetail.setDateVisit(this.dateService.stringToDate(details.dateVisit, 'dd/MM/yyyy'));
                itemDetail.setMedicalAttention(details.medicalAttention);
                itemDetail.setMedicalAttentionDesc(super.getIndexedTextInComponent('medicalAttention', index));
                itemDetail.setMedicalAttentionOthers(details.medicalAttentionOthers);
                itemDetail.setClinicalExpense(details.clinicalExpense);
                itemDetail.setClinicalExpenseCurrency(details.clinicalExpenseCurrency);
                this.clinicalExpenseItems.push(itemDetail);
            });

            this.claimPA.getAccidentalMedicalExpenses().setClinicalExpenses(this.clinicalExpenseItems);

            if (this.clinicalExpenseForm.get('fullyRecovered').value !== null) {
                this.claimPA.getAccidentalMedicalExpenses().setFullyRecovered(this.clinicalExpenseForm.get('fullyRecovered').value == 'true');
            }
            this.claimPA.getAccidentalMedicalExpenses().setTreatment(super.getValueInComponent('treatment'));
        });

        let self = this;

       // Stop validation of fields when not required.
       setTimeout(() => {self.hideFields(self.getBooleanString(self.claimPA.getAccidentalMedicalExpenses().getWasAdmitted()))},10);
       this.ameForm.get('wasAdmitted').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.hideFields(data);
       });

       setTimeout(() => {self.hideTreatmentField(self.getBooleanString(self.claimPA.getAccidentalMedicalExpenses().getFullyRecovered()))},10);
       this.clinicalExpenseForm.get('fullyRecovered').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.hideTreatmentField(data);
       });

        let formArray : FormArray =  <FormArray> self.clinicalExpenseForm.controls["items"];
        formArray.controls.forEach(function (formArrayItem) {
            formArrayItem.get('medicalAttention').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.medicalAttentionChanged(data, formArrayItem);
            });
        });

    }

    buildHospitalExpenseItems(hospitalExpenses: HospitalExpenseDetails[]): FormGroup[] {

        let formGroupArray : FormGroup[] = [];

        if(hospitalExpenses != null && hospitalExpenses.length > 0 ) {
            for(let hospitalExpense of hospitalExpenses) {
                let rowFormGroup = this.fb.group({
                    hospitalName: [hospitalExpense.getHospitalName()],
                    dateAdmission: [hospitalExpense.getDateAdmission()],
                    dateDischarge: [hospitalExpense.getDateDischarge()],
                    hospitalExpense: [hospitalExpense.getHospitalExpense()],
                    hospitalExpenseCurrency: [hospitalExpense.getHospitalExpenseCurrency()]
                });
                formGroupArray.push(rowFormGroup);
            }
        }
        else {
            formGroupArray.push(this.createDefaultHospitalExpenseItems());
        }

        return formGroupArray;
    }

    createDefaultHospitalExpenseItems(): FormGroup {
        return this.fb.group({
            hospitalName: '',
            dateAdmission: null,
            dateDischarge: null,
            hospitalExpense: '',
            hospitalExpenseCurrency: this.currency
        });
    }
    
    buildClinicalExpenseItems(clinicalExpenses: ClinicalExpenseDetails[]): FormGroup[] {

        let formGroupArray : FormGroup[] = [];

        if(clinicalExpenses != null && clinicalExpenses.length > 0 ) {
            for(let clinicalExpense of clinicalExpenses) {
                let rowFormGroup = this.fb.group({
                    dateVisit: [clinicalExpense.getDateVisit()],
                    medicalAttention: [clinicalExpense.getMedicalAttention()],
                    medicalAttentionDesc: [clinicalExpense.getMedicalAttentionDesc()],
                    medicalAttentionOthers: [clinicalExpense.getMedicalAttentionOthers()],
                    clinicalExpense: [clinicalExpense.getClinicalExpense()],
                    clinicalExpenseCurrency: [clinicalExpense.getClinicalExpenseCurrency()]
                });
                formGroupArray.push(rowFormGroup);
            }
        }
        else {
            formGroupArray.push(this.createDefaultClinicalExpenseItems());
        }

        return formGroupArray;
    }

    createDefaultClinicalExpenseItems(): FormGroup {
        return this.fb.group({
            dateVisit: null,
            medicalAttention: '',
            medicalAttentionDesc: '',
            medicalAttentionOthers: '',
            clinicalExpense: '',
            clinicalExpenseCurrency: this.currency
        });
    }

    addHospitalItem(): void {
        let self = this;
        setTimeout(function () {

            let items = self.hospitalExpenseForm.get('items') as FormArray;
            let newItem : FormGroup = self.createDefaultHospitalExpenseItems();
            items.push(newItem);

        }, 10);
    }
    
    removeHospitalItem(i) {
        let items = this.hospitalExpenseForm.get('items') as FormArray;
        items.removeAt(i);
        this.hospitalExpenseItems.splice(i, 1);
    }

    addClinicalItem(): void {
        let self = this;
        setTimeout(function () {

            let items = self.clinicalExpenseForm.get('items') as FormArray;
            let newItem : FormGroup = self.createDefaultClinicalExpenseItems();
            items.push(newItem);

            newItem.get('medicalAttention').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.medicalAttentionChanged(data, newItem);
            });

        }, 10);
    }
    
    removeClinicalItem(idx: number) {
        let items = this.clinicalExpenseForm.get('items') as FormArray;
        items.removeAt(idx, {emitEvent: true});

        items.value.forEach(formItem =>{
            this.clinicalExpenseItems.forEach(item =>{
                if (item.getMedicalAttention() === formItem.medicalAttention) {
                    item.setMedicalAttentionDesc(formItem.medicalAttentionDesc);
                }
            });
        });
    }

    hideFields(wasAdmitted) {
        if (wasAdmitted === "true") {
            this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().setClinicalExpenses(null);
            this.toggleForms(this.hospitalExpenseForm, true);
            this.toggleForms(this.clinicalExpenseForm, false);
            this.hideShowHospitalSubMenu(true);
        } else if (wasAdmitted === "false") {
            this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().setHospitalExpenses(null);
            this.toggleForms(this.clinicalExpenseForm, true);
            this.toggleForms(this.hospitalExpenseForm, false);
            this.hideShowHospitalSubMenu(false);
        } else {
            this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().setClinicalExpenses(null);
            this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().setHospitalExpenses(null);
            this.toggleForms(this.clinicalExpenseForm, false);
            this.toggleForms(this.hospitalExpenseForm, false);
            this.hideShowHospitalSubMenu(false);
        }
    }

    hideShowHospitalSubMenu(showFlag: boolean) {
        this.sideMenuService.emitClaimTypeSubMenuHideShow({claimTypeId: 0, subMenuIndex: 1,
            value: ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES], show: showFlag});
        this.sideMenuService.emitClaimTypeSubMenuHideShow({claimTypeId: 0, subMenuIndex: 2,
            value: ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES],  show: showFlag});
    }

    hideTreatmentField(hasRecovered) {
        if (String(hasRecovered) === 'false') {
            this.clinicalExpenseForm.get('treatment').enable({onlySelf: false, emitEvent: false});
        } else if (String(hasRecovered) === 'true') {
            this.clinicalExpenseForm.get('treatment').reset();
            this.clinicalExpenseForm.get('treatment').disable({onlySelf: false, emitEvent: false});
        }
    }

    toggleForms(form: FormGroup, show: boolean) {
        if(show){
            form.enable({onlySelf: false, emitEvent: false});
        } else {
            form.disable({onlySelf: false, emitEvent: false});
        }
    }

    getCurrencyValue(val: any) : string {
        if(val == null || (val != null && val == '')) {
            return this.currency;
        }
        return val;
    }

    medicalAttentionChanged(medicalAttention, formGroup) {
        if (medicalAttention === 'Others') {
            formGroup.get('medicalAttentionOthers').enable({onlySelf: false, emitEvent: false});
        } else  {
            formGroup.get('medicalAttentionOthers').disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 0});
            if(this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getWasAdmitted()){
                this.router.navigate(["/claimform/accidentalMedicalExpenses/icuDetails"], {
                    relativeTo: this.activatedRoute
                });
            } else {
                this.sideMenuService.emitClaimTypeSubMenuHideShow({claimTypeId: 0 , subMenuIndex: 1, value: ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES]});

                let url = this.claim.getCountry().toLowerCase() === 'sgp' ? "/claimform/accidentalMedicalExpenses/medicalCertificates" 
                        : "/claimform/accidentalMedicalExpenses/supportDoc";
                this.router.navigate([url], {
                    relativeTo: this.activatedRoute
                });
            }
        }
    }

    validateMoneyFields(wasAdmitted: boolean): boolean {

        let data = wasAdmitted ? this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getHospitalExpenses() : this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getClinicalExpenses();
        let moneyField = wasAdmitted ? "hospitalExpense" : "clinicalExpense";
        data.forEach((currVal) => {
            let formArray: FormArray = <FormArray>this.hospitalExpenseForm.controls["items"];
            formArray.controls.forEach((currentValue) => {
                if (currentValue.get(moneyField).value == ''
                    || currentValue.get(moneyField).value == null) {
                    return false;
                }
            });
        });
        return false;
    }

    validateForm(): boolean {
        let wasAdmitted = this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getWasAdmitted();
        if(super.validateForm(this.ameForm)){
            if(wasAdmitted){
                return super.validateForm(this.hospitalExpenseForm);
            } else if (!wasAdmitted) {
                return super.validateForm(this.clinicalExpenseForm);
            }
        }
        return false;
    }

    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    getDatesForValidation(i, fieldType): [Date, Date[][], Date] {
        return this.hospitalExpenseHelperService.getDatesForValidation(i, fieldType, this.claimPA.getAccidentalMedicalExpenses().getHospitalExpenses());
    }

    checkForHospitalCash() {
        this.transactionInfo.getClaimTypeItemList().forEach(element => {
            if(element.claimTypeCode == ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH] && element.show == true){
                this.hospitalCashFound = true;
            }
        });
    }

    showSvgPath() {
        $('img.svg,.icon').each(function () {
            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            $.get(imgURL, function (data) {
                // Get the SVG tag, ignore the rest
                var $svg = $(data).find('svg');

                // Add replaced image's ID to the new SVG
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Check if the viewport is set, else we gonna set it if we can.
                if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                }

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAPageView() {
        let pageStep = this.transactionInfo.getGaPageStep() + ' - Hospital/Clinical Expense';
        let vPath = this.transactionInfo.getGaVPath() + '/hospital-clinical-expense';
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': pageStep,
            'vPath': vPath,
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 6}
                }
            }
        });
    }

}
