import {Component, Injector, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { ClaimsBaseComponent } from '../claim-base.component';
import { TransactionInfoService } from '../../services/transaction-info.service';
import { ClaimService } from '../../services/claim.service';
import { SideMenuService } from '../../services/side-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { ClaimHome } from 'src/app/model/claim-home.model';
import { DynamicRoutingService } from 'src/app/services/dynamic-routing.service';
import { ClaimTypeItem } from 'src/app/model/claim-type-item.model';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClaimTypeItemHelperService } from 'src/app/services/claim-type-item-helper.service';
import { Others } from 'src/app/model/common/others.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

declare var $: any;

@Component({
    selector: 'app-confirm-incident-details',
    templateUrl: './confirm-incident-details.component.html',
    styleUrls: ['./confirm-incident-details.component.css']
})
export class ConfirmIncidentDetailsComponent extends ClaimsBaseComponent  implements OnInit {
    confirmIncidentDetails: FormGroup;
    claimHome: ClaimHome;
    transactionInfo: TransactionInfo;
    deleteClaimTypeModalRef: any;
    claimTypeList: ClaimTypeItem[] = [];
    selectedClaimTypeId: number = -1;
    placeOfIncidentInit: String;
    isHomeAddressInit: boolean;
    shouldOpenPrompt: boolean = false;
    showMap: boolean = false;
    
    @ViewChild('confirm_delete_claim_type', {static: true}) deleteClaimTypeModal: TemplateRef<any>;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private dynamicRoutingService: DynamicRoutingService,
                private claimService: ClaimService,
                private sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private claimTypeItemHelperService: ClaimTypeItemHelperService,
                private modalService: NgbModal,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimHome = this.claim.getClaimHome();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
        this.claimTypeList = this.transactionInfo.getClaimTypeItemList();
        this.placeOfIncidentInit = this.claimHome.getPlaceOfIncident();
        this.isHomeAddressInit = this.claimHome.getIsHomeWhereIncidentOccurred();
        this.shouldOpenPrompt = (this.placeOfIncidentInit === 'home' && this.isHomeAddressInit) || 
                                (this.placeOfIncidentInit !== 'home' && this.claimHome.getHasHSRRisk());
    }

    ngOnInit() {
        this.pushGAConfirmIncidentDetailsView();

        let dateOfLoss = this.claimHome.getDateOfIncident() != null ? this.claimHome.getDateOfIncident() : this.claim.getDateOfLoss();

        this.confirmIncidentDetails = this.fb.group({
            dateOfIncident: [dateOfLoss],
            placeOfIncident: [this.claimHome.getPlaceOfIncident()],
            isHomeWhereIncidentOccurred: [super.getBooleanString(this.claimHome.getIsHomeWhereIncidentOccurred())],
            incidentAccidentOccur: [this.claimHome.getIncidentAccidentOccur()]
        });
    }

    ngAfterViewInit() {
         this.confirmIncidentDetails.valueChanges.subscribe(data => {
            this.claimHome.setDateOfIncident(super.getValueInComponent('dateOfIncident'));
            this.claimHome.setPlaceOfIncident(super.getValueInComponent('placeOfIncident'));
            this.claimHome.setPlaceOfIncidentDesc(super.getSelectedRadioText('placeOfIncident'));
            if (this.confirmIncidentDetails.get('isHomeWhereIncidentOccurred').value !== null) {
                this.claimHome.setIsHomeWhereIncidentOccurred(this.confirmIncidentDetails.get('isHomeWhereIncidentOccurred').value == 'true');
            } else {
                this.claimHome.setIsHomeWhereIncidentOccurred(null);
            }
            this.claimHome.setIncidentAccidentOccur(this.confirmIncidentDetails.get('incidentAccidentOccur').value)
        });

        let self = this;

       // Stop validation of fields when not required.
       setTimeout(function () {self.hideIncidentOccured(self.claimHome.getPlaceOfIncident()),10});
       this.confirmIncidentDetails.get('placeOfIncident').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.resetOthers();
        this.hideIncidentOccured(data);
       });

       setTimeout(function () {self.hideMap(self.claimHome.getPlaceOfIncident(), self.getBooleanString(self.claimHome.getIsHomeWhereIncidentOccurred()),
           self.claimHome.getRiskAddress()),10});
       this.confirmIncidentDetails.get('isHomeWhereIncidentOccurred').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.hideMap(self.claimHome.getPlaceOfIncident(), data, self.claimHome.getRiskAddress());
       });
   }

   hideMap(placeOfIncident, isHomeWhereincidentOccured, riskAddress) {
    if (placeOfIncident == 'home' && isHomeWhereincidentOccured == 'false') {
        this.confirmIncidentDetails.get('incidentAccidentOccur').enable({onlySelf: false, emitEvent: false});
        this.showMap = true;
    } else if (placeOfIncident == 'outside') {
        this.confirmIncidentDetails.get('incidentAccidentOccur').enable({onlySelf: false, emitEvent: false});
        this.showMap = true;
    } else {
        if (this.transactionInfo.isResidentialFire()) {
            this.showMap = true;
        } else if (this.isNotNullOrUndefinedStr(riskAddress)) {
            this.confirmIncidentDetails.get('incidentAccidentOccur').reset();
            this.confirmIncidentDetails.get('incidentAccidentOccur').disable({onlySelf: false, emitEvent: false});
            this.showMap = false;
        }
    }
   }

   hideIncidentOccured(placeOfIncident) {
        if (placeOfIncident == 'home') {
           // if risk situation is blank in policy details, show address text field and default to Home - Yes address workflow
            if (!this.isNotNullOrUndefinedStr(this.claimHome.getRiskAddress())) {
                this.confirmIncidentDetails.get('isHomeWhereIncidentOccurred').setValue('true');
                this.confirmIncidentDetails.get('isHomeWhereIncidentOccurred').disable({onlySelf: false, emitEvent: false});
                this.confirmIncidentDetails.get('incidentAccidentOccur').enable({onlySelf: false, emitEvent: false});
                this.showMap = true;
            } else {
                this.confirmIncidentDetails.get('isHomeWhereIncidentOccurred').enable({onlySelf: false, emitEvent: false});
                this.hideMap(placeOfIncident, this.confirmIncidentDetails.get('isHomeWhereIncidentOccurred').value, this.claimHome.getRiskAddress());
            }
        } else {
            this.confirmIncidentDetails.get('isHomeWhereIncidentOccurred').reset();
            this.confirmIncidentDetails.get('isHomeWhereIncidentOccurred').disable({onlySelf: false, emitEvent: false});
            this.hideMap(placeOfIncident, null, this.claimHome.getRiskAddress());
        }
    }

    goToNext() {
        if(this.validateForm()){
            //build claim types
            this.buildHomeClaimTypes();
            this.setGaForOthers();
            if (this.isRemoveCurrentClaimType()) {
                if (this.shouldOpenPrompt) {
                    this.openDeleteModal();
                } else {
                    this.deleteClaimType();
                }
            } else {
                // navigate to next screen
                this.sideMenuService.emitConfirmIncidentDetailsInfo('');
                this.sideMenuService.emitComplete('finishConfirmIncidentDetails');
                this.sideMenuService.emitProgress(1);
            
                if ((this.claimHome.getPlaceOfIncident() === 'home' && this.claimHome.getIsHomeWhereIncidentOccurred()) || 
                    (this.claimHome.getPlaceOfIncident() === 'outside' && this.claimHome.getHasHSRRisk())) {
                    this.transactionInfo.setIsClaimDetailsLabel(false);
                    this.sideMenuService.emitSelectClaimType(true);
                    let url = "/claimform/claimDetail";
                    this.router.navigate([url], {
                        relativeTo: this.activatedRoute
                    });
                } else {
                    this.goToOthersClaimDetails();
                }
            }
        }
    }

    buildHomeClaimTypes(): void {
        let claimTypesList = this.transactionInfoService.getTransactionInfo().getClaimTypeItemList();
        let outsideHomeClaimTypes = [ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST], ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]];

        //Added checker for risks for DMH and Personal Accident and TP liability
        let removeClaimTypes = this.checkRisks();

        // for home
        if (this.confirmIncidentDetails.get('placeOfIncident').value === 'home' && this.claimHome.getIsHomeWhereIncidentOccurred()) {
            claimTypesList.forEach((claimType, index)=>{
                if((outsideHomeClaimTypes.indexOf(claimType['claimTypeCode']) > -1) ||
                    (removeClaimTypes.indexOf(claimType['claimTypeCode']) > -1)){
                    claimType['show'] = false;
                } else {
                    claimType['show'] = true;
                }
            });
        }

        // for outside home
        if (this.confirmIncidentDetails.get('placeOfIncident').value === 'outside' && this.claimHome.getHasHSRRisk()) {
            claimTypesList.forEach((claimType)=>{
               if(outsideHomeClaimTypes.indexOf(claimType.claimTypeCode) !== -1  ||
                   (this.claimHome.getHasHPWRisk() && claimType.claimTypeCode === ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_DMH]) || 
                   (this.claimHome.getHasHPLHHLRisk() && claimType.claimTypeCode === ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_TP_LIABILITY])) {
                  claimType['show'] = true;
               } else {
                  claimType['show'] = false;
               }
            });
        }

        this.transactionInfoService.getTransactionInfo().setClaimTypeItemList(claimTypesList);
    }

    //Hide DMH and/or Personal Accident Claim types based on risk availability
    checkRisks(): String[]{
        let hideRisks = [];
        if(!this.claimHome.getHasHPWRisk()){
            hideRisks.push(ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_DMH]);
        }
        if(!this.claimHome.getHasHPPRisk()){
            hideRisks.push(ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_PA]);
        }
        // default to hide tp liability claim type for home
        hideRisks.push(ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_TP_LIABILITY]);
        return hideRisks;
    }

    validateForm(): boolean {
        return super.validateForm(this.confirmIncidentDetails);
    }

    resetOthers() {
        let arr: string[] = [];
        this.claim.getClaimHome().getHomeDetails().setOthers(new Others());
        this.claimService.getClaim().setSelectedClaimTypesList(arr);
    }

    isRemoveCurrentClaimType() {
        //check if there is any selected claimtype, remove them
        this.selectedClaimTypeId = this.getSelectedClaimTypeToDelete();
        let placeOfIncidentForm = this.confirmIncidentDetails.get('placeOfIncident').value;
        let isHomeAddressForm = this.confirmIncidentDetails.get('isHomeWhereIncidentOccurred').value;

        if ((placeOfIncidentForm !== this.placeOfIncidentInit ||
            (!UtilitiesService.isNullOrUndefined(isHomeAddressForm) && isHomeAddressForm !== super.getBooleanString(this.isHomeAddressInit))) &&
            this.selectedClaimTypeId !== -1) {
                return true;
        }
        return false;
    }

    goToOthersClaimDetails() {
        this.sideMenuService.emitSelectClaimType(false);
        let chooseIndex:number = 11;
        let selectedClaimCode:string = ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_OTHERS];
        let hasActiveClaimType:boolean = true;
        let claimTypeData: ClaimTypeItem[] = this.transactionInfo.getClaimTypeItemList();
        let claimTypeDetailMenu = this.transactionInfo.getNavigationMenu().getClaimDetailMenu();
        const selectedClaimItemList: string[] = this.claim.getSelectedClaimTypesList();
        this.transactionInfo.setIsClaimDetailsLabel(true);

        let claimTypeSelected =  claimTypeData.find(claimTypeItem =>
            claimTypeItem.claimTypeCode === selectedClaimCode);
        if(!UtilitiesService.isNullOrUndefined(claimTypeSelected)){
            claimTypeSelected.selected = true;
            if(selectedClaimItemList.indexOf(claimTypeSelected.claimTypeCode) < 0){
                selectedClaimItemList.push(claimTypeSelected.claimTypeCode);
            }
        }

        if (Object.keys(claimTypeDetailMenu[chooseIndex]['subMenus']).length !== 0) {
            this.claim.setSelectedClaimTypesList(selectedClaimItemList);
            this.transactionInfoService.getTransactionInfo().setClaimTypeItemList(claimTypeData);
            this.sideMenuService.emitCliamType(chooseIndex);
            this.sideMenuService.emitComplete('finishDetail');
            this.sideMenuService.emitProgress(1);

            let nextLinkUrl: string = claimTypeDetailMenu[chooseIndex]['subMenus'][0]['url'];
            this.router.navigate([nextLinkUrl]);

            //set document form builder of selected claim type
            if(selectedClaimCode !== '' && hasActiveClaimType) {
                this.transactionInfo.setDynamicRoutes(this.dynamicRoutingService.getDynamicHomeRoutes(selectedClaimCode));
            }
        }
    }

    getSelectedClaimTypeToDelete() {
        let selectedClaimTypeId = -1;
        for (let x = 0; x < this.claimTypeList.length; x++) {
            if (this.claimTypeList[x]['selected'] === true) {
                selectedClaimTypeId = this.claimTypeList[x]['claimTypeId'];
                break;
            }
        }
        return selectedClaimTypeId;
    }

    openDeleteModal() {
        this.deleteClaimTypeModalRef = this.modalService.open(this.deleteClaimTypeModal, {size: 'sm',
            backdrop: 'static',
            keyboard: false
        });
    }

    deleteClaimType() {
        this.claimTypeItemHelperService.deleteClaimType(this.selectedClaimTypeId);
        if (this.shouldOpenPrompt) {
            this.deleteClaimTypeModalRef.close();
        }        
        setTimeout(() => {
            if (this.claimHome.getPlaceOfIncident() === 'home' && this.claimHome.getIsHomeWhereIncidentOccurred() || 
                this.claimHome.getPlaceOfIncident() === 'outside' && this.claimHome.getHasHSRRisk()) {
                this.transactionInfo.setIsClaimDetailsLabel(false);
                this.sideMenuService.emitSelectClaimType(true);
                this.sideMenuService.setStepNumber(2);
                this.sideMenuService.emitProgress(0);
                this.router.navigate(["/claimform/claimDetail"], {
                    relativeTo: this.activatedRoute
                });
            } else {
                this.sideMenuService.emitProgress(1);
                this.goToOthersClaimDetails();
            }
        }, 100);
    }

    pushGAConfirmIncidentDetailsView() {
        let channel: string = "HKSI";
        let country = this.claim.getCountry();

        if (country && country == "HKG" && this.claim.getIsManulife()){
            channel = "Manulife";
        } else if(country && country  == "HGI") {
            channel = "QGI";
        }

        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Confirm Incident Details',
            'Channel': channel, //QGI or HKSI or Manulife
            'vPath': '/confirm-incident-details',
            'event': 'vpageview',
            'ecommerce': {
              'checkout': {
                  'actionField': {'step': 3},	//Step3-Confirm-Incident-Details
              }    
            }      
        });
    }

    setGaForOthers() {
        if (this.claimHome.getPlaceOfIncident() !== 'home') {
            this.transactionInfo.setGaPageStep('Outside home');
            this.transactionInfo.setGaVPath('outside-home');
        } else if (!this.claimHome.getIsHomeWhereIncidentOccurred()) {
            this.transactionInfo.setGaPageStep('Other insured address');
            this.transactionInfo.setGaVPath('other-insured-address');
        }
    }
}
