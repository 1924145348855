import {Component, Injector, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import {ClinicalExpenses} from "../../../../model/domestic-helper/clinical-expenses.model";
import { UiDropdownSelectComponent } from "src/app/ui/ui-dropdown-select/ui-dropdown-select.component";
import { PlanHelperService } from 'src/app/services/plan-helper.service';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import {Notifications} from "../../../../utilities/components/notification-messages/notifications.model";
import {ClaimTypeItemHelperService} from "../../../../services/claim-type-item-helper.service";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ClaimService } from 'src/app/services/claim.service';

@Component({
    selector: 'app-clinical-expenses-visit',
    templateUrl: './clinical-expenses-visit.component.html',
    styleUrls: ['./clinical-expenses-visit.component.css']
})

export class ClinicalExpensesVisitComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    clinicalForm: FormGroup;
    clinicalExpenses: ClinicalExpenses;
    transactionInfo: TransactionInfo;
    notifications: Notifications;
    @ViewChild('DMH_CLINICAL_TYPE_OF_TREATMENT', {static: true}) el: UiDropdownSelectComponent;
    @ViewChild('info_modal', {static: true}) infoModal: TemplateRef<any>;
    modalTitle: string = '';
    modalDescription: string = '';
    showEmergencyAEModal: boolean = false;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private planHelperService : PlanHelperService,
                private transactionInfoService : TransactionInfoService,
                private injector : Injector,
                private claimTypeItemHelperService: ClaimTypeItemHelperService,
                private claimService: ClaimService,
                private modalService: NgbModal) {

        super(injector);
        this.clinicalExpenses = this.claim.getClaimDomesticHelper().getClinicalExpenses();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
        if(this.clinicalExpenses.getTotalAmountOfExpensesCurrency() == null){
            this.clinicalExpenses.setTotalAmountOfExpensesCurrency('HKD');
        }
        if(this.clinicalExpenses.getDateOfVisit() == null){
            this.clinicalExpenses.setDateOfVisit(this.claim.getDateOfLoss());
        }
        this.notifications = new Notifications();
        this.notifications.showPreamble = false;
    }

    ngOnInit() {

        this.pushGAViewClinicalExpensesVisit();

        this.clinicalForm = this.fb.group({
            dateOfVisit: [this.clinicalExpenses.getDateOfVisit()],
            typeOfClinicalExpenses: [this.clinicalExpenses.getTypeOfClinicalExpenses()],
            totalAmountOfExpensesCurrency: [this.clinicalExpenses.getTotalAmountOfExpensesCurrency()],
            totalAmountOfExpensesValue: [this.clinicalExpenses.getTotalAmountOfExpensesValue()],
            typeOfIllnessOrInjury: [this.clinicalExpenses.getTypeOfIllnessOrInjury()],
            otherTypeOfIllnessOrInjury: [this.clinicalExpenses.getOtherTypeOfIllnessOrInjury()]
        });
    }

    ngAfterViewInit() {

        this.clinicalForm.valueChanges.subscribe(data => {
            this.clinicalExpenses.setDateOfVisit(super.getValueInComponent('dateOfVisit'));
            this.clinicalExpenses.setTypeOfClinicalExpenses(super.getValueInComponent('typeOfClinicalExpenses'));
            this.clinicalExpenses.setTypeOfClinicalExpensesCode(this.clinicalForm.get('typeOfClinicalExpenses').value);
            this.clinicalExpenses.setTypeOfClinicalExpensesDesc(this.getTextInComponent('typeOfClinicalExpenses'));
            this.clinicalExpenses.setTotalAmountOfExpensesCurrency(this.clinicalForm.get('totalAmountOfExpensesCurrency').value);
            this.clinicalExpenses.setTotalAmountOfExpensesValue(super.getValueInComponent('totalAmountOfExpensesValue'));           
            this.clinicalExpenses.setTypeOfIllnessOrInjury(super.getValueInComponent('typeOfIllnessOrInjury'));
            this.clinicalExpenses.setTypeOfIllnessOrInjuryDesc(this.getTextInComponent('typeOfIllnessOrInjury'));
            this.clinicalExpenses.setOtherTypeOfIllnessOrInjury(super.getValueInComponent('otherTypeOfIllnessOrInjury'));
        });

        setTimeout(() => {
            this.removeDropDownListItemBasedOnPlanCode();
        }, 10);


        // Stop validation of the other field when reason isn't other
        let self = this;
        self.clinicalForm.get('typeOfIllnessOrInjury').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideOtherTypeOfIllnessOrInjury(data);
        });

        self.clinicalForm.get('typeOfClinicalExpenses').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            if (data == 'LT' || data == 'NC') {
                this.modalDescription = 'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.detailsOfClinicVisit.clinicTypeNotCovered';
                this.showInfoModal();
            } else if (this.claimService.isMILDMHProductPlan(this.claim) && data === 'AE') {
                this.modalDescription = 'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.detailsOfClinicVisit.emergencyServicePopup2'
                this.showInfoModal();
            }
        });
    }

    hideOtherTypeOfIllnessOrInjury(value) {
        if (value && value.toLowerCase() === 'others') {
            this.clinicalForm.get('otherTypeOfIllnessOrInjury').enable({onlySelf: false, emitEvent: false});
        } else {
            this.clinicalForm.get('otherTypeOfIllnessOrInjury').reset();
            this.clinicalForm.get('otherTypeOfIllnessOrInjury').disable({onlySelf: false, emitEvent: false});
        }
    }
    
    removeDropDownListItemBasedOnPlanCode() {
        let domesticInfoPlan  = this.planHelperService.getDomesticPlanDetails(this.claim.getPlanCode(), this.claim.getCatalogueCode(),
            this.claim.getCountry());
        for(let index = this.el.fieldConfig['options'].length - 1; index >=0; index--){
            let element = this.el.fieldConfig['options'][index];
            if(domesticInfoPlan?.physiotherapy == 'No' && element.value == 'PH'){
                this.el.fieldConfig['options'].splice(index,1);
            }
            if(domesticInfoPlan?.registeredChineseMedicinePractitioner == 'No' && element.value == 'RC'){
                this.el.fieldConfig['options'].splice(index,1);
            }
            if(domesticInfoPlan?.accidentEmergencyService == 'No' && element.value == 'AE'){
                this.el.fieldConfig['options'].splice(index,1);
            }
            if(domesticInfoPlan?.generalPractitioner == 'No' && element.value == 'GP'){
                this.el.fieldConfig['options'].splice(index,1);
            }
            if(domesticInfoPlan?.followUpConsultation == 'No' && element.value == 'FC'){
                this.el.fieldConfig['options'].splice(index,1);
            }
            if(domesticInfoPlan?.nonMemberOutpatientConsultation == 'No' && element.value == 'NC'){
                this.el.fieldConfig['options'].splice(index,1);
            }
            if(domesticInfoPlan?.labTestExtraMedicationCoPayment == 'No' && element.value == 'LT'){
                this.el.fieldConfig['options'].splice(index,1);
            }
        }
    }

    back() {
        this.router.navigate(["/claimform/clinical/relatedConditions"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        this.notifications.clearMessages();
        if (this.validateForm()) {
            this.validateEmergencyServiceModal();
            if (!this.showEmergencyAEModal) {
                let isDateOfVisitAllowed = this.claimTypeItemHelperService.isAllowedDomesticClaimDetailsGroup(1, this.claim);
                if (isDateOfVisitAllowed) {
                    this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 2});
                    this.router.navigate(["/claimform/clinical/supportDoc"], {
                        relativeTo: this.activatedRoute
                    });
                } else {
                    this.notifications = new Notifications();
                    this.notifications.showPreamble = false;
                    let errorMessage = "error_messages.domesticClaimGroup.notAllowedDateOfVisit";

                    this.notifications.addErrorMessage(errorMessage);
                }
            }
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.clinicalForm);
    }

    validateEmergencyServiceModal() {
        if (!this.showEmergencyAEModal && this.claimService.isMILDMHProductPlan(this.claim) && this.clinicalForm.get('typeOfClinicalExpenses').value === 'AE' && 
            (this.clinicalForm.get('totalAmountOfExpensesCurrency').value !== 'HKD' || this.clinicalForm.get('totalAmountOfExpensesValue').value < 180 )) {
                this.showEmergencyAEModal = true;
                this.modalDescription = 'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.detailsOfClinicVisit.emergencyServicePopup1'
                this.showInfoModal();
        } else {
            this.showEmergencyAEModal = false;
        }
    }

    showInfoModal() {
        this.modalService.open(this.infoModal, {size: 'md', backdrop: 'static', keyboard: false});
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewClinicalExpensesVisit() {
        (<any>window).dataLayer.push({
            'pageStep': 'Clinical Expenses – Details of Visit',
            'vPath': '/claim/clinical-expenses/visit-details',
            'event': 'vpageview'          
        });
    }

}
