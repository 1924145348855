<div>
    <div class="prev-bar">
        <a (click)="back()"  style="color: #0064c1" class="new-btn btn btn-secondary"><i
                class="fas fa-arrow-left"></i> {{'pageSummaryClaim.back' | translate}} </a>
        <div *ngIf="showPdfDownloadButton">
            <button (click)="downloadFilePDFButtonClick()" class="new-btn btn btn-primary float-right">PDF</button>
        </div>
    </div>
    <div class="col-12">
        <h5>{{'pageSummaryClaim.title' | translate}}</h5>
        <p>{{'pageSummaryClaim.desc' | translate}}</p>
    </div>
    <qnect-notification-messages [notifications]="notifications"></qnect-notification-messages>
    <BR>
    <div class="row col-12">
        <div class="col-xl-9 col-lg-12">
            <ng-container *ngIf="transactionInfo.isEmployeeCompensationProduct()">
                <div class="view-content">
                    <a href="javascript:;" (click)="viewPDF()">
                        <em class="fas fa-list-alt"></em>&nbsp;
                        <span>{{ 'pageSummaryClaim.preview' | translate}}
                        {{ getClaimTypeLabel() | translate}}
                        {{ 'pageSummaryClaim.previewPdfRequired'  | translate}}
                        </span>
                    </a>
                </div>
            </ng-container>
                <div *ngIf="this.isMultiClaimant">
                    <div *ngFor="let claimItem of claimList; let i = index">
                        <div class="form-group section">
                            <label class="section-title">{{'pageSummaryClaim.policy' | translate}} {{claimItem.getPolicyNumber()}}</label>
                            <div class="section-content">
                                <div class="row">
                                    <div class="col col-xs-12 col-lg-7">
                                        {{'pageSummaryClaim.insuredPerson' | translate}}
                                    </div>
                                    <div class="col col-xs-12 col-lg-5 content">
                                        {{claimItem.getClaimantName()}}
                                    </div>
                                </div>
                                <div class="row" *ngIf="claimItem.getClaimantIdNumber()">
                                    <div class="col col-xs-12 col-lg-7">
                                        <span *ngIf="transactionInfo.getRealCountry()==='HKG'">{{'pageSummaryClaim.hkidPassport' | translate}}</span>
                                        <span *ngIf="transactionInfo.getRealCountry()==='SGP'">{{'pageSummaryClaim.uenNricFinPassportNo' | translate}}</span>
                                        <span *ngIf="transactionInfo.getRealCountry()==='MYS'">{{'pageGetStarted.body.genericHolderPersonalMys' | translate}}</span>
                                    </div>
                                    <div class="col col-xs-12 col-lg-5 content">
                                        {{claimItem.getClaimantIdNumber()}}
                                    </div>
                                </div>
                                <ng-container *ngIf="transactionInfo.getDateOfBirth() || transactionInfo.getRealCountry()!=='HKG'">
                                    <div class="row" *ngIf="claimItem.getClaimantDateOfBirth()">
                                        <div class="col col-xs-12 col-lg-7">
                                            {{'pageGetStarted.body.dateOfBirth' | translate}}
                                        </div>
                                        <div class="col col-xs-12 col-lg-5 content">
                                            {{getDate(claimItem.getClaimantDateOfBirth())}}
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <!--Travel Summary sections-->
                        <travel-sections-summary *ngIf="transactionInfo.isTravelProduct()" [claimItem]="claimItem"></travel-sections-summary>
                    </div>
                </div>

                <div *ngIf="!this.isMultiClaimant">
                    <div class="form-group section" *ngIf="!transactionInfo.isEmployeeCompensationProduct()">
                        <label *ngIf="!transactionInfo.isMotorProduct() || !this.isNotNullOrUndefinedStr(claim.getIsCoverNotePreNumbered()) || claim.getIsCoverNotePreNumbered() == 'PN'" class="section-title">{{ 'pageSummaryClaim.policy'  | translate}} {{claim.getPolicyNumber()}}</label>
                        <label *ngIf="transactionInfo.isMotorProduct() && isNotNullOrUndefinedStr(claim.getIsCoverNotePreNumbered()) && claim.getIsCoverNotePreNumbered().startsWith('CN')" class="section-title">{{ 'pageSummaryClaim.coverNote' | translate}} {{claim.getPolicyNumber()}}</label>
                        <div class="section-content" *ngIf="!transactionInfo.isMotorProduct() && !transactionInfo.isGenericClaim() && !transactionInfo.isHomeProduct()">
                            <div class="row" *ngIf="claim.getClaimantName()">
                                <div class="col col-xs-12 col-lg-7">
                                    {{'pageSummaryClaim.insuredPerson' | translate}}
                                </div>
                                <div class="col col-xs-12 col-lg-5 content">
                                    {{claim.getClaimantName()}}
                                </div>
                            </div>
                            <div class="row" *ngIf="claim.getClaimantIdNumber()">
                                <div class="col col-xs-12 col-lg-7">
                                    <span *ngIf="transactionInfo.getRealCountry()==='HKG'">{{'pageSummaryClaim.hkidPassport' | translate}}</span>
                                    <span *ngIf="transactionInfo.getRealCountry()==='SGP'">{{'pageSummaryClaim.uenNricFinPassportNo' | translate}}</span>
                                    <span *ngIf="transactionInfo.getRealCountry()==='MYS'">{{'pageGetStarted.body.genericHolderPersonalMys' | translate}}</span>
                                </div>
                                <div class="col col-xs-12 col-lg-5 content">
                                    {{claim.getClaimantIdNumber()}}
                                </div>
                            </div>
                            <ng-container *ngIf="transactionInfo.getDateOfBirth() || transactionInfo.getRealCountry()!=='HKG'">
                                <div class="row" *ngIf="claim.getClaimantDateOfBirth()">
                                    <div class="col col-xs-12 col-lg-7" >
                                        {{'pageGetStarted.body.dateOfBirth' | translate}}
                                    </div>
                                    <div class="col col-xs-12 col-lg-5 content">
                                        {{getDate(claim.getClaimantDateOfBirth())}}
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <!--Travel Summary sections-->
                    <travel-sections-summary *ngIf="transactionInfo.isTravelProduct()"></travel-sections-summary>

                    <!--Domestic Helper Summary sections-->
                    <domestic-helper-sections-summary *ngIf="transactionInfo.isDomesticHelperProduct()"></domestic-helper-sections-summary>

                    <!--Motor Summary sections-->
                    <motor-sections-summary *ngIf="transactionInfo.isMotorProduct()"></motor-sections-summary>

                    <!--Generic Summary sections-->
                    <generic-sections-summary *ngIf="transactionInfo.isGenericClaim()"></generic-sections-summary>

                    <!--Home Summary sections-->
                    <home-sections-summary *ngIf="transactionInfo.isHomeProduct()"></home-sections-summary>

                    <!--Personal Accident Summary sections-->
                    <personal-accident-sections-summary *ngIf="transactionInfo.isAccidentProduct()"></personal-accident-sections-summary>

                    <!--Employee Compensation Summary sections-->
                    <employee-compensation-sections-summary *ngIf="transactionInfo.isEmployeeCompensationProduct()"></employee-compensation-sections-summary>
                </div>


                <!-- Another Insurance -->
                <ng-container *ngIf="(!(claim.getCountry() ==='SGP' && transactionInfo.isAccidentProduct()))">
                    <another-insurance-summary></another-insurance-summary>
                </ng-container>

                <!-- payment & contact -->
                <payment-contact-summary></payment-contact-summary>

                <!-- terms & conditions -->
                <app-summary-delarations #summaryDeclarationComponent></app-summary-delarations>

        </div>
        <div class="col-xl-3 col-lg-12">
        </div>
    </div>

    <div class="submit-bar">
        <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goTonext()" class="new-btn btn btn-primary float-right"> {{ 'pageSummaryClaim.submit' | translate }} <i class="fas fa-arrow-right"></i></button>
    </div>

    <ng-container *ngIf="showTotalBox">
        <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
    </ng-container>

</div>

<ng-template #doc_error_modal let-c="close" let-d="dismiss">
    <div class="modal-body">
        <h5 class="modal-title">{{'error_messages.notificationsErrMsg.error' | translate }}</h5><br/>
        <p>{{ 'error_messages.notificationsErrMsg.saveDocErrorMsg' | translate }}</p>
    </div>
    <div class="mt-2">
        <button class="new-btn btn-primary btn" (click)="c('Close click')" >
            {{ 'claimSection.generalLabels.generalButtonLabels.ok' | translate}}
        </button>
        <br/><br/>
    </div>
</ng-template>
