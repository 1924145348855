import {Component, ElementRef, Injector, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import { ClaimsBaseComponent } from "src/app/claim-pages/claim-base.component";
import { Claim } from "src/app/model/claim.model";
import { LostPersonalItem } from "src/app/model/travel/lost-personal-item.model";
import { SideMenuService } from "src/app/services/side-menu.service";
import { ClaimService } from "src/app/services/claim.service";
import { TransactionInfoService } from "src/app/services/transaction-info.service";

@Component({
    selector: 'app-lost-details',
    templateUrl: './lost-details.component.html',
    styleUrls: ['./lost-details.component.css']
})
export class LostDetailsComponent extends ClaimsBaseComponent implements OnInit {

    @ViewChild('lossType', {static: true}) el: ElementRef;

    detailOfDamageForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    lostPersonalitem: LostPersonalItem;

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private transactionInfoService: TransactionInfoService,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.lostPersonalitem = this.claim.getClaimHome().getHomeDetails().getLostPersonalItem();
        if(this.lostPersonalitem.getDateTheDamageOccurred() == null){
            this.lostPersonalitem.setDateTheDamageOccurred(this.claim.getClaimHome().getDateOfIncident());
        }
    }

    ngOnInit() {
        this.pushGALostDetailsView();
        
        this.detailOfDamageForm = this.fb.group(
            {
                howDamageOccurred: [this.lostPersonalitem.getHowDamageOccurred()],
                otherDamageOccurred: [this.lostPersonalitem.getOtherDamageOccurred()]
            }
        );
    }

    ngAfterViewInit() {

        let lostPersonalItem: LostPersonalItem = this.lostPersonalitem;

        this.detailOfDamageForm.valueChanges.subscribe(data => {
            lostPersonalItem.setHowDamageOccurred(super.getValueInComponent('howDamageOccurred'));
            lostPersonalItem.setHowDamageOccurredDesc(super.getTextInComponent('howDamageOccurred'));
            lostPersonalItem.setOtherDamageOccurred(super.getValueInComponent('otherDamageOccurred'));

        });
        // Stop validation of the other field when reason isn't other
        let self = this;
        setTimeout(function () {self.hideOthersField(lostPersonalItem.getHowDamageOccurred()),10});
        this.detailOfDamageForm.get('howDamageOccurred').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideOthersField(data);
        });
    }

    hideOthersField(howDamageOccurred) {

        if (howDamageOccurred && howDamageOccurred.toLowerCase() === 'others') {
            this.detailOfDamageForm.get('otherDamageOccurred').enable({onlySelf: false, emitEvent: false});
        } else {
            this.detailOfDamageForm.get('otherDamageOccurred').reset();
            this.detailOfDamageForm.get('otherDamageOccurred').disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        if (this.validateForm()) {
            this.transactionInfoService.getDynamicRoutingService().goToNextRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
        }
    }

    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/claimDetail"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.detailOfDamageForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.detailOfDamageForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 1, subMenuIndex: 0});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGALostDetailsView() {
        let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep() + ' - Details of the Incident';
        let vPath = '/claim/' + this.transactionInfoService.getTransactionInfo().getGaVPath() + '/details';
    
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
          'pageStep': pageStep,
          'vPath': vPath,
          'event': 'vpageview',
          'ecommerce': {
            'checkout': {
                'actionField': {'step': 4}	 //Step4-Claim Details
              }
          }
        });
    }
}
