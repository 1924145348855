import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import {ClaimTypes} from '../../../model/claim-type.model';
import { ECAccidentPlaceDetails } from 'src/app/model/employee-compensation/ec-accident-place-details.model';
import { UiOptionsModel } from 'src/app/ui/ui-options.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import {takeUntil} from "rxjs/operators";
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
    selector: 'app-ec-supplementary-info',
    templateUrl: './ec-supplementary-info.component.html'
})
export class ECSupplementaryInfoComponent extends ClaimsBaseComponent implements OnInit {

    supplementaryInfoForm: FormGroup;
    accidentPlaceDetails: ECAccidentPlaceDetails;
    showTotalBox = false;
    placeTypeOptions: UiOptionsModel[] = [];
    transactionInfo: TransactionInfo;
    isSpokenOtherLangEnabled = false;
    isReadingOtherLangEnabled = false;
    isWrittenOtherLangEnabled = false;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private sideMenuService: SideMenuService,
                private modalService: NgbModal,) {
        super(injector);
        this.transactionInfo = this.injector.get(TransactionInfoService).getTransactionInfo();
        this.accidentPlaceDetails = this.claim.getClaimEmployeeCompensation().getAccidentPlaceDetails();

        this.isSpokenOtherLangEnabled = this.isNotNullOrUndefinedStr(this.accidentPlaceDetails.getSpokenOtherText()) ? true : false;
        this.isReadingOtherLangEnabled = this.isNotNullOrUndefinedStr(this.accidentPlaceDetails.getReadingOtherText()) ? true : false;
        this.isWrittenOtherLangEnabled = this.isNotNullOrUndefinedStr(this.accidentPlaceDetails.getWrittenOtherText()) ? true : false;
    }

    ngOnInit() {
        if(!this.isNotNullOrUndefinedStr(this.accidentPlaceDetails.getContactNoCode())){
            this.accidentPlaceDetails.setContactNoCode(UtilitiesService.getCountryCode(this.claim.getCountry()));
        }
        if(!this.isNotNullOrUndefinedStr(this.accidentPlaceDetails.getAuthorizedPersonTelNoCode())){
            this.accidentPlaceDetails.setAuthorizedPersonTelNoCode(UtilitiesService.getCountryCode(this.claim.getCountry()));
        }

        this.supplementaryInfoForm = this.fb.group({
            commencementDate: [this.accidentPlaceDetails.getCommencementDate()],
            expectedCompletionDate: [this.accidentPlaceDetails.getCompletionDate()],
            contractorName: [this.accidentPlaceDetails.getContractorName()],
            siteAddress: [this.accidentPlaceDetails.getSiteAddress()],
            contractNo: [this.accidentPlaceDetails.getContractNo()],
            accidentDate: [this.accidentPlaceDetails.getAccidentDate()],
            contactNo: [this.accidentPlaceDetails.getContactNo()],
            contactNoCode: [this.accidentPlaceDetails.getContactNoCode()],

            projectNature: [this.accidentPlaceDetails.getProjectNature()],
            projectNatureDesc: [this.accidentPlaceDetails.getProjectNatureDesc()],
            isPrivateProject: [super.getBooleanString(this.accidentPlaceDetails.getIsPrivateProject())],
            authorizedPersonName: [this.accidentPlaceDetails.getAuthorizedPersonName()],
            authorizedPersonPosition: [this.accidentPlaceDetails.getAuthorizedPersonPosition()],
            autorizedPersonPhoneNo: [this.accidentPlaceDetails.getAuthorizedPersonTelNo()],
            autorizedPersonPhoneNoCode: [this.accidentPlaceDetails.getAuthorizedPersonTelNoCode()],
            publicWorks: [this.accidentPlaceDetails.getPublicWorks()],
            publicWorksDesc: [this.accidentPlaceDetails.getPublicWorksDesc()],
            publicWorksOther: [this.accidentPlaceDetails.getPublicWorksOther()],

            placeOfFall: [this.accidentPlaceDetails.getPlaceOfFall()],
            placeOfFallDesc: [this.accidentPlaceDetails.getPlaceOfFallDesc()],
            placeOfFallOther: [this.accidentPlaceDetails.getPlaceOfFallOther()],

            ethnicity: [this.accidentPlaceDetails.getEthnicity()],
            ethnicityDesc: [this.accidentPlaceDetails.getEthnicityDesc()],
            ethnicityOther: [this.accidentPlaceDetails.getEthnicityOther()],

            spokenCantonese:  [this.accidentPlaceDetails.getSpokenCantonese()],
            spokenPutonghua:  [this.accidentPlaceDetails.getSpokenPutonghua()],
            spokenEnglish:  [this.accidentPlaceDetails.getSpokenEnglish()],
            spokenOtherText: [this.accidentPlaceDetails.getSpokenOtherText()],
            spokenOtherOpt: [this.accidentPlaceDetails.getSpokenOtherOpt()],

            readingChinese:  [this.accidentPlaceDetails.getReadingChinese()],
            readingEnglish:  [this.accidentPlaceDetails.getReadingEnglish()],
            readingOtherText: [this.accidentPlaceDetails.getReadingOtherText()],
            readingOtherOpt: [this.accidentPlaceDetails.getReadingOtherOpt()],

            writtenChinese:  [this.accidentPlaceDetails.getWrittenChinese()],
            writtenEnglish:  [this.accidentPlaceDetails.getWrittenEnglish()],
            writtenOtherText: [this.accidentPlaceDetails.getWrittenOtherText()],
            writtenOtherOpt: [this.accidentPlaceDetails.getWrittenOtherOpt()]

        });
    }

    ngAfterViewInit() {
        this.supplementaryInfoForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
            this.accidentPlaceDetails.setCommencementDate(this.supplementaryInfoForm.get('commencementDate').value);
            this.accidentPlaceDetails.setCompletionDate(this.supplementaryInfoForm.get('expectedCompletionDate').value);
            this.accidentPlaceDetails.setContractorName(this.supplementaryInfoForm.get('contractorName').value);
            this.accidentPlaceDetails.setSiteAddress(this.supplementaryInfoForm.get('siteAddress').value);
            this.accidentPlaceDetails.setContractNo(this.supplementaryInfoForm.get('contractNo').value);
            this.accidentPlaceDetails.setAccidentDate(super.getValueInComponent('accidentDate'));
            this.accidentPlaceDetails.setContactNo(this.supplementaryInfoForm.get('contactNo').value);
            this.accidentPlaceDetails.setContactNoCode(this.supplementaryInfoForm.get('contactNoCode').value);

            this.accidentPlaceDetails.setProjectNature(this.supplementaryInfoForm.get('projectNature').value);
            this.accidentPlaceDetails.setProjectNatureDesc(super.getTextInComponent('projectNature'));
            if (this.supplementaryInfoForm.get('isPrivateProject').value != null) {
                this.accidentPlaceDetails.setIsPrivateProject(this.supplementaryInfoForm.get('isPrivateProject').value == 'true');
            }
            this.accidentPlaceDetails.setAuthorizedPersonName(this.supplementaryInfoForm.get('authorizedPersonName').value);
            this.accidentPlaceDetails.setAuthorizedPersonPosition(this.supplementaryInfoForm.get('authorizedPersonPosition').value);
            this.accidentPlaceDetails.setAuthorizedPersonTelNo(this.supplementaryInfoForm.get('autorizedPersonPhoneNo').value);
            this.accidentPlaceDetails.setAuthorizedPersonTelNoCode(this.supplementaryInfoForm.get('autorizedPersonPhoneNoCode').value);
            this.accidentPlaceDetails.setPublicWorks(this.supplementaryInfoForm.get('publicWorks').value);
            this.accidentPlaceDetails.setPublicWorksDesc(super.getTextInComponent('publicWorks'));
            this.accidentPlaceDetails.setPublicWorksOther(this.supplementaryInfoForm.get('publicWorksOther').value);

            this.accidentPlaceDetails.setPlaceOfFall(this.supplementaryInfoForm.get('placeOfFall').value);
            this.accidentPlaceDetails.setPlaceOfFallDesc(super.getTextInComponent('placeOfFall'));
            this.accidentPlaceDetails.setPlaceOfFallOther(this.supplementaryInfoForm.get('placeOfFallOther').value);

            this.accidentPlaceDetails.setEthnicity(this.supplementaryInfoForm.get('ethnicity').value);
            this.accidentPlaceDetails.setEthnicityDesc(super.getTextInComponent('ethnicity'));
            this.accidentPlaceDetails.setEthnicityOther(this.supplementaryInfoForm.get('ethnicityOther').value);

            this.accidentPlaceDetails.setSpokenCantonese(this.supplementaryInfoForm.get('spokenCantonese').value);
            this.accidentPlaceDetails.setSpokenPutonghua(this.supplementaryInfoForm.get('spokenPutonghua').value);
            this.accidentPlaceDetails.setSpokenEnglish(this.supplementaryInfoForm.get('spokenEnglish').value);
            this.accidentPlaceDetails.setSpokenOtherText(this.supplementaryInfoForm.get('spokenOtherText').value);
            this.accidentPlaceDetails.setSpokenOtherOpt(this.supplementaryInfoForm.get('spokenOtherOpt').value);

            this.accidentPlaceDetails.setReadingChinese(this.supplementaryInfoForm.get('readingChinese').value);
            this.accidentPlaceDetails.setReadingEnglish(this.supplementaryInfoForm.get('readingEnglish').value);
            this.accidentPlaceDetails.setReadingOtherText(this.supplementaryInfoForm.get('readingOtherText').value);
            this.accidentPlaceDetails.setReadingOtherOpt(this.supplementaryInfoForm.get('readingOtherOpt').value);

            this.accidentPlaceDetails.setWrittenChinese(this.supplementaryInfoForm.get('writtenChinese').value);
            this.accidentPlaceDetails.setWrittenEnglish(this.supplementaryInfoForm.get('writtenEnglish').value);
            this.accidentPlaceDetails.setWrittenOtherText(this.supplementaryInfoForm.get('writtenOtherText').value);
            this.accidentPlaceDetails.setWrittenOtherOpt(this.supplementaryInfoForm.get('writtenOtherOpt').value);

        });

        let self = this;
        
        setTimeout(function () {self.resetPrivateProjectFields(self.accidentPlaceDetails.getIsPrivateProject())},10);
        this.supplementaryInfoForm.get('isPrivateProject').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.resetPrivateProjectFields(data);
        });

        setTimeout(function () {self.hideShowFieldPublicWorksOther(self.accidentPlaceDetails.getPublicWorks())},10);
        this.supplementaryInfoForm.get('publicWorks').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowFieldPublicWorksOther(data);
        });

        setTimeout(function () {self.hideShowFieldPlaceOfFallOther(self.accidentPlaceDetails.getPlaceOfFall())},10);
        this.supplementaryInfoForm.get('placeOfFall').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowFieldPlaceOfFallOther(data);
        });

        setTimeout(function () {self.hideShowFieldEthnicityOther(self.accidentPlaceDetails.getEthnicity())},10);
        this.supplementaryInfoForm.get('ethnicity').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowFieldEthnicityOther(data);
        });
    }

    resetPrivateProjectFields(data) {
        if (data === 'true') {
            this.supplementaryInfoForm.get('authorizedPersonName').enable({onlySelf: false, emitEvent: false});
            this.supplementaryInfoForm.get('authorizedPersonPosition').enable({onlySelf: false, emitEvent: false});
            this.supplementaryInfoForm.get('autorizedPersonPhoneNo').enable({onlySelf: false, emitEvent: false});

            this.supplementaryInfoForm.get('publicWorks').reset();
            this.supplementaryInfoForm.get('publicWorks').disable({onlySelf: false, emitEvent: false});
            this.supplementaryInfoForm.get('publicWorksOther').reset();
            this.supplementaryInfoForm.get('publicWorksOther').disable({onlySelf: false, emitEvent: false});
        } else if (data === 'false') {
            this.supplementaryInfoForm.get('publicWorks').enable({onlySelf: false, emitEvent: false});
            this.supplementaryInfoForm.get('publicWorksOther').enable({onlySelf: false, emitEvent: false});

            this.supplementaryInfoForm.get('authorizedPersonName').reset();
            this.supplementaryInfoForm.get('authorizedPersonName').disable({onlySelf: false, emitEvent: false});
            this.supplementaryInfoForm.get('authorizedPersonPosition').reset();
            this.supplementaryInfoForm.get('authorizedPersonPosition').disable({onlySelf: false, emitEvent: false});
            this.supplementaryInfoForm.get('autorizedPersonPhoneNo').reset();
            this.supplementaryInfoForm.get('autorizedPersonPhoneNo').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideShowFieldPublicWorksOther(publicWorks) {
        if (publicWorks && publicWorks.toLowerCase() === '99') {
            this.supplementaryInfoForm.get('publicWorksOther').enable({onlySelf: false, emitEvent: false});
        } else {
            this.supplementaryInfoForm.get('publicWorksOther').reset();
            this.supplementaryInfoForm.get('publicWorksOther').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideShowFieldPlaceOfFallOther(placeOfFall) {
        if (placeOfFall && placeOfFall.toLowerCase() === '08') {
            this.supplementaryInfoForm.get('placeOfFallOther').enable({onlySelf: false, emitEvent: false});
        } else {
            this.supplementaryInfoForm.get('placeOfFallOther').reset();
            this.supplementaryInfoForm.get('placeOfFallOther').disable({onlySelf: false, emitEvent: false});
        }
    }
    
    hideShowFieldEthnicityOther(ethnicity) {
        if (ethnicity && ethnicity.toLowerCase() === '11') {
            this.supplementaryInfoForm.get('ethnicityOther').enable({onlySelf: false, emitEvent: false});
        } else {
            this.supplementaryInfoForm.get('ethnicityOther').reset();
            this.supplementaryInfoForm.get('ethnicityOther').disable({onlySelf: false, emitEvent: false});
        }
    }


    validateForm(): boolean {
        return super.validateForm(this.supplementaryInfoForm);
    }
    
    goToNext() {
        if (this.validateForm()) {
            let route = this.transactionInfo.getRouteByCurrentUrl('/supplementaryInfo', true);
            if (route) {
                this.sideMenuService.emitClaimComplete({claimTypeId: route.claimIndex, subMenuIndex: route.routeIndex});
                this.router.navigate([route.routeUrl], {
                    relativeTo: this.activatedRoute
                })
            }
        }
    }

    back() {
        if (this.transactionInfoService.getTransactionInfo().getCurrentClaimType() == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2]) {
            this.router.navigate(["/claimform/form2/placeOfAccident"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    showModal() {
        console.log('showModel');
        this.showTotalBox = true;
    }
  
    getData(msg) {
        this.showTotalBox = msg;
    }

    open(content) {
        this.modalService.open(content, {size: 'lg'});
    }

    addRemoveOtherLanguage(type, action) {
        if (action === 'add') {
            switch(type) {
                case 'spoken':
                    this.isSpokenOtherLangEnabled = true;
                    this.supplementaryInfoForm.get('spokenOtherText').enable({onlySelf: false, emitEvent: false});
                    this.supplementaryInfoForm.get('spokenOtherOpt').enable({onlySelf: false, emitEvent: false});
                    break; 
                case 'reading':
                    this.isReadingOtherLangEnabled = true;
                    this.supplementaryInfoForm.get('readingOtherText').enable({onlySelf: false, emitEvent: false});
                    this.supplementaryInfoForm.get('readingOtherOpt').enable({onlySelf: false, emitEvent: false});
                    break;
                case 'written': 
                    this.isWrittenOtherLangEnabled = true;
                    this.supplementaryInfoForm.get('writtenOtherText').enable({onlySelf: false, emitEvent: false});
                    this.supplementaryInfoForm.get('writtenOtherOpt').enable({onlySelf: false, emitEvent: false});
                    break;
                default:
                    break;
            }
        } else { //remove
            switch(type) {
                case 'spoken':
                    this.isSpokenOtherLangEnabled = false;
                    this.supplementaryInfoForm.get('spokenOtherText').reset();
                    this.supplementaryInfoForm.get('spokenOtherText').disable({onlySelf: false, emitEvent: false});
                    this.supplementaryInfoForm.get('spokenOtherOpt').reset();
                    this.supplementaryInfoForm.get('spokenOtherOpt').disable({onlySelf: false, emitEvent: false});
                    break; 
                case 'reading':
                    this.isReadingOtherLangEnabled = false;
                    this.supplementaryInfoForm.get('readingOtherText').reset();
                    this.supplementaryInfoForm.get('readingOtherText').disable({onlySelf: false, emitEvent: false});
                    this.supplementaryInfoForm.get('readingOtherOpt').reset();
                    this.supplementaryInfoForm.get('readingOtherOpt').disable({onlySelf: false, emitEvent: false});
                    break;
                case 'written': 
                    this.isWrittenOtherLangEnabled = false;
                    this.supplementaryInfoForm.get('writtenOtherText').reset();
                    this.supplementaryInfoForm.get('writtenOtherText').disable({onlySelf: false, emitEvent: false});
                    this.supplementaryInfoForm.get('writtenOtherOpt').reset();
                    this.supplementaryInfoForm.get('writtenOtherOpt').disable({onlySelf: false, emitEvent: false});
                    break;
                default:
                    break;
            }
        }       

    }
}
