import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import { Claim } from "src/app/model/claim.model";
import { PoliceReport } from "src/app/model/motor/police-report.model";
import { ClaimService } from "src/app/services/claim.service";
import { SideMenuService } from "src/app/services/side-menu.service";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { ClaimsBaseComponent } from "src/app/claim-pages/claim-base.component";

@Component({
    selector: 'app-pa-authority-report',
    templateUrl: './authority-report.component.html'
})
export class PaAuthorityReportComponent extends ClaimsBaseComponent implements OnInit {

    policeReportForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    policeReport: PoliceReport;
    isFire: boolean;

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private transactionInfoService: TransactionInfoService,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.policeReport = this.claim.getClaimPersonalAccident().getPoliceReport();
    }

    ngOnInit() {
        this.pushGAPageView();
        this.policeReportForm = this.fb.group({
            hasReportedIncidentToPolice: [super.getBooleanString(this.policeReport.getHasReportedIncidentToPolice())],
            dateOfReport: [this.policeReport.getDateOfReport()],
            referenceNumber: [this.policeReport.getPoliceReportNumber()]
        });
    }

    ngAfterViewInit() {

        this.policeReportForm.valueChanges.subscribe(data => {
            if (this.policeReportForm.get('hasReportedIncidentToPolice').value != null) {
                this.policeReport.setHasReportedIncidentToPolice(this.policeReportForm.get('hasReportedIncidentToPolice').value == 'true');
            }
            this.policeReport.setDateOfReport(super.getValueInComponent('dateOfReport'));
            this.policeReport.setPoliceReportNumber(super.getValueInComponent('referenceNumber'))
        });

        // Stop validation of fields when not required.
        let self = this;
        
        setTimeout(() => {self.hidePoliceReport(self.getBooleanString(self.policeReport.getHasReportedIncidentToPolice()))},10);
        this.policeReportForm.get('hasReportedIncidentToPolice').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hidePoliceReport(data);
        });

    }
    
    hidePoliceReport(hasReportedIncidentToPolice) {
        if (hasReportedIncidentToPolice == 'true') {
            this.policeReportForm.get('dateOfReport').enable({onlySelf: false, emitEvent: false});
            this.policeReportForm.get('referenceNumber').enable({onlySelf: false, emitEvent: false});
        } else {
            this.policeReportForm.get('referenceNumber').reset();
            this.policeReportForm.get('dateOfReport').reset();
            this.policeReportForm.get('referenceNumber').disable({onlySelf: false, emitEvent: false});
            this.policeReportForm.get('dateOfReport').disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 1});
            let url = this.claim.getCountry().toLowerCase() === 'sgp' ? "/claimform/anotherInsurance" 
                        : "/claimform/paOthers/supportDoc";
            this.router.navigate([url], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.router.navigate(["/claimform/paOthers/details"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.policeReportForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAPageView() {
        let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep() + ' – Report the loss';
        let vPath = this.transactionInfoService.getTransactionInfo().getGaVPath() + '/report-the-loss';
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': pageStep,
            'vPath': vPath,
            'event': 'vpageview'
        });
    }
}
