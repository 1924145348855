import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimTravel} from "../../../../model/claim-travel.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {MissConnection} from "../../../../model/travel/miss-connection.model";
import {takeUntil} from "rxjs";
import {UtilitiesService} from "../../../../utilities/utilities.service";


@Component({
  selector: 'app-loss-of-miss-connect',
  templateUrl: './loss-of-miss-connect.component.html',
  styleUrls: ['./loss-of-miss-connect.component.css']
})

export class LossOfMissConnectComponent extends ClaimsBaseComponent implements OnInit {
    missConnectExtraCostForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    missConnection: MissConnection;
    currency = '';

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.missConnection = this.claim.getClaimTravel().getMissConnection();
        this.currency = UtilitiesService.getCountryCurrency(this.claim.getCountry());

    }

    ngOnInit() {

        this.pushGAViewConnectLoss();

        this.missConnectExtraCostForm = this.fb.group({
            missConnectTransCost: [this.missConnection.getExtraTransportationCost()],
            missConnectAccomCost: [this.missConnection.getExtraAccommodationCost()],
            misConTransportationCurrency: [this.missConnection.getExtraTransportationCurrency()],
            misConAccommodationCurrency: [this.missConnection.getExtraAccommodationCurrency()],
            hasMissConnectAccomCost: [super.getBooleanString(this.missConnection.getHasExtAccomCost())],
            hasMissConnectTransCost: [super.getBooleanString(this.missConnection.getHasExtTransCost())]
        });
    }

    ngAfterViewInit() {

        this.missConnectExtraCostForm.valueChanges.subscribe(data => {

            let missConnect: MissConnection = this.claim.getClaimTravel().getMissConnection();

            missConnect.setExtraTransportationCost(super.getValueInComponent('missConnectTransCost'));
            missConnect.setExtraAccommodationCost(super.getValueInComponent('missConnectAccomCost'));
            missConnect.setExtraTransportationCurrency(this.missConnectExtraCostForm.get('misConTransportationCurrency').value);
            missConnect.setExtraAccommodationCurrency(this.missConnectExtraCostForm.get('misConAccommodationCurrency').value);
            if (this.missConnectExtraCostForm.get('hasMissConnectAccomCost').value != null) {
                missConnect.setHasExtAccomCost(this.missConnectExtraCostForm.get('hasMissConnectAccomCost').value == 'true');
            }
            if (this.missConnectExtraCostForm.get('hasMissConnectTransCost').value != null) {
                missConnect.setHasExtTransCost(this.missConnectExtraCostForm.get('hasMissConnectTransCost').value == 'true');
            }

            this.validateClaimDetailsComplete();
        });

        // Stop validation of fields when not required.
        let self = this;
        setTimeout(function () {self.hideExtraTransCost(self.getBooleanString(self.missConnection.getHasExtTransCost())),10});
        this.missConnectExtraCostForm.get('hasMissConnectTransCost').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideExtraTransCost(data);
        });

        // Stop validation of fields when not required.
        setTimeout(function () {self.hideAccomExtraCost(self.getBooleanString(self.missConnection.getHasExtAccomCost())),10});
        this.missConnectExtraCostForm.get('hasMissConnectAccomCost').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideAccomExtraCost(data);
        });
    }

    hideExtraTransCost(hasTranExtraCost) {
        if (hasTranExtraCost == 'true') {
            this.missConnectExtraCostForm.get('missConnectTransCost').enable({onlySelf: false, emitEvent: false});
            this.missConnectExtraCostForm.get('misConTransportationCurrency').enable({onlySelf: false, emitEvent: false});
        } else {
            this.missConnectExtraCostForm.get('missConnectTransCost').reset();
            this.missConnectExtraCostForm.get('misConTransportationCurrency').setValue(this.currency);
            this.missConnectExtraCostForm.get('missConnectTransCost').disable({onlySelf: false, emitEvent: false});
            this.missConnectExtraCostForm.get('misConTransportationCurrency').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideAccomExtraCost(hasAccomExtraCost) {
        if (hasAccomExtraCost == 'true') {
            this.missConnectExtraCostForm.get('missConnectAccomCost').enable({onlySelf: false, emitEvent: false});
            this.missConnectExtraCostForm.get('misConAccommodationCurrency').enable({onlySelf: false, emitEvent: false});
        } else {
            this.missConnectExtraCostForm.get('missConnectAccomCost').reset();
            this.missConnectExtraCostForm.get('misConAccommodationCurrency').setValue(this.currency);
            this.missConnectExtraCostForm.get('missConnectAccomCost').disable({onlySelf: false, emitEvent: false});
            this.missConnectExtraCostForm.get('misConAccommodationCurrency').disable({onlySelf: false, emitEvent: false});
        }
    }

    back() {
        this.router.navigate(["/claimform/missedConnect/detailOfMissConnect"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 12, subMenuIndex: 1});

            this.router.navigate(["/claimform/missedConnect/missConnectSupportDoc/uploadDocument"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.missConnectExtraCostForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitClaimComplete({claimTypeId: 11, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 11, subMenuIndex: 1});
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.missConnectExtraCostForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewConnectLoss() {
        (<any>window).dataLayer.push({
            'pageStep': 'Missed Connection – Extra Cost',
            'vPath': '/claim/missed-connection/extra-cost',
            'event': 'vpageview',
        });
    }
}
