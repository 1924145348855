import {Component, Injector, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {Claim} from "../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import {TravelReRoute} from "../../../../model/travel/travel-reroute.model";
import * as moment from 'moment';
import {takeUntil} from "rxjs";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { UtilitiesService } from "src/app/utilities/utilities.service";

@Component({
  selector: 'app-rerouting-detail',
  templateUrl: './rerouting-detail.component.html',
  styleUrls: ['./rerouting-detail.component.css']
})

export class ReroutingDetailComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    reroutingForm: FormGroup;
    claim: Claim;
    travelReRoute: TravelReRoute;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private transactionInfoService: TransactionInfoService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.travelReRoute = this.claim.getClaimTravel().getTravelReRoute();
        if(this.travelReRoute.getReroutingOriginalSchedule() == null){
            this.travelReRoute.setReroutingOriginalSchedule(this.claim.getDateOfLoss());
        }
    }

    ngOnInit() {

        this.pushGAViewRerouteDetails();

        this.reroutingForm = this.fb.group({
            reroutingCountryOccurred: [this.travelReRoute.getReroutingCountryOccurred()],
            reroutingOriginalSchedule: [this.travelReRoute.getReroutingOriginalSchedule()],
            reroutingTranspoCancelOrDelay: [this.travelReRoute.getReroutingTranspoCancelOrDelay()],
            reroutingActualDelaySchedule: [this.travelReRoute.getReroutingActualDelaySchedule()],
            reroutingTotalDelay: [this.travelReRoute.getReroutingTotalDelay()]
        });
    }

    ngAfterViewInit() {
        this.reroutingForm.valueChanges.subscribe(data => {
            this.travelReRoute.setReroutingCountryOccurred(super.getValueInComponent('reroutingCountryOccurred'));
            this.travelReRoute.setReroutingCountryOccurredDesc(this.getTextInComponent('reroutingCountryOccurred'));
            this.travelReRoute.setReroutingOriginalSchedule(super.getValueInComponent('reroutingOriginalSchedule'));
            this.travelReRoute.setReroutingTranspoCancelOrDelay(super.getValueInComponent('reroutingTranspoCancelOrDelay'));
            this.travelReRoute.setReroutingActualDelaySchedule(super.getValueInComponent('reroutingActualDelaySchedule'));


            if (!UtilitiesService.isNullOrUndefined(this.reroutingForm.get('reroutingActualDelaySchedule').value)) {
                var result = this.getDateDiff(this.reroutingForm.get('reroutingOriginalSchedule').value, this.reroutingForm.get('reroutingActualDelaySchedule').value);
                this.travelReRoute.setReroutingTotalDelay(result);
            }

            this.validateClaimDetailsComplete();
        });

        //Stop validation of fields when not required.
        let self = this;
        setTimeout(function () {self.hideActualDelaySchedule(self.travelReRoute.getReroutingTranspoCancelOrDelay()),10});
        this.reroutingForm.get('reroutingTranspoCancelOrDelay').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideActualDelaySchedule(data);
        });
    }

    hideActualDelaySchedule(isActualOrDelayed) {
        if (isActualOrDelayed && isActualOrDelayed.toLowerCase() == 'delayed') {
            this.reroutingForm.get('reroutingActualDelaySchedule').enable({onlySelf: false, emitEvent: false});
        } else {
            this.reroutingForm.get('reroutingActualDelaySchedule').reset();
            this.reroutingForm.get('reroutingActualDelaySchedule').disable({onlySelf: false, emitEvent: false});
        }
    }

    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 10, subMenuIndex: 0});
            this.router.navigate(["/claimform/reRouting/meansOfTransportation"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.reroutingForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.reroutingForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitClaimComplete({claimTypeId: 10, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 10, subMenuIndex: 0});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    getDateDiff(startDate, endDate) {
        var result = "";
        if (startDate != null && endDate != null) {
            var dateStart = moment(new Date(startDate), "MM/dd/yyyy HH:mm");
            var dateEnd = moment(new Date(endDate), "MM/dd/yyyy HH:mm");
            var duration = moment.duration(dateEnd.diff(dateStart));
    
            var days = Math.floor(duration.asDays());
            var hours = Math.floor(duration.asHours());
            var minutes = duration.asMinutes();
    
            // hours = hours - (days * 24);
            minutes = minutes - (days * 24 * 60) - (hours * 60);
    
//            result = (days==0?"":days) + " " + hours + ":" + minutes
            result = hours + ":" + minutes
            console.log("result : " + result);
        } 
        return result;
    }

    //Google Analytics
    pushGAViewRerouteDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Travel Re-routing – Re-Routing Details',
            'vPath': '/claim/travel-re-routing/details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }

}
