import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {BaggageDelay} from "src/app/model/travel/baggage-delay.model";


@Component({
    selector: 'app-details-of-baggage-delay',
    templateUrl: './details-of-baggage-delay.component.html',
    styleUrls: ['./details-of-baggage-delay.component.css']
})
export class DetailsOfBaggageDelayComponent extends ClaimsBaseComponent implements OnInit {
    detailOfBaggageDelayForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    baggageDelay: BaggageDelay;

    constructor(private fb: FormBuilder, 
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {

            super(injector);
            this.claim = this.claimService.getClaim();
            this.baggageDelay = this.claim.getClaimTravel().getBaggageDelay();
            if(this.baggageDelay.getCollectBaggageTime() == null){
                this.baggageDelay.setCollectBaggageTime(this.claim.getDateOfLoss());
            }
    }

    ngOnInit() {

        this.pushGAViewBaggageDetails();

        this.detailOfBaggageDelayForm = this.fb.group({
            collectBaggageTime: [this.baggageDelay.getCollectBaggageTime()],
            wasDelayOnReturnFlight: [super.getBooleanString(this.baggageDelay.getWasDelayOnReturnFlight())],
            countryOccurred: [this.baggageDelay.getCountryOccurred()],
        });

    }

    ngAfterViewInit() {

        this.detailOfBaggageDelayForm.valueChanges.subscribe(data => {
            this.baggageDelay.setCollectBaggageTime(super.getValueInComponent('collectBaggageTime'));
            if (this.detailOfBaggageDelayForm.get('wasDelayOnReturnFlight').value != null) {
                this.baggageDelay.setWasDelayOnReturnFlight(this.detailOfBaggageDelayForm.get('wasDelayOnReturnFlight').value == 'true');
            }
            this.baggageDelay.setCountryOccurred(super.getValueInComponent('countryOccurred'));
            this.baggageDelay.setCountryOccurredDesc(super.getTextInComponent('countryOccurred'));

            this.validateClaimDetailsComplete();
        });
        console.log("BaggageTime"+this.baggageDelay.getCollectBaggageTime());
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 3, subMenuIndex: 0});

            this.router.navigate(["/claimform/baggageDelay/emergencyPurchasedItem"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/claimDetail"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.detailOfBaggageDelayForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.detailOfBaggageDelayForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitClaimComplete({claimTypeId: 3, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 3, subMenuIndex: 0});
        }
    }

    //Google Analytics
    pushGAViewBaggageDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Baggage Delay - Details',
            'vPath': '/claim/baggage-delay/details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }

}
