import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {Claim} from "../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import {ClinicalExpenses} from "../../../../model/domestic-helper/clinical-expenses.model";
import { PlanHelperService } from 'src/app/services/plan-helper.service';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { DomesticInfoPlan } from 'src/app/model/domestic-helper/domestic-info-plan.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { UtilitiesService } from "src/app/utilities/utilities.service";

@Component({
  selector: 'app-clinical-expenses-conditions',
  templateUrl: './clinical-expenses-conditions.component.html',
  styleUrls: ['./clinical-expenses-conditions.component.css']
})

export class ClinicalExpensesConditionsComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    clinicalForm: FormGroup;
    claim: Claim;
    clinicalExpenses: ClinicalExpenses;
    transactionInfo: TransactionInfo;
    domesticInfoPlan : DomesticInfoPlan;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private planHelperService : PlanHelperService,
                private transactionInfoService : TransactionInfoService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.clinicalExpenses = this.claim.getClaimDomesticHelper().getClinicalExpenses();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {

        this.pushGAViewClinicalExpensesConditions();

        let planCode = this.claim.getPlanCode();
        let catalogCode = this.claim.getCatalogueCode();
        let country = this.transactionInfo.getCountry();
    
        this.domesticInfoPlan = this.planHelperService.getDomesticPlanDetails(planCode, catalogCode, country);
        this.clinicalExpenses.setCancerHeartDiseaseExtensionApplicability(this.domesticInfoPlan.cancerHeartDiseaseExtensionApplicability);
        this.clinicalForm = this.fb.group({
            hasRelatedConditionsForSickness: [super.getBooleanString(this.clinicalExpenses.getHasRelatedConditionsForSickness())],
            hasRelatedConditionsForInjury: [super.getBooleanString(this.clinicalExpenses.getHasRelatedConditionsForInjury())],
            sicknessRelatedCondition: [this.clinicalExpenses.getSicknessRelatedCondition()],
            injuryRelatedCondition: [this.clinicalExpenses.getInjuryRelatedCondition()]
        });
    }

    ngAfterViewInit() {

        setTimeout(() => {
            this.clinicalForm.valueChanges.subscribe(data => {
                let clinicalExpenses: ClinicalExpenses = this.claim.getClaimDomesticHelper().getClinicalExpenses();
                clinicalExpenses.setHasRelatedConditionsForSickness(this.clinicalForm.get('hasRelatedConditionsForSickness').value == 'true');
                clinicalExpenses.setHasRelatedConditionsForSicknessDesc(super.getSelectedRadioText('hasRelatedConditionsForSickness'));
                clinicalExpenses.setHasRelatedConditionsForInjury(this.clinicalForm.get('hasRelatedConditionsForInjury').value == 'true');

                if (!UtilitiesService.isNullOrUndefined(this.clinicalForm.get('hasRelatedConditionsForSickness').value)) {
                    clinicalExpenses.setSicknessRelatedCondition(super.getValueInComponent('sicknessRelatedCondition'));
                }

                if (!UtilitiesService.isNullOrUndefined(this.clinicalForm.get('hasRelatedConditionsForInjury').value)) {
                    clinicalExpenses.setInjuryRelatedCondition(super.getValueInComponent('injuryRelatedCondition'));
                }

            });
        }, 10);

        // Stop validation of fields when not required.
        let self = this;

        setTimeout(function () {self.hideShowSicknessRelatedConditions(self.getBooleanString(self.clinicalExpenses.getHasRelatedConditionsForSickness())),10});
            this.clinicalForm.get('hasRelatedConditionsForSickness').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowSicknessRelatedConditions(data);
        });

        setTimeout(function () {self.hideShowInjuryRelatedConditions(self.getBooleanString(self.clinicalExpenses.getHasRelatedConditionsForInjury())),10});
            this.clinicalForm.get('hasRelatedConditionsForInjury').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowInjuryRelatedConditions(data);
            });


        
    }

    hideShowSicknessRelatedConditions(value) {
        if (value == 'true') {
            this.clinicalForm.get('sicknessRelatedCondition').enable({onlySelf: false, emitEvent: false});
        } else {
            this.clinicalForm.get('sicknessRelatedCondition').reset();
            this.clinicalForm.get('sicknessRelatedCondition').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideShowInjuryRelatedConditions(value) {
        if (value == 'true') {
            this.clinicalForm.get('injuryRelatedCondition').enable({onlySelf: false, emitEvent: false});
        } else {
            this.clinicalForm.get('injuryRelatedCondition').reset();
            this.clinicalForm.get('injuryRelatedCondition').disable({onlySelf: false, emitEvent: false});
        }
    }

    back() {
        this.router.navigate(["/claimform/clinical/detailsOfClinicalExpenses"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 1});
            this.router.navigate(["/claimform/clinical/detailsOfClinicalVisit"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.clinicalForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewClinicalExpensesConditions() {
        (<any>window).dataLayer.push({
            'pageStep': 'Clinical Expenses – Related Conditions',
            'vPath': '/claim/clinical-expenses/related-conditions',
            'event': 'vpageview'         
        });
    }


}
