import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, FormArray} from "@angular/forms";
import {SideMenuService} from "../../../../../services/side-menu.service";
import {ClaimsBaseComponent} from "../../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {MotorHelperService} from '../../../../../services/motor-helper.service';
import { MotorAccidentCollission } from "src/app/model/motor/motor-accident-collission.model";
import {VehicleItemDetail} from '../../../../../model/motor/vehicle-item-detail.model';
import {InjuredItemDetail} from '../../../../../model/motor/injured-item-detail.model';
import {PropertyItemDetail} from '../../../../../model/motor/property-item-detail.model';
import {takeUntil} from 'rxjs/operators';
import { UtilitiesService } from "src/app/utilities/utilities.service";

@Component({
    selector: 'app-damage-details',
    templateUrl: './damage-details.component.html',
    styleUrls: ['./damage-details.component.css']
})

export class ThirdPartyDetailsComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    damageForm: FormGroup;
    damageTypeList = [];
    collision: MotorAccidentCollission;
    isVehicle: Boolean = false;
    isPerson: Boolean = false;
    isProperty: Boolean = false;
    showErrMsg: boolean = false;

    vehicleDamageItemsArray: VehicleItemDetail[] = [];
    injuredItemsArray: InjuredItemDetail[] = [];
    propertyDamageItemsArray: PropertyItemDetail[] = [];


    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private motorHelperService: MotorHelperService,
                private injector : Injector) {

        super(injector);
        this.collision = this.claim.getClaimMotor().getCollission();
    }

    ngOnInit() {

        this.pushGA();

        this.vehicleDamageItemsArray = this.collision.getVehicleDamageItemList();
        this.injuredItemsArray = this.collision.getInjuredItemList();
        this.propertyDamageItemsArray = this.collision.getPropertyDamageItemList();

        // get damage part list - vehicle or person or property list
        this.damageTypeList = this.motorHelperService.getDamageType();

        if(this.collision.getDetailsOfDamagedType().length != 0){
            this.collision.setHasSelectedDamageType(true);
            this.buildExistingDamageTypeList();
        }

        this.damageForm = this.fb.group({
            hasAccidentInvolve: [super.getBooleanString(this.collision.getHasAccidentInvolve())],
            didYouHit: [this.collision.getDidYouHit()],
            vehicleItem: this.fb.array(this.buildVehicleStoredItems(this.vehicleDamageItemsArray)),
            personItem:  this.fb.array(this.buildInjuredStoredItems(this.injuredItemsArray)),
            propertyItem:  this.fb.array(this.buildPropertyStoredItems(this.propertyDamageItemsArray)),
            hasPaidOrReceived: [super.getBooleanString(this.collision.getHasPaidOrReceived())]
        });


        this.initilizeItemsArray();

    }

    ngAfterViewInit() {

        this.damageForm.valueChanges.subscribe(data => {

            this.collision.setDidYouHit(this.damageForm.get('didYouHit').value);
            if(this.damageForm.get('hasAccidentInvolve').value != null) {
                this.collision.setHasAccidentInvolve(this.damageForm.get('hasAccidentInvolve').value == 'true')
            }
            if(this.damageForm.get('hasPaidOrReceived').value != null) {
                this.collision.setHasPaidOrReceived(this.damageForm.get('hasPaidOrReceived').value == 'true')
            }

            if(this.collision.getHasAccidentInvolve){
                if(!UtilitiesService.isNullOrUndefined(data.personItem) && Array.isArray(data.personItem) && data.personItem.length > 0){
                    this.injuredItemsArray = [];
                    data.personItem.forEach((item, i) => {

                        let injuredDamageItemDetails : InjuredItemDetail = new InjuredItemDetail();
                        injuredDamageItemDetails.setExtentOfInjury(item.extentOfInjury);
                        injuredDamageItemDetails.setExtentOfInjuryDesc(super.getIndexedTextInComponent("extentOfInjury", i));
                        injuredDamageItemDetails.setAgeGroup(item.ageGroup);
                        injuredDamageItemDetails.setAgeGroupDesc(super.getIndexedTextInComponent("ageGroup", i));
                        injuredDamageItemDetails.setRelationship(item.relationship);
                        injuredDamageItemDetails.setRelationshipDesc(super.getIndexedTextInComponent("relationship", i));
                        injuredDamageItemDetails.setInjuredPersonName(item.injuredPersonName);
                        injuredDamageItemDetails.setInjuredPersonNumber(item.injuredPersonNumber);
                        this.injuredItemsArray.push(injuredDamageItemDetails);
                    });
                    this.collision.setInjuredItemList(this.injuredItemsArray);
                }

                if(!UtilitiesService.isNullOrUndefined(data.vehicleItem) && Array.isArray(data.vehicleItem) && data.vehicleItem.length > 0){
                    this.vehicleDamageItemsArray = [];
                    for (let item of data.vehicleItem) {

                        let vehicleDamageItemDetails: VehicleItemDetail = new VehicleItemDetail();
                        vehicleDamageItemDetails.setVehicleRegNo(item.vehicleRegNo);
                        vehicleDamageItemDetails.setVehicleOwnerName(item.vehicleOwnerName);
                        vehicleDamageItemDetails.setVehicleOwnerNumber(item.vehicleOwnerNumber);
                        vehicleDamageItemDetails.setOtherVehicleInsured(item.otherVehicleInsured);
                        this.vehicleDamageItemsArray.push(vehicleDamageItemDetails);
                    }
                    this.collision.setVehicleDamageItemList(this.vehicleDamageItemsArray);
                }

                if(!UtilitiesService.isNullOrUndefined(data.propertyItem) && Array.isArray(data.propertyItem) && data.propertyItem.length > 0){
                    this.propertyDamageItemsArray = [];
                    for (let item of data.propertyItem) {

                        let propertyDamageItemDetails : PropertyItemDetail = new PropertyItemDetail();
                        propertyDamageItemDetails.setWhatDidYouHit(item.whatDidYouHit);
                        propertyDamageItemDetails.setPropertyOwnerName(item.propertyOwnerName);
                        propertyDamageItemDetails.setPropertyOwnerNo(item.propertyOwnerNo);
                        this.propertyDamageItemsArray.push(propertyDamageItemDetails);
                    }
                    this.collision.setPropertyDamageItemList(this.propertyDamageItemsArray);
                }
            }
        });

        this.damageForm.get('hasAccidentInvolve').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideConditionalField(data);
        });

    }

    hideConditionalField(hasAccidentInvolved) {

        if (!UtilitiesService.isNullOrUndefined(hasAccidentInvolved) && (hasAccidentInvolved == false || hasAccidentInvolved == "false")) {

            setTimeout(() => {
                this.resetThirdPartyDetails();
                this.initilizeItemsArray();
                this.claim.getClaimMotor().getCollission().setHasPaidOrReceived(null);
                this.damageForm.get('hasPaidOrReceived').setValue(null);
                this.damageForm.get('hasPaidOrReceived').reset();
                this.damageForm.get('hasPaidOrReceived').disable({onlySelf: false, emitEvent: false});
            }, 200);
        } else {
            this.damageForm.get('hasPaidOrReceived').enable({onlySelf: false, emitEvent: false});
        }

    }

    initilizeItemsArray (){
        // Populate the first item model row
        if (this.vehicleDamageItemsArray == null) {
            this.vehicleDamageItemsArray = [];
            this.collision.setVehicleDamageItemList(this.vehicleDamageItemsArray);
        }

        if (this.vehicleDamageItemsArray.length == 0) {
            let vehicleItemDetail: VehicleItemDetail = new VehicleItemDetail();
            this.vehicleDamageItemsArray.push(vehicleItemDetail);
        }

        if (this.injuredItemsArray == null) {
            this.injuredItemsArray = [];
            this.collision.setInjuredItemList(this.injuredItemsArray);
        }

        if (this.injuredItemsArray.length == 0) {
            let injuredItemDetail: InjuredItemDetail = new InjuredItemDetail();
            this.injuredItemsArray.push(injuredItemDetail);
        }


        if (this.propertyDamageItemsArray == null) {
            this.propertyDamageItemsArray = [];
            this.collision.setPropertyDamageItemList(this.propertyDamageItemsArray);
        }

        if (this.propertyDamageItemsArray.length == 0) {
            let propertyItemDetail: PropertyItemDetail = new PropertyItemDetail();
            this.propertyDamageItemsArray.push(propertyItemDetail);
        }
    }

    addDamageItem(damageType: string): void {
        let self = this;
        setTimeout(function () {
            if(damageType == "vehicleItem"){
                let items = self.damageForm.get('vehicleItem') as FormArray;
                let newItem : FormGroup  = self.createVehicleDefaultItems();
                items.push(newItem);
            } else if (damageType == "personItem"){
                let items = self.damageForm.get('personItem') as FormArray;
                let newItem: FormGroup = self.createInjuredDefaultItems();
                items.push(newItem);
            } else if (damageType == "propertyItem"){
                let items = self.damageForm.get('propertyItem') as FormArray;
                let newItem :FormGroup = self.createPropertyDefaultItems();
                items.push(newItem);
            }
        }, 10);
    }

    removeDamageItem(damageType: string, i: number) {
        let damageItem = this.damageForm.get(damageType) as FormArray;
        damageItem.removeAt(i);

        damageItem.value.forEach(formItem =>{
            this.injuredItemsArray.forEach(item =>{
                if (item.getExtentOfInjury() === formItem.extentOfInjury) {
                    item.setExtentOfInjuryDesc(formItem.extentOfInjuryDesc);
                }
                if (item.getAgeGroup() === formItem.ageGroup) {
                    item.setAgeGroupDesc(formItem.ageGroupDesc);
                }
                if (item.getRelationship() === formItem.relationship) {
                    item.setRelationshipDesc(formItem.relationshipDesc);
                }
            });
        });
    }

    enableDamageTypeDetails(damageType: string) {
        console.log("enableDamageTypeDetails: METHOD");

        if(damageType == "Vehicle(s)"){
            this.damageForm.get('vehicleItem').enable({onlySelf: false, emitEvent: false});
        } else if (damageType == "Person(s)"){
            this.damageForm.get('personItem').enable({onlySelf: false, emitEvent: false});
        } else if (damageType == "Property"){
            this.damageForm.get('propertyItem').enable({onlySelf: false, emitEvent: false});
        } else if (damageType == "All") {
            this.damageForm.get('vehicleItem').reset();
            this.damageForm.get('vehicleItem').disable({onlySelf: false, emitEvent: false});
            this.damageForm.get('personItem').reset();
            this.damageForm.get('personItem').disable({onlySelf: false, emitEvent: false});
            this.damageForm.get('propertyItem').reset();
            this.damageForm.get('propertyItem').disable({onlySelf: false, emitEvent: false});
        }
    }

    disableDamageTypeDetails(damageType: string) {
        console.log("disableDamageTypeDetails: METHOD");

        if(damageType == "Vehicle(s)"){
            this.damageForm.get('vehicleItem').reset();
            this.damageForm.get('vehicleItem').disable({onlySelf: false, emitEvent: false});

        } else if (damageType == "Person(s)"){
            this.damageForm.get('personItem').reset();
            this.damageForm.get('personItem').disable({onlySelf: false, emitEvent: false});
        } else if (damageType == "Property"){
            this.damageForm.get('propertyItem').reset();
            this.damageForm.get('propertyItem').disable({onlySelf: false, emitEvent: false});
        } else if (damageType == "All") {
            this.damageForm.get('vehicleItem').reset();
            this.damageForm.get('vehicleItem').disable({onlySelf: false, emitEvent: false});
            this.damageForm.get('personItem').reset();
            this.damageForm.get('personItem').disable({onlySelf: false, emitEvent: false});
            this.damageForm.get('propertyItem').reset();
            this.damageForm.get('propertyItem').disable({onlySelf: false, emitEvent: false});
        }

    }

    back() {
        let coverType = this.claim.getClaimMotor().getCoverType();
        if(coverType === "CO" || coverType === "TF" || this.isNotNullOrUndefinedStr(this.claim.getIsCoverNotePreNumbered())){
            this.router.navigate(["/claimform/collission/vehicleDamage"], {
                relativeTo: this.activatedRoute
            });
        }else if(this.claim.getClaimMotor().getCollission().getWasVehicleParkWhenDamage() !== true){
            this.router.navigate(["/claimform/collission/driverDetails"], {
                relativeTo: this.activatedRoute
            });
        }else{
            this.router.navigate(["/claimform/collission/accidentDetails"], {
                relativeTo: this.activatedRoute
            });
        }

    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 3});
            this.router.navigate(["/claimform/collission/policeReport"], {
                relativeTo: this.activatedRoute
            });
        }
    }


    validateForm(): boolean {
        let damageTypeSelectedItems = this.collision.getDetailsOfDamagedType();
        let isValidated : boolean = true;

        if(this.collision.getHasAccidentInvolve() && damageTypeSelectedItems.length == 0){
            this.collision.setHasSelectedDamageType(false);
            isValidated = false;
            this.showErrMsg = true;
        }

        return super.validateForm(this.damageForm) && isValidated;
    }

    buildVehicleStoredItems(vehicleDamageItemsArray: VehicleItemDetail[]): FormGroup[] {
        let formGroupArray : FormGroup[] = [];
        if(vehicleDamageItemsArray != null && vehicleDamageItemsArray.length > 0 ) {
            for (let i = 0; i < vehicleDamageItemsArray.length; i++) {
                let rowFormGroup = this.fb.group({
                    vehicleRegNo: [vehicleDamageItemsArray[i].getVehicleRegNo()],
                    vehicleOwnerName: [vehicleDamageItemsArray[i].getVehicleOwnerName()],
                    vehicleOwnerNumber: [vehicleDamageItemsArray[i].getVehicleOwnerNumber()],
                    otherVehicleInsured: [vehicleDamageItemsArray[i].getOtherVehicleInsured()]
                });

                formGroupArray.push(rowFormGroup);
            }
        } else {
            formGroupArray.push(this.createVehicleDefaultItems());
        }

        return formGroupArray;
    }

    createVehicleDefaultItems(): FormGroup {
        return this.fb.group({
            vehicleRegNo: '',
            vehicleOwnerName: '',
            vehicleOwnerNumber: '',
            otherVehicleInsured: ''
        });
    }


    buildInjuredStoredItems(injuredDamageItemsArray: InjuredItemDetail[]): FormGroup[] {
        let formGroupArray : FormGroup[] = [];
        if(injuredDamageItemsArray != null && injuredDamageItemsArray.length > 0 ) {
            for (let i = 0; i < injuredDamageItemsArray.length; i++) {
                let rowFormGroup = this.fb.group({
                    injuredPersonName: [injuredDamageItemsArray[i].getInjuredPersonName()],
                    ageGroup: [injuredDamageItemsArray[i].getAgeGroup()],
                    ageGroupDesc: [injuredDamageItemsArray[i].getAgeGroupDesc()],
                    relationship: [injuredDamageItemsArray[i].getRelationship()],
                    relationshipDesc: [injuredDamageItemsArray[i].getRelationshipDesc()],
                    injuredPersonNumber: [injuredDamageItemsArray[i].getInjuredPersonNumber()],
                    extentOfInjury: [injuredDamageItemsArray[i].getExtentOfInjury()],
                    extentOfInjuryDesc: [injuredDamageItemsArray[i].getExtentOfInjuryDesc()]
                });

                formGroupArray.push(rowFormGroup);
            }
        } else {
            formGroupArray.push(this.createInjuredDefaultItems());
        }

        return formGroupArray;
    }

    createInjuredDefaultItems(): FormGroup {
        return this.fb.group({
            injuredPersonName: '',
            ageGroup: '',
            ageGroupDesc: '',
            relationship: '',
            relationshipDesc: '',
            injuredPersonNumber: '',
            extentOfInjury: '',
            extentOfInjuryDesc: ''
        });
    }

    buildPropertyStoredItems(propertyDamageItemsArray: PropertyItemDetail[]): FormGroup[] {
        let formGroupArray : FormGroup[] = [];
        if(propertyDamageItemsArray != null && propertyDamageItemsArray.length > 0 ) {
            for (let i = 0; i < propertyDamageItemsArray.length; i++) {
                let rowFormGroup = this.fb.group({
                    whatDidYouHit: [propertyDamageItemsArray[i].getWhatDidYouHit()],
                    propertyOwnerName: [propertyDamageItemsArray[i].getPropertyOwnerName()],
                    propertyOwnerNo: [propertyDamageItemsArray[i].getPropertyOwnerNo()]
                });

                formGroupArray.push(rowFormGroup);
            }
        } else {
            formGroupArray.push(this.createPropertyDefaultItems());
        }

        return formGroupArray;
    }

    createPropertyDefaultItems(): FormGroup {
        return this.fb.group({
            whatDidYouHit: '',
            propertyOwnerName: '',
            propertyOwnerNo: ''
        });
    }


    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    buildExistingDamageTypeList(){
        let existingDamageTypeList = this.collision.getDetailsOfDamagedType();

        for (let existingDamageType of existingDamageTypeList) {
            for (let damageType of this.damageTypeList) {
                if(existingDamageType == damageType.damageTypeValue){
                    damageType.selected = true;
                    if(damageType.damageTypeValue === "Vehicle(s)"){
                        this.isVehicle = true;
                    }else if(damageType.damageTypeValue === "Person(s)"){
                        this.isPerson = true;
                    }else if(damageType.damageTypeValue === "Property"){
                        this.isProperty = true;
                    }
                }
            }
        }
    }

    selectDamageType(selectedValue: string) {
        this.showErrMsg = false;
        let motorWsSelectedItems: string[]  = this.collision.getDetailsOfDamagedType().length > 0 ? this.collision.getDetailsOfDamagedType() : [];
        let motorWsSelectedItemsDesc: string[] =  this.collision.getDetailsOfDamagedTypeDesc().length > 0 ? this.collision.getDetailsOfDamagedTypeDesc() : [];
        let self = this;
        self.damageTypeList.forEach(function (damageTypeList, index){
            if (damageTypeList.damageTypeValue == selectedValue) {
                if (damageTypeList.selected) {
                    damageTypeList.selected = false;
                    setTimeout(function () {self.isEnabledDisabledDamageType(selectedValue, "Disable"),10});
                    self.removeDamageTypeList(selectedValue,motorWsSelectedItems, motorWsSelectedItemsDesc);
                } else {
                    damageTypeList.selected = true;
                    setTimeout(function () {self.isEnabledDisabledDamageType(selectedValue, "Enable"),10});
                    motorWsSelectedItems.push(damageTypeList.damageTypeValue);
                    let damageTypeNameText = damageTypeList.damageTypeName;
                    motorWsSelectedItemsDesc.push(damageTypeNameText);
                    self.collision.setHasSelectedDamageType(true);
                    self.enableDamageTypeDetails(damageTypeList.damageTypeValue);
                }
                self.collision.setDetailsOfDamagedType(motorWsSelectedItems);
                self.collision.setDetailsOfDamagedTypeDesc(motorWsSelectedItemsDesc);
            }

        });
    }

    isEnabledDisabledDamageType(damageType: string, isEnableDisableType: string){

        let type = isEnableDisableType === "Enable" ? true : false;
        if(damageType === "Person(s)"){
            this.isPerson = type;
        }else if(damageType === "Vehicle(s)"){
            this.isVehicle = type;
        }else if(damageType === "Property"){
            this.isProperty = type;
        }
    }

    removeDamageTypeList(selectedValue: string, motorWsSelectedItems: string[], motorWsSelectedItemsDesc: string[]) {
        let indexSelected: number = -1 ; // determine index for description to remove or not
        for(let i= motorWsSelectedItems.length - 1; i >= 0; i--){
            if (motorWsSelectedItems[i] == selectedValue) {
                indexSelected = i;
                break;
            }
        }
    
        if (indexSelected >= 0) {
            motorWsSelectedItems.splice(indexSelected, 1);
            motorWsSelectedItemsDesc.splice(indexSelected, 1);
        }
    
        if (selectedValue == "Property") {
            this.propertyDamageItemsArray = [];
            this.collision.setPropertyDamageItemList(this.propertyDamageItemsArray);
           if (this.propertyDamageItemsArray.length == 0) { 
              let propertyItemDetail: PropertyItemDetail = new PropertyItemDetail();
              this.propertyDamageItemsArray.push(propertyItemDetail);
            }
        }else if(selectedValue == "Vehicle(s)"){
            this.vehicleDamageItemsArray = [];
            this.collision.setVehicleDamageItemList(this.vehicleDamageItemsArray);
            if (this.vehicleDamageItemsArray.length == 0) {
             let vehicleItemDetail: VehicleItemDetail = new VehicleItemDetail();
                this.vehicleDamageItemsArray.push(vehicleItemDetail);
            }
    
        }else if(selectedValue == "Person(s)"){
            this.injuredItemsArray = [];
            this.collision.setInjuredItemList(this.injuredItemsArray);
            if (this.injuredItemsArray.length == 0) {
                let injuredItemDetail: InjuredItemDetail = new InjuredItemDetail();
                this.injuredItemsArray.push(injuredItemDetail);
            }
    
        }

        this.disableDamageTypeDetails(selectedValue);
        this.collision.setDetailsOfDamagedType(motorWsSelectedItems);
        this.collision.setDetailsOfDamagedTypeDesc(motorWsSelectedItemsDesc);
    } 
     
    

    resetThirdPartyDetails() {
        let self = this;
        self.damageTypeList.forEach(function (damageTypeItem, index){
            if (damageTypeItem.selected) {
                damageTypeItem.selected = false;
            }
        });

        let emptyList: string[] = [];
        self.collision.setDetailsOfDamagedType(emptyList);
        self.collision.setDetailsOfDamagedTypeDesc(emptyList);
        this.damageForm.get('vehicleItem').reset();
        this.damageForm.get('vehicleItem').disable({onlySelf: false, emitEvent: false});
        this.damageForm.get('personItem').reset();
        this.damageForm.get('personItem').disable({onlySelf: false, emitEvent: false});
        this.damageForm.get('propertyItem').reset();
        this.damageForm.get('propertyItem').disable({onlySelf: false, emitEvent: false});
        this.damageForm.get('hasPaidOrReceived').reset();
        this.damageForm.get('hasPaidOrReceived').disable({onlySelf: false, emitEvent: false});
        this.showErrMsg = false;
    }

    //Google Analytics
    pushGA() {
        (<any>window).dataLayer.push({
            'pageStep': 'Motor Accident/Damage – Third Party Details',
            'vPath': '/claim/motor-accident-damage/third-party-details',
            'event': 'vpageview',
        });
    }

}