import {Component, Injector, OnInit, HostListener, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { ECAccidentDetails } from 'src/app/model/employee-compensation/ec-accident-details.model';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import {takeUntil} from 'rxjs/operators';
import {TransactionInfo} from '../../../model/transaction-info.model';
import {FatalCaseComponent} from '../fatal-case/fatal-case.component';
import {FatalCase} from '../../../model/employee-compensation/fatal-case.model';
import {ClaimTypes} from '../../../model/claim-type.model';
import {BodyPartsComponent} from '../injured-body-parts/body-parts/body-parts.component';

@Component({
    selector: 'app-ec-accident-details',
    templateUrl: './ec-accident-details.component.html'
})
export class ECAccidentDetailsComponent extends ClaimsBaseComponent implements OnInit {

    accidentDetailsForm: FormGroup;
    accidentDetails: ECAccidentDetails;
    showTotalBox = false;
    tooltipText = '';
    mouseX = 0;
    mouseY = 0;
    currentClaimType: string;
    transactionInfo: TransactionInfo;
    @ViewChild('fatalCase', {static: false}) fatalCaseComponent: FatalCaseComponent;
    @ViewChild('bodyParts', {static: false}) bodyPartsComponent: BodyPartsComponent;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private sideMenuService: SideMenuService) {
        super(injector);
        this.accidentDetails = this.claim.getClaimEmployeeCompensation().getAccidentDetails();
        this.transactionInfo = transactionInfoService.getTransactionInfo();
        if(!this.isNotNullOrUndefined(this.accidentDetails.getAccidentDate())){
            this.accidentDetails.setAccidentDate(this.claim.getDateOfLoss());
        }
        if (!this.isNotNullOrUndefined(this.accidentDetails.getMeridiem())) {
            this.accidentDetails.setMeridiem('a.m.');
        }
    }

    ngOnInit() {
        this.accidentDetailsForm = this.fb.group({
            accidentDate: [this.accidentDetails.getAccidentDate()],
            accidentAddress: [this.accidentDetails.getAccidentAddress()],
            accidentDescription: [this.accidentDetails.getAccidentDescription()],
            accidentType: [this.accidentDetails.getAccidentType()],
            accidentTypeOthers: [this.accidentDetails.getAccidentTypeOthers()],
            fallFromHeightOf: [this.accidentDetails.getFallFromHeightOf()],
            accidentResult: [this.accidentDetails.getResult()],
            occurredAtWork: [this.accidentDetails.getOccurredAtWork()],
            timeOfAccident: [this.accidentDetails.getTimeOfAccident()],
            meridiem: [this.accidentDetails.getMeridiem()],
            hospitalName: [this.accidentDetails.getHospital()],
            treatmentType: [this.accidentDetails.getTreatmentType()]
        });
    }

    ngAfterViewInit() {
        this.accidentDetailsForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.accidentDetails.setAccidentDate(super.getValueInComponent('accidentDate'));
                this.accidentDetails.setAccidentAddress(this.accidentDetailsForm.get('accidentAddress').value);
                this.accidentDetails.setAccidentDescription(this.accidentDetailsForm.get('accidentDescription').value);
                this.accidentDetails.setAccidentType(this.accidentDetailsForm.get('accidentType').value);
                this.accidentDetails.setAccidentTypeDesc(super.getTextInComponent('accidentType'));
                this.accidentDetails.setAccidentTypeOthers(this.accidentDetailsForm.get('accidentTypeOthers').value);
                this.accidentDetails.setFallFromHeightOf(this.accidentDetailsForm.get('fallFromHeightOf').value);
                this.accidentDetails.setResult(this.accidentDetailsForm.get('accidentResult').value);
                this.accidentDetails.setResultDesc(super.getSelectedRadioText('accidentResult'));
                this.accidentDetails.setOccurredAtWork(this.accidentDetailsForm.get('occurredAtWork').value);
                this.accidentDetails.setOccurredAtWorkDesc(super.getSelectedRadioText('occurredAtWork'));
                this.accidentDetails.setTimeOfAccident(this.accidentDetailsForm.get('timeOfAccident').value);
                this.accidentDetails.setMeridiem(this.accidentDetailsForm.get('meridiem').value);
                this.accidentDetails.setHospital(this.accidentDetailsForm.get('hospitalName').value);
                this.accidentDetails.setTreatmentType(super.getMultiSelectDropdownValue('treatmentType'));
        });

        this.accidentDetailsForm.get('accidentType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.validateAccidentTypeOthers(data);
        });

        this.accidentDetailsForm.get('accidentResult').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.validateResult(data);
        });

        this.accidentDetailsForm.get('treatmentType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.checkHasOutpatientTreatmentOnly(data);
        });
    }

    validateAccidentTypeOthers(fieldValue) {
        this.accidentDetailsForm.get('accidentTypeOthers').reset();
        this.accidentDetailsForm.get('accidentTypeOthers').disable({onlySelf: false, emitEvent: false});
        this.accidentDetailsForm.get('fallFromHeightOf').reset();
        this.accidentDetailsForm.get('fallFromHeightOf').disable({onlySelf: false, emitEvent: false});
        if (fieldValue == '4') {
            this.accidentDetailsForm.get('fallFromHeightOf').enable({onlySelf: false, emitEvent: false});
        } else if (fieldValue == '17_15') {
            this.accidentDetailsForm.get('accidentTypeOthers').enable({onlySelf: false, emitEvent: false});
        }
    }

    validateResult(data) {
        if (data?.toLowerCase() == 'injury') {
            this.claim.getClaimEmployeeCompensation().setFatalCase(new FatalCase());
        }
        this.sideMenuService.emitClaimTypeSubMenuShowUsingUrl({claimTypeId: 1, routeIndex: 5,
            routeUrl: "/claimform/form2/directSettlement", show: data?.toLowerCase() == 'injury'});
    }

    //Set outpatient treatment to empty if treatment type is not out-patient treatment ONLY
    checkHasOutpatientTreatmentOnly(data){
        if (!(this.claim.getCountry()?.toLowerCase() === 'hkg' && data?.length == 1 
                && data[0]?.value?.toLowerCase() == 'out-patient treatment')) {
            this.claim.getClaimEmployeeCompensation().setOutpatientTreatments([]);
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.accidentDetailsForm);
    }
    
    goToNext() {
        let isFatalCaseFormValid = this.accidentDetails.getResult()?.toLowerCase() == 'death' ? this.fatalCaseComponent.validateForm() : true;
        let isBodyPartsFormValid = this.transactionInfo.getCurrentClaimType() == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2B] ?
            this.bodyPartsComponent.validateBodyPartsForm() : true;
        if (this.validateForm() && isFatalCaseFormValid && isBodyPartsFormValid) {
            let route = this.transactionInfo.getRouteByCurrentUrl('/detailsOfAccident', true);
            if (route) {
                this.sideMenuService.emitClaimComplete({claimTypeId: route.claimIndex, subMenuIndex: route.routeIndex});
                this.router.navigate([route.routeUrl], {
                    relativeTo: this.activatedRoute
                })
            }
        }
    }

    back() {
        let route = this.transactionInfo.getRouteByCurrentUrl('/detailsOfAccident', false);
        this.router.navigate([route.routeUrl], {
            relativeTo: this.activatedRoute
        });
    }

    showModal() {
        this.showTotalBox = true;
    }
  
    getData(msg) {
        this.showTotalBox = msg;
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(e: MouseEvent) {
        this.mouseX = e.clientX;
        this.mouseY = e.clientY + window.scrollY ;
    }

    showTooltip(txt: string) {
        this.tooltipText = txt;
    }

    hideToolTip() {
        this.tooltipText = '';
    }

    onClickNotClose(e: any) {
        e.stopPropagation();
    }
}
