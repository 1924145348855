import { Component, OnInit, Injector } from '@angular/core';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimHome } from 'src/app/model/claim-home.model';
import { HomeDetails } from 'src/app/model/home/home-details.model';
import { takeUntil } from 'rxjs/operators';
import {HomeItem} from '../../../../../model/home/home-item.model';
import {SideMenuService} from "../../../../../services/side-menu.service";

@Component({
  selector: 'app-weather-details',
  templateUrl: './weather-details.component.html',
  styleUrls: ['./weather-details.component.css']
})
export class WeatherDetailsComponent  extends ClaimsBaseComponent implements OnInit {

  weatherForm: FormGroup;
  claimHome: ClaimHome;
  homeDetails: HomeDetails;
  showTotalBox = false;

  constructor(private fb: FormBuilder,
      private transactionInfoService: TransactionInfoService,
      private claimService: ClaimService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private injector: Injector) {

      super(injector);
      this.claim = this.claimService.getClaim();
      this.claimHome = this.claim.getClaimHome();
      this.homeDetails = this.claimHome.getHomeDetails();
  }

  ngOnInit() {

      this.pushGAWeatherDetailsView();

      this.weatherForm = this.fb.group({
          typeOfWeather: [this.homeDetails.getWeather().getTypeOfWeather()],
          typeOfWeatherOthers: [this.homeDetails.getWeather().getTypeOfWeatherOthers()],
          isThereSeriousDamage: [super.getBooleanString(this.homeDetails.getIsThereSeriousDamage())],
          isThereInteriorDamage: [super.getBooleanString(this.homeDetails.getIsThereInteriorDamage())],
          isThereFixturesDamage: [super.getBooleanString(this.homeDetails.getIsThereFixturesDamage())],
          damageDescription: [this.homeDetails.getDamageDescription()],
          isAnyItemDamaged: [super.getBooleanString(this.homeDetails.getIsAnyItemDamaged())],
          isAppointBuilder: [super.getBooleanString(this.homeDetails.getIsAppointBuilder())],
          isAssistBuilder: [super.getBooleanString(this.homeDetails.getIsAssistBuilder())],
          isThereWindowDamage: [super.getBooleanString(this.homeDetails.getIsThereWindowDamage())],
          isThePropertySafeToStayIn: [super.getBooleanString(this.homeDetails.getIsThePropertySafeToStayIn())]
      });
  }

  ngAfterViewInit() {

      this.weatherForm.valueChanges.subscribe(data => {
        this.homeDetails.getWeather().setTypeOfWeather(this.weatherForm.get('typeOfWeather').value);
        this.homeDetails.getWeather().setTypeOfWeatherDesc(super.getTextInComponent('typeOfWeather'));
        this.homeDetails.getWeather().setTypeOfWeatherOthers(this.weatherForm.get('typeOfWeatherOthers').value);
        if (this.weatherForm.get('isThePropertySafeToStayIn').value != null) {
            this.homeDetails.setIsThePropertySafeToStayIn(this.weatherForm.get('isThePropertySafeToStayIn').value == 'true');
        }
        if (this.weatherForm.get('isAppointBuilder').value != null) {
            this.homeDetails.setIsAppointBuilder(this.weatherForm.get('isAppointBuilder').value == 'true');
        }

        if (this.weatherForm.get('isThereSeriousDamage').value != null) {
            this.homeDetails.setIsThereSeriousDamage(this.weatherForm.get('isThereSeriousDamage').value == 'true');
        }
        this.homeDetails.setDamageDescription(this.weatherForm.get('damageDescription').value);
        if (this.weatherForm.get('isAnyItemDamaged').value != null) {
            this.homeDetails.setIsAnyItemDamaged(this.weatherForm.get('isAnyItemDamaged').value == 'true');
        }
        if (this.weatherForm.get('isAssistBuilder').value != null) {
            this.homeDetails.setIsAssistBuilder(this.weatherForm.get('isAssistBuilder').value == 'true');
        } 
        if (this.weatherForm.get('isThereWindowDamage').value != null) {
            this.homeDetails.setIsThereWindowDamage(this.weatherForm.get('isThereWindowDamage').value == 'true');
         } 
        if (this.weatherForm.get('isThereInteriorDamage').value !== null) {
            this.homeDetails.setIsThereInteriorDamage(this.weatherForm.get('isThereInteriorDamage').value == 'true');
        }
        if (this.weatherForm.get('isThereFixturesDamage').value !== null) {
            this.homeDetails.setIsThereFixturesDamage(this.weatherForm.get('isThereFixturesDamage').value == 'true');
        }
        
      });

      let self = this;

       // Stop validation of fields when not required.
       setTimeout(function () {self.hideAppointBuilder(self.getBooleanString(self.homeDetails.getIsThePropertySafeToStayIn())),10});
       this.weatherForm.get('isThePropertySafeToStayIn').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.hideAppointBuilder(data);
       });
       setTimeout(function () {self.toggleAssistBuilder(),10});
       this.weatherForm.get('isThereSeriousDamage').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.toggleAssistBuilder()
       });
       this.weatherForm.get('isThereInteriorDamage').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.toggleAssistBuilder();
       });
       this.weatherForm.get('isThereFixturesDamage').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.toggleAssistBuilder();
       });
        setTimeout(function () {self.showOthersField(self.homeDetails.getWeather().getTypeOfWeather()),10});
       this.weatherForm.get('typeOfWeather').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.showOthersField(data);
        });
       this.weatherForm.get('isAnyItemDamaged').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
          this.clearHomeItemList(data);
       });
  }

    hideAppointBuilder(isPropertySafeToStayIn) {
        if (isPropertySafeToStayIn === "false") {
            this.weatherForm.get('isAppointBuilder').enable({onlySelf: false, emitEvent: false});
        } else {
            this.weatherForm.get('isAppointBuilder').reset();
            this.weatherForm.get('isAppointBuilder').disable({onlySelf: false, emitEvent: false});
        }
    }

    toggleAssistBuilder() {
        let showAssistBuilder = (this.isNotNullOrUndefined(this.weatherForm.get('isThereSeriousDamage').value) && this.weatherForm.get('isThereSeriousDamage').value == "true") ||
            (this.isNotNullOrUndefined(this.weatherForm.get('isThereInteriorDamage').value) && this.weatherForm.get('isThereInteriorDamage').value == "true") ||
            (this.isNotNullOrUndefined(this.weatherForm.get('isThereFixturesDamage').value) && this.weatherForm.get('isThereFixturesDamage').value == "true") ? true : false;
        if (showAssistBuilder == true) {
            this.weatherForm.get('isAssistBuilder').enable({ onlySelf: false, emitEvent: false });
            this.weatherForm.get('damageDescription').enable({ onlySelf: false, emitEvent: false });
        } else {
            this.homeDetails.setIsAssistBuilder(false);
            this.weatherForm.get('isAssistBuilder').reset();
            this.weatherForm.get('isAssistBuilder').disable({ onlySelf: false, emitEvent: false });
            this.weatherForm.get('damageDescription').reset();
            this.weatherForm.get('damageDescription').disable({ onlySelf: false, emitEvent: false });
        }
    }

    showOthersField(showOthers) {
        if (showOthers === "Others") {
            this.weatherForm.get('typeOfWeatherOthers').enable({onlySelf: false, emitEvent: false});
        } else {
            this.weatherForm.get('typeOfWeatherOthers').reset();
            this.weatherForm.get('typeOfWeatherOthers').disable({onlySelf: false, emitEvent: false});
        }
    }

    clearHomeItemList(hasDamagedItems) {
        if (hasDamagedItems === "false") {
            this.homeDetails.setHomeItems([] as HomeItem[]);
        }
    }

  goToNext() {
    if (this.validateForm()) {
        let routes = this.transactionInfoService.getTransactionInfo().getDynamicRoutes();
      this.transactionInfoService.getTransactionInfo().setDynamicRoutes(this.transactionInfoService.getDynamicRoutingService().updateScreenVisibility(routes, 
          this.homeDetails.getIsAnyItemDamaged(), this.claim.getSelectedClaimTypesList()[0], "homeItems", this.router));
      this.transactionInfoService.getDynamicRoutingService().goToNextRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
    } 
  }

  validateForm(): boolean {
      return super.validateForm(this.weatherForm);
  }


  showModal() {
      console.log('showModel');
      this.showTotalBox = true;
  }

  getData(msg) {
      this.showTotalBox = msg;
  }

  back() {
    this.injector.get(SideMenuService).emitProgress(-1);
    this.router.navigate(["/claimform/claimDetail/selectClaim"], {
        relativeTo: this.activatedRoute
    }); 
  }

  pushGAWeatherDetailsView() {
    let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep() + ' - Incident Details';
    let vPath = '/claim/' + this.transactionInfoService.getTransactionInfo().getGaVPath() + '/incident-details';

    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).dataLayer.push({
      'pageStep': pageStep,
      'vPath': vPath,
      'event': 'vpageview',
      'ecommerce': {
        'checkout': {
            'actionField': {'step': 4}	 //Step4-Claim Details
          }
      }
    });
  }
}