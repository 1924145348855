import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { Claim } from 'src/app/model/claim.model';
import { ClaimService } from 'src/app/services/claim.service';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { HospitalCashDetailsComponent } from '../../claim-types/hospital-cash/hospital-cash-details/hospital-cash-details.component';

@Component({
    selector: 'app-pa-hospital-cash',
    templateUrl: './pa-hospital-cash.component.html'
})
export class PaHospitalCashComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    transactionInfo: TransactionInfo;
    showTotalBox = false;

    @ViewChild('hospitalCashDetails',  {static: false}) hospitalCash: HospitalCashDetailsComponent;

    constructor(private claimService: ClaimService,
                private transactionInfoService: TransactionInfoService,
                private sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {
        super(injector);
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH]);
        this.claim = this.claimService.getClaim();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {
        this.pushGAPageView();
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 0});
            this.sideMenuService.emitProgress(1);
            this.sideMenuService.emitComplete('finishChooseClaim');
            this.router.navigate(["/claimform/anotherInsurance"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return this.hospitalCash.validateForm();
    }

    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAPageView() {
        let pageStep = this.transactionInfo.getGaPageStep() + ' – Hospital Cash';
        let vPath = this.transactionInfo.getGaVPath() + '/pa-hospital-cash';
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': pageStep,
            'vPath': vPath,
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 6}
                }
            }
        });
    }
}
