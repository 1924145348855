import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from '@angular/router';
import {SideMenuService} from "../../../services/side-menu.service";
import {ClaimsBaseComponent} from "../../../claim-pages/claim-base.component";
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {UtilitiesService} from "../../../utilities/utilities.service";
import {TransactionInfo} from '../../../model/transaction-info.model';

declare var $: any;

@Component({
    selector: 'app-generic-claim-detail',
    templateUrl: './generic-claim-detail.component.html',
    styleUrls: ['./generic-claim-detail.component.css']
})
export class GenericClaimDetailComponent extends ClaimsBaseComponent implements OnInit {
    showTotalBox = false;
    isAgent = false;
    genericForm: FormGroup;
    currency: string;
    country: string;
    hasGenericClaimType = false;
    transactionInfo: TransactionInfo;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private injector : Injector,
                private fb: FormBuilder) {

        super(injector);
        this.transactionInfo = this.injector.get(TransactionInfoService).getTransactionInfo();
        this.isAgent = this.transactionInfo.getIsTransactionOrigin();
        this.country = this.transactionInfo.getRealCountry();
        this.hasGenericClaimType = ["HKG", "HGI"].indexOf(this.country) !== -1 && this.claim.getPolicyRisks()?.length > 1 &&
            this.isNotNullOrUndefined(this.claim.getPolicyRisks().find(riskItem =>  TransactionInfo.HK_EC_RISKS.indexOf(riskItem.getRiskType()) !== -1));
    }

    ngOnInit() {

        this.pushGAView();

        if (!this.isNotNullOrUndefinedStr(this.claim.getCurrencyOfAmount())){
            this.claim.setCurrencyOfAmount(UtilitiesService.getCountryCurrency(this.country));
        }

        if (!this.isNotNullOrUndefinedStr(this.claim.getClaimEventOccurred())) {
            this.claim.setClaimEventOccurred(this.country);
        }

        this.genericForm = this.fb.group({
            descIncident: [this.claim.getIncidentDescription()],
            countryOfOccurrence: [this.claim.getClaimEventOccurred()],
            estAmountLoss: [this.claim.getAmountClaimed()],
            estAmountLossCurrency: [this.claim.getCurrencyOfAmount()],
            dateOfLoss: [this.claim.getDateOfLoss()]
        });

    }

    ngAfterViewInit() {
        this.genericForm.valueChanges.subscribe(data => {
            this.claim.setIncidentDescription(super.getValueInComponent('descIncident'));
            this.claim.setClaimEventOccurred(super.getValueInComponent('countryOfOccurrence'));
            this.claim.setClaimEventOccurredDesc(this.getTextInComponent('countryOfOccurrence'));
            this.claim.setAmountClaimed(super.getValueInComponent('estAmountLoss'));
            this.claim.setCurrencyOfAmount(this.genericForm.get('estAmountLossCurrency').value);

            if(this.isAgent) {
                this.claim.setDateOfLoss(super.getValueInComponent('dateOfLoss'));
            }
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProgress(1);
            this.sideMenuService.emitComplete('finishGenericClaimDetail');
            this.router.navigate(["/claimform/genericUploadDocument"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    showModal() {
        console.log('showModel');
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    validateForm(): boolean {
        return super.validateForm(this.genericForm);
    }

    pushGAView() {
        let channel = this.claim.getCountry();
        if (channel === 'HKG' && this.claim.getIsManulife()){
            channel = 'Manulife';
        } else if(channel  == "HGI") {
            channel = "QGI";
        }

        (<any>window).dataLayer.push({
            'pageStep': 'Claim Details',
            'Channel': channel,
            'vPath': '/claim-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 3}
                }
            }
        });
    }
}
