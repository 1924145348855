import {Injectable} from "@angular/core";
import {DomesticInfoPlan} from "../model/domestic-helper/domestic-info-plan.model";
import {MotorCoverInfo} from '../model/motor/motor-cover-info.model';
import {ClaimTypes} from '../model/claim-type.model';

@Injectable()
export class PlanInfoService {

    domesticHksiPlanList: DomesticInfoPlan[] = [
        {
            "planCode": "A1",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "Yes",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "Yes",
            "physiotherapy": "Yes",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "A2",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "Yes",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "Yes",
            "physiotherapy": "Yes",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "A5",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "Yes",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "Yes",
            "physiotherapy": "Yes",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "A6",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "Yes",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "Yes",
            "physiotherapy": "Yes",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "F1",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "Yes",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "F2",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "Yes",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "F3",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "No",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "F4",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "No",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "F5",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "Yes",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "F6",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "Yes",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "No",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "No",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "H1",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "H2",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "H5",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "H6",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "M1",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "M2",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "M3",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "M4",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "M5",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "M6",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "M7",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "M8",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "N0",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "No",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "N1",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "No",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "N2",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "N3",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "N4",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "N5",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "N6",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "Yes",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "Yes",
            "nonMemberOutpatientConsultation": "Yes",
            "labTestExtraMedicationCoPayment": "Yes"
        },
        {
            "planCode": "N7",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "Yes",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "Yes",
            "nonMemberOutpatientConsultation": "Yes",
            "labTestExtraMedicationCoPayment": "Yes"
        },
        {
            "planCode": "N8",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "Yes",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "Yes",
            "nonMemberOutpatientConsultation": "Yes",
            "labTestExtraMedicationCoPayment": "Yes"
        },
        {
            "planCode": "N9",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "Yes",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "Yes",
            "nonMemberOutpatientConsultation": "Yes",
            "labTestExtraMedicationCoPayment": "Yes"
        },
        {
            "planCode": "Q1",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "Yes",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "Yes",
            "physiotherapy": "Yes",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "Q2",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "Yes",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "Yes",
            "physiotherapy": "Yes",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "Q3",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "No",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "Q4",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "No",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "Q5",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "Yes",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "Yes",
            "physiotherapy": "Yes",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "Q6",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "Yes",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "Yes",
            "physiotherapy": "Yes",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "T1",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "No",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "T2",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "No",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "U1",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "Yes",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "U2",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "Yes",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "U3",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "No",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "U4",
            "catalogCode": "",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "No",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "No",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "No",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "U5",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "Yes",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "U6",
            "catalogCode": "",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "Yes",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        }
    ];

    domesticQgiPlanList: DomesticInfoPlan[] = [
        {
            "planCode": "CO",
            "catalogCode": "DMHE33",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "Yes",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "Yes",
            "accidentEmergencyService": "Yes",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "CO",
            "catalogCode": "DMHE41",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "Yes",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "Yes",
            "accidentEmergencyService": "Yes",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "CO",
            "catalogCode": "DMHE44",
            "clinicalExpenses": "Yes",
            "dentalExpenses": "Yes",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "Yes",
            "deathOrPermanentDisability": "Yes",
            "repatriation": "Yes",
            "claimQuestionReplacement": "Yes",
            "claimQuestionTemporary": "Yes",
            "helpersLiability": "Yes",
            "dishonestyOfHelperLoss": "Yes",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "Yes",
            "accidentEmergencyService": "Yes",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "BA",
            "catalogCode": "DMHE34",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "No",
            "repatriation": "Yes",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "BA",
            "catalogCode": "DMHE42",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "No",
            "repatriation": "Yes",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        },
        {
            "planCode": "BA",
            "catalogCode": "DMHE45",
            "clinicalExpenses": "No",
            "dentalExpenses": "No",
            "hospitalizationOfHelper": "Yes",
            "lossOfCashServiceAllowance": "No",
            "deathOrPermanentDisability": "No",
            "repatriation": "Yes",
            "claimQuestionReplacement": "No",
            "claimQuestionTemporary": "No",
            "helpersLiability": "No",
            "dishonestyOfHelperLoss": "No",
            "employeeCompensation": "Yes",
            "otherClaims": "Yes",
            "cancerHeartDiseaseExtensionApplicability": "Yes",
            "generalPractitioner": "No",
            "accidentEmergencyService": "No",
            "registeredChineseMedicinePractitioner": "No",
            "physiotherapy": "No",
            "followUpConsultation": "No",
            "nonMemberOutpatientConsultation": "No",
            "labTestExtraMedicationCoPayment": "No"
        }
    ];

    hksiTravelPlanDelayedHrsList = [
        {
            "catalogCode": "PTRMCS",                          // Single MIL Optimum, Single MIL Value
            "planCode": "M7,M8,M9,1M,2M,3M",
            "allowedDelayedHours":  5,
            "isAnnual":  false
        },
        {
            "catalogCode": "PTRQTR",                          // Single Agency Premier
            "planCode": "P1,P2,P3",
            "allowedDelayedHours":  5,
            "isAnnual":  false
        },
        {
            "catalogCode": "PTRMAT",                         // Annual MIL Prime,Annual MIL Esteem,Annual MIL Optimum
            "planCode": "MA,MB,MC,MD,ME,MF,MG,MH,MI",
            "allowedDelayedHours":  5,
            "isAnnual":  true
        },
        {
            "catalogCode": "PTRQAT",                         // Annual Agency Economy, Annual Agency Superior, Annual Agency Premier
            "planCode": "1I,1C,1F,2I,2C,2F,3I,3C,3F",
            "allowedDelayedHours":  5,
            "isAnnual":  true
        },
        {
            "catalogCode": "PTRMCS",                         // Single MIL Value
            "planCode": "1M,2M,3M",
            "allowedDelayedHours":  8,
            "isAnnual":  false
        },
        {
            "catalogCode": "PTRMCS",                        // Single MIL Prime, Single MIL Esteem
            "planCode": "M1,M2,M3,M4,M5,M6",
            "allowedDelayedHours":  6,
            "isAnnual":  false
        },
        {
            "catalogCode": "PTRQTR",                        // Single Agency Economy, Single Agency Superior
            "planCode": "E4,E5,E6,S1,S2,S3",
            "allowedDelayedHours":  6,
            "isAnnual":  false
        }
    ];

    qgiTravelPlanDelayedHrsList = [
        {
            "catalogCode": "PITO71,PITO51,PITO21",          // QGI Single Area 1,2,3
            "planCode": "A1,B1,A2,B2,A3,B3",
            "areaCode": "1,2,3",
            "allowedDelayedHours": 4,
            "isAnnual":  false
        },
        {
            "catalogCode": "PITO71,PITO51,PITO21",          // QGI Annual Area 3
            "planCode": "A3,B3,C3,H3,J3",
            "areaCode": "3",
            "allowedDelayedHours": 6,
            "isAnnual":  true
        }
    ];

    motorCoverInfoPlanList: MotorCoverInfo[] = [
        {
            "isPolicyHolder": true,
            "contractType": "MVA",
            "riskType": "MPA",
            "coverType": "CO",
            "windscreen": "Yes",
            "collision": "Yes",
            "theft": "Yes"
        },
        {
            "isPolicyHolder": true,
            "contractType": "MVA",
            "riskType": "MPA",
            "coverType": "TP",
            "windscreen": "No",
            "collision": "Yes",
            "theft": "No"
        },
        {
            "isPolicyHolder": true,
            "contractType": "MVA",
            "riskType": "MPA",
            "coverType": "PD",
            "windscreen": "No",
            "collision": "Yes",
            "theft": "No"
        },
        {
            "isPolicyHolder": true,
            "contractType": "MVA",
            "riskType": "MPA",
            "coverType": "TF",
            "windscreen": "No",
            "collision": "Yes",
            "theft": "Yes"
        },
        {
            "isPolicyHolder": true,
            "contractType": "MVA",
            "riskType": "MVA",
            "coverType": "CO",
            "windscreen": "No",
            "collision": "Yes",
            "theft": "Yes"
        },
        {
            "isPolicyHolder": true,
            "contractType": "MVA",
            "riskType": "MVA",
            "coverType": "TP",
            "windscreen": "No",
            "collision": "Yes",
            "theft": "No"
        },
        {
            "isPolicyHolder": true,
            "contractType": "MVA",
            "riskType": "MVA",
            "coverType": "PD",
            "windscreen": "No",
            "collision": "Yes",
            "theft": "No"
        },
        {
            "isPolicyHolder": true,
            "contractType": "MVA",
            "riskType": "MVA",
            "coverType": "TL",
            "windscreen": "No",
            "collision": "Yes",
            "theft": "Yes"
        },
        {
            "isPolicyHolder": true,
            "contractType": "MVA",
            "riskType": "MVA",
            "coverType": "TF",
            "windscreen": "No",
            "collision": "Yes",
            "theft": "Yes"
        },
        {
            "isPolicyHolder": true,
            "contractType": "MVA",
            "riskType": "MVY",
            "coverType": "CO",
            "windscreen": "No",
            "collision": "Yes",
            "theft": "Yes"
        },
        {
            "isPolicyHolder": true,
            "contractType": "MVA",
            "riskType": "MVY",
            "coverType": "TP",
            "windscreen": "No",
            "collision": "Yes",
            "theft": "No"
        },
        {
            "isPolicyHolder": true,
            "contractType": "MVA",
            "riskType": "MVY",
            "coverType": "PD",
            "windscreen": "No",
            "collision": "Yes",
            "theft": "No"
        },
        {
            "isPolicyHolder": true,
            "contractType": "MVA",
            "riskType": "MVY",
            "coverType": "TF",
            "windscreen": "No",
            "collision": "Yes",
            "theft": "Yes"
        },
        {
            "isPolicyHolder": false,
            "contractType": "MVA",
            "riskType": "MPA",
            "coverType": "CO",
            "windscreen": "Yes",
            "collision": "No",
            "theft": "No"
        }
    ];

    qgiPlanList = [
        {
            "catalogCode": "PITO71",
            "planCode": "A1,B1",
            "areaCode": 1,
            "periodOfInsurance": "!0"
        },
        {
            "catalogCode": "PITO71",
            "planCode": "A2,B2",
            "areaCode": 2,
            "periodOfInsurance": "!0"
        },
        {
            "catalogCode": "PITO71",
            "planCode": "A3,B3",
            "areaCode": 3,
            "periodOfInsurance": "!0"
        },
        {
            "catalogCode": "PITO51",
            "planCode": "A1,B1",
            "areaCode": 1,
            "periodOfInsurance": "!0"
        },
        {
            "catalogCode": "PITO51",
            "planCode": "A2,B2",
            "areaCode": 2,
            "periodOfInsurance": "!0"
        },
        {
            "catalogCode": "PITO51",
            "planCode": "A3,B3",
            "areaCode": 3,
            "periodOfInsurance": "!0"
        },
        {
            "catalogCode": "PITO21",
            "planCode": "A1",
            "areaCode": 1,
            "periodOfInsurance": "!0"
        },
        {
            "catalogCode": "PITO21",
            "planCode": "A2",
            "areaCode": 2,
            "periodOfInsurance": "!0"
        },
        {
            "catalogCode": "PITO21",
            "planCode": "A3",
            "areaCode": 3,
            "periodOfInsurance": "!0"
        },
        {
            "catalogCode": "PITO71",
            "planCode": "A3,B3,C3,H3,J3",
            "areaCode": 3,
            "periodOfInsurance": "0"
        },
        {
            "catalogCode": "PITO51",
            "planCode": "A3,B3,C3,H3,J3",
            "areaCode": 3,
            "periodOfInsurance": "0"
        },
        {
            "catalogCode": "PITO21",
            "planCode": "A3,C3,H3,J3",
            "areaCode": 3,
            "periodOfInsurance": "0"
        }
    ];

    personalAccidentCoverageList = [
        {
            "country": "HKG",
            "claimType": ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES],
            "product": "PAN",
            "coverCode": "09"
        },
        {
            "country": "HKG",
            "claimType": ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES],
            "product": "PAN",
            "coverCode": "A2"
        },
        {
            "country": "HKG",
            "claimType": ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES],
            "product": "PAN",
            "coverCode": "B3"
        },
        {
            "country": "HKG",
            "claimType": ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES],
            "product": "PAN",
            "coverCode": "CV"
        },
        {
            "country": "HKG",
            "claimType": ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES],
            "product": "PAN",
            "coverCode": "G4"
        },
        {
            "country": "HKG",
            "claimType": ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES],
            "product": "PAN",
            "coverCode": "M2"
        },
        {
            "country": "HKG",
            "claimType": ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES],
            "product": "PAN",
            "coverCode": "M3"
        },
        {
            "country": "HKG",
            "claimType": ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES],
            "product": "PAN",
            "coverCode": "P2"
        },
        {
            "country": "HKG",
            "claimType": ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES],
            "product": "PAN",
            "coverCode": "S3"
        },
        {
            "country": "HKG",
            "claimType": ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH],
            "product": "PAN",
            "coverCode": "08"
        },
        {
            "country": "HKG",
            "claimType": ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH],
            "product": "PAN",
            "coverCode": "A4"
        },
        {
            "country": "HKG",
            "claimType": ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH],
            "product": "PAN",
            "coverCode": "M7"
        },
        {
            "country": "HKG",
            "claimType": ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH],
            "product": "PAN",
            "coverCode": "P4"
        },
        {
            "country": "HKG",
            "claimType": ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH],
            "product": "PAN",
            "coverCode": "S4"
        },
        {
            "country": "HGI",
            "claimType": ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES],
            "product": "PAN",
            "coverCode": "A3"
        }
    ];

    hksiTravelSilverPlanList = [
        {
            "catalogCode": "PTRHBS",
            "planCodes": "HC,HD,HI,HJ",
        },
        {
            "catalogCode": "PTRHBA",
            "planCodes": "11,12,13,14",
        },
        {
            "catalogCode": "PTRMBS",
            "planCodes": "BC,BD,BI,BJ",
        },
        {
            "catalogCode": "PTRMBA",
            "planCodes": "15,16,17,18",
        },
        {
            "catalogCode": "PTRMTC",
            "planCodes": "BC,BD,BI,BJ",
        }
    ];

    getDomesticPlanInfoByCountry(country: string): DomesticInfoPlan[] {

       if(country.toLowerCase() == "hgi"){
           return this.domesticQgiPlanList
       } else {
           return this.domesticHksiPlanList;
       }
    }

    getTravelPlanDelayedHrsList(country: string) {
        if(country.toLowerCase() == "hgi"){
            return this.qgiTravelPlanDelayedHrsList;
        } else {
            return this.hksiTravelPlanDelayedHrsList;
        }
    }

    getMotorCoverInfoList(): MotorCoverInfo[] {
        return this.motorCoverInfoPlanList;
    }

    getQgiPlanList() {
        return this.qgiPlanList;
    }

    getPACoverageList() {
        return this.personalAccidentCoverageList;
    }

    getHksiTravelSilverPlanList() {
        return this.hksiTravelSilverPlanList;
    }

}
