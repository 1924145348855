import {Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../../services/side-menu.service";
import { HttpClient } from '@angular/common/http';
import {MultiClaimDocumentsComponent} from "../../../../multi-claim-documents/multi-claim-documents.component";
import { ClaimService } from "../../../../../services/claim.service";
import { DocumentHelperService } from "../../../../../services/document-helper.service";
import { TransactionInfoService } from "../../../../../services/transaction-info.service";
import { DocumentFormFactoryService } from "../../../../../services/document-form-factory.service";
import { ClaimTypes } from "../../../../../model/claim-type.model";
import { SpinnerService } from '../../../../../core/spinner/spinner.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import { DocumentField } from 'src/app/model/document-field';
import { Claim } from 'src/app/model/claim.model';
import {TranslateService} from "@ngx-translate/core";
import { UtilitiesService } from "src/app/utilities/utilities.service";
import {TransactionInfo} from '../../../../../model/transaction-info.model';

declare var $: any;
@Component({
    selector: 'app-upload-document',
    templateUrl: './upload-document.component.html',
    styleUrls: ['./upload-document.component.css']
})
export class UploadDocumentComponent extends MultiClaimDocumentsComponent implements OnInit {

    public upLoadFileModel: FormGroup;
    showTotalBox = false;
    unuploadedDocs: string[][] = [];
    doc = "DOC";
    isDocUploadComplete: Boolean = false;
    @ViewChild('alert_unuploaded_docs', {static: true}) docAlertModal: TemplateRef<any>;
    docAlertModalRef: any;
    requiredDocuments: DocumentField[];
    claim: Claim;
    listOfUnuploadedDocs: any;
    translateService: TranslateService;
    transactionInfo: TransactionInfo;
    constructor(private fb: FormBuilder,
        public sideMenuService: SideMenuService,
        private activatedRoute: ActivatedRoute,
        private router: Router, 
        claimService: ClaimService,
        documentHelperService: DocumentHelperService,
        transactionInfoService: TransactionInfoService,
        http: HttpClient,
        private translate: TranslateService,
        documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
                super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
                this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL]);
                this.claim = this.claimService.getClaim();
                this.translateService = this.translate;
                this.setupMultiClaimant();
                this.transactionInfo = transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {
        
        this.upLoadFileModel = this.fb.group(
            {
                airTicket: [],
                carrierReport: [],
                travelItinerary: [],
                extraTransCost: [],
                extraAccomCost: [],
                otherItems: this.fb.array([this.createItem()])

            });

            super.ngOnInit();
            //Modify required documents upload
            this.requiredDocuments = this.formBuilder.getRequiredDocuments();
            this.pushGATravelDocumentView();
    }

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: [],
        });
    }

    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

    goBack() {
        this.router.navigate([this.transactionInfo.getRoute(0, 3, false)?.url], {
            relativeTo: this.activatedRoute
        })
    }

    goToNext() {
        this.listOfUnuploadedDocs = "";
        if(this.verifySTPUploadedDocs()){
            this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 3});
            this.sideMenuService.emitProgress(1);
            this.router.navigate(["/claimform/moreClaimType"], {
                relativeTo: this.activatedRoute
            });
        }else{
            this.showDocAlertModal();
        }
      
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    verifySTPUploadedDocs(){
        // not applicable for MYS
        if (this.claim.getCountry() === 'MYS') {
            return true;
        } else {
            // verify required documents for stp
            this.verifyUploadedDocsPrimary();
            if(this.claim.getClaimantList().length > 0){
                this.verifyUploadedDocsOther();
            }
            return this.unuploadedDocs.length < 1 && this.claim.getDocumentForm()?.documents?.length > 0;
        }
    }


    verifyUploadedDocsPrimary(){
            const documentsDetails = this.requiredDocuments;
            let uploadedDocuments = this.claim.getDocumentForm().documents;

            // we check all required document if uploaded
            for (let documentsDetailsValue of documentsDetails) {

                if (documentsDetailsValue.stpRequired) {

                    //check if there are already documents uploaded
                    let docUploaded: boolean = false;
                    if (uploadedDocuments.length < 1) {
                        this.unuploadedDocs.push([documentsDetailsValue.id, documentsDetailsValue.description]);

                    } else {
                        // check if there was an uploaded document for stp required document by documentsDetailsValue.id
                        for (let uploadedDoc of uploadedDocuments) {
                            //if required documents uploaded we skip then proceed to check the other details
                            if(uploadedDoc.docSubTypeId.startsWith(documentsDetailsValue.id) && uploadedDoc.docClaimType == this.getCurrentClaimType() &&
                                !uploadedDoc.claimant){
                                docUploaded = true;
                                break;
                            }
                        }

                        if(docUploaded == false){
                            this.unuploadedDocs.push([documentsDetailsValue.id, documentsDetailsValue.description]);
                        }
                    }

                }
            }
    }


    verifyUploadedDocsOther(){
        const documentsDetails = this.requiredDocuments;
        let uploadedDocuments = this.claim.getDocumentForm().documents;

        let self = this;
        this.claim.getClaimantList().forEach(function (claimantItem , index) {
            let docUploaded: boolean = false;
            let newClaimantName: string ='none';
            let hasRCPTPPAID: boolean = false;
            let docRCPTPPAIDCount = 0;
            let hasRCPTADDTL: boolean = false;
            let docRCPTADDTLCount = 0;
            newClaimantName = claimantItem.getClaimantIdentifier();

            for (let documentsDetailsValue of documentsDetails) {

                if (documentsDetailsValue.stpRequired) {
                    let docUploaded: boolean = false;

                    if (documentsDetailsValue.id.startsWith("RCPTPPAID")) {
                        hasRCPTPPAID = true;
                    }

                    if (documentsDetailsValue.id.startsWith("RCPTADDTL")) {
                        hasRCPTADDTL = true;
                    }

                    for (let uploadedDoc of uploadedDocuments) {
                        if(uploadedDoc.claimant != null){
                            let uploadClaimantName: string = uploadedDoc.claimant.getClaimantIdentifier();
                            if(!UtilitiesService.isNullOrUndefined(newClaimantName) && !UtilitiesService.isNullOrUndefined(uploadClaimantName)){
                                if(newClaimantName == uploadClaimantName){
                                    if(uploadedDoc.docSubTypeId.startsWith(documentsDetailsValue.id)){
                                        if (uploadedDoc.docSubTypeId.startsWith("RCPTPPAID")) {
                                            docRCPTPPAIDCount++;
                                        }
                                        if (uploadedDoc.docSubTypeId.startsWith("RCPTADDTL")) {
                                            docRCPTADDTLCount++;
                                        }
                                        docUploaded = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }

                    if(docUploaded == false && !documentsDetailsValue.id.startsWith("RCPTPPAID") && !documentsDetailsValue.id.startsWith("RCPTADDTL")){
                        self.unuploadedDocs.push([documentsDetailsValue.id, documentsDetailsValue.description]);
                    }
                }
            }

            if (hasRCPTPPAID && docRCPTPPAIDCount == 0){
                self.unuploadedDocs.push(["RCPTPPAID", "pageSummaryClaim.claimItem.sectionTD.subSectionSD.receiptPrepaidCosts"]);
            }

            if (hasRCPTADDTL && docRCPTADDTLCount == 0){
                self.unuploadedDocs.push(["RCPTADDTL", "pageSummaryClaim.claimItem.sectionTD.subSectionSD.receiptAdditionalCosts"]);
            }

        });
    }

    showDocAlertModal() {
        this.getSTPUnuploadedDocs();
         this.docAlertModalRef = this.modalService.open(this.docAlertModal, {size: 'lg',
             backdrop: 'static',
             keyboard: false
         });
    }

    getSTPUnuploadedDocs() {
        //remove redundant doc file
        let copyUnuploadedDocs: string[][]  = [];
        let carrierReportCount = 0;
        let airticketCount = 0;
        let rcptPaidCount = 0;
        let rcptAddtlCount = 0;

        this.unuploadedDocs.forEach(function (unuploaded, index){
            if(unuploaded[0].startsWith("CARRIERREPORT") && carrierReportCount == 0){
                copyUnuploadedDocs.push([unuploaded[0], unuploaded[1]]);
                carrierReportCount++;
            }else if(unuploaded[0].startsWith("AIRTICKET") && airticketCount == 0){
                copyUnuploadedDocs.push([unuploaded[0], unuploaded[1]]);
                airticketCount++;
            }else if(unuploaded[0].startsWith("RCPTPPAID")){
                rcptPaidCount++;
            }else if(unuploaded[0].startsWith("RCPTADDTL")){
                rcptAddtlCount++;
            }
        });


        if (rcptPaidCount >= 2) {
            copyUnuploadedDocs.push(["RCPTPPAID", "pageSummaryClaim.claimItem.sectionTD.subSectionSD.receiptPrepaidCosts"]);
        }
        if (rcptAddtlCount >= 2) {
            copyUnuploadedDocs.push(["RCPTADDTL", "pageSummaryClaim.claimItem.sectionTD.subSectionSD.receiptAdditionalCosts"]);
        }

        // set unuploaded documents to display in alert message
        if(copyUnuploadedDocs.length >= 1){

            let totalLength = copyUnuploadedDocs.length;
            let maxSize = copyUnuploadedDocs.length -1;
            let self = this;
            let currentLanguage = this.translate.currentLang;
            copyUnuploadedDocs.forEach(function (description, index) {
                self.translate.stream(description[1]).subscribe((res:string) => {
                    if(self.listOfUnuploadedDocs == null || self.listOfUnuploadedDocs == "" || self.listOfUnuploadedDocs === undefined){
                        if(index > 0 || totalLength > 1){
                            self.listOfUnuploadedDocs = res + ", ";
                        }else{
                            if(currentLanguage == 'zh' || currentLanguage == 'cn'){
                                self.listOfUnuploadedDocs = res + "。";
                            }else{
                                self.listOfUnuploadedDocs = res + ".";
                            }
                        }
                    }else if(index == maxSize){
                        if(currentLanguage == 'zh' || currentLanguage == 'cn'){
                            self.listOfUnuploadedDocs += (res + "。");
                        }else{
                            self.listOfUnuploadedDocs += (res + ".");
                        }
                    }else{
                        self.listOfUnuploadedDocs += (res + ", ");
                    }
                });

            });
        }
        this.unuploadedDocs = [];
    }

    continueToNext(){
        this.sideMenuService.emitProgress(1);
        this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 3});
        this.router.navigate(["/claimform/moreClaimType"], {
            relativeTo: this.activatedRoute
        });
        this.docAlertModalRef.close();
    }

    //START - Google Analytics
    pushGATravelDocumentView() {
        (<any>window).dataLayer.push({
            'pageStep': 'Travel Delay – Document Upload',
            'vPath': '/claim/travel-delay/documents',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 6},	//Step6-DocumentUpload
                }
            }

        });
    }
    //END - Google Analytics
}

